import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Jumbotron from "react-bootstrap/Jumbotron";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

import { Link, useParams } from "react-router-dom";

import { useAuthState } from "../../../../../providers/AuthState";

import { db, functions } from "../../../../../config/firebase";

function SuperUserOrganizationSkillsCards({ organization, orgId }) {
  const { superuser } = useAuthState();
  const [showSkillsModal, setShowSkillsModal] = useState(false);

  const [systemWideSkills, setSystemWideSkills] = useState([]);
  const [activeSkillPath, setActiveSkillPath] = useState("");

  useEffect(() => {
    db.collection("paths")
      // .where("status", "==", "Available")
      .get()
      .then(function (docs) {
        let skills = [];
        docs.forEach(function (doc) {
          let skill = { ...doc.data(), ref: doc.ref };
          skills.push(skill);
        });
        setSystemWideSkills(skills);
      });
  }, [orgId]);

  function isOrganizationSkillActive(skill) {
    if (organization.skills) {
      return (
        organization.skills.filter((s) => {
          return s.code === skill.code;
        }).length > 0
      );
    }
    return false;
  }

  function handleShowSkillsModal() {
    setShowSkillsModal(true);
  }

  function handleCloseSKillsModal() {
    setShowSkillsModal(false);
  }

  function addSkillToOrganization(skill) {
    //console.log("[SKILL] ", skill);
    organization.addSkill(skill);
  }

  function removeSkillFromOrganization(skill) {
    /* TODO: Fix this ugly thing */
    let filtered = organization.skills.filter((s) => {
      return s.code === skill.code;
    });

    organization.deleteSkill(filtered[0]);
  }

  return (
    <Row>
      <Col>
        <Row>
          <Col xs={12}>
            <div
              style={{
                marginBottom: "10px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
              }}
            >
              <Row>
                <Col xs={6}>
                  <h5
                    className="text-primary"
                    style={{
                      letterSpacing: "-.02em",
                      fontWeight: "600",
                    }}
                  >
                    Organization Skill Paths
                  </h5>
                </Col>
                <Col xs={6}>
                  <div className="float-right">
                    {activeSkillPath !== "" && (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        style={{ marginBottom: "5px" }}
                        onClick={() => {
                          setActiveSkillPath("");
                        }}
                      >
                        View Skills
                      </Button>
                    )}{" "}
                    {superuser ? (
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="font-weight-bold"
                        style={{ marginBottom: "5px" }}
                        onClick={handleShowSkillsModal}
                      >
                        Edit Skills
                      </Button>
                    ) : (
                        ""
                      )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {activeSkillPath === "" && (
          <Row style={{ marginBottom: "15px", minHeight: "450px" }}>
            {Array.isArray(organization.skills) &&
              organization.skills.map((skill, index) => {
                let objective;
                if (skill.objective.length > 80) {
                  objective = skill.objective.substring(0, 80) + "...";
                } else {
                  objective = skill.objective;
                }
                return (
                  <Col xs={4} key={skill.name}>
                    <SkillCard
                      cardTitle={skill.name}
                      cardCode={skill.code}
                      cardObjective={objective}
                      isSuperUser={superuser}
                      setActiveSkillPath={setActiveSkillPath}
                      skillPath={skill}
                      key={skill.name}
                      organization={organization}
                    />
                  </Col>
                );
              })}
            {organization.skills ? (
              organization.skills.length === 0 ? (
                <Col xs={12}>
                  <Jumbotron>
                    <h1>No Skills Added Yet</h1>
                    <p>
                      To start adding skills, please contact your Account
                      Representative.
                    </p>
                  </Jumbotron>
                </Col>
              ) : (
                  ""
                )
            ) : (
                ""
              )}
            {/* <Col xs={4}>
            <SkillCard
              cardTitle="Customer Success Pillars"
              isSuperUser={superuser}
            />
          </Col>
          <Col xs={4}>
            <SkillCard
              cardTitle="Sales Success Pillars"
              isSuperUser={superuser}
            />
          </Col>
          <Col xs={4}>
            <SkillCard
              cardTitle="Account Success Pillars"
              isSuperUser={superuser}
            />
          </Col> */}
          </Row>
        )}
      </Col>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={showSkillsModal}
        onHide={handleCloseSKillsModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Skill Paths
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginBottom: "15px" }}>
            {Array.isArray(systemWideSkills) &&
              systemWideSkills.map((skill, index) => {
                let objective;
                if (skill.objective.length > 80) {
                  objective = skill.objective.substring(0, 80) + "...";
                } else {
                  objective = skill.objective;
                }
                return (
                  <Col
                    xs={4}
                    style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    key={skill.name}
                  >
                    <Card>
                      <Card.Body>
                        <div className="card-title h6 font-weight-bold">
                          {skill.name}
                        </div>
                        <Card.Text style={{ minHeight: "80px" }}>
                          {objective}
                        </Card.Text>
                        {isOrganizationSkillActive(skill) ? (
                          <Button
                            variant="outline-danger"
                            onClick={() => {
                              removeSkillFromOrganization(skill);
                            }}
                          >
                            Remove
                          </Button>
                        ) : (
                            <Button
                              variant="outline-primary"
                              onClick={() => {
                                addSkillToOrganization(skill);
                              }}
                            >
                              Add
                            </Button>
                          )}
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseSKillsModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}

function SkillCard({
  cardTitle,
  cardCode,
  cardObjective,
  isSuperUser,
  setActiveSkillPath,
  skillPath,
  organization,
}) {
  const [showOptionsModal, setShowOptionsModal] = useState(false);

  function copySkill() {
    console.log("---COPYING SKILL");
    let copySkillToOrg = functions.httpsCallable("orgSkills-copySkillToOrg");
    let skillPathId = skillPath.id;
    copySkillToOrg({
      skillPathId: skillPathId,
      orgId: organization.id,
    }).then(() => {
      console.log(skillPath);
      skillPath.ref.update({ custom: true });
    });
  }

  function revertToTemplate() {
    skillPath.ref.update({ custom: false }).then(() => {
      db.doc(`/organizations/${organization.id}/paths/${skillPath.id}`).update({
        active: false,
      });
    });
  }

  function openEditOptionsModal() {
    setShowOptionsModal(true);
  }

  function closeEditOptionsModal() {
    setShowOptionsModal(false);
  }

  return (
    <Card
      style={{
        borderColor: skillPath.custom ? "#461987" : "rgba(0, 0, 0, 0.125)",
      }}
    >
      <Card.Body>
        <Card.Title>{cardTitle}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{cardCode}</Card.Subtitle>
        <Card.Text style={{ height: "50px" }}>{cardObjective}</Card.Text>
        <div className="float-right">
          {!skillPath.custom && !isSuperUser && (
            <DropdownButton
              size="sm"
              variant="outline-primary"
              as={ButtonGroup}
              title="Actions"
              id="bg-nested-dropdown"
            >
              <Dropdown.Item eventKey="1">
                <Link
                  to={`/organization/${organization.id}/content/skills/${cardCode}/1`}
                >
                  Manage Skill
                </Link>
              </Dropdown.Item>

              <Dropdown.Item eventKey="2" onClick={openEditOptionsModal}>
                Edit Options
              </Dropdown.Item>
              <Dropdown.Item eventKey="3"
                href={`https://us-central1-metisphere-firebase.cloudfunctions.net/analytics-generateProgressCSV?pathId=${skillPath.id}&orgId=${organization.id}`}
                target="_blank"
                rel="noopener noreferrer"
                download
              // className="btn btn-outline-primary btn-sm"
              >Export Progress
              </Dropdown.Item>
            </DropdownButton>
          )}
          {(isSuperUser || skillPath.custom) && (
            <DropdownButton
              size="sm"
              variant="outline-primary"
              as={ButtonGroup}
              title="Actions"
              id="bg-nested-dropdown"
            >
              {!skillPath.custom && isSuperUser && (
                <Dropdown.Item eventKey="1" onClick={copySkill}>
                  Copy to Organization
                </Dropdown.Item>
              )}
              {skillPath.custom && (
                <Dropdown.Item eventKey="2">
                  <Link
                    to={`/organization/${organization.id}/skills/${cardCode}`}
                  >
                    Customize
                  </Link>
                </Dropdown.Item>
              )}
              {skillPath.custom && isSuperUser && (
                <Dropdown.Item eventKey="3" onClick={revertToTemplate}>
                  Revert to Template
                </Dropdown.Item>
              )}
              {!skillPath.custom && (
                <Dropdown.Item eventKey="4">
                  <Link
                    to={`/organization/${organization.id}/content/skills/${cardCode}/1`}
                  >
                    Manage Skill
                  </Link>
                </Dropdown.Item>
              )}
              <Dropdown.Item eventKey="5" onClick={openEditOptionsModal}>
                Edit Options
              </Dropdown.Item>
              <Dropdown.Item eventKey="6"
                href={`https://us-central1-metisphere-firebase.cloudfunctions.net/analytics-generateProgressCSV?pathId=${skillPath.id}`}
                target="_blank"
                rel="noopener noreferrer"
                download
              // className="btn btn-outline-primary btn-sm"
              >Export Progress
              </Dropdown.Item>
            </DropdownButton>
          )}
        </div>
        {/* {isSuperUser ? <Button variant="danger">Remove</Button> : ""} */}
      </Card.Body>
      <SkillOptionModal
        showModal={showOptionsModal}
        handleClose={closeEditOptionsModal}
        skillPath={skillPath}
      />
    </Card>
  );
}

function SkillOptionModal({ showModal, handleClose, skillPath }) {
  const [contentApprovalGroup, setContentApprovalGroup] = useState(
    skillPath.wisdomApprovalGroup ? skillPath.wisdomApprovalGroup.id : ""
  );

  const [stepsPerStage, setStepsPerStage] = useState([]);
  const [smeGroups, setSMEGroups] = useState([]);
  const [experienceForWisdom, setExperienceForWisdom] = useState({});
  const [enableAll, setEnableAll] = useState(false);

  const { orgId } = useParams();

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent && !skillPath.custom) {
      db
        .doc(`paths/${skillPath.id}`)
        .get()
        .then((skillPathDoc) => {
          const stages = skillPathDoc.data().stages;

          const stepPerStage = [];
          const experienceForWisdomMap = {};
          stages.forEach((stage) => {
            const stageToStep = { name: stage.name, steps: [] };
            stage.steps.forEach((step) => {
              stageToStep.steps.push({
                name: step.name,
                id: step.id,
                experienceForWisdom:
                  skillPath.stepExperienceForWisdom[step.id] ?? false,
              });
              experienceForWisdomMap[step.id] = skillPath.stepExperienceForWisdom[step.id] ?? false;
            });

            stepPerStage.push(stageToStep);
          });
          setStepsPerStage(stepPerStage);
          setExperienceForWisdom(experienceForWisdomMap);
        });
    }

    return () => {
      isCurrent = false;
    };
  }, [skillPath]);

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      db.collection(`/organizations/${orgId}/approvals`)
        .get()
        .then((docs) => {
          const groups = [];
          docs.forEach((doc) => {
            groups.push({
              label: doc.data().name,
              id: doc.id,
            });
          });

          setSMEGroups(groups);
        });
    }

    return () => {
      isCurrent = false;
    }

  }, [orgId]);

  function getSMEGroupName(groupId) {
    const groupObj = smeGroups.find((group) => {
      return group.id === groupId;
    });

    return groupObj.label ?? "";
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    const contentApprovalGroupName = getSMEGroupName(contentApprovalGroup);

    const wisdomApprovalGroupDoc = {
      name: contentApprovalGroupName,
      id: contentApprovalGroup,
    };

    skillPath.ref
      .update({ wisdomApprovalGroup: wisdomApprovalGroupDoc, stepExperienceForWisdom: experienceForWisdom })
      .then(() => {
        console.log("Wisdom group saved successfully");
      })
      .catch((err) => {
        console.log(
          "There was an error updating the wisdom approval group",
          err
        );
      });

    handleClose();
  }

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Path Options: {skillPath.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col md={6}>
              <Form.Group controlId="smeGroup">
                <Form.Label>Content Approval Group</Form.Label>
                <Form.Control
                  as="select"
                  value={contentApprovalGroup}
                  onChange={(e) => {
                    setContentApprovalGroup(e.target.value);
                  }}
                >
                  <option></option>
                  {smeGroups.map((smeGroup) => {
                    return (
                      <option value={smeGroup.id}>{smeGroup.label}</option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Row>
          <hr />
          <Form.Row>
            <Col>
              <div style={{ fontSize: '1.1em' }}>Give experience credit for accepted wisdom</div>
              <div style={{ marginLeft: "10px" }}>
                <Form.Row>
                  <Form.Group controlId="enableAll">
                    <Form.Check
                      type="switch"
                      label="Enable All"
                      checked={enableAll}
                      onChange={(e) => {
                        const newEnableAllVal = !enableAll;
                        setEnableAll(newEnableAllVal);

                        const experienceForWisdomMap = { ...experienceForWisdom };
                        for (const stepId in experienceForWisdomMap) {
                          experienceForWisdomMap[stepId] = newEnableAllVal;
                        }
                        setExperienceForWisdom(experienceForWisdomMap);
                      }}
                    />
                  </Form.Group>
                </Form.Row></div>
              {stepsPerStage.map((stage) => {
                return (
                  <div>
                    <u>{stage.name}</u>
                    <div style={{ marginLeft: "10px" }}>
                      {stage.steps.map((step) => {
                        return (
                          <Form.Row>
                            <Form.Group controlId={step.id}>
                              <Form.Check
                                type="switch"
                                label={step.name}
                                checked={experienceForWisdom[step.id]}
                                onChange={(e) => {
                                  const experienceForWisdomMap = { ...experienceForWisdom, [step.id]: !experienceForWisdom[step.id] };
                                  setExperienceForWisdom(experienceForWisdomMap);
                                }}
                              />
                            </Form.Group>
                          </Form.Row>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Row>
                <Col>
                  <div className="float-right">
                    <Button variant="outline-primary" type="submit">
                      Save
                    </Button>{" "}
                    <Button variant="outline-danger" onClick={handleClose}>
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Form.Row>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

//   function UserPathProgress({ skillPathId, orgId }) {
//   const [skillPath, setSkillPath] = useState(null);
//   const [users, setUsers] = useState([]);
//   const [currentStage, setCurrentStage] = useState(0);
//   const [showExperienceModal, setShowExperienceModal] = useState(false);
//   const [experiencePath, setExperiencePath] = useState({ stepId: "" });
//   const [experiences, setExperiences] = useState([]);

//   function handleShowExperienceModal() {
//     setShowExperienceModal(true);
//   }

//   function handleCloseExperienceModal() {
//     setShowExperienceModal(false);
//   }

//   useEffect(() => {
//     if (skillPathId) {
//       db.doc(`paths/${skillPathId}`)
//         .get()
//         .then((doc) => {
//           const skillPath = {
//             ...doc.data(),
//             id: doc.id,
//             ref: doc.ref,
//           };
//           setSkillPath(skillPath);
//           return skillPath;
//         })
//         .then(() => {
//           db.collection(`organizations/${orgId}/users`)
//             .get()
//             .then((snapshotDocs) => {
//               const orgUserDocs = [];
//               snapshotDocs.forEach((doc) => {
//                 orgUserDocs.push({ ...doc.data(), id: doc.id, ref: doc.ref });
//               });

//               setUsers(orgUserDocs);
//             });
//         });
//     }
//   }, [skillPathId, orgId]);

//   useEffect(() => {
//     if (experiencePath.stepId) {
//       db.collection(
//         `${experiencePath.user.ref.path}/paths/${skillPath.code}/experiences`
//       )
//         .where("step.id", "==", experiencePath.stepId)
//         .get()
//         .then((snapshotDocs) => {
//           const expDocs = [];
//           snapshotDocs.forEach((doc) => {
//             expDocs.push({ ...doc.data() });
//           });
//           setExperiences(expDocs);
//         });
//     }
//   }, [experiencePath.stepId, experiencePath.user.ref.path, skillPath.code]);

//   function userHasPath(user) {
//     return user.paths && user.paths[skillPath.code];
//   }

//   function getStepStatusForUser(user, step) {
//     let status = "-";
//     if (
//       userHasPath(user) &&
//       user.paths[skillPath.code].progression.stageCompletion[
//         skillPath.stages[currentStage].id
//       ].completedSteps.includes(step.id)
//     ) {
//       status = "A";
//     } else if (
//       userHasPath(user) &&
//       user.paths[skillPath.code].progression.stageCompletion[
//         skillPath.stages[currentStage].id
//       ].submittedSteps.includes(step.id)
//     ) {
//       status = "S";
//     }

//     return status;
//   }

//   return (
//     skillPath && (
//       <div>
//         <h2>
//           {skillPath.code} - {skillPath.name}
//         </h2>
//         <Form.Group controlId="stage">
//           <Form.Label>Stage</Form.Label>
//           <Form.Control
//             as="select"
//             onChange={(e) => {
//               setCurrentStage(e.target.value);
//             }}
//             value={currentStage}
//           >
//             {skillPath.stages.map((stage, ind) => {
//               return <option value={ind}>{stage.name}</option>;
//             })}
//           </Form.Control>
//         </Form.Group>
//         <Table>
//           <thead>
//             <tr>
//               <td>Name</td>
//               {skillPath.stages[currentStage].steps.map((step) => {
//                 return <td>{step.name}</td>;
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {users.map((user) => {
//               return (
//                 <tr>
//                   <td>{user.displayName}</td>
//                   {skillPath.stages[currentStage].steps.map((step) => {
//                     return (
//                       <td>
//                         <span
//                           style={{
//                             cursor: "pointer",
//                             color:
//                               getStepStatusForUser(user, step) === "S"
//                                 ? "#007bff"
//                                 : getStepStatusForUser(user, step) === "A"
//                                 ? "#28a745"
//                                 : "gray",
//                           }}
//                           onClick={() => {
//                             setExperiencePath({ stepId: step.id, user: user });
//                             handleShowExperienceModal();
//                           }}
//                         >
//                           {getStepStatusForUser(user, step)}
//                         </span>
//                       </td>
//                     );
//                   })}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </Table>
//         {experiences.length > 0 && (
//           <Modal
//             size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             show={showExperienceModal}
//             onHide={handleCloseExperienceModal}
//             centered
//           >
//             <Modal.Header closeButton>
//               <Modal.Title id="contained-modal-title-vcenter">
//                 User Experience
//               </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               {experiences.map((experience) => {
//                 return (
//                   <Row
//                     style={{
//                       marginBottom: "15px",
//                     }}
//                   >
//                     <Col>
//                       <strong>Submitted</strong>
//                       <p>
//                         {new Date(
//                           experience.dateSubmitted.seconds * 1000
//                         ).toLocaleString()}
//                       </p>
//                       <strong>Status</strong>
//                       <p>{experience.status}</p>
//                       {experience.status === "Approved" && (
//                         <div>
//                           <strong>Approver's Comments</strong>
//                           <p>{experience.approverComments}</p>
//                         </div>
//                       )}
//                       <strong>Experience</strong>
//                       <p>{experience.experienceLog}</p>
//                       <hr></hr>
//                     </Col>
//                   </Row>
//                 );
//               })}
//             </Modal.Body>
//             <Modal.Footer>
//               <Button onClick={handleCloseExperienceModal}>Close</Button>
//             </Modal.Footer>
//           </Modal>
//         )}
//       </div>
//     )
//   );
// }

export default SuperUserOrganizationSkillsCards;
