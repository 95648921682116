import React from "react";

function SubTitle({ title }) {
  return (
    <h6
      className="text-secondary"
      style={{ letterSpacing: "-.02em", fontWeight: "600" }}
    >
      {title}
    </h6>
  );
}

export default SubTitle;
