import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useParams, useRouteMatch } from "react-router-dom";

import { useSkillPathContext } from "./../providers";

import "./stage-list.styles.css";

function StageList({ userProgress }) {
  let { url } = useRouteMatch();
  let showNew = true;
  let urlStart = "/skills";

  const { skillPathId, skillStageSlug, orgId } = useParams();
  const { skillPath, addingContent } = useSkillPathContext();

  if (url.indexOf("user") > -1) {
    showNew = false;
    urlStart += "/user";
  } else if (orgId) {
    if (addingContent) {
      urlStart = `/organization/${orgId}/content/skills`;
    } else {
      urlStart = `/organization/${orgId}/skills`;
    }
  }

  function getStageClass(userProgress, curStage) {
    if (curStage.order + "" === skillStageSlug) {
      return "active";
    } else if (userProgress && userProgress.stages) {
      if (
        userProgress.stages[curStage.id] &&
        userProgress.stages[curStage.id].complete
      ) {
        return "completed";
      }
    } else {
      return "";
    }
  }

  return (
    skillPath && (
      <div>
        <Row>
          <Col>
            <h4 style={{ textAlign: "center" }}>Skill Stages</h4>
          </Col>
        </Row>
        <Row>
          {skillPath.stages.map((stage, ind) => {
            return (
              <Col key={stage.id} className="stage-nav">
                <Link to={`${urlStart}/${skillPathId}/${ind + 1}`}>
                  <div
                    className={`stage-nav-ele ${getStageClass(
                      userProgress,
                      stage
                    )}`}
                  >
                    {userProgress &&
                    userProgress.stages &&
                    userProgress.stages[ind] &&
                    userProgress.stages[ind].complete
                      ? // <svg
                        //   class="bi bi-check"
                        //   width="1em"
                        //   height="1em"
                        //   viewBox="0 0 16 16"
                        //   fill="currentColor"
                        //   xmlns="http://www.w3.org/2000/svg"
                        // >
                        //   <path
                        //     fill-rule="evenodd"
                        //     d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                        //   />
                        // </svg>
                        ind + 1 + " "
                      : ind + 1 + " "}
                  </div>

                  {ind < skillPath.stages.length - 1 || showNew ? (
                    <div className="line clearfix"></div>
                  ) : (
                    <div className="clearfix"></div>
                  )}
                </Link>
                <div>{stage.name}</div>
              </Col>
            );
          })}
          {showNew && (
            <Col className="stage-nav ">
              <Link to={`${urlStart}/${skillPath.code}/new`}>
                <div className="stage-nav-ele">+</div>
                <div className="clearfix"></div>
              </Link>
            </Col>
          )}
        </Row>
      </div>
    )
  );
}

export default StageList;
