import { api } from "../api";

export default class Organization {
  constructor(doc) {
    /* Fields */
    this.id = doc.id || "";
    this.name = doc.name || "";
    this.adminName = doc.adminName || "";
    this.adminEmail = doc.adminEmail || "";
    this.orgAdminId = doc.orgAdminId || "";
    this.status = doc.status || "";
    this.vendor = doc.vendor || false;
    this.domains = doc.domains || [];
    this.notes = doc.notes || "";
    this.createdBy = doc.createdBy || "";
    this.onboarding = doc.onboarding || true;
    this.platformLicenses = doc.platformLicenses || 0;
    this.logoPath = doc.logoPath || "";
    this.active = doc.active || "";
    this.lastActive = doc.lastActive || "";

    /* Collections */
    this.licenses = doc.licenses || [];
    this.users = doc.users || [];
    this.approvalGroups = doc.approvalGroups || [];
    this.skillPaths = doc.skillPaths || [];
    this.invites = doc.invites || [];
    this.users = doc.users || [];
    this.approvalGroups = doc.approvalGroups || [];
    this.skills = doc.skills || [];

    this.inviteErrors = [];

    return this;
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      name: this.name,
      adminName: this.adminName,
      adminEmail: this.adminEmail,
      orgAdminId: this.orgAdminId,
      status: this.status,
      vendor: this.vendor,
      domains: this.domains,
      notes: this.notes,
      createdBy: this.createdBy,
      platformLicenses: this.platformLicenses,
      logoPath: this.logoPath,
      active: this.active,
      lastActive: this.lastActive,
      onboarding: this.onboarding,
    };
  }

  toReducerFormat() { }

  save() {
    //console.log("Saving...");
    return api.organization.update(this.toFirestoreFormat());
  }

  get(orgId) {
    return api.organization.get(orgId);
  }

  deleteInvite(invite) {
    return api.organization.deleteInvite(this, invite);
  }

  getValidInvites(invites) {
    // let userEmails = this.users.map((o) => o["email"]);
    return invites.filter(this.checkInvite.bind(this));
  }

  getInviteErrors(invites) {
    let userEmails = this.users.map((o) => o["email"]);
    let inviteErrors = [];
    invites.filter(
      function (invite) {
        if (invite.displayName === "") {
          inviteErrors.push("The name is empty");
        }
        if (invite.email === "") {
          inviteErrors.push("The email is empty");
        }
        if (
          !this.domains.includes(
            invite.email
              .trim()
              .substring(invite.email.indexOf("@") + 1, invite.email.length)
          )
        ) {
          inviteErrors.push(
            "The email you have entered is not a valid domain!"
          );
        }
        if (userEmails.includes(invite.email)) {
          inviteErrors.push("This user already exists");
        }
        if (this.users.length >= this.licenses) {
          inviteErrors.push(
            "Your maximum user license count has been exceeded.  Please contact your Sales Representative to obtain more licenses."
          );
        }
        return inviteErrors === 0;
      }.bind(this)
    );
    return inviteErrors;
  }

  getTrialInviteErrors(invites) {
    let userEmails = this.users.map((o) => o["email"]);
    let inviteErrors = [];
    invites.filter(
      function (invite) {
        if (invite.displayName === "") {
          inviteErrors.push("The name is empty");
        }
        if (invite.email === "") {
          inviteErrors.push("The email is empty");
        }
        // if (
        //   !this.domains.includes(
        //     invite.email
        //       .trim()
        //       .substring(invite.email.indexOf("@") + 1, invite.email.length)
        //   )
        // ) {
        //   inviteErrors.push(
        //     "The email you have entered is not a valid domain!"
        //   );
        // }
        if (userEmails.includes(invite.email)) {
          inviteErrors.push("This user already exists");
        }
        return inviteErrors === 0;
      }.bind(this)
    );
    return inviteErrors;
  }

  checkInvite(invite) {
    let userEmails = this.users.map((o) => o["email"]);
    let valid = true;
    // if (
    //   !this.domains.includes(
    //     invite.email
    //       .trim()
    //       .substring(invite.email.indexOf("@") + 1, invite.email.length)
    //   )
    // ) {
    //   valid = false;
    // }
    // if (userEmails.includes(invite.email)) {
    //   valid = false;
    // }
    return valid;
  }

  setInvites(invites) {
    this.invites = invites;
  }

  setUsers(users) {
    this.users = users;
  }

  setApprovalGroups(approvalGroups) {
    this.approvalGroups = approvalGroups;
  }

  setSkills(skills) {
    this.skills = skills;
  }

  addSkill(skill) {
    return api.organization.addSkill(this.id, skill);
  }

  deleteSkill(skill) {
    return api.organization.deleteSkill(this.id, skill);
  }

  updateUserskills(user, skills) {
    //console.log("[CLASS USER SKILLS] ", user);
    //console.log("[CLASS USER SKILLS] ", skills);
    return api.organization.updateUserSkills(this.id, user.id, skills);
  }

  getInvites() {
    return api.organization.getInviteList(
      this.id,
      function (invites) {
        //console.log("[GET INVITES LIST TO OBJECT]", invites);
        //console.log("[GET INVITES BIND TO THIS]", this);
        this.invites = invites;
      }.bind(this)
    );
  }

  getInviteList() {
    return api.organization.getInvites(this.id).onSnapshot(function (snapshot) {
      let invites = [];
      snapshot.forEach(function (doc) {
        invites.push({ ...doc.data(), id: doc.id });
      });
      //console.log("[GETTING INVITE LIST] ", invites);
      this.invites = invites;
    });
  }

  processInvite(invite) {
    //console.log("[PROCESSING INVITES] ", invite);
    //console.log("[API INVITES] ", api.organization);
    let orgId = this.id;
    return api.organization
      .addInvite(orgId, invite.toFirestoreFormat())
      .then(this.resetInvites)
      .catch(this.catchInviteErrors);
  }

  resetInvites() {
    this.resetInviteErrors();
    this.resetInviteForm();
  }

  resetInviteForm() {
    this.invite.form = {
      displayName: "",
      email: "",
      isManager: "No",
      isSME: "No",
      claimed: false,
      userId: "",
    };
  }

  resetInviteErrors() {
    this.invite.errors = [];
  }

  catchInviteErrors() {
    //console.log("Error creating invite!");
  }

  addInvites(invites) {
    this.getValidInvites(invites).forEach(this.processInvite.bind(this));
    return;
  }

  getUser(user) {
    return api.organization.getUserById(this.id, user.id);
  }

  updateUser(user) {
    return api.organization.updateUserById(this.id, user.id, user);
  }
  getUserDisplayNameById(userId) {
    let userDisplayName = "";
    let currentUsers = this.users.filter(function (user) {
      return userId === user.id;
    });
    if (currentUsers.length > 0) {
      userDisplayName = currentUsers[0].displayName;
    }
    return userDisplayName;
  }

  updateAdministrator(orgAdmin) {
    return api.organization.updateOrganizationAdmin(
      orgAdmin.id,
      orgAdmin.displayName,
      orgAdmin.email
    );
  }

  getApprovalGroups() {
    return api.organization.getApprovalGroups(this.id);
  }
  getApprovalGroup(groupId) {
    return api.organization.getApprovalGroup(this.id, groupId);
  }
  updateApprovalGroup(group) {
    return api.organization.updateApprovalGroup(this.id, group);
  }
  createApprovalGroup(group) {
    return api.organization.createApprovalGroup(this.id, group);
  }

  completeOnboarding() {
    return api.organization.completeOnboarding(this.id);
  }

  static firebaseConvertor() {
    return this.toFirestoreFormat();
  }
}
