import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import UserSkillStepDetailLayout from "./user-skill-step-detail";
import ContentSkillStepLayout from "./content-skill-step";

import { useSkillPathContext } from "./../components/providers";

function UserSkillStepLayout() {
  let { path } = useRouteMatch();

  const { skillPath, addingContent } = useSkillPathContext();
  return (
    skillPath && (
      <div>
        {addingContent && (
          <Switch>
            <Route
              path={`${path}/:skillStepSlug`}
              component={ContentSkillStepLayout}
            />
            <Route path={`${path}`} component={ContentSkillStepLayout} />
          </Switch>
        )}
        {!addingContent && (
          <Switch>
            <Route
              path={`${path}/:skillStepSlug`}
              component={UserSkillStepDetailLayout}
            />
            <Route path={`${path}`} component={UserSkillStepDetailLayout} />
          </Switch>
        )}
      </div>
    )
  );
}

export default UserSkillStepLayout;
