import { db } from "../../../../config/firebase";

export function getOrgByAdminEmail(email) {
  //console.log("[GET ORG EMAIL] ", email);
  return db
    .collection("organizations")
    .where("adminEmail", "==", email)
    .limit(1)
    .get()
    .then(function (doc) {
      let org = undefined;
      //console.log("[ORG]", doc);
      if (doc.exists) {
        org = { ...doc.data(), id: doc.id };
      }
      return new Promise(function (resolve, reject) {
        resolve(org);
      });
    })
    .catch(function (err) {
      //console.log("[ORG ERR] ", err);
    });
}

export function getOrgByAdminId(id) {
  // //console.log("[GET ORG ID] ", id);
  return db
    .collection("organizations")
    .where("orgAdminId", "==", id)
    .where("claimed", "==", true)
    .limit(1)
    .get()
    .then(function (query) {
      let org = undefined;

      //console.log("[ORG DOCS] ", query);

      if (query.docs.length > 0) {
        let doc = query.docs[0];
        //console.log("[ORG]", doc);

        org = { ...doc.data(), id: doc.id };
      }
      return new Promise(function (resolve, reject) {
        resolve(org);
      });
    })
    .catch(function (err) {
      //console.log("[ORG ERR] ", err);
    });
}

export function getOrgUserProfile(orgId, userId) {
  //console.log("[GET ORG USER CONTEXT ID] ", orgId, userId);
  return db
    .collection(`organizations/${orgId}/users`)
    .doc(userId)
    .get()
    .then(function (doc) {
      let user = undefined;
      //console.log("[ORG]", doc);

      if (doc.exists) {
        user = { ...doc.data(), id: doc.id, ref: doc.ref };
      }
      return new Promise(function (resolve, reject) {
        resolve(user);
      });
    })
    .catch(function (err) {
      //console.log("[ORG ERR] ", err);
    });
}

export function getOrgContextByOrgId(orgId) {
  //console.log("[GET ORG USER CONTEXT ID] ", orgId);
  return db
    .collection("organizations")
    .doc(orgId)
    .get()
    .then(function (doc) {
      let org = undefined;
      //console.log("[ORG]", doc);

      if (doc.exists) {
        org = { ...doc.data(), id: doc.id };
      }
      return new Promise(function (resolve, reject) {
        resolve(org);
      });
    })
    .catch(function (err) {
      //console.log("[ORG ERR] ", err);
    });
}

export function getWisdomManagerForOrgSkills(orgId, userGroups) {
  return db.collection(`organizations/${orgId}/skills`).get().then(orgSkills => {
    const wisdomManagerForSkills = [];
    orgSkills.forEach(orgSkillDoc => {
      const orgSkill = orgSkillDoc.data();
      if (orgSkill.wisdomApprovalGroup && userGroups.some(group => { return group.id === orgSkill.wisdomApprovalGroup.id })) {
        wisdomManagerForSkills.push(orgSkill.id);
      }
    });
    return wisdomManagerForSkills;
  })

}

export function getOrgUsersById(orgId) {
  //console.log("[GET ORG USER CONTEXT ID] ", orgId);
  return db
    .collection(`organizations/${orgId}/users`)
    .orderBy("displayName", "asc")
    .get()
    .then(function (snapshot) {
      let orgUsers = [];
      //console.log("[ORG USERS CONTEXT]", snapshot);

      if (snapshot.size > 0) {
        snapshot.docs.forEach(function (doc) {
          let orgUser = { ...doc.data(), id: doc.id };
          orgUsers.push(orgUser);
        });
      }
      return new Promise(function (resolve, reject) {
        resolve(orgUsers);
      });
    })
    .catch(function (err) {
      //console.log("[ORG ERR] ", err);
    });
}

export function getApprovalGroupsByOrgId(orgId) {
  return db
    .collection(`organizations/${orgId}/approvals`)
    .get()
    .then(function (snapshot) {
      let orgApprovalGroups = [];
      console.log("[ORG APPROVAL GROUPS CONTEXT]", snapshot);

      if (snapshot.size > 0) {
        snapshot.docs.forEach(function (doc) {
          let orgApprovalGroup = { ...doc.data(), id: doc.id };
          orgApprovalGroups.push(orgApprovalGroup);
        });
      }
      return new Promise(function (resolve, reject) {
        resolve(orgApprovalGroups);
      });
    })
    .catch(function (err) {
      console.log("[ORG ERR] ", err);
    });
}

export function getApprovalGroupsByOrgIdAndUserId(orgId, userId) {
  console.log("[ORG APPROVAL GROUPS CONTEXT] ", orgId, userId);
  return db
    .collection(`organizations/${orgId}/approvals`)
    .where("approvers", "array-contains", userId)
    .get()
    .then(function (snapshot) {
      let orgApprovalGroups = [];
      console.log("[ORG APPROVAL GROUPS CONTEXT]", snapshot);

      if (snapshot.size > 0) {
        snapshot.docs.forEach(function (doc) {
          let orgApprovalGroup = { ...doc.data(), id: doc.id };
          orgApprovalGroups.push(orgApprovalGroup);
        });
      }
      return new Promise(function (resolve, reject) {
        resolve(orgApprovalGroups);
      });
    })
    .catch(function (err) {
      console.log("[ORG ERR] ", err);
    });
}

export function getOrgSkillPathsByOrganizationAndUserId(orgId, userId) {
  console.log(
    "[ORG APPROVAL GROUPS CONTEXT] ",
    `organizations/${orgId}/users/${userId}/paths`
  );
  return db
    .collection(`organizations/${orgId}/users/${userId}/paths`)
    .get()
    .then(function (snapshot) {
      let paths = [];
      console.log("[ORG PATH CONTEXT]", snapshot);

      if (snapshot.size > 0) {
        snapshot.docs.forEach(function (doc) {
          let path = { ...doc.data(), id: doc.id };
          paths.push(path);
        });
      }
      return new Promise(function (resolve, reject) {
        resolve(paths);
      });
    })
    .catch(function (err) {
      console.log("[PATH ERR] ", err);
    });
}

export function getAllOrgInvitesByUserId(user) {
  return db
    .collection("orgInvites")
    .where("orgAdminId", "==", user.uid)
    .where("claimed", "==", false)
    .limit(1)
    .get()
    .then(function (snapshot) {
      let orgs = [];
      snapshot.docs.forEach(function (doc) {
        let org = {
          ...doc.data(),
          id: doc.id,
        };
        if (org.status !== "TRIAL") {
          orgs.push(org);
        }
      });
      //console.log("ORGS ", orgs);
      return new Promise(function (resolve, reject) {
        resolve(orgs);
      });
    });
}

export function getAllOrgTrialInvitesByUserId(user) {
  return db
    .collection("orgInvites")
    .where("orgAdminId", "==", user.uid)
    .where("claimed", "==", false)
    .limit(1)
    .get()
    .then(function (snapshot) {
      let orgs = [];
      snapshot.docs.forEach(function (doc) {
        let org = {
          ...doc.data(),
          id: doc.id,
        };
        if (org.status === "TRIAL") {
          orgs.push(org);
        }
      });
      //console.log("ORGS ", orgs);
      return new Promise(function (resolve, reject) {
        resolve(orgs);
      });
    });
}

export function getAllOrgUserInvitesByUserId(org, user) {
  return db
    .collection(`organizations/${org.id}/invites`)
    .where("userId", "==", user.uid)
    .where("claimed", "==", false)
    .limit(1)
    .get()
    .then(function (snapshot) {
      console.log("SUCCESS: ", `organizations/${org.id}/invites`);
      let invites = [];
      snapshot.docs.forEach(function (doc) {
        let invite = {
          ...doc.data(),
          id: doc.id,
        };
        invites.push(invite);
      });
      //console.log("ORGS ", orgs);
      return new Promise(function (resolve, reject) {
        resolve(invites);
      });
    })
    .catch(function (error) {
      ///organizations/4moJbAaTt518F25HXLCS/users/hEcjGMtnd2e9r58Mi9TFWwSjafF2
      console.log("ERROR: ", error);
    });
}

export function markOrgUserInviteClaimed(org, user, invite) {
  return db
    .collection(`organizations/${org.id}/invites`)
    .doc(invite.id)
    .update({ claimed: true });
}

export function claimOrgInviteByUserId(user, currentOrgInvite) {
  return db
    .collection("orgInvites")
    .doc(currentOrgInvite.id)
    .update({ claimed: true })
    .then(function (docRef) {
      db.collection("organizations")
        .doc(currentOrgInvite.orgId)
        .update({ claimed: true })
        .then(function (docRef) {
          return new Promise(function (resolve, reject) {
            resolve(docRef);
          });
        });
    });
}

export default {
  getOrgByAdminEmail,
  getOrgByAdminId,
  getOrgUserProfile,
  getOrgContextByOrgId,
  getOrgUsersById,
  getApprovalGroupsByOrgId,
  getApprovalGroupsByOrgIdAndUserId,
  getOrgSkillPathsByOrganizationAndUserId,
  getAllOrgInvitesByUserId,
  getAllOrgTrialInvitesByUserId,
  getAllOrgUserInvitesByUserId,
  markOrgUserInviteClaimed,
  claimOrgInviteByUserId,
  getWisdomManagerForOrgSkills
};
