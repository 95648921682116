import { db } from "../../../../config/firebase";
import Organization from "../classes/organization";
import Invite from "../classes/invite";
import User from "../classes/user";
import ApprovalGroup from "../classes/approvalGroup";
import SkillPath from "../classes/skillPath";

function getOrganizationById(id, setDoc) {
  return db
    .collection("organizations")
    .doc(id)
    .onSnapshot((doc) => {
      setDoc(
        new Organization({
          ...doc.data(),
          id: doc.id,
        })
      );
    });
}

function getInviteList(id, setDoc) {
  return db
    .collection(`/organizations/${id}/invites`)
    .where("claimed", "==", false)
    .onSnapshot((snapshot) => {
      let invites = [];

      snapshot.docs.forEach(function (doc) {
        //console.log("[GET INVITE API ORG DATA] ", doc.data());

        let invite = new Invite({ ...doc.data(), id: doc.id });
        //console.log("[GET INVITE API ORG OBJECT ", invite);

        invites.push(invite);
      });
      setDoc(invites);
    });
}

function getUserList(id, setDoc) {
  return db
    .collection(`/organizations/${id}/users`)
    .orderBy("email", "asc")
    .onSnapshot((snapshot) => {
      let users = [];
      snapshot.docs.forEach(function (doc) {
        let user = new User({
          ...doc.data(),
          orgId: id,
          id: doc.id,
        });
        users.push(user);
      });
      setDoc(users);
    });
}

function getApprovalGroupsList(id, setDoc) {
  return db
    .collection(`/organizations/${id}/approvals`)
    .orderBy("name", "asc")
    .onSnapshot((snapshot) => {
      let approvalGroups = [];
      snapshot.docs.forEach(function (doc) {
        let group = new ApprovalGroup({ ...doc.data(), orgId: id, id: doc.id });
        approvalGroups.push(group);
      });
      setDoc(approvalGroups);
    });
}

function getSkillPaths(id, setDoc) {
  return db.collection(`/organizations/${id}/skills`).onSnapshot((snapshot) => {
    let skillPaths = [];
    snapshot.docs.forEach(function (doc) {
      let skill = new SkillPath({ ...doc.data(), orgId: id, ref: doc.ref });
      skillPaths.push(skill);
    });
    setDoc(skillPaths);
  });
}

export function get(orgId) {
  return db.collection("organizations").doc(orgId).get();
}

export function deleteInvite(organization, invite) {
  return db
    .collection(`organizations/${organization.id}/invites`)
    .doc(invite.id)
    .delete();
}

// export function getInviteList(orgId, setDoc) {
//   return db
//     .collection(`/organizations/${orgId}/invites`)
//     .onSnapshot((snapshot) => {
//       let invites = [];
//       //console.log("[GET INVITES LIST FROM DB]", snapshot.docs);
//       snapshot.docs.forEach(function (doc) {
//         let invite = new Invite({ ...doc.data(), id: doc.id });
//         invites.push(invite);
//       });
//       //console.log("[GET INVITES LIST FROM DB]", invites);
//       setDoc(invites);
//     });
// }

export function getInvitesByOrgId(orgId) {
  db.collection(`organizations/${orgId}/invites`).where("claimed", "==", false);
  // .onSnapshot(function (snapshot) {
  //   let invited = [];
  //   // //console.log("[---- PULLING INVITES ----]");
  //   // //console.log(snapshot.docs);
  //   snapshot.docs.map(function (doc) {
  //     let orgUser = {
  //       ...doc.data(),
  //       id: doc.id,
  //     };

  //     invited.push(orgUser);

  //     return true;
  //   });
  //   // //console.log(invited);
  //   // //console.log("[---- END PULLING INVITES ----]");
  //   setOrgInvites(invited);
  // });
}

export function addSkill(orgId, skill) {
  return db.collection(`organizations/${orgId}/skills`).add(skill);
}

export function deleteSkill(orgId, skill) {
  //console.log("[DELETE SKILL] ", skill);
  return db.collection(`organizations/${orgId}/skills`).doc(skill.id).delete();
}

export function updateUserSkills(orgId, userId, skills) {
  //console.log("[USER SKILLS] ", orgId);
  //console.log("[USER SKILLS] ", userId);
  //console.log("[USER SKILLS] ", skills);
  return db
    .collection(`organizations/${orgId}/users`)
    .doc(userId)
    .update({ skills: skills });
}

// export function deleteSkillFromUser(orgId, userId, skills) {
//   //console.log("[DELETE SKILL] ", skills);
//   return db
//     .collection(`organizations/${orgId}/users`)
//     .doc(userId)
//     .update({ skills: skills });
// }

export function addInvite(orgId, invite) {
  return db.collection(`organizations/${orgId}/invites`).add(invite);
}

export function getInvites(orgId) {
  return db
    .collection(`organizations/${orgId}/invites`)
    .where("claimed", "==", false);
}

export function update(organization) {
  console.log("Saving to Firebase...", organization);
  //console.log(organization);
  return db
    .collection("organizations")
    .doc(organization.id)
    .set(organization, { merge: true });
}

export function getUserById(orgId, userId) {
  return db.collection(`organizations/${orgId}/users`).doc(userId).get();
}

export function updateUserById(orgId, userId, user) {
  //console.log("[UPDATE BY USER ID] ", orgId, userId, user);
  return db.collection(`organizations/${orgId}/users`).doc(userId).update(user);
}

export function getApprovalGroups(orgId) {
  return db
    .collection(`organizations/${orgId}/approvals`)
    .orderBy("createdDate");
}

export function updateApprovalGroup(orgId, approvalGroup) {
  return db
    .collection(`organizations/${orgId}/approvals`)
    .doc(approvalGroup.id)
    .update(approvalGroup);
}

export function createApprovalGroup(orgId, approvalGroup) {
  return db.collection(`organizations/${orgId}/approvals`).add(approvalGroup);
}

export function updateSkillPath(orgId, skillPath) {
  return db
    .collection(`organizations/${orgId}/skills`)
    .doc(skillPath.id)
    .update(skillPath);
}

export function createSkillPath(orgId, skillPath) {
  return db.collection(`organizations/${orgId}/skills`).add(skillPath);
}

export function completeOnboarding(orgId) {
  return db
    .collection("organizations")
    .doc(orgId)
    .update({ onboarding: false });
}

export function wasInviteClaimedByUser(orgId, user) {
  return db
    .collection(`organizations/${orgId}/invites`)
    .where("userId", "==", user.id)
    .where("claimed", "==", true)
    .limit(1)
    .get()
    .then(function (snapshot) {
      return new Promise(function (resolve, reject) {
        resolve(!snapshot.empty);
      });
    })
    .catch(function (error) {
      ///organizations/4moJbAaTt518F25HXLCS/users/hEcjGMtnd2e9r58Mi9TFWwSjafF2
      console.log("ERROR: ", error);
    });
}

export function updateAdministrator(
  orgId,
  orgAdminId,
  orgAdminDisplayName,
  orgAdminEmail
) {
  return db.doc(`organizations/${orgId}`).update({
    orgAdminId: orgAdminId,
    adminName: orgAdminDisplayName,
    adminEmail: orgAdminEmail,
  });
}

export default {
  update,
  get,
  getOrganizationById,
  getInviteList,
  getUserList,
  getApprovalGroupsList,
  getSkillPaths,
  addSkill,
  deleteSkill,
  updateUserSkills,
  addInvite,
  getInvites,
  deleteInvite,
  getInvitesByOrgId,
  getUserById,
  updateUserById,
  updateApprovalGroup,
  createApprovalGroup,
  updateSkillPath,
  createSkillPath,
  getApprovalGroups,
  completeOnboarding,
  wasInviteClaimedByUser,
  updateAdministrator,
};
