import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import { db } from "./../../../../../config/firebase"

import "../../../../../style/Dashboard.scss";

function TrialViewsDashboard() {
  const [trialViews, setTrialViews] = useState([]);

  useEffect(() => {
    return db.collection('analytics').where('type', '==', 'TRIAL_VIEW').orderBy('date', 'desc').onSnapshot(querySnapshot => {
      const trialViewsArr = [];

      querySnapshot.forEach(doc => {
        trialViewsArr.push({
          ...doc.data(),
          id: doc.id,
          ref: doc.ref
        });
      })

      setTrialViews(trialViewsArr);

    });
  }, []);

  return (
    <Row>
      <Col>
        <Table>
          <thead>
            <tr>
              <td>Id</td>
              <td>Date Viewed</td>
              <td>Occurence</td>

            </tr>
          </thead>
          <tbody>
            {trialViews.map(doc => {
              return (<tr>
                <td>{doc.id}</td>
                <td>{(new Date(
                  doc.date.seconds * 1000
                )).toLocaleDateString()}</td>
                <td>{doc.nonce}</td>

              </tr>);
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default TrialViewsDashboard;
