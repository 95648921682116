import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

function SuperUserAnalyticsCard({
  analytic,
  analyticDescriptor,
  analyticTitle,
  analyticTheme,
}) {
  return (
    <Card bg="white" text="dark" className="dashboard-card">
      <Card.Body>
        <Row>
          <Col>
            <Card.Title className="text-center font-weight-bold">
              {analyticTitle}
            </Card.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
              <div className="font-weight-bold h1 text-center">
                <span className={analyticTheme}>{analytic}</span>
              </div>
              <div
                className="text-uppercase text-muted text-small text-center"
                style={{ marginTop: "-10px", fontSize: "12px" }}
              >
                {analyticDescriptor}
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default SuperUserAnalyticsCard;
