import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useAuthState } from "../../../../providers/AuthState";

import { api } from "../../../../core/auth/utils/api";

import Auth from "../../../../core/auth/utils/classes/auth";

function AcceptTerms({ setStep, currentOrgInvite }) {
  const { user, dispatch, orgContext } = useAuthState();

  function confirmAcceptence() {
    api.organization
      .claimOrgInviteByUserId(user, currentOrgInvite)
      .then(function () {
        setStep(2);
      });
  }

  function nextStep() {
    setTimeout(function () {
      confirmAcceptence()
    }, 1000);
  }

  return (
    <div style={{ textAlign: "left" }}>
      <p>
        You've begun your trial to the world's leading corporate learning
        platform! To begin onboarding for <b>{orgContext.name}</b>, please begin
        below.
      </p>
      <p>
        By accepting this invite to manage Metisphere, you warrant that you are
        authorized administrator of your organization. You acknowledge receipt
        and acceptance of Metisphere's <a href="http://www.metisphere.com/terms" target="_blank">Terms and Conditions</a> and our
        Privacy Policy.
      </p>
      <div style={{ textAlign: "center" }}>
        <Button variant="outline-secondary" size="lg" onClick={nextStep} style={{ marginTop: "70px" }}>
          Accept Terms and Begin Onboarding
      </Button>
      </div>

    </div>
  );
}

export default AcceptTerms;
