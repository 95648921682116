import React from "react";
import { Row, Col, Jumbotron } from "react-bootstrap";

export default function NoSkills() {
  return (
    <Row>
      <Col xs={12}>
        <Jumbotron>
          <h5 className="text-primary">No active skills.</h5>
          <p>Contact an administrator to enroll in a new skill path!</p>
        </Jumbotron>
      </Col>
    </Row>
  );
}
