import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useRouteMatch, useParams } from "react-router-dom";

import { useSkillPathContext } from "./../providers";

import "./step-list.styles.css";
import { ArrowReturnRight } from "react-bootstrap-icons";

function StepList({ userProgress }) {
  let { url } = useRouteMatch();
  let showNew = true;
  let urlStart = "/skills";
  const { skillPathId, skillStageSlug, skillStepSlug, orgId } = useParams();
  const { skillPath, addingContent } = useSkillPathContext();

  let steps = [];
  const skillStage = skillPath.stages.find((stage) => {
    return stage.order + "" === skillStageSlug + "";
  });
  if (skillStage) {
    steps = skillStage.steps;
    steps.sort((left, right) => {
      return left.order - right.order;
    });
  }

  if (url.indexOf("user") > -1) {
    showNew = false;
    urlStart += "/user";
  } else if (orgId) {
    if (addingContent) {
      urlStart = `/organization/${orgId}/content/skills`;
    } else {
      urlStart = `/organization/${orgId}/skills`;
    }
  }

  function getStepClass(userProgress, curStage, curStep, ind) {
    if (curStep.order + "" === skillStepSlug + "") {
      return "active";
    } else if (
      userProgress &&
      userProgress.stages &&
      userProgress.stages[curStage.id] &&
      userProgress.stages[curStage.id].steps[curStep.id]
    ) {
      if (userProgress.stages[curStage.id].steps[curStep.id].complete) {
        return "completed";
      } else if (
        userProgress.stages[curStage.id].steps[curStep.id].status === "Returned"
      ) {
        return "returned";
      } else if (
        userProgress.stages[curStage.id].steps[curStep.id].status ===
        "Submitted"
      ) {
        return "submitted";
      }
    } else {
      return "";
    }
  }

  // skillStepSlug === ind + 1 + ""
  // ? "active"
  // : userProgress &&
  //   userProgress.stages[skillStageSlug - 1] &&
  //   userProgress.stages[skillStageSlug - 1].steps[ind] &&
  //   userProgress.stages[skillStageSlug - 1].steps[ind]
  //     .complete
  // ? "completed"
  // : ""
  return (
    skillPath && (
      <ListGroup>
        {steps.map((step, ind) => {
          return (
            <ListGroup.Item
              key={`step_${ind}`}
              className={`step-nav-ele ${getStepClass(
                userProgress,
                skillPath.stages[skillStageSlug - 1],
                step,
                ind
              )}`}
            >
              <Link
                to={`${urlStart}/${skillPathId}/${skillStageSlug}/${step.order}`}
              >{`${step.order}. ${step.name}`}</Link>
            </ListGroup.Item>
          );
        })}
        {skillStepSlug !== "new" && showNew && (
          <ListGroup.Item key={`step_add`}>
            <Link to={`${urlStart}/${skillPath.code}/${skillStageSlug}/new`}>
              Add Step
            </Link>
          </ListGroup.Item>
        )}
      </ListGroup>
    )
  );
}

export default StepList;
