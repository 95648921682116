import React from "react";
import { Row, Col, Alert } from "react-bootstrap";

import { ContentDetail } from "../common";

function UserStepDetail({ skillStep, userProgressText, experienceForWisdom }) {
  return (
    skillStep && (
      <div>
        <Row>
          <Col>
            {experienceForWisdom && <Alert style={{ padding: "0.25rem 0.75rem" }} variant="info text-center">EXPERTS: Skip this step by contributing your wisdom!</Alert>}
            <ContentDetail
              heading={"Objective"}
              leftHeading={userProgressText}
              content={skillStep.objective}
            />
            <ContentDetail
              heading={"Skill Step Action"}
              content={skillStep.instructions}
            />
            <ContentDetail
              heading={"Desired Outcome"}
              content={skillStep.evidenceRequired}
            />
          </Col>
        </Row>
      </div>
    )
  );
}

export default UserStepDetail;
