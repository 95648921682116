import React from "react";
import "./style/App.scss";
import { AuthStateProvider } from "./providers/AuthState";
import { OrgStateProvider } from "./providers/OrgState";
import PrimaryLayout from "./core/layout/PrimaryLayout";

function App() {
  return (
    <OrgStateProvider>
      <AuthStateProvider>
        <PrimaryLayout />
      </AuthStateProvider>
    </OrgStateProvider>
  );
}

export default App;
