import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Container, Carousel } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { db } from "./../../../../config/firebase";

import StepContentForm from "./step-content-form";
import { ContentPlayer } from "./../content";
import { useSkillPathContext } from "./../providers";
import { useAuthState } from "../../../../providers/AuthState";

function StepContent({ skillStep, canEdit, addingContent }) {
  const [stepContents, setStepContents] = useState([]);
  const [orgStepContents, setOrgStepContents] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const { skillPath } = useSkillPathContext();
  const { orgContext } = useAuthState();
  let { orgId } = useParams();

  if (!addingContent && !orgId) {
    orgId = orgContext.id;
  }

  let realId = skillStep.id;
  if (realId === "staged") {
    realId = skillStep.ref.parent.parent.id;
  }
  useEffect(() => {
    let stepPath = skillStep.ref.path;
    if (skillStep.ref.id === "staged") {
      stepPath = skillStep.ref.parent.parent.path;
    }

    let originalContentCallback;
    let orgContentCallback;

    // Get content from the template path
    if (!skillPath.custom) {
      originalContentCallback = db
        .collection(`${stepPath}/content`)
        .onSnapshot((snapshot) => {
          const contentArr = [];
          snapshot.forEach((doc) => {
            contentArr.push({
              ...doc.data(),
              id: doc.id,
              ref: doc.ref,
              templateContent: true,
            });
          });

          contentArr.sort((left, right) => {
            return left.order - right.order;
          });

          setStepContents(contentArr);
        });
    }

    // Get content that has been added to the path on the org
    let orgSkillStepPath = `/organizations/${orgId}/${stepPath}/content`;
    if (skillPath.custom) {
      orgSkillStepPath = `${stepPath}/content`;
    }

    orgContentCallback = db
      .collection(orgSkillStepPath)
      .onSnapshot((snapshot) => {
        const contentArr = [];
        snapshot.forEach((doc) => {
          contentArr.push({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
            templateContent: false,
          });
        });

        contentArr.sort((left, right) => {
          return left.order - right.order;
        });

        setOrgStepContents(contentArr);
      });

    return () => {
      if (originalContentCallback) {
        originalContentCallback();
      }
      if (orgContentCallback) {
        orgContentCallback();
      }
    };
  }, [realId, skillStep.ref.path]);

  const contentArr = [...stepContents, ...orgStepContents];

  return (
    <div>
      {canEdit && <StepContentForm skillStep={skillStep} />}
      <div style={{ marginTop: "15px" }}>
        <ContentPlayer
          stepContents={contentArr}
          canEdit={canEdit}
          skillStep={skillStep}
        />
      </div>
    </div>
  );
}

export default StepContent;
