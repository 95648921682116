import React from "react";
import { Link } from "react-router-dom";

function SignedOutLinks() {
  return (
    <div>
      <Link
        to="/signIn"
        className="nav-link text-light text-uppercase font-weight-bold"
        style={{ fontWeight: "bold" }}
      >
        Sign In
      </Link>
      {/* <Link
        to="/SignUp"
        className="nav-link text-light text-uppercase font-weight-bold"
        style={{ fontWeight: "bold" }}
      >
        Sign Up
      </Link> */}
    </div>
  );
}

export default SignedOutLinks;
