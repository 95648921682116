import React, { useState, useEffect } from "react";

import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Jumbotron from "react-bootstrap/Jumbotron";
import Alert from "react-bootstrap/Alert";

import ApprovalGroup from "../../../organizations/utils/classes/approvalGroup";

import { useAuthState } from "../../../../providers/AuthState";
import { useOrganizationForm } from "../hooks";

function SubjectMatterExperts({ setStep }) {
  /*
   * Form State Functionality
   * orgUsers - collection of all users in the organization on the platform
   */
  const { orgContext, orgUsers } = useAuthState();
  const [alertVariant, setAlertVariant] = useState("info");
  const [loading, setLoading] = useState(true);

  let organization = useOrganizationForm(orgContext.id);
  let currentUserIds = organization.users.map(o => o.id);

  console.log("Current User IDs: ", currentUserIds);
  let orgId = orgContext.id;

  const [modalApprovalGroup, setModalApprovalGroup] = useState({
    form: new ApprovalGroup({
      id: "",
      orgId: orgId,
      name: "Expert Group",
      description:
        "This a team of experts that can approve learning content in you organization.",
      approvers: currentUserIds
    }),
    show: false,
  });

  const [errors, setErrors] = useState([]);

  function addApprover(approverId) {
    let mag = { ...modalApprovalGroup };
    mag.form.addApprover(approverId);
    setModalApprovalGroup(mag);
  }

  function removeApprover(approverId) {
    let mag = { ...modalApprovalGroup };
    mag.form.removeApprover(approverId);
    setModalApprovalGroup(mag);
  }

  function validateGroupsComplete() {
    if (modalApprovalGroup.form.approvers.length > 0) {
      setStep(4);
    }
  }

  useEffect(() => {
    if (organization.approvalGroups.length === 0) {
      let ag = new ApprovalGroup({
        id: "",
        orgId: orgId,
        name: "Expert Group",
        description:
          "This a team of experts that can approve learning content in you organization.",
        approvers: currentUserIds
      });

      ag.save().then(function (doc) {
        let mag = { ...modalApprovalGroup };
        mag.form = ag;
        if (doc) {
          mag.form.id = doc.id;
        }
        setModalApprovalGroup(mag);
        setLoading(false);
      });
    } else {
      let ag = organization.approvalGroups[0];

      ag.save().then(function (doc) {
        let mag = { ...modalApprovalGroup };
        mag.form = ag;
        if (doc) {
          mag.form.id = doc.id;
        }
        setModalApprovalGroup(mag);
        setLoading(false);
      });
    }
  }, [organization.users.length]);


  return (
    <div>
      <div style={{ minHeight: "400px" }}>
        {errors.map(function (error) {
          return <Alert variant="danger">{error}</Alert>;
        })}
        <Alert variant={(modalApprovalGroup.form.approvers.length > 0 ? "success" : "danger")} style={{ textAlign: "center" }}>
          <b>Please note:</b> You must add at least expert approver to the
            group.
            <table style={{ marginTop: "10px", width: "100%" }}>
            <tr>
              <td>
                <small>APPROVERS</small>
                <br />
                <b>{modalApprovalGroup.form.approvers.length}/1</b>
              </td>
            </tr>
          </table>
        </Alert>
        <Form>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Approval Group Name"
              value={
                modalApprovalGroup.form ? modalApprovalGroup.form.name : ""
              }
              onChange={(e) => {
                let mag = { ...modalApprovalGroup };
                mag.form.updateField("name", e.target.value);
                setModalApprovalGroup(mag);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Approval Group Description"
              value={
                modalApprovalGroup ? modalApprovalGroup.form.description : ""
              }
              onChange={(e) => {
                let mag = { ...modalApprovalGroup };
                mag.form.updateField("description", e.target.value);
                setModalApprovalGroup(mag);
              }}
            />
          </Form.Group>
          {organization.users.filter(function (user) {
            return user.isSME === "Yes";
          }).length === 0 ? (
              <Alert variant="primary">
                Before creating an approval group, please ensure at least one
                organiation user is an SME.
              </Alert>
            ) : (
              ""
            )}
          <ListGroup as="ul" style={{ marginBottom: "15px" }}>
            {Array.isArray(organization.users) &&
              organization.users
                .map((user, index) => {
                  if (Array.isArray(modalApprovalGroup.form.approvers) && !loading) {
                    let approverBtn = !modalApprovalGroup.form.approvers.includes(
                      user.id
                    ) ? (
                        <Button
                          variant="primary"
                          className="float-right"
                          size="sm"
                          onClick={(e) => {
                            addApprover(user.id);
                            modalApprovalGroup.form.save();
                          }}
                        >
                          Add to Expert Group
                        </Button>
                      ) : (
                        <Button
                          variant="outline-danger"
                          className="float-right"
                          size="sm"
                          onClick={(e) => {
                            //console.log("Remove!");
                            removeApprover(user.id);
                            modalApprovalGroup.form.save();
                          }}
                        >
                          Remove from Expert Group
                        </Button>
                      );
                    return (
                      <ListGroup.Item as="li" key={index}>
                        {user.displayName}
                        {approverBtn}
                      </ListGroup.Item>
                    );
                  }
                  return "";
                })}
          </ListGroup>
        </Form>
      </div>
      <div>
        <Row>
          <Col xs={6}></Col>
          <Col xs={6}>
            <div className="text-right">
              <Button
                variant="outline-secondary"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setStep(2);
                }}
              >
                Back
              </Button>
              <Button
                variant="outline-secondary"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setStep(4);
                }}
              >
                Skip Step and Add Later
              </Button>
              <Button
                variant="outline-primary"
                onClick={validateGroupsComplete}
              >
                Next Step
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SubjectMatterExperts;
