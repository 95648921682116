import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Container, Row, Col } from "react-bootstrap";

import { api } from "../../utils";

import StepDetail from "./step-detail";
import StepApprovals from "./step-approvals";

function StepVersions({ skillStep }) {
  const [versions, setVersions] = useState([]);
  const [modalVersion, setModalVersion] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (version) => {
    setShow(true);
    setModalVersion(version);
  };

  useEffect(() => {
    if (skillStep.ref) {
      return api.skillStep.getSkillStepVersions(
        skillStep.ref.parent.parent.path,
        setVersions
      );
    }
  }, [skillStep.ref]);

  return (
    <div>
      <h4>Versions</h4>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>#</th>
            <th>Date Published</th>
            <th>Published By</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {versions.map((version, ind) => {
            return (
              <tr key={version.id}>
                <td style={{ textAlign: "center" }}>{version.version}</td>
                <td>
                  {version.datePublished &&
                    new Date(
                      version.datePublished.seconds * 1000
                    ).toLocaleDateString()}
                </td>
                <td>{version.author}</td>
                <td>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      handleShow(version);
                    }}
                  >
                    View
                  </Button>{" "}
                  {/* <Button size="sm" variant="outline-primary">
                    Revert To
                  </Button> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {modalVersion && (
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {modalVersion.name}
              <span style={{ fontSize: "1.25rem", color: "#6c757d" }}>
                {" "}
                - Version {modalVersion.version}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <h6>Overview</h6>
                  <StepDetail
                    skillStep={modalVersion}
                    currentVersion={skillStep}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Approvals</h6>
                  <StepApprovals
                    skillStep={modalVersion}
                    currentVersion={skillStep}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default StepVersions;
