import React, { useState, useEffect } from "react";

import "../../style/Dashboard.scss";

import Container from "react-bootstrap/Container";

import { useAuthState } from "../../providers/AuthState";

import { api } from "../../core/auth/utils/api";

import Admin from "./Admin";
import Manager from "./Manager";
import Learner from "./Learner";
import SuperUserDashboard from "../../features/superusers";

function Dashboard() {
  const { profileType, orgContext, user } = useAuthState();
  const [pending, setPending] = useState([]);

  useEffect(() => {
    if (user) {
      api.organization
        .getAllOrgTrialInvitesByUserId(user)
        .then(function (orgs) {
          setPending(orgs);
        });
    }
  }, [user]);

  if (profileType === "SUPERUSER")
    return (
      <Container style={{ padding: 0 }} fluid>
        <SuperUserDashboard />
      </Container>
    );
  if (
    profileType === "ADMIN" ||
    (pending.length > 0 && orgContext.status === "TRIAL")
  )
    return (
      <Container style={{ padding: 0 }} fluid>
        <Admin />
      </Container>
    );
  if (profileType === "MANAGER")
    return (
      <Container style={{ padding: 0 }} fluid>
        <Manager />
      </Container>
    );
  return (
    <Container style={{ padding: 0 }} fluid>
      <Learner />
    </Container>
  );
}
export default Dashboard;
