import React from "react";

import { Page } from "./../components/common";
import { PathForm } from "./../components/skill-path";

function CreateSkillPathLayout() {
  return (
    <Page>
      <PathForm />
    </Page>
  );
}

export default CreateSkillPathLayout;
