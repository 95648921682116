import React from "react";
import { firebase } from "../../config/firebase";
import { Route, Redirect } from "react-router-dom";

import { useAuthState, isSuperUser } from "../../providers/AuthState";
import MetisphereSymbol from "../../metisphere-symbol-no-tm.svg";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export function PrivateRoute({ children, ...rest }) {
  const { authenticated } = useAuthState();

  if (firebase.auth().currentUser) {
    if (authenticated) {
      return <Route {...rest} />;
    } else {
      return (
        <div className="wait-for-auth-container">
          <div>
            {/* <Spinner animation="grow" variant="secondary" /> */}
            <div className="loader"></div>
            <img
              src={MetisphereSymbol}
              style={{
                width: "50px",
                height: "50px",
                marginLeft: "25.5px",
                marginTop: "-244px",
              }}
              alt="Metisphere Logo"
            />{" "}
          </div>
        </div>
      );
    }
  } else {
    return <Redirect to="/signIn" />;
  }
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export function SuperUserRoute({ children, ...rest }) {
  const { profile, authenticated } = useAuthState();

  if (firebase.auth().currentUser) {
    if (isSuperUser(profile.type)) {
      if (authenticated) {
        return <Route {...rest} />;
      } else {
        return (
          <div className="wait-for-auth-container">
            <div>
              {/* <Spinner animation="grow" variant="secondary" /> */}
              <div className="loader"></div>
              <img
                src={MetisphereSymbol}
                style={{
                  width: "50px",
                  height: "50px",
                  marginLeft: "25.5px",
                  marginTop: "-244px",
                }}
                alt="Metisphere Logo"
              />{" "}
            </div>
          </div>
        );
      }
    } else {
      return <Redirect to="/signIn" />;
    }
  } else {
    return <Redirect to="/signIn" />;
  }
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export function PublicOnlyRoute({ children, ...rest }) {
  const { authenticated, complete } = useAuthState();

  if (!firebase.auth().currentUser) {
    return <Route {...rest} />;
  } else if (!complete) {
    return (
      <div className="wait-for-auth-container">
        <div>
          {/* <Spinner animation="grow" variant="secondary" /> */}
          <div className="loader"></div>
          <img
            src={MetisphereSymbol}
            style={{
              width: "50px",
              height: "50px",
              marginLeft: "25.5px",
              marginTop: "-244px",
            }}
            alt="Metisphere Logo"
          />{" "}
        </div>
      </div>
    );
  } else if (complete && authenticated) {
    return <Redirect to="/" />;
  }
}
