import { api } from "../api";

export default class Auth {
  constructor(doc) {
    this.email = doc.email || "";
    this.password = doc.password || "";
    this.passwordConfirmation = doc.passwordConfirmation || "";
    this.user = doc.user || {};
    return this;
  }

  toFirestoreFormat() {
    return {
      email: this.email,
      password: this.password,
    };
  }

  toReducerFormat() { }

  logout() {
    return api.auth.logout();
  }

  resetPassword() {
    return api.auth.resetPassword();
  }

  updateProfile(profile) {
    return api.auth.updateProfile(this.user, profile);
  }

  updatePassword(user, profile) {
    //console.log("[USER] ", user.updatePassword);
    //console.log("[profile] ", profile);
    return api.auth.updatePassword(user, this.password).then(function () {
      //console.log("[FT USER ID] ", user.uid);
      //console.log("[FT PROFILE] ", profile);
      return api.profile.setFirstTimePasswordUpdate(user, profile);
    });
  }

  validatePasswordUpdate() {
    if (this.getUpdatePasswordErrors().length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getUpdatePasswordErrors() {
    let errors = [];
    if (this.password === this.passwordConfirmation) {
      errors.push(["Passwords do not match."]);
    }
    if (this.password.length > 6) {
      errors.push("Password is not long enough");
    }
    return errors;
  }

  rememberMe() { }

  completeToken(tokenDoc) {
    return api.auth.completeToken(tokenDoc);
  }

  login() {
    return new Promise(
      function (resolve, reject) {
        if (this.email && this.password) {
          //resolve(api.auth.login(this.email, this.password));
          api.auth
            .login(this.email, this.password)
            .then(function (user) {
              //api.logging.login();
              resolve(user);
            })
            .catch(function (error) {
              //api.logging.loginError();
              reject(error);
            });
        } else {
          reject({ message: "Please complete the login form." });
        }
      }.bind(this)
    );
  }

  isRequired() {
    throw new Error("User is a required parameter.");
  }

  // loadContext() {
  //   if (!this.user) {
  //     throw new Error("User is not set!");
  //   }
  //   let auth = this;
  //   let user = this.user;
  //   return api.profile.get(user).then(function (doc) {
  //     return new Promise(function (resolve, reject) {
  //       let profile = doc.data();
  //       //console.log("[PROFILE] ", profile);
  //       // This case exists only becuase there is sometimes a delay in the creation of the profile
  //       // If it's not created right away, wait three seconds and try again
  //       // @TODO - Add maximum retry limit
  //       if (!profile) {
  //         reject(Error("Profile not found"));
  //       } else {
  //         //console.log("[FOUND PROFILE] ", profile);

  //         return api.profile.getPhoto(user, profile).then(function (url) {
  //           profile.photoPath = url;
  //           //console.log("[PROFILE PHOTOURL] ", url);
  //           return (
  //             api.organization
  //               // .getOrgByAdminEmail(user.email)
  //               .getOrgByAdminId(user.uid)
  //               .then(function (org) {
  //                 resolve({
  //                   type: "LOGIN",
  //                   user: user,
  //                   profile: profile,
  //                   adminOrg: org,
  //                 });
  //               })
  //           );
  //         });
  //       }
  //     });
  //   });
  // }

  loadContext() {
    if (!this.user) {
      throw new Error("User is not set!");
    }
    let user = this.user;

    //console.log(user.uid);
    return api.profile.get(user).then(function (doc) {
      return new Promise(function (resolve, reject) {
        let profile = doc.data();
        //console.log("[PROFILE] ", profile);
        // This case exists only becuase there is sometimes a delay in the creation of the profile
        // If it's not created right away, wait three seconds and try again
        // @TODO - Add maximum retry limit
        if (!profile) {
          reject(Error("Profile not found"));
          // api.auth.logout();
        } else {
          return api.profile.getPhoto(user, profile).then(function (url) {
            profile.photoPath = url;
            return (
              api.organization
                // .getOrgByAdminEmail(user.email)
                .getOrgByAdminId(user.uid)
                .then(function (adminOrg) {
                  if (profile.orgs) {
                    let org = profile.orgs[0];
                    api.organization
                      .getOrgUserProfile(org.orgId, org.orgUserId)
                      .then(function (userOrgContext) {
                        api.organization
                          .getOrgContextByOrgId(org.orgId)
                          .then(function (orgContext) {
                            //console.log("[ORG CONTEXT] ", orgContext);
                            api.organization
                              .getOrgUsersById(org.orgId)
                              .then(function (orgUsers) {
                                let profileType;
                                let orgManagers =
                                  orgUsers &&
                                  orgUsers.filter(function (orgUser) {
                                    return orgUser.isManager !== "No";
                                  });

                                if (user.email.includes("metisphere.com")) {
                                  profileType = "SUPERUSER";
                                } else if (adminOrg) {
                                  profileType = "ADMIN";
                                } else if (userOrgContext.isManager === "Yes") {
                                  profileType = "MANAGER";
                                } else {
                                  profileType = "LEARNER";
                                }

                                api.organization
                                  .getApprovalGroupsByOrgIdAndUserId(
                                    org.orgId,
                                    user.uid
                                  )
                                  .then(function (userApprovalGroups) {
                                    api.organization
                                      .getApprovalGroupsByOrgId(org.orgId)
                                      .then(function (orgApprovalGroups) {
                                        api.organization
                                          .getOrgSkillPathsByOrganizationAndUserId(
                                            org.orgId,
                                            user.uid
                                          )
                                          .then(function (orgUserPaths) {
                                            api.organization.getWisdomManagerForOrgSkills(org.orgId, userApprovalGroups).then(wisdomManagerForSkills => {
                                              resolve({
                                                type: "LOGIN",
                                                user: user,
                                                profile: profile,
                                                profileType: profileType,
                                                adminOrg: adminOrg,
                                                orgProfile: userOrgContext,
                                                orgContext: orgContext,
                                                orgUsers: orgUsers,
                                                orgManagers: orgManagers,
                                                orgApprovalGroups: orgApprovalGroups,
                                                userApprovalGroups: userApprovalGroups,
                                                skillPaths: orgUserPaths,
                                                loggedInTime: new Date(),
                                                wisdomManagerForSkills: wisdomManagerForSkills,
                                              });
                                            });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  } else {
                    resolve({
                      type: "LOGIN",
                      user: user,
                      profile: profile,
                      adminOrg: adminOrg,
                      profileType: "SUPERUSER",
                      orgProfile: undefined,
                      orgContext: undefined,
                      orgUsers: undefined,
                      orgManagers: undefined,
                    });
                  }
                })
            );
          });
        }
      });
    });
  }

  loadAuth(user) {
    return { type: "AUTH_LOADED" };
  }
}
