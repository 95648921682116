import React from "react";
import { Row, Col } from "react-bootstrap";

import { Page, PageTitle } from "./../components/common";

import { PathList } from "./../components/skill-path";

function DashboardLayout() {
  return (
    <Page>
      <PageTitle title="Skills Dashboard" />
      <Row>
        <Col md={10}>
          <PathList />
        </Col>
        {/* <Col md={4}>
          <DashboardAnalytics />
        </Col> */}
      </Row>
    </Page>
  );
}

export default DashboardLayout;
