import React from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

import {
  StepList,
  StepDetail,
  StepForm,
  StepRelated,
  StepApprovals,
  StepVersions,
  StepContent,
} from "./../components/skill-step";

import { db } from "./../../../config/firebase";

import { useSkillPathContext } from "./../components/providers";
import { useSkillStep } from "./../components/skill-step/hooks";

function SkillStepLayout() {
  let { path } = useRouteMatch();

  const { skillStageSlug, skillStepSlug } = useParams();
  const { skillPath } = useSkillPathContext();

  let skillStepRef = null;
  if (skillPath) {
    skillStepRef = skillPath.getSkillStepRefFromSlugs({
      skillStageSlug,
      skillStepSlug,
    });
  }

  let stepRefPath = "";
  if (skillStepRef) {
    stepRefPath = `${skillStepRef.ref.path}/versions/staged`;
  }
  const skillStep = useSkillStep(stepRefPath);

  return (
    skillPath &&
    skillStep && (
      <div>
        <Row>
          <Col md={3}>
            <h4>Skill Steps</h4>
          </Col>
          <Col md={8}>
            {skillStep.id &&
              <h4>
                {skillStageSlug}.{skillStep.order}: {skillStep.name} - {skillStep.approvalType}
              </h4>
            }
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <StepList />
          </Col>
          <Col md={8}>
            <Switch>
              <Route path={`${path}/edit`}>
                <StepForm existingSkillStep={skillStep} />
              </Route>
              <Route path={`${path}`}>
                {skillStep.id && (
                  <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="overview" title="Instructions">
                      <StepDetail skillStep={skillStep} canEdit={true} />
                    </Tab>
                    <Tab eventKey="content" title="Content">
                      <StepContent skillStep={skillStep} canEdit={true} />
                    </Tab>
                    <Tab eventKey="approvals" title="Approvals">
                      <StepApprovals skillStep={skillStep} />
                    </Tab>
                    <Tab eventKey="related" title="Related">
                      <StepRelated skillStep={skillStep} />
                    </Tab>
                    <Tab eventKey="versions" title="Versions">
                      <StepVersions skillStep={skillStep} />
                    </Tab>
                  </Tabs>
                )}
              </Route>
            </Switch>
          </Col>
        </Row>
      </div>
    )
  );
}

export default SkillStepLayout;
