import React, { useState, useEffect } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { HandThumbsUp } from "react-bootstrap-icons";

import { useAuthState } from "../../../providers/AuthState";
import { Recognition } from "../utils/classes/recognition";
import SkillStage from "./recognition-skill-stage";

function RecognitionModal({
  showRecognitionModal,
  setShowRecognitionModal,
  sendRecognition,
  disableSkillSelection,
  path,
  stage,
  step,
  disabledConfetti,
  postSubmitHandler,
  recognition,
}) {
  const {
    user,
    orgUsers,
    orgProfile,
    orgManagers,
    skillPaths,
  } = useAuthState();
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentManager, setCurrentManager] = useState();

  const [selectedPathIndex, setSelectedPathIndex] = useState(0);
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedStep, setSelectedStep] = useState("");

  const [recognitionErrors, setRecognitionErrors] = useState([]);

  function like() {
    sendRecognition({ type: "TOGGLE_LIKE", recognition, user });
  }

  if (skillPaths) {
    console.log("Skill: ", skillPaths);
    // skillPaths[0].stages.map(function (stage) {
    //   return console.log("Stage: ", stage.id);
    // });
  }

  useEffect((e) => {}, [path, stage, step]);

  function handleHideRecognitionModal() {
    setShowRecognitionModal(false);
  }

  //   function toggleNotifyManager(managerId) {
  //     console.log("[TOGGLE MANAGER] ", managerId);
  //     if (recognition.managers.includes(managerId)) {
  //       recognition.managers = recognition.managers.filter(function (
  //         currentManagerId
  //       ) {
  //         return currentManagerId !== managerId;
  //       });
  //     } else {
  //       recognition.managers.push(managerId);
  //     }

  //     console.log("[RECOGNITION MANAGERS] ", recognition.managers);

  //     setRecognition(recognition);
  //   }

  //   function notifyManager(managerId) {
  //     console.log("[NOTIFY MANAGER] ", recognition.managers.includes(managerId));
  //     if (recognition.managers.includes(managerId)) {
  //       return true;
  //     }
  //     return false;
  //   }

  //   function isMyManager(current, mine) {
  //     return current == mine;
  //   }

  //   function toggleEscalation() {
  //     recognition.escalation = !recognition.escalation;
  //     setRecognition(recognition);
  //   }

  // if (!recognition.managers.includes(orgProfile.manager)) {
  //   recognition.managers.push(orgProfile.manager);
  // }

  function getCurrentManager(userId) {
    let selectedUser = orgUsers.filter(function (orgUser) {
      return userId === orgUser.id;
    });
    let manager = "";
    console.log("[SELECTED MANAGER] ", selectedUser[0]);
    if (selectedUser.length > 0) {
      manager = selectedUser[0].manager;
    }
    console.log("[SELECTED MANAGER] ", manager);

    return manager;
  }

  console.log("[CURRENT RECOGNITION] ", recognition);

  function isLiked() {
    if (recognition.likes && Array.isArray(recognition.likes)) {
      return recognition.likes.includes(user.uid);
    }
  }

  let buttonVariant = isLiked() ? "primary" : "outline-primary";

  return (
    <div>
      {showConfetti && !disabledConfetti ? (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={500}
          recycle={false}
        />
      ) : (
        ""
      )}
      <Modal
        size="lg"
        show={showRecognitionModal}
        onHide={handleHideRecognitionModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Recognition
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Row>
            <Col>
              <h4>
                <span className="text-primary">
                  {recognition.recognizedToDisplayName}{" "}
                </span>
                was recognized by{" "}
                <span className="text-secondary">
                  {recognition.recognizedFromDisplayName}
                </span>{" "}
                for{" "}
                <span className="text-primary">
                  {recognition.pathDisplayName}
                </span>
                !
              </h4>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <h5 className="font-weight-bold">Description of Recognition</h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>{recognition.description}</Col>
          </Row>
          <Row>
            <Col>
              <h5 className="font-weight-bold">What Was Witnessed?</h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>{recognition.witness}</Col>
          </Row>
          <Row>
            <Col>
              <h5 className="font-weight-bold">
                Did this help us avoid an escalation?
              </h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>{recognition.escalation ? "Yes" : "No"}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleHideRecognitionModal}
          >
            Close
          </Button>
          <Button variant={buttonVariant} onClick={like}>
            <HandThumbsUp className="mr-1" />
            Like
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RecognitionModal;
