import React, { useState } from "react";

import { RecognitionCard, RecognitionViewModal } from "./../../recognitions";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../../style/Recognition.scss";

function RecognitionPanelContainer({ children }) {
  return <div className="recognition-panel-container">{children}</div>;
}

function RecognitionPanel({ panelSize, recognitions, send }) {
  const [showViewRecognitionModal, setShowViewRecognitionModal] = useState(
    false
  );
  const [currentRecognition, setCurrentRecognition] = useState({});

  let recognitionsPanel = recognitions.filter(function (recognition, index) {
    if (index < 3) {
      return true;
    }
    return false;
  });

  function handleOpen(recognition) {
    setCurrentRecognition(recognition);
    setShowViewRecognitionModal(true);
  }

  if (recognitions.length > 0) {
    return (
      <RecognitionPanelContainer>
        <Row>
          <Col className="recognition-cards">
            <Row>
              {recognitionsPanel.map(function (recognition) {
                return (
                  <Col xs={12} sm={panelSize} key={recognition.id}>
                    <RecognitionCard
                      recognition={recognition}
                      send={send}
                      handleOpen={handleOpen}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <RecognitionViewModal
          showRecognitionModal={showViewRecognitionModal}
          setShowRecognitionModal={setShowViewRecognitionModal}
          sendRecognition={send}
          recognition={currentRecognition}
        />
      </RecognitionPanelContainer>
    );
  }
}

export default RecognitionPanel;
