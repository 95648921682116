import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Route, useRouteMatch } from "react-router-dom";

import { StageDetail, StageList } from "./../components/skill-stage";
import UserSkillStepLayout from "./user-skill-step";

import { useSkillPathContext } from "./../components/providers";

import { useAuthState } from "../../../providers/AuthState";

import { db } from "./../../../config/firebase";

function UserSkillStageLayout() {
  const [userProgress, setUserProgress] = useState(null);
  const { skillPath, addingContent } = useSkillPathContext();
  let { path } = useRouteMatch();

  const { user, orgContext } = useAuthState();

  const userId = user.uid;
  const orgId = orgContext.id;

  useEffect(() => {
    if (skillPath.id && !addingContent) {
      db.doc(
        `/organizations/${orgId}/users/${userId}/paths/${skillPath.id}`
      ).onSnapshot((snapshot) => {
        //console.log("       [User Progress]", snapshot.data());
        setUserProgress({
          ...snapshot.data(),
          id: snapshot.id,
        });
      });
    }
  }, [skillPath.id, orgId, userId, addingContent]);

  return (
    skillPath && (
      <Row>
        <Col>
          <Row>
            <Col>
              <Route path={`${path}/:skillStageSlug`}>
                <StageList userProgress={userProgress} />
              </Route>
            </Col>
          </Row>
          <Route path={`${path}/:skillStageSlug`}>
            <Row>
              <Col>
                <StageDetail canEdit={false} />
                <UserSkillStepLayout />
              </Col>
            </Row>
          </Route>
        </Col>
      </Row>
    )
  );
}

export default UserSkillStageLayout;
