import React from "react";
import { Button } from "react-bootstrap";

function StyleButton({ isActive, onToggle, style, children }) {
  return (
    <Button
      variant="outline-secondary"
      className={isActive ? "active" : ""}
      onMouseDown={(evt) => {
        evt.preventDefault();
        onToggle(style);
      }}
    >
      {children}
    </Button>
  );
}

export default StyleButton;
