import React from "react";
import { useAuthState } from "../../providers/AuthState";
import { firebase } from "../../config/firebase";
import { get } from "lodash";

import MetisphereSymbol from "../../metisphere-symbol-no-tm.svg";

function WaitForAuthLoadingScreen() {
  return (
    <div className="wait-for-auth-container">
      <div>
        {/* <Spinner animation="grow" variant="secondary" /> */}
        <div className="loader"></div>
        <img
          src={MetisphereSymbol}
          style={{
            width: "50px",
            height: "50px",
            marginLeft: "25.5px",
            marginTop: "-244px",
          }}
          alt="Metisphere Logo"
        />{" "}
      </div>
    </div>
  );
}

export function isLoaded(...args) {
  return !args || !args.length
    ? true
    : args.every((arg) => arg !== undefined && get(arg, "isLoaded") !== false);
}

function WaitForAuth({ children }) {
  const { complete } = useAuthState();

  if (isLoaded(firebase.auth) && complete) {
    return children;
  } else {
    return WaitForAuthLoadingScreen();
  }
}

export default WaitForAuth;
