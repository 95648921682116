import { useReducer } from "react";
import Profile from "../../../utils/classes/profile";

function profileReducer(state, action) {
  switch (action.type) {
    case "UPDATE": {
      //console.log({ ...state, ...action.payload.profile });
      return { ...state, ...action.payload.profile };
    }
    case "UPDATE_FIELD": {
      console.log("[ACTION] ", action);
      console.log("[STATE] ", state);
      console.log("[MERGED STATE] ", {
        ...state,
        [action.payload.field]: action.payload.value,
      });
      return { ...state, [action.payload.field]: action.payload.value };
    }
    default:
      return state;
  }
}

function useProfileForm() {
  let initialState = new Profile({});
  const [state, dispatch] = useReducer(profileReducer, initialState);

  const value = {
    ...state,
    updateField(field, value) {
      dispatch({
        type: "UPDATE_FIELD",
        payload: { field: field, value: value },
      });
    },
    update(profile) {
      dispatch({
        type: "UPDATE",
        payload: { profile: profile },
      });
    },
    get() {
      console.log("Get State: ", state);
      return new Profile(state);
    },
    save() {
      let profile = new Profile(state);
      return profile.save();
    },
  };
  return value;
}

export default useProfileForm;
