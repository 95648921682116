import { db } from "../../../../config/firebase";
import { SkillStep } from "../classes/skill-step";

function getStepCollection(ref, setDocs) {
  return ref.onSnapshot((snapshot) => {
    const docs = [];

    snapshot.forEach((doc) => {
      docs.push({
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      });
    });
    setDocs(docs);
  });
}

export function getId({ skillPathId, skillStageId }) {
  return db
    .collection(`/paths/${skillPathId}/stages/${skillStageId}/steps`)
    .doc();
}

export function update({ skillPathId, skillStageId, skillStep }) {
  return db
    .doc(`/paths/${skillPathId}/stages/${skillStageId}/steps/${skillStep.id}`)
    .set(skillStep);
}

export function updateStaged({ skillPathId, skillStageId, skillStep }) {
  return db
    .doc(
      `/paths/${skillPathId}/stages/${skillStageId}/steps/${skillStep.id}/versions/staged`
    )
    .set(skillStep);
}

export function updateStagedOnOrg({
  skillPathId,
  skillStageId,
  skillStep,
  orgId,
}) {
  return db
    .doc(
      `/organizations/${orgId}/paths/${skillPathId}/stages/${skillStageId}/steps/${skillStep.id}/versions/staged`
    )
    .set(skillStep);
}

export function getStepByRef(skillStepRef, setSkillStep) {
  return skillStepRef.onSnapshot((snapshot) => {
    //console.log("     [SNAPSHOT]", snapshot);
    setSkillStep(
      new SkillStep({
        ...snapshot.data(),
        id: snapshot.id,
        ref: snapshot.ref,
      })
    );
  });
}

export function getStepDataByRef(skillStepRef, setSkillStep) {
  return skillStepRef.get().then((doc) => {
    //console.log(doc);
    setSkillStep(
      new SkillStep({
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      })
    );
  });
}

function getSkillStepVersions(skillStepPath, setVersions) {
  const versionsRef = db
    .doc(skillStepPath)
    .collection("versions")
    .orderBy("datePublished");

  getStepCollection(versionsRef, setVersions);
}

export default {
  update,
  updateStaged,
  getId,
  getStepByRef,
  getStepDataByRef,
  getSkillStepVersions,
  updateStagedOnOrg,
};
