import React from "react";
import { Row, Col } from "react-bootstrap";

function Page({ children }) {
  return (
    <Row style={{ marginBottom: "50px" }}>
      <Col>{children}</Col>
    </Row>
  );
}

export default Page;
