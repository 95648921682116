import { db } from "./../../../config/firebase";

import { api } from "./../../../core/logging/utils/api";

/*
  Logs experience if the user has an active open recognition step
*/
function logExperience(userProfile, userRecognition) {
  const pathId = userRecognition.path;
  return db
    .doc(`${userProfile.ref.path}/paths/${pathId}`)
    .get()
    .then((doc) => {
      const pathProgress = { ...doc.data(), id: doc.id, ref: doc.ref };

      if (
        pathProgress.activeStage &&
        pathProgress.stages[pathProgress.activeStage]
      ) {
        const activeSteps = pathProgress.stages[pathProgress.activeStage].steps;
        const uncompeleteActiveSteps = [];

        for (const key in activeSteps) {
          if (!activeSteps[key].complete) {
            uncompeleteActiveSteps.push(activeSteps[key]);
          }
        }

        if (uncompeleteActiveSteps.length > 0) {
          const uncompleteIds = uncompeleteActiveSteps.map((step) => {
            return step.id;
          });

          return db
            .collection(
              `/paths/${pathId}/stages/${pathProgress.activeStage}/steps`
            )
            .get()
            .then((docs) => {
              const recognitionDocs = [];
              docs.forEach((doc) => {
                const docData = doc.data();
                if (
                  docData.approvalType === "Recognition" &&
                  uncompleteIds.indexOf(doc.id) > -1
                ) {
                  recognitionDocs.push({
                    ...doc.data(),
                    id: doc.id,
                    ref: doc.ref,
                  });
                }
              });

              recognitionDocs.sort((left, right) => {
                return left.order - right.order;
              });

              const recognitionStep = recognitionDocs[0];
              if (recognitionStep) {
                return db
                  .doc(`/paths/${pathId}`)
                  .get()
                  .then((pathDoc) => {
                    const experienceDoc = {
                      dateSubmitted: new Date(),
                      dateApproved: new Date(),
                      status: "Approved",
                      experienceLog: "You have given a recognition!",
                      type: "recognition",
                      step: {
                        id: recognitionStep.id,
                        name: recognitionStep.name,
                        path: recognitionStep.ref.path,
                      },
                      skill: {
                        id: pathDoc.id,
                        code: pathDoc.data().code,
                        name: pathDoc.data().name,
                        path: pathDoc.ref.path,
                      },
                      approver: {
                        // id: orgProfile.manager,
                        // name: "Jason Manners",
                        type: recognitionStep.approvalType,
                      },
                    };

                    // Add a new experience
                    return db
                      .collection(
                        `${userProfile.ref.path}/paths/${pathId}/experiences`
                      )
                      .add(experienceDoc);
                  });
              }
            });
        }
      }
    })
    .then((experienceDoc) => {
      return api.logging.createLogEntry(
        getOrgId(userProfile.ref),
        api.logging.TYPES.EXPERIENCE.SUBMIT,
        {
          message: `${userProfile.displayName} has sbumitted a recognition that satisfied an experience.`,
          related: db.doc(
            `${userProfile.ref.path}/paths/${pathId}/experiences/${experienceDoc.id}`
          ),
          response: "SUCCESS",
        }
      );
    });
}

function getOrgId(ref) {
  if (ref.parent.id === "organizations") {
    return ref.id;
  } else if (ref.parent) {
    return getOrgId(ref.parent);
  } else {
    return null;
  }
}

export { logExperience };
