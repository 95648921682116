import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Jumbotron from "react-bootstrap/Jumbotron";

import moment from "moment";

import { db, functions } from "../../../../../config/firebase";

import { api } from "../../../utils/api";

import "../../../../../style/Dashboard.scss";

import User from "../../../utils/classes/user";

import { useOrgState } from "../../../../../providers/OrgState";
import { useAuthState } from "../../../../../providers/AuthState";

function OrganizationUsers({ organization, orgId }) {
  /*
   * Form State Functionality
   * orgUsers - collection of all users in the organization on the platform
   */

  const { currentActiveUser, dispatch } = useOrgState();
  const { user, orgManagers } = useAuthState();

  let newUser = new User({ orgId: orgId });

  const [currentUser, setCurrentUser] = useState(newUser);
  const [currentActiveFlag, setCurrenActiveFlag] = useState(false);

  const [inviteClaimed, setInviteClaim] = useState(false);
  const [currentUserFlag, setCurrentUserFlag] = useState(
    currentActiveUser.id !== ""
  );

  /* State variable to control search */
  const [search, setSearch] = useState([]);

  /* Modal functionality for inviting users */
  const [show, setShow] = useState(false);

  function handleUserModalClose() {
    setShow(false);
  }

  function handleUserModalShow(currentUser) {
    organization.getUser(currentUser).then(function (doc) {
      let u = new User({
        ...doc.data(),
        id: doc.id,
        orgId: orgId,
      });
      setCurrentUser(u.toFirestoreFormat());
      setShow(true);
    });
  }

  function deleteUser(currentUser) {
    db.doc(`organizations/${orgId}/users/${currentUser.id}`).delete();
    setCurrent(organization.users[0]);
  }

  function handleUserUpdate() {
    organization.updateUser(currentUser).then(function (doc) {
      let u = new User({ orgId: orgId });
      dispatch({
        type: "SET_CURRENT_ORG_USER",
        payload: { currentActiveUser: currentUser },
      });
      setCurrentUser(u);
      setShow(false);
    });
  }

  /*
   * Use Effect - On page load
   * Get all of the superusers in the entire platform
   * Only for use with superusers!!  DB access is restricted to superusers only
   */
  useEffect(() => {
    if (organization.users.length > 0 && !currentActiveFlag) {
      let firstUser = organization.users[0];
      setCurrent(firstUser);
      setCurrenActiveFlag(true);
    }
  }, [orgId, organization.users]);

  function setCurrent(currentUser) {
    api.organization
      .wasInviteClaimedByUser(orgId, currentUser)
      .then(function (claimed) {
        console.log("[CLAIMED DOC] ", claimed);
        setInviteClaim(claimed);
        dispatch({
          type: "SET_CURRENT_ORG_USER",
          payload: { currentActiveUser: currentUser },
        });
      });
  }

  function isUserSkillActive(currentUser, skill) {
    if (currentUser.skills) {
      return (
        currentUser.skills.filter((s) => {
          return s.code === skill.code;
        }).length > 0
      );
    }
    return false;
  }

  function removeSkillFromUser(currentUser, skill) {
    organization.removeSkillFromUser(currentUser, skill);

    // Set the user progress to inactive
    db.doc(
      `organizations/${orgId}/users/${currentUser.id}/paths/${skill.id}`
    ).update({
      active: false,
    });
  }

  function addSkillToUser(currentUser, skill) {
    organization.addSkillToUser(currentUser, skill);
    var assignPath = functions.httpsCallable("skills-assignPath");
    assignPath({
      pathId: skill.id,
      userId: currentUser.id,
      organizationId: orgId,
    });
  }

  function NoUsers() {
    return (
      <Jumbotron>
        <h1>Waiting on Administrator</h1>
        <p>
          This organization was created and we are still waiting on the primary
          administrator to their claim the account.
        </p>
        <p>
          <Button variant="outline-primary">Remind</Button>
        </p>
      </Jumbotron>
    );
  }

  // useEffect(
  //   (e) => {
  //     if (firstUser) {
  //       setCurrent(firstUser);
  //     }
  //   },
  //   [firstUser, setCurrent]
  // );

  if (organization.users) {
    if (organization.users.length > 0) {
      return (
        <div>
          <div
            style={{
              marginBottom: "10px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
            }}
          >
            <Row>
              <Col xs={6}>
                <h5
                  className="text-primary"
                  style={{
                    letterSpacing: "-.02em",
                    fontWeight: "600",
                  }}
                >
                  Users
                </h5>
              </Col>
              <Col xs={6}>
                <Button
                  variant="outline-danger"
                  size="sm"
                  className="float-right font-weight-bold"
                  style={{ marginBottom: "5px", marginLeft: "5px" }}
                  onClick={function () {
                    deleteUser(currentActiveUser);
                  }}
                >
                  Delete User
                </Button>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="float-right font-weight-bold"
                  style={{ marginBottom: "5px" }}
                  onClick={function () {
                    handleUserModalShow(currentActiveUser);
                  }}
                >
                  Edit User
                </Button>
              </Col>
            </Row>
          </div>
          <Row style={{ minHeight: "450px" }}>
            <Col xs={3}>
              <Form>
                <Form.Group
                  controlId="searchForm.SearchBox"
                  style={{ marginBottom: "5px" }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    size="sm"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form>

              <ListGroup as="ul" style={{ marginBottom: "5px" }}>
                {Array.isArray(organization.users) &&
                  organization.users.map((currentUser, index) => {
                    let listGroupClass = "";
                    if (currentActiveUser) {
                      if (currentUser.id === currentActiveUser.id) {
                        listGroupClass = "text-sm active";
                      } else if (
                        currentUser.email.includes(search) ||
                        search.trim() === ""
                      ) {
                        listGroupClass = "text-sm";
                      } else {
                        listGroupClass = "text-sm d-none";
                      }
                    } else if (
                      !currentUser.active &&
                      currentUser.activationDate
                    ) {
                      return "";
                    }
                    return (
                      <ListGroup.Item
                        as="li"
                        className={listGroupClass}
                        key={currentUser.id}
                        style={{
                          padding: ".5rem .75rem",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          setCurrent(currentUser);
                        }}
                      >
                        <small>{currentUser.email}</small>
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Col>
            <Col xs={9}>
              {currentActiveUser.id ? (
                <Row>
                  <Col xs={6}>
                    <Row>
                      <Col xs={12}>
                        <OrganizationAttributeCard
                          label="NAME"
                          value={
                            currentActiveUser
                              ? currentActiveUser.displayName
                              : "None"
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <OrganizationAttributeCard
                          label="EMAIL"
                          value={
                            currentActiveUser.email
                              ? currentActiveUser.email
                              : "None"
                          }
                        />{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <OrganizationAttributeCard
                          label="IS USER AN SME?"
                          value={
                            currentActiveUser ? currentActiveUser.isSME : "None"
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Row>
                      <Col xs={12}>
                        <OrganizationAttributeCard
                          label="USER'S MANAGER"
                          value={
                            currentActiveUser.manager
                              ? organization.getUserDisplayNameById(
                                  currentActiveUser.manager
                                )
                              : "None"
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <OrganizationAttributeCard
                          label="CREATED DATE"
                          dateValue={
                            currentActiveUser
                              ? currentActiveUser.createdDate
                              : "None"
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <OrganizationAttributeCard
                          label="IS USER A MANAGER?"
                          value={
                            currentActiveUser
                              ? currentActiveUser.isManager
                              : "None"
                          }
                        />
                      </Col>
                    </Row>
                    {/* <Row>
                    <Col xs={12}>
                      <OrganizationAttributeCard
                        label="INVITE CLAIMED?"
                        value={inviteClaimed ? "Yes" : "No"}
                      />
                    </Col>
                  </Row> */}
                  </Col>
                  <Col xs={12}>
                    {organization.skills.length > 0 ? (
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th style={{ width: "250px" }}>Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(organization.skills) &&
                            organization.skills.map(function (skill) {
                              return (
                                <tr key={skill.name}>
                                  <td>{skill.name}</td>
                                  <td>
                                    {isUserSkillActive(
                                      currentActiveUser,
                                      skill
                                    ) ? (
                                      <Button
                                        variant="outline-danger"
                                        onClick={() => {
                                          removeSkillFromUser(
                                            currentActiveUser,
                                            skill
                                          );
                                        }}
                                      >
                                        Remove
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                          addSkillToUser(
                                            currentActiveUser,
                                            skill
                                          );
                                        }}
                                      >
                                        Add
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ) : (
                <Jumbotron>
                  <h1 className="text-primary">Select a user</h1>
                  <p>
                    To edit user information, or change user licenses please
                    select a user.
                  </p>
                </Jumbotron>
              )}
            </Col>
          </Row>
          <Modal
            show={show}
            onHide={handleUserModalClose}
            style={{ minWidth: "800px" }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <h5 className="text-secondary">Profile Details</h5>
                <OrganizationAttributeCard
                  label="CREATED DATE"
                  value={currentUser.displayName}
                />
                <OrganizationAttributeCard
                  label="CREATED DATE"
                  value={currentUser.email}
                />
                <OrganizationAttributeCard
                  label="CREATED DATE"
                  dateValue={currentUser.createdDate}
                />
                <h5 className="text-secondary">Organization Details</h5>

                <Form.Group controlId="userForm.manager">
                  <Form.Control
                    as="select"
                    value={currentUser.manager}
                    onChange={(e) => {
                      let u = { ...currentUser };
                      u.manager = e.target.value;
                      setCurrentUser(u);
                    }}
                  >
                    <option value="none">None</option>
                    {Array.isArray(orgManagers) &&
                      orgManagers.map((orgManager, index) => {
                        return (
                          <option value={orgManager.id} key={orgManager.id}>
                            {orgManager.displayName}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="userForm.isSME">
                  <Form.Label>SME</Form.Label>
                  <Form.Control
                    as="select"
                    value={currentUser.isSME}
                    onChange={(e) => {
                      let u = { ...currentUser };
                      u.isSME = e.target.value;
                      setCurrentUser(u);
                    }}
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="userForm.isManager">
                  <Form.Label>Manager</Form.Label>
                  <Form.Control
                    as="select"
                    value={currentUser.isManager}
                    onChange={(e) => {
                      let u = { ...currentUser };
                      u.isManager = e.target.value;
                      setCurrentUser(u);
                    }}
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                onClick={handleUserModalClose}
              >
                Close
              </Button>
              <Button
                variant="outline-primary"
                onClick={handleUserUpdate}
                className="float-right"
                style={{ marginLeft: "5px" }}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return <NoUsers />;
    }
  } else {
    return <NoUsers />;
  }
}

function OrganizationAttributeCard({ label, value, dateValue, args }) {
  let displayValue = value ? <div>{value}</div> : "";
  let displayDateValue = <div>{moment().format("MMM Do YY")}</div>;
  if (dateValue instanceof Date) {
    displayDateValue = (
      <div>{moment(displayDateValue).format("MMM Do YY")}</div>
    );
  } else if (dateValue) {
    displayDateValue = (
      <div>{moment(dateValue.toDate()).format("MMM Do YY")}</div>
    );
  } else {
    displayDateValue = "";
  }
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      {displayValue}
      {displayDateValue}
    </div>
  );
}

export default OrganizationUsers;
