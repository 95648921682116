import { api } from "../api";

export default class Invite {
  constructor(doc) {
    /* Fields */
    this.id = doc.id || "";
    this.displayName = doc.displayName || "";
    this.email = doc.email || "";
    this.isManager = doc.isManager || "No";
    this.isSME = doc.isSME || "No";
    this.claimed = doc.claimed || false;
    this.userId = doc.userId || "";
    this.org = doc.org || "";
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      displayName: this.displayName,
      email: this.email,
      isManager: this.isManager,
      isSME: this.isSME,
      claimed: this.claimed,
      userId: this.userId,
    };
  }

  toReducerFormat() {}

  save() {
    //console.log("Saving...");
    return api.organization.update(this.toFirestoreFormat());
  }

  get(orgId, inviteId) {
    return api.invite.get(orgId, inviteId);
  }

  updateField(field, value) {
    this[field] = value;
  }

  clear() {
    this.id = "";
    this.displayName = "";
    this.email = "";
    this.isManager = "No";
    this.isSME = "No";
    this.claimed = false;
    this.userId = "";
  }

  //   deleteInvite(organization, invite) {
  //     return api.invite.deleteInvite(organization.id, this.id);
  //   }

  //   getValidInvites() {
  //     return invites.filter(
  //       function (invite) {
  //         if (
  //           !this.domains.includes(
  //             invite.email
  //               .trim()
  //               .substring(invite.email.indexOf("@") + 1, invite.email.length)
  //           )
  //         ) {
  //           this.inviteErrors.push(
  //             "The email you have entered is not a valid domain!"
  //           );
  //         }
  //       }.bind(this)
  //     );
  //   }

  //   getInviteForm() {
  //     return this.invite.form;
  //   }

  //   processInvite(invite) {
  //     return api.organization
  //       .sendInvite(invite)
  //       .then(this.resetInvites)
  //       .catch(this.catchInviteErrors);
  //   }

  //   resetInvites() {
  //     this.resetInviteErrors();
  //     this.resetInviteForm();
  //   }

  //   resetInviteForm() {
  //     this.invite.form = {
  //       displayName: "",
  //       email: "",
  //       isManager: "No",
  //       isSME: "No",
  //       claimed: false,
  //     };
  //   }

  //   resetInviteErrors() {
  //     this.invite.errors = [];
  //   }

  //   catchInviteErrors() {
  //     //console.log("Error creating invite!");
  //   }

  //   sendInvites() {
  //     let inviteErrors = [];
  //     this.getValidInvites().forEach(this.processInvite);
  //     return;
  //   }

  static firebaseConvertor() {
    return this.toFirestoreFormat();
  }
}
