import React, { useState, useEffect } from "react";
import { Row, Col, Form, ListGroup, Media, Jumbotron } from "react-bootstrap";
import { Route, useRouteMatch, Link } from "react-router-dom";
import { MainHeading } from "../../../core/helpers/layout-components";

import ApprovalDetailLayout from "./approval-detail";

import { db } from "./../../../config/firebase";

import { Page } from "./../../skills/components/common";
import { useAuthState } from "../../../providers/AuthState";
import moment from "moment";

function ApprovalsLayout() {
  const [approvalGroups, setApprovalGroups] = useState([]);
  const [steps, setSteps] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasApprovals, setHasApprovals] = useState(false);

  const [selectedStep, setSelectedStep] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const { orgProfile, userApprovalGroups } = useAuthState();

  let { path } = useRouteMatch();
  useEffect(() => {
    const approverIds = [
      orgProfile.ref.id,
      ...userApprovalGroups.map((group) => group.id),
    ];

    return db
      .collection(`${orgProfile.ref.parent.parent.path}/experienceApprovals`)
      .where("experience.status", "==", "Submitted")
      .where("approver.id", "in", approverIds)
      .orderBy("dateSubmitted", "desc")
      .onSnapshot((snapshot) => {
        const defaultApprovalGroups = [
          { label: "Today", id: "today", approvals: [] },
          { label: "Last 7 Days", id: "week", approvals: [] },
          { label: "Older", id: "older", approvals: [] },
        ];

        const steps = [];
        const users = [];

        let hasApprovalsBool = hasApprovals;

        snapshot.forEach((doc) => {
          const docData = doc.data();

          const daysFromNow = moment().diff(
            moment(new Date(docData.dateSubmitted.seconds * 1000)),
            "days"
          );

          const approvalDoc = {
            ...docData,
            id: doc.id,
            ref: doc.ref,
          };

          hasApprovalsBool = true;

          if (daysFromNow === 0) {
            defaultApprovalGroups[0].approvals.push(approvalDoc);
          } else if (daysFromNow < 8) {
            defaultApprovalGroups[1].approvals.push(approvalDoc);
          } else {
            defaultApprovalGroups[2].approvals.push(approvalDoc);
          }

          if (!steps.some((step) => step.id === docData.step.id)) {
            steps.push({
              ...docData.step,
            });
          }

          if (!users.some((user) => user.id === docData.submitter.id)) {
            users.push({
              ...docData.submitter,
            });
          }
        });
        setApprovalGroups(defaultApprovalGroups);
        setSteps(steps);
        setUsers(users);
        setHasApprovals(hasApprovalsBool);
      });
  }, [orgProfile]);

  return (
    <Page>
      <Row style={{ marginBottom: "15px" }}>
        <Col md={6}>
          <MainHeading title="Approvals" />
        </Col>
        <Col></Col>
      </Row>
      {!hasApprovals && (
        <Jumbotron>
          <h5 className="text-primary" style={{ textAlign: "center" }}>
            No logs submitted for your approval
          </h5>
        </Jumbotron>
      )}
      {hasApprovals && (
        <Row>
          <Col md={5}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="steps">
                  <Form.Label>Step</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setSelectedStep(e.target.value);
                    }}
                    value={selectedStep}
                  >
                    <option />
                    {steps.map((step) => {
                      return <option value={step.id}>{step.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="users">
                  <Form.Label>User</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setSelectedUser(e.target.value);
                    }}
                    value={selectedUser}
                  >
                    <option />
                    {users.map((user) => {
                      return <option value={user.id}>{user.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <div>
              {approvalGroups.map((approvalGroup) => {
                const approvalsForGroup = approvalGroup.approvals.filter(
                  (approval) => {
                    const userMatch =
                      !selectedUser || approval.submitter.id === selectedUser;
                    const stepMatch =
                      !selectedStep || approval.step.id === selectedStep;
                    return userMatch && stepMatch;
                  }
                );
                if (approvalsForGroup.length === 0) {
                  return null;
                }

                return (
                  <div>
                    <div
                      style={{
                        fontSize: "1.2rem",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      {approvalGroup.label}{" "}
                    </div>
                    <ListGroup variant="flush">
                      {approvalsForGroup.map((approval) => {
                        const stepName = approval.skill
                          ? `${approval.skill.code}.${
                              approval.step.stageOrder || "-"
                            }.${approval.step.order || "-"}  ${
                              approval.step.name
                            }`
                          : approval.step.name;

                        return (
                          // <ListGroup.Item>
                          <Link
                            className="list-group-item list-group-item-action"
                            to={`/approvals/${approval.id}`}
                          >
                            <Media>
                              <span
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  border: "1px solid #aaa",
                                  borderRadius: "50%",
                                  textAlign: "center",
                                  marginRight: "10px",
                                  marginTop: "5px",
                                  fontSize: "1.25rem",
                                  backgroundColor: "#7E67AE",
                                  color: "#EBEBEB",
                                }}
                              >
                                {approval.submitter.name[0]}
                              </span>
                              {/* <img
                          width={32}
                          height={32}
                          className="mr-3"
                          src="https://via.placeholder.com/32"
                          alt="Generic placeholder"
                        /> */}
                              <Media.Body>
                                <h5 style={{ fontSize: "1.05rem" }}>
                                  {approval.submitter.name} - {stepName}
                                </h5>
                                <div style={{ fontSize: "0.95rem" }}>
                                  Submitted{" "}
                                  {moment(
                                    new Date(
                                      approval.dateSubmitted.seconds * 1000
                                    )
                                  ).fromNow()}
                                </div>
                              </Media.Body>
                            </Media>
                          </Link>
                          // </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col md={7}>
            <Route
              exact
              path={`${path}/:approvalId`}
              component={ApprovalDetailLayout}
            />
          </Col>
        </Row>
      )}
    </Page>
  );
}

export default ApprovalsLayout;
