import React, { useState } from "react";
import { Collapse, Row, Col, Button } from "react-bootstrap";
import { useParams, Link, useHistory } from "react-router-dom";

import { ContentDetail } from "../common";

import { useSkillStage } from "./hooks";
import { useSkillPathContext } from "./../providers";

function StageDetail({ canEdit = false }) {
  const [viewDetails, setViewDetails] = useState(false);

  const { skillStageSlug } = useParams();
  let history = useHistory();

  const { skillPath } = useSkillPathContext();

  const skillStageRef =
    skillPath.stages.length > skillStageSlug - 1 &&
    skillPath.stages[skillStageSlug - 1].ref;
  const skillStage = useSkillStage(skillStageRef);

  function deleteStage() {
    skillPath.id = skillPath.ref.parent.parent.id;
    skillPath
      .deleteStage(skillStage)
      .save()
      .then(() => {
        history.push(`/skills/${skillPath.code}`);
      });
  }

  return (
    skillStage && (
      <div>
        {/* <SubTitle title={skillStage.name} /> */}
        <Collapse in={viewDetails}>
          <div id="stageDetails">
            <ContentDetail heading={"Type"} content={skillStage.type} />
            <ContentDetail
              heading={"Objective"}
              content={skillStage.objective}
            />
            {canEdit && (
              <div>
                <Link
                  className="btn btn-outline-primary btn-sm"
                  to={`/skills/${skillPath.code}/${skillStageSlug}/edit`}
                >
                  Edit
                </Link>{" "}
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to delete? Stage ${skillStageSlug}: ${skillStage.name}`
                      )
                    ) {
                      deleteStage();
                    }
                  }}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </Collapse>
        <Row>
          <Col>
            <Link
              className="float-right"
              variant="primary"
              aria-controls="stageDetails"
              aria-expanded={viewDetails}
              onClick={() => {
                setViewDetails(!viewDetails);
              }}
            >
              {!viewDetails ? "Stage Details" : "Collapse Details"}
            </Link>
          </Col>
        </Row>
        <hr />
      </div>
    )
  );
}

export default StageDetail;
