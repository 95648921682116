import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

let config = {
  apiKey: "AIzaSyDhveQyu0ZP6itpxzw1ir-Pu2iudIep86I",
  authDomain: "metisphere-firebase.firebaseapp.com",
  databaseURL: "https://metisphere-firebase.firebaseio.com",
  projectId: "metisphere-firebase",
  storageBucket: "metisphere-firebase.appspot.com",
  messagingSenderId: "26130294837",
  appId: "1:26130294837:web:3dbe9679bdf2ac768fa97c",
};

firebase.initializeApp(config);

var db = firebase.firestore();

const functions = firebase.functions();

export { firebase, db, functions };
