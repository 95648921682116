import React from "react";
import { Row, Col } from "react-bootstrap";

import { PageTitle } from "../common";
import { useSkillPathContext } from "./../providers";

function UserPathHeader() {
  const { skillPath } = useSkillPathContext();

  return (
    skillPath.id && (
      <div>
        <Row>
          <Col>
            <PageTitle title={skillPath.name} pretitle={`Skill Path: ${skillPath.code}`} />
          </Col>
        </Row>
      </div>
    )
  );
}

export default UserPathHeader;
