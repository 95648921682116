import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { SuperUserDashboard } from "./components/superuser-dashboard";

function SuperUserConsole() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={SuperUserDashboard} />
    </Switch>
  );
}

export default SuperUserConsole;
