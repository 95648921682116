import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Jumbotron,
  Modal,
  Container,
  Badge,
  Form,
  Button,
  Table,
  Alert,
} from "react-bootstrap";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from "draft-js";

import { db } from "./../../../config/firebase";
import { api } from "./../../../common/utils/api";

import { RichTextEditor } from "./../../../common/components";

import { isSuperUser, useAuthState } from "./../../../providers/AuthState";

function WisdomApprovalsLayout({ orgId }) {
  const [wisdomApprovals, setWisdomApprovals] = useState([]);
  const [activeApproval, setActiveApproval] = useState(null);
  const [activeWisdom, setActiveWisdom] = useState(null);
  const [wisdomIsActive, setWisdomIsActive] = useState(true);
  const [showApprovalModal, setShowApprovalModal] = useState(false);

  const [activeWisdomEditorState, setActiveWisdomEditorState] = useState(() => {
    return EditorState.createEmpty();
  });

  const {
    orgProfile,
    orgContext,
    adminOrg,
    wisdomManagerForSkills,
    profileType,
  } = useAuthState();

  useEffect(() => {
    return db
      .collection(`organizations/${orgId}/wisdomApprovals`)
      .where("status", "==", "Submitted")
      .orderBy("submittedDate", "desc")
      .onSnapshot((snapshot) => {
        const approvalsArr = [];

        snapshot.forEach((approvalDoc) => {
          if (
            adminOrg ||
            wisdomManagerForSkills.some((skillId) => {
              return skillId === approvalDoc.data().path.id;
            }) ||
            isSuperUser(profileType)
          ) {
            approvalsArr.push({
              ...approvalDoc.data(),
              id: approvalDoc.id,
              ref: approvalDoc.ref,
            });
          }
        });

        setWisdomApprovals(approvalsArr);
      });
  }, [orgId]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (activeApproval) {
        activeApproval.wisdomRef.get().then((wisdomDoc) => {
          const currentWisdom = {
            ...wisdomDoc.data(),
            id: wisdomDoc.id,
            ref: wisdomDoc.ref,
          };
          currentWisdom.body = EditorState.createWithContent(
            convertFromRaw(currentWisdom.body)
          );

          setActiveWisdom(currentWisdom);
          setActiveWisdomEditorState(currentWisdom.body);
        });
      } else {
        setActiveWisdom(null);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [activeApproval]);

  function hasApprovals() {
    return wisdomApprovals.length > 0;
  }

  function openApprovalModal(approval) {
    setShowApprovalModal(true);
    setActiveApproval(approval);
  }

  function approveWisdom() {
    const approvalUpdateDoc = {
      title: activeWisdom.title,
      body: convertToRaw(activeWisdomEditorState.getCurrentContent()),
      bodyText: activeWisdomEditorState.getCurrentContent().getPlainText(),
      status: "Approved",
      approved: true,
      active: wisdomIsActive,
    };

    activeApproval.ref
      .update({ status: "Approved" })
      .then(() => {
        return activeWisdom.ref.update(approvalUpdateDoc);
      })
      .then(() => {
        if (activeWisdom.experienceCreditForApprovedWisdom) {
          const experienceDoc = {
            dateSubmitted: new Date(),
            dateApproved: new Date(),
            status: "Approved",
            experienceLog: "You have contributed to the collective wisdom!",
            type: "wisdom",
            step: {
              id: activeApproval.step.id,
              name: activeApproval.step.name,
              path: activeApproval.step.ref.path,
            },
            skill: {
              id: activeApproval.path.id,
              code: activeApproval.path.code,
              name: activeApproval.path.name,
              path: activeApproval.path.ref.path,
            },
            approver: {
              id: orgProfile.id,
              type: "wisdom",
            },
          };
          // Add a new experience
          return db
            .collection(
              `${activeApproval.submittedBy.path}/paths/${activeApproval.path.id}/experiences`
            )
            .add(experienceDoc);
        }
        return null;
      })
      .then(() => {
        return api.logging.createLogEntry(
          orgContext.id,
          api.logging.TYPES.WISDOM.APPROVE,
          {
            message: `${orgProfile.displayName} has approved wisdom.`,
            related: activeWisdom.ref,
            response: "SUCCESS",
          }
        );
      })
      .then(() => {
        handleClose();
      });
  }

  function rejectWisdom() {
    activeApproval.ref
      .update({ status: "Rejected" })
      .then(() => {
        return activeWisdom.ref.update({ status: "Rejected", active: false });
      })
      .then(() => {
        return api.logging.createLogEntry(
          orgContext.id,
          api.logging.TYPES.WISDOM.RETURN,
          {
            message: `${orgProfile.displayName} has returned wisdom.`,
            related: activeWisdom.ref,
            response: "SUCCESS",
          }
        );
      })
      .then(() => {
        handleClose();
      });
  }

  function handleClose() {
    setShowApprovalModal(false);
  }

  return (
    <div>
      <Row>
        <Col>
          {!hasApprovals() && (
            <Jumbotron>
              <h5 className="text-primary" style={{ textAlign: "center" }}>
                No pending approvals.
              </h5>
            </Jumbotron>
          )}
          {hasApprovals() && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Path</th>
                  <th>Step</th>
                  <th>Status</th>
                  <th>Submitted By</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {wisdomApprovals.map((approval) => {
                  return (
                    <tr>
                      <td>
                        {approval.path.code}-{approval.path.name}
                      </td>
                      <td>{approval.step.name}</td>
                      <td>{approval.status}</td>
                      <td>{approval.submittedBy.displayName}</td>
                      <td>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => {
                            openApprovalModal(approval);
                          }}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>

      {activeApproval && activeWisdom && (
        <Modal show={showApprovalModal} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Wisdom Approval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div>
                <Row>
                  <Col>
                    <h4>
                      {activeApproval.path.code}-{activeApproval.path.name}:{" "}
                      {activeApproval.step.name}
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        fontSize: "1.5rem",
                        marginBottom: "10px",
                      }}
                    >
                      <Form.Group controlId="wisdomName">
                        <Form.Control
                          type="text"
                          placeholder="Title"
                          value={activeWisdom.title}
                          onChange={(e) => {
                            setActiveWisdom({
                              ...activeWisdom,
                              title: e.target.value,
                            });
                            // setWisdomTitle(e.target.value);
                          }}
                        />
                      </Form.Group>
                      {/* <div>
                        <span style={{ borderBottom: "1px solid #6c757d" }}>
                          {activeWisdom.title}
                        </span>
                      </div> */}
                    </div>
                  </Col>
                  <Col>
                    <div className="float-right">
                      <div style={{ fontSize: "1.2em" }}>
                        {activeWisdom.tags.map((tag) => {
                          return (
                            <Badge
                              variant="primary"
                              style={{ marginRight: "3px" }}
                            >
                              {tag}
                            </Badge>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <RichTextEditor
                      editorState={activeWisdomEditorState}
                      setEditorState={setActiveWisdomEditorState}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="saveToDB">
                      <Form.Check
                        type="switch"
                        label="Active"
                        defaultChecked={wisdomIsActive}
                        onChange={(e) => {
                          setWisdomIsActive(!wisdomIsActive);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              {activeWisdom.experienceCreditForApprovedWisdom && (
                <Row>
                  <Col>
                    <Alert variant="success mt-2 text-center">
                      {activeApproval.submittedBy.displayName} will receive
                      experience credit if this wisdom is approved.
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <div className="float-right">
                    <Button variant="outline-primary" onClick={approveWisdom}>
                      Approve
                    </Button>{" "}
                    <Button variant="outline-danger" onClick={rejectWisdom}>
                      Reject
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default WisdomApprovalsLayout;
