import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ApprovalsDashboardLayout from "./approvals-dashboard";

function ApprovalsLayout() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={ApprovalsDashboardLayout} />
    </Switch>
  );
}

export default ApprovalsLayout;
