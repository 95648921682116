import React from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

import {
  StepList,
  StepDetail,
  StepForm,
  StepRelated,
  StepApprovals,
  StepVersions,
  StepContent,
  StepWisdom,
} from "./../components/skill-step";

import { db } from "./../../../config/firebase";

import { useSkillPathContext } from "./../components/providers";
import { useSkillStep } from "./../components/skill-step/hooks";

function ContentSkillStepLayout() {
  let { path } = useRouteMatch();

  const { skillStageSlug, skillStepSlug, orgId } = useParams();
  const { skillPath } = useSkillPathContext();

  let skillStepRef = null;
  if (skillPath) {
    skillStepRef = skillPath.getSkillStepRefFromSlugs({
      skillStageSlug,
      skillStepSlug,
    });
  }

  let stepRefPath = "";
  if (skillStepRef) {
    // @TODO: Figure out why it referenced versions/staged, should be referencing published version
    stepRefPath = `${skillStepRef.ref.path}/versions/staged`;
    // stepRefPath = `${skillStepRef.ref.path}`;
  }
  const skillStep = useSkillStep(stepRefPath);

  return (
    skillPath && (
      <div>
        <Row>
          <Col md={3}>
            <h4>Steps</h4>
          </Col>
          <Col md={8}>
            <h4>{skillStep.name}</h4>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <StepList />
          </Col>
          <Col md={8}>
            {skillStep.id && (
              <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
                <Tab eventKey="overview" title="Overview">
                  <StepDetail skillStep={skillStep} canEdit={false} />
                </Tab>
                <Tab eventKey="content" title="Content">
                  <StepContent
                    skillStep={skillStep}
                    canEdit={true}
                    addingContent={true}
                    orgId={orgId}
                  />
                </Tab>
                <Tab eventKey="wisom" title="Wisdom">
                  <StepWisdom skillStep={skillStep} canEdit={true} />
                </Tab>
                <Tab eventKey="approvals" title="Approvals">
                  <StepApprovals skillStep={skillStep} />
                </Tab>
                <Tab eventKey="related" title="Related">
                  <StepRelated skillStep={skillStep} />
                </Tab>
              </Tabs>
            )}
          </Col>
        </Row>
      </div>
    )
  );
}

export default ContentSkillStepLayout;
