import { useReducer, useEffect } from "react";

import { api } from "../../../utils";
import { SkillStage } from "../../../utils/classes/skill-stage";

function skillStageReducer(state, action) {
  switch (action.type) {
    case "LOAD_STAGE": {
      return action.payload;
    }
    case "UPDATE_FIELD": {
      return { ...state, [action.payload.field]: action.payload.value };
    }

    default:
      return state;
  }
}

function useSkillStageForm(skillPath, skillStageSlug) {
  const [state, dispatch] = useReducer(skillStageReducer, {
    path: skillPath,
    order: skillPath.stages.length + 1,
  });

  useEffect(() => {
    if (skillStageSlug && skillPath.stages.length >= skillStageSlug) {
      return api.skillStage.getStageByRef(
        skillPath.getSkillStageRefFromSlugs({ skillStageSlug }),
        (skillStage) => {
          dispatch({ type: "LOAD_STAGE", payload: skillStage });
        }
      );
    }
  }, [skillPath, skillStageSlug]);


  const value = {
    ...state,
    updateField(field, value) {
      dispatch({
        type: "UPDATE_FIELD",
        payload: { field: field, value: value },
      });
    },
    getSkillStageInstance() {
      return new SkillStage(state);
    },
  };
  return value;
}

export default useSkillStageForm;
