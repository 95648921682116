import { useReducer } from "react";
import Auth from "../utils/classes/auth";

function authReducer(state, action) {
  switch (action.type) {
    case "UPDATE_FIELD": {
      //console.log(action);

      return { ...state, [action.payload.field]: action.payload.value };
    }
    default:
      return state;
  }
}
function useLoginForm() {
  let initialState = new Auth({});
  const [state, dispatch] = useReducer(authReducer, initialState);

  const value = {
    ...state,
    updateField(field, value) {
      dispatch({
        type: "UPDATE_FIELD",
        payload: { field: field, value: value },
      });
    },
    update(auth) {
      dispatch({
        type: "UPDATE",
        payload: { signup: auth },
      });
    },
    get() {
      return new Auth(state);
    },
    login() {
      let auth = new Auth(state);
      return auth.login();
    },
    completeToken(tokenDoc) {
      let auth = new Auth(state);
      return auth.completeToken(tokenDoc);
    },
    updatePassword(user, profile) {
      let auth = new Auth(state);
      return auth.updatePassword(user, profile);
    },
    validatePasswordUpdate() {
      let auth = new Auth(state);
      return auth.validatePasswordUpdate();
    },
    getUpdatedPasswordErrors() {
      let auth = new Auth(state);
      return auth.getUpdatePasswordErrors();
    },
  };
  return value;
}

export default useLoginForm;
