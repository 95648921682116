import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SuperUserAnalyticsCard from "./superuser-analytics-card";

import "../../../../../style/Dashboard.scss";

function SuperUserDashboardAnalytics() {
  return (
    <Row>
      <Col>
        <Row>
          <Col xs={12} md={9} style={{ paddingRight: "0px" }}>
            <SuperUserAnalyticsCard
              analytic="$36,370,000"
              analyticTitle="Total Revenue"
              analyticDescriptor="Total ARR (TTM)"
              analyticTheme="text-success"
            />
          </Col>
          <Col xs={12} md={3}>
            <SuperUserAnalyticsCard
              analytic="491%"
              analyticTitle="Total YoY Growth"
              analyticDescriptor="Percent Year over Year"
              analyticTheme="text-success"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3} style={{ paddingRight: "0px" }}>
            <SuperUserAnalyticsCard
              analytic="1,430"
              analyticTitle="Total Organizations"
              analyticDescriptor="Organizations Onboarded"
              analyticTheme="text-secondary"
            />
          </Col>
          <Col xs={12} md={3} style={{ paddingRight: "0px" }}>
            <SuperUserAnalyticsCard
              analytic="143,000"
              analyticTitle="Total Users"
              analyticDescriptor="Total Active Users"
              analyticTheme="text-secondary"
            />
          </Col>
          <Col xs={12} md={3} style={{ paddingRight: "0px" }}>
            <SuperUserAnalyticsCard
              analytic="1,949,871"
              analyticTitle="Total Skills Cards"
              analyticDescriptor="Skills Cards Completed"
              analyticTheme="text-secondary"
            />
          </Col>
          <Col xs={12} md={3}>
            <SuperUserAnalyticsCard
              analytic="10,840,567"
              analyticTitle="Total Experiences"
              analyticDescriptor="Total Experience Logged"
              analyticTheme="text-secondary"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3} style={{ paddingRight: "0px" }}>
            <SuperUserAnalyticsCard
              analytic="190,000"
              analyticTitle="Total Courses Completed"
              analyticDescriptor="Total Skills Courses Completed"
              analyticTheme="text-secondary"
            />
          </Col>
          <Col xs={12} md={3} style={{ paddingRight: "0px" }}>
            <SuperUserAnalyticsCard
              analytic="63,460"
              analyticTitle="Total Courses Available"
              analyticDescriptor="Total Skills Courses Offered"
              analyticTheme="text-secondary"
            />
          </Col>
          <Col xs={12} md={3} style={{ paddingRight: "0px" }}>
            <SuperUserAnalyticsCard
              analytic="1,302,000"
              analyticTitle="Total Approved Skills"
              analyticDescriptor="Total Approved Skills"
              analyticTheme="text-secondary"
            />
          </Col>
          <Col xs={12} md={3}>
            <SuperUserAnalyticsCard
              analytic="32,000"
              analyticTitle="Total Partners"
              analyticDescriptor="Total Third Party Partners"
              analyticTheme="text-secondary"
            />
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SuperUserDashboardAnalytics;
