import React from "react";
import { Row, Col } from "react-bootstrap";

import { ContentDetail } from "../common";

import { useSkillPathContext } from "./../providers";

function PathDetail() {
  const { skillPath } = useSkillPathContext();

  return (
    skillPath && (
      <div>
        <Row>
          <Col>
            <ContentDetail
              heading={"Objective"}
              content={skillPath.objective}
            />
          </Col>
          <Col>
            <ContentDetail
              heading={"Published Version"}
              content={skillPath.version - 1}
            />
          </Col>
        </Row>
        <hr />
      </div>
    )
  );
}

export default PathDetail;
