import React, { useState } from "react";
import { Row, Col, Button, Modal, Container } from "react-bootstrap";

import StepContentForm from "./../skill-step/step-content-form";
import { useSkillPathContext } from "../providers";

function ContentPane({ content, canEdit, skillStep }) {
  const [showEditModal, setShowEditModal] = useState(false);

  const { addingContent } = useSkillPathContext();

  function handleEdit(content) {
    setShowEditModal(true);
  }

  function handleClose() {
    setShowEditModal(false);
  }

  function handleDelete(content) {
    if (window.confirm(`Are you sure you want to delete ${content.title}?`)) {
      content.ref.delete();
    }
  }

  if (content) {
    return (
      <div>
        <Row>
          <Col>
            <div
              style={{
                fontSize: "1.1rem",

                marginBottom: "10px",
              }}
            >
              <div>
                <span style={{ borderBottom: "1px solid #6c757d" }}>
                  {content.title}
                </span>
              </div>
            </div>
          </Col>
        </Row>
        {canEdit && (
          <Row>
            <Col md={2}>
              <strong>Type</strong>
              <p>{content.type}</p>
            </Col>
            <Col md={8}>
              <strong>Link</strong>
              {content.type !== 'Link' && (
                <p>{content.link}</p>
              )}
              {content.type === 'Link' && (
                <div>
                  <a
                    className="btn btn-outline-primary btn-sm"
                    href={content.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Go To Link
                </a>
                </div>
              )}

            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {canEdit && <strong>Description</strong>}
            <p>{content.description}</p>
          </Col>
        </Row>
        {
          content.link && content.type !== 'Link' && (
            <Row>
              <Col>
                <iframe
                  width="100%"
                  height="315"
                  src={content.link}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  title="Content Link"
                  allowFullScreen
                ></iframe>
              </Col>
            </Row>
          )
        }
        {!canEdit && content.link && content.type === "Link" && (
          <Row style={{ marginBottom: '15px', verticalAlign: 'top', height: '315px' }}>
            <Col>
              <a
                className="btn btn-outline-primary btn-sm"
                href={content.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go To Link
              </a>
            </Col>
          </Row>
        )}
        {
          canEdit &&
          ((!content.templateContent && addingContent) || !addingContent) && (
            <div>
              <Button
                variant="outline-primary"
                onClick={() => {
                  handleEdit(content);
                }}
              >
                Edit
              </Button>{" "}
              <Button
                variant="outline-danger"
                onClick={() => {
                  handleDelete(content);
                }}
              >
                Delete
              </Button>
            </div>
          )
        }
        {
          canEdit && content.templateContent && (
            <div style={{ minHeight: "38px" }}></div>
          )
        }
        {
          showEditModal && (
            <Modal show={showEditModal} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Edit Content</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  {content && (
                    <StepContentForm
                      existingStepContent={content}
                      skillStep={skillStep}
                      handleClose={handleClose}
                    />
                  )}
                </Container>
              </Modal.Body>
            </Modal>
          )
        }
      </div >
    );
  }
  return null;
}

export default ContentPane;
