import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../../style/Recognition.scss";

function Recognitions({ recognitions, send }) {
  return (
    <Row>
      <Col className="recognition-cards">
        <Row>
          <Col xs={12}>Waiting for Recognitions</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Recognitions;
