import { api } from "../api";

export class SkillStage {
  constructor(doc) {
    this.id = doc.id || "";
    this.name = doc.name || "";
    this.order = doc.order || "";
    this.type = doc.type || "Progress";
    this.objective = doc.objective || "";
    this.skillPathId = "";

    return this;
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      name: this.name,
      order: this.order,
      objective: this.objective,
      type: this.type,
    };
  }

  toReducerFormat() {}

  setParentIds(skillPathId) {
    this.skillPathId = skillPathId;
    if (this.id === "" && this.skillPathId) {
      const ref = api.skillStage.getId(this.skillPathId);
      this.id = ref.id;
      this.ref = ref;
    }

    return this;
  }

  save(orgId) {
    if (orgId) {
      return api.skillStage.updateOnOg(
        this.skillPathId,
        orgId,
        this.toFirestoreFormat()
      );
    } else {
      return api.skillStage.update(this.skillPathId, this.toFirestoreFormat());
    }
  }

  static firebaseConvertor(skillStage) {
    return skillStage.toFirestoreFormat();
  }
}
