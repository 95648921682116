import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  ListGroup,
  Button,
  Form,
  Modal,
  Nav,
  Tab,
  Alert,
} from "react-bootstrap";

import moment from "moment";

function OrganizationSettings({ organization, orgId }) {
  return (
    <div>
      <h5 className="text-secondary font-weight-bold">Actions</h5>
      <div>
        <ListGroup>
          <ListGroup.Item>
            <Row>
              <Col xs={9}>
                <div className="font-weight-bold text-danger">
                  Delete organization.
                </div>
                <div className="text-muted">
                  Permanently delete this organization, users, and skills from
                  Metisphere.
                </div>
              </Col>
              <Col xs={3}>
                <Button variant="outline-danger">Delete</Button>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col xs={9}>
                <div className="font-weight-bold text-secondary">
                  Change the Administrator
                </div>
                <div className="text-muted">
                  Change the administrator associated to this organization.
                </div>
              </Col>
              <Col xs={3}>
                <Button variant="outline-secondary">Change</Button>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>This is a test.</ListGroup.Item>
          <ListGroup.Item>This is a test.</ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
}

function OrganizationAttributeCard({
  label,
  value,
  dateValue,
  dateFromFirebase,
}) {
  let displayValue = value ? <div>{value}</div> : "";
  let displayDateValue = "";
  //console.log("[DATE] ", dateValue);
  if (dateValue) {
    //console.log(dateValue.hasOwnProperty("toDate"));
    if (!moment(dateValue).isValid()) {
      displayDateValue = moment(dateValue.toDate()).format("MMM Do YY");
    } else {
      displayDateValue = moment(dateValue).format("MMM Do YY");
    }
  }
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      {displayValue}
      <div>{displayDateValue}</div>
    </div>
  );
}

export default OrganizationSettings;
