import skillPath from "./skill-path";
import skillStage from "./skill-stage";
import skillStep from "./skill-step";

export const api = {
  skillPath,
  skillStage,
  skillStep,
};

// import { update, getActiveForVendor, getDoc, getSkillPath } from "./skill-path";

// export const api = {
//   skillPath: {
//     update,
//     getActiveForVendor,
//     getDoc,
//     getSkillPath,
//   },
//   stagePath: { getId, update },
// };
