import React from "react";

import Button from "react-bootstrap/Button";

function RecognitionButton({ onClickHandler }) {
  return (
    <Button
      variant="outline-primary"
      className="font-weight-bold float-right"
      onClick={onClickHandler}
      style={{ marginBottom: "10px" }}
    >
      Give Recognition
    </Button>
  );
}

export default RecognitionButton;
