import { useReducer, useEffect } from "react";
import { SkillStep } from "../../../utils/classes/skill-step";

function skillStepReducer(state, action) {
  switch (action.type) {
    case "LOAD_STEP": {
      return { ...action.payload };
    }
    case "UPDATE_FIELD": {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        published: false,
      };
    }
    default:
      return state;
  }
}

function useSkillStepForm(skillPath, skillStageSlug, existingSkillStep) {
  const [state, dispatch] = useReducer(
    skillStepReducer,
    existingSkillStep || {
      path: skillPath,
      order:
        (skillPath.stages[skillStageSlug - 1] &&
          skillPath.stages[skillStageSlug - 1].steps.length + 1) ||
        1,
      skillStageSlug,
      numberOfLogsRequired: 1,
      published: false,
    }
  );

  useEffect(() => {
    //console.log("loading", existingSkillStep);
    if (existingSkillStep) {
      dispatch({
        type: "LOAD_STEP",
        payload: existingSkillStep,
      });
    }
  }, [existingSkillStep]);

  const value = {
    ...state,
    updateField(field, value) {
      dispatch({
        type: "UPDATE_FIELD",
        payload: { field: field, value: value },
      });
    },
    getSkillStepInstance() {
      return new SkillStep(state);
    },
  };
  return value;
}

export default useSkillStepForm;
