import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

import { api } from "../../utils/api";

import { useAuthState } from "../../../../providers/AuthState";
import { useProfileForm } from "../profile/hooks";

function ProfileEdit() {
  let uid = undefined;
  const profileForm = useProfileForm();

  const { user, profile } = useAuthState();

  useEffect(() => {
    if (user) {
      api.profile.get(user.uid).then(function (doc) {
        let p = {
          ...doc.data(),
          id: doc.id,
        };
        profileForm.update(p);
      });
    }
  }, [user]);

  function ThirdPartyAuthProfile() {
    return (
      <Row>
        <Col>
          <Row>
            <Col xs={12}>
              {" "}
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0,0,.125)",
                  marginBottom: "15px",
                }}
              >
                <Row>
                  <Col xs={12}>
                    <h6
                      className="text-primary"
                      style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                    >
                      Profile Details
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center" xs={4}>
              <img
                src={user.photoURL}
                style={{ width: "128px", height: "128px", borderRadius: "50%" }}
                alt="Profile Photo"
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <AttributeCard label="Name" value={user.displayName} />
                </Col>
                <Col>
                  <AttributeCard label="Login" value={user.b.b.a} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AttributeCard label="Email" value={user.email} />
                </Col>
                <Col>
                  <AttributeCard
                    label="Email Verified"
                    value={user.emailVerified ? "Yes" : "No"}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AttributeCard
                    label="Phone"
                    value={user.phoneNumber ? user.phoneNumber : "NA"}
                  />
                </Col>
                <Col>
                  <AttributeCard label="User Type" value={profileForm.type} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  function NonThirdPartyAuthProfile() {
    return (
      <Row>
        <Col>
          <Row>
            <Col xs={12}>
              {" "}
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0,0,.125)",
                  marginBottom: "15px",
                }}
              >
                <Row>
                  <Col xs={12}>
                    <h6
                      className="text-primary"
                      style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                    >
                      Profile Details
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center" xs={4}>
              <img
                src={profile.photoPath}
                style={{ width: "128px" }}
                alt="Profile Photo"
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <AttributeCard label="Name" value={user.displayName} />
                </Col>
                <Col>
                  <AttributeCard label="Login" value="metisphere.com" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AttributeCard label="Email" value={user.email} />
                </Col>
                <Col>
                  <AttributeCard
                    label="Email Verified"
                    value={user.emailVerified ? "Yes" : "No"}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AttributeCard label="User Type" value={profileForm.type} />
                </Col>
                <Col></Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  function AuthProfile({ user }) {
    if (user) {
      if (user.b.b.a === "google.com") {
        return <ThirdPartyAuthProfile />;
      }
    }
    return <NonThirdPartyAuthProfile />;
  }

  // function handleSubmit() {
  //   api.profileForm.update(profileForm.get().toFirestoreFormat()).then(function () {
  //     //console.log("Success!");
  //   });
  // }

  function getEditLinkPath() {
    if (user) {
      if (user.b.b.a === "google.com") {
        return "/profile/edit/thirdParty";
      }
    }
    return "/profile/edit";
  }

  ////console.log("Is Third Party Auth: ", isThirdPartyAuth(user));

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,.125)",
                marginBottom: "15px",
                marginTop: "15px",
              }}
            >
              <Row>
                <Col xs={6}>
                  <div className="text-info header-pretitle">profileForm</div>
                  <h4
                    className="text-secondary"
                    style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                  >
                    {profileForm.displayName}
                  </h4>
                </Col>
                <Col>
                  <Link
                    className="btn btn-outline-primary font-weight-bold float-right"
                    to={getEditLinkPath()}
                    style={{ marginRight: "5px" }}
                  >
                    Edit
                  </Link>
                </Col>
              </Row>
            </div>
            <div>
              <AuthProfile user={user} />
            </div>
            <div>
              <Row>
                <Col className="text-center" xs={4}>
                  {/* {" "}
                  <div
                    style={{
                      borderBottom: "1px solid rgba(0,0,0,.125)",
                      marginBottom: "15px",
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <div
                          className="text-secondary text-left"
                          style={{
                            letterSpacing: "-.02em",
                            fontWeight: "600",
                            paddingBottom: "15px",
                          }}
                        >
                          Organizations
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <ListGroup
                    style={{
                      letterSpacing: "-.02em",
                      fontWeight: "600",
                      marginTop: "15px",
                    }}
                  >
                    <ListGroup.Item className="text-left">
                      Metisphere
                    </ListGroup.Item>
                    <ListGroup.Item className="text-left">
                      Trillium Ventures, LLC
                    </ListGroup.Item>
                  </ListGroup> */}
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <AttributeCard
                        label="Transcript Email"
                        value={profileForm.transcriptEmail}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AttributeCard label="City" value={profileForm.city} />
                    </Col>
                    <Col>
                      <AttributeCard
                        label="Country"
                        value={profileForm.country}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AttributeCard
                        label="Learning Summary Frequency"
                        value={profileForm.learnerSummaryFrequency}
                      />
                    </Col>
                    <Col>
                      <AttributeCard
                        label="SME Summary Frequency"
                        value={profileForm.smeSummaryFrequency}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function AttributeCard({ label, value, args }) {
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      <div>{value}</div>
    </div>
  );
}

// function FormGroupCard({ label, value, args }) {
//   return (
//     <div style={{ paddingBottom: "15px" }}>
//       <div className="text-info header-pretitle">{label}</div>
//       <div style={{ paddingTop: "15px" }}>{value}</div>
//     </div>
//   );
// }

export default ProfileEdit;
