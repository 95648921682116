import React from "react";
import { Row, Col, Jumbotron } from "react-bootstrap";

export default function NoRecognitions() {
  return (
    <Row>
      <Col xs={12}>
        <Jumbotron>
          <h5 className="text-primary">No recognitions.</h5>
          <p>
            Click "Add Recognition" to give your organizations first
            recognition.
          </p>
        </Jumbotron>
      </Col>
    </Row>
  );
}
