import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";

import { useSkillPathContext } from "../providers";
import { api } from "../../utils";

function PathVersions() {
  const [versions, setVersions] = useState([]);

  const { skillPath } = useSkillPathContext();

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && skillPath.id && skillPath.ref.parent.parent.id) {
      api.skillPath.getSkillPathVersions(
        skillPath.ref.parent.parent.id,
        setVersions
      );
    }

    return () => {
      isCurrent = false;
    };
  }, [skillPath.id]);

  return (
    <div>
      <h4>Versions</h4>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>#</th>
            <th>Date Published</th>
            <th>Published By</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {versions.map((version, ind) => {
            return (
              <tr key={version.id}>
                <td style={{ textAlign: "center" }}>{version.version}</td>
                <td>
                  {version.datePublished &&
                    new Date(
                      version.datePublished.seconds * 1000
                    ).toLocaleDateString()}
                </td>
                <td>{version.author}</td>
                {/* <td>
                  <Button size="sm" variant="outline-primary">
                    View
                  </Button>{" "}
                  <Button size="sm" variant="outline-primary">
                    Revert To
                  </Button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default PathVersions;

// getSkillPathVersions
