import { db } from "../../../../config/firebase";

export function get(profileId) {
  return db.collection("profiles").doc(profileId).get();
}

export function update(profile) {
  console.log("Saving to Firebase...");
  console.log(profile);
  return db.collection("profiles").doc(profile.id).update(profile);
}

export default {
  update,
  get,
};
