import { db, firebase } from "../../../../config/firebase";

export function get(firebaseUserObject) {
  return db.collection("profiles").doc(firebaseUserObject.uid).get();
}

export function getPhoto(user, profile) {
  if (profile.photoURL) {
    var storage = firebase.storage();
    //Get Photo Ref to Cloud Storage
    var profilePhotoRef = storage.ref(profile.photoURL);
    // Get the download URL to display the image
    return profilePhotoRef.getDownloadURL();
  } else {
    return new Promise(function (resolve, reject) {
      let url = "";
      resolve(url);
    });
  }
}

export function setFirstTimePasswordUpdate(user, profile) {
  let updatedProfile = { passwordUpdated: true };
  return db.collection("profiles").doc(user.uid).update(updatedProfile);
}

export default {
  get,
  getPhoto,
  setFirstTimePasswordUpdate,
};
