import React from "react";
import { Row, Col } from "react-bootstrap";

import { StepList, StepForm } from "./../components/skill-step";

import { useSkillPathContext } from "./../components/providers";

function CreateSkillStepLayout() {
  const { skillPath } = useSkillPathContext();

  return (
    skillPath && (
      <div>
        <Row>
          <Col md={3}>
            <h4>Steps</h4>
          </Col>
          <Col md={8}>
            <h4>New Step</h4>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <StepList />
          </Col>
          <Col md={8}>
            <StepForm />
          </Col>
        </Row>
      </div>
    )
  );
}

export default CreateSkillStepLayout;
