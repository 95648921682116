import { db } from "../../../../config/firebase";
import { SkillStage } from "../classes/skill-stage";

export function getId(skillPathId) {
  return db.collection(`/paths/${skillPathId}/stages`).doc();
}

export function update(skillPathId, skillStage) {
  return db
    .doc(`/paths/${skillPathId}/stages/${skillStage.id}`)
    .set(skillStage);
}

export function updateOnOg(skillPathId, orgId, skillStage) {
  return db
    .doc(`/organizations/${orgId}/paths/${skillPathId}/stages/${skillStage.id}`)
    .set(skillStage);
}

export function getStageByRef(stageRef, setStage) {
  return stageRef.onSnapshot((snapshot) => {
    setStage(
      new SkillStage({
        ...snapshot.data(),
        id: snapshot.id,
        ref: snapshot.ref,
      })
    );
  });
}

export default {
  update,
  getId,
  getStageByRef,
  updateOnOg,
};
