import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import { firebase } from "../../config/firebase";

import { useAuthState } from "../../providers/AuthState";

import { useHistory } from "react-router-dom";

function SignedInLinks() {
  const { user, profile, dispatch } = useAuthState();
  const history = useHistory();

  //console.log("User ID: ", user.uid);

  function handleSignOut() {
    firebase
      .auth()
      .signOut()
      .then(function () {
        history.push("/signIn");
        dispatch({ type: "LOGOUT" });
      })
      .catch(function (error) {
        //console.log("An error has occured!");
      });
  }

  function handleProfile() {
    history.push("/profile");
  }

  function handlePasswordUpdate() {
    history.push("/signInBeforePasswordUpdate");
  }

  console.log("[USER] ", user.b.b.a, user.photoURL);

  if (user.b.b.a === "google.com") {
    return (
      <div>
        <Dropdown as={ButtonGroup}>
          <Button
            variant="outline-primary"
            style={{ color: "white" }}
            size="sm"
          >
            {user.photoURL ? (
              <img
                src={user.photoURL}
                alt={user.displayName}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "1px solid white",
                  marginRight: "8px",
                }}
              />
            ) : (
              ""
            )}
            {user.displayName}
          </Button>

          <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleProfile}>Profile</Dropdown.Item>
            <Dropdown.Item
              href={`https://www.metisphere.com/my-support?mid=${user.uid}&email=${user.email}&firstname=${user.displayName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Log Support Ticket
            </Dropdown.Item>
            <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else {
    return (
      <div>
        <Dropdown as={ButtonGroup}>
          <Button variant="primary" style={{ color: "white" }} size="sm">
            {profile.photoPath ? (
              <img
                src={profile.photoPath}
                alt={user.displayName}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "1px solid white",
                  marginRight: "8px",
                }}
              />
            ) : (
              ""
            )}
            {user.displayName}
          </Button>

          <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleProfile}>Profile</Dropdown.Item>
            <Dropdown.Item
              href={`https://www.metisphere.com/my-support?mid=${user.uid}&email=${user.email}&firstname=${user.displayName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Log Support Ticket
            </Dropdown.Item>
            <Dropdown.Item onClick={handlePasswordUpdate}>
              Update Password
            </Dropdown.Item>
            <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default SignedInLinks;
