import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { Link, useRouteMatch, useParams } from "react-router-dom";

import { useSkillPathContext } from "./../providers";

function PathThreads({ userProgress }) {
  let { url } = useRouteMatch();
  const { skillPath, addingContent } = useSkillPathContext();

  const { orgId } = useParams();

  let urlStart = "/skills";
  // if (url.indexOf("user") > -1) {
  //   urlStart += "/user";
  // }
  // else if (url.indexOf("content") > -1) {

  //   urlStart = "/content/skills";
  // }

  if (url.indexOf("user") > -1) {
    urlStart += "/user";
  } else if (orgId) {
    if (addingContent) {
      urlStart = `/organization/${orgId}/content/skills`;
    } else {
      urlStart = `/organization/${orgId}/skills`;
    }
  }

  const flatSteps = [];

  if (userProgress && !addingContent) {
    if (userProgress.stages) {
      // userProgress.stages.forEach((stage) => {
      //   stage.steps &&
      //     stage.steps.forEach((step) => {
      //       flatSteps.push(step);
      //     });
      // });
    }
  }

  return (
    skillPath && (
      <div>
        <ListGroup variant="flush">
          {skillPath.threads.map((thread, ind) => {
            return (
              <ListGroup.Item key={ind}>
                <strong>{thread.name}</strong>
                <Row>
                  <Col md={4}>
                    <ListGroup>
                      {thread.steps.map((step) => {
                        return (
                          <ListGroup.Item
                            action
                            variant={
                              (
                                flatSteps.find((fstep) => {
                                  return fstep.name === step.name;
                                }) || { complete: false }
                              ).complete
                                ? "success"
                                : ""
                            }
                          >
                            <Link to={`${urlStart}/${step.url}`}>
                              {step.stage.order}. {step.stage.name} ->{" "}
                              {step.name}
                            </Link>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </div>
    )
  );
}

export default PathThreads;
