import { Machine, assign, send } from "xstate";

import { db } from "../config/firebase";
import { recognitionConverter } from "../core/recognitions/utils/classes/recognition";

function createRecognitionMachine(orgContext) {
  return new Machine({
    initial: "listening",
    type: "parallel",
    context: {
      orgContext: orgContext,
      recognitions: [],
    },
    invoke: {
      id: "snapshot",
      src: (context, event) => (callback, onEvent) => {
        //console.log("[ORG ID]", context, event, callback, onEvent);
        if (orgContext) {
          let orgId = context.orgContext.id;

          const unsubscribeRecognitions = getRecognitions(orgId, callback);

          onEvent((e) => {
            switch (e.type) {
              case "UNSUBSCRIBE":
                unsubscribeRecognitions();
                break;
              default:
                //console.log("UNSUBSCRIBE");
                break;
            }
          });
        }
      },
    },
    states: {
      subscribe: {
        initial: "listening",
        states: {
          listening: {
            on: {
              UPDATE: "update",
              COMPLETE: "complete",
            },
          },
          update: {
            invoke: {
              src: (context, event) => {
                return new Promise((resolve, reject) => {
                  //console.log("[UPDATING...]", event);
                  resolve(event.recognitions);
                });
              },
              onDone: {
                target: "listening",
                actions: assign({
                  recognitions: (ctx, event) => event.data,
                }),
              },
            },
          },
          complete: {
            onEntry: send("UNSUBSCRIBE", { to: "snapshot" }),
            type: "final",
          },
        },
      },
      add: {
        initial: "idle",
        states: {
          idle: {
            on: {
              ADD_RECOGNITION: "pending",
            },
          },
          pending: {
            invoke: {
              src: (state, action) => {
                return addRecognition(state.orgContext.id, action.recognition);
              },
              onDone: {
                target: "idle",
              },
            },
          },
          success: {},
        },
      },
      like: {
        initial: "idle",
        states: {
          idle: {
            on: {
              TOGGLE_LIKE: "pending",
            },
          },
          pending: {
            invoke: {
              src: (state, action) => {
                // console.log(
                //   "[ADDING RECOGNITION]",
                //   state.orgContext.id,
                //   action.recognition
                // );
                return toggleLike(
                  state.orgContext,
                  action.recognition,
                  action.user
                );
              },
              onDone: {
                target: "idle",
              },
            },
          },
          success: {},
        },
      },
    },
  });
}

function getRecognitions(orgId, callback) {
  let unsubscribe = db
    .collection(`organizations/${orgId}/recognitions`)
    .orderBy("created", "desc")
    .withConverter(recognitionConverter)
    .onSnapshot((snapshot) => {
      let recognitions = [];
      snapshot.docs.forEach(function (doc) {
        let recognition = {
          ...doc.data(),
          id: doc.id,
        };
        recognitions.push(recognition);
      });
      callback({ type: "UPDATE", recognitions });
    });
  return unsubscribe;
}

function addRecognition(orgId, recognition) {
  console.log("[RECOGNITION] ", recognition);
  return db
    .collection(`organizations/${orgId}/recognitions`)
    .withConverter(recognitionConverter)
    .add(recognition);
}

function toggleLike(org, recognition, user) {
  if (recognition.likes.includes(user.uid)) {
    recognition.likes = recognition.likes.filter(
      (userLike) => userLike !== user.uid
    );
  } else {
    recognition.likes.push(user.uid);
  }

  console.log("[TOGGLE LIKES] ", recognition.likes);

  // FIX ME
  return db
    .collection(`organizations/${org.id}/recognitions`)
    .doc(recognition.id)
    .withConverter(recognitionConverter)
    .set(recognition, { merge: true });
  // .then(function (doc) {
  //   //console.log("[TOGGLE_LIKE DOC UPDATED] ", doc);
  // })
  // .catch(function (err) {
  //   //console.log("[TOGGLE_LIKE ERROR]", err);
  // });
}
export default createRecognitionMachine;
