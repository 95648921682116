import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import { MainHeading } from "../../../../core/helpers/layout-components";

import OrganizationSkillsCards from "./tabs/organization-skills";
import OrganizationOverview from "./tabs/organization-overview";
import OrganizationUsers from "./tabs/organization-user-list";
import OrganizationApprovals from "./tabs/organization-approvals-list";
import OrganizationSettings from "./tabs/organization-settings";

import { useOrganizationForm } from "../hooks";

import Invite from "../../utils/classes/invite";

import { Link, useParams } from "react-router-dom";

import "../../../../style/Dashboard.scss";
import Jumbotron from "react-bootstrap/Jumbotron";

import { useAuthState } from "../../../../providers/AuthState";

function OrganizationView() {
  let { orgId } = useParams();
  const organization = useOrganizationForm(orgId);

  const { profileType } = useAuthState();

  const orgEditPath = "/organization/edit/" + orgId;

  let newInvite = new Invite({});
  const [invites, setInvites] = useState([newInvite]);
  const [inviteErrors, setInviteErrors] = useState([]);

  /* Modal functionality for inviting users */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // useEffect(() => {
  //   organization.load(orgId);
  // }, [orgId]);

  function addUsers() {
    setShow(true);
  }

  function handleInvite() {
    //console.log("[GET INVITE ERRORS]");
    let errors = organization.getInviteErrors(invites);
    if (errors.length === 0) {
      organization.addInvites(invites);
      invites.forEach((invite) => {
        invite.clear();
      });
      setInvites(invites);
      setInviteErrors([]);
    }
    setInviteErrors(errors);
    //organization.addInvites(invites);
  }

  function completeOnboarding() {
    organization.completeOnboarding();
  }

  function updateValues(current, field, value, index, setter) {
    let newObject = [...current];
    newObject[index].updateField(field, value);
    setter(newObject);
  }

  function deleteInvite(invite) {
    organization.deleteInvite(invite);
  }

  const skillsProgress =
    organization.skills.length > 0 ? (
      <span className="text-success">COMPLETE</span>
    ) : (
      <span className="text-secondary">IN-PROGRESS</span>
    );

  const usersProgress =
    organization.users.length > 1 ? (
      <span className="text-success">COMPLETE</span>
    ) : (
      <span className="text-secondary">IN-PROGRESS</span>
    );

  return (
    <Row style={{ marginTop: "15px" }} id="organization">
      <Col>
        <Row>
          <Col>
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,.125)",
                marginBottom: "15px",
              }}
            >
              <Row style={{ marginBottom: "15px" }}>
                <Col md={6}>
                  <MainHeading title="My Organization" />
                </Col>
                <Col>
                  {" "}
                  <Button
                    variant="outline-primary"
                    className="font-weight-bold float-right"
                    onClick={addUsers}
                  >
                    Invite Users
                  </Button>
                  <Link
                    className="btn btn-outline-secondary font-weight-bold float-right"
                    style={{ marginRight: "10px" }}
                    to={orgEditPath}
                  >
                    Edit Organization
                  </Link>
                </Col>
              </Row>
              {organization.onboarding ? (
                <Row>
                  <Col xs={12}>
                    <Jumbotron
                      style={{
                        // backgroundColor: "white",
                        // padding: "0",
                        padding: "2rem",
                        marginBottom: "0px",
                      }}
                    >
                      {" "}
                      <Row>
                        <Col xs={6}>
                          <h4 className="text-secondary">
                            Complete your onboarding!
                          </h4>
                          <p>
                            To complete setup of your organization, please
                            follow these steps:
                          </p>
                        </Col>
                        <Col xs={6}>
                          <Button
                            variant="outline-secondary"
                            className="float-right"
                            style={{ marginTop: "-20px", marginRight: "-20px" }}
                            onClick={completeOnboarding}
                          >
                            <span
                              aria-hidden="true"
                              className="font-weight-bold"
                            >
                              ×
                            </span>
                          </Button>
                        </Col>
                        <Col xs={4}>
                          <Card
                            bg="white"
                            text="dark"
                            className="dashboard-card"
                          >
                            <Card.Body>
                              <Row>
                                <Col>
                                  <Card.Title className="text-center">
                                    Step 1
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted text-center">
                                    Profile
                                  </Card.Subtitle>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div className="font-weight-bold h4 text-center">
                                      {
                                        <span className="text-success">
                                          COMPLETE
                                        </span>
                                      }
                                    </div>
                                    <div
                                      className="text-muted text-small text-center"
                                      style={{
                                        fontSize: "12px",
                                        minHeight: "60px",
                                      }}
                                    >
                                      Make sure that your organizations details
                                      are updated
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xs={4}>
                          <Card
                            bg="white"
                            text="dark"
                            className="dashboard-card"
                          >
                            <Card.Body>
                              <Row>
                                <Col>
                                  <Card.Title className="text-center">
                                    Step 2
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted text-center">
                                    Skills
                                  </Card.Subtitle>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div className="font-weight-bold h4 text-center">
                                      {skillsProgress}
                                    </div>
                                    <div
                                      className="text-muted text-small text-center"
                                      style={{
                                        fontSize: "12px",
                                        minHeight: "60px",
                                      }}
                                    >
                                      Register Skills Cards for your
                                      organization
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xs={4}>
                          <Card
                            bg="white"
                            text="dark"
                            className="dashboard-card"
                          >
                            <Card.Body>
                              <Row>
                                <Col>
                                  <Card.Title className="text-center">
                                    Step 3
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted text-center">
                                    Users
                                  </Card.Subtitle>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div className="font-weight-bold h4 text-center">
                                      <span className="text-secondary">
                                        {usersProgress}
                                      </span>
                                    </div>
                                    <div
                                      className="text-muted text-small text-center"
                                      style={{
                                        fontSize: "12px",
                                        minHeight: "60px",
                                      }}
                                    >
                                      Add some additional users beyond the
                                      administrator
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Jumbotron>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row>
                <Col xs={12}>
                  <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="overview" title="Overview">
                      <OrganizationOverview
                        organization={organization}
                        orgId={orgId}
                      />
                    </Tab>
                    <Tab eventKey="skills" title="Skills Path">
                      <OrganizationSkillsCards
                        organization={organization}
                        orgId={orgId}
                      />
                    </Tab>
                    <Tab eventKey="users" title="Users">
                      <OrganizationUsers
                        organization={organization}
                        orgId={orgId}
                      />
                    </Tab>
                    <Tab eventKey="approvals" title="Approval Groups">
                      <OrganizationApprovals
                        organization={organization}
                        orgId={orgId}
                      />
                    </Tab>
                    {profileType === "SUPERUSER" ? (
                      <Tab eventKey="settings" title="Settings">
                        <OrganizationSettings
                          organization={organization}
                          orgId={orgId}
                        />
                      </Tab>
                    ) : (
                      ""
                    )}
                  </Tabs>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Invite Users in Your Org to Metisphere!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Array.isArray(inviteErrors) &&
            inviteErrors.map((inviteError) => {
              if (inviteError !== "") {
                return <Alert variant="danger">{inviteError}</Alert>;
              }
              return "";
            })}
          <Form>
            <ListGroup as="ul" style={{ marginBottom: "15px" }}>
              {Array.isArray(invites) &&
                invites.map((invite, index) => {
                  return (
                    <ListGroup.Item as="li" key={index}>
                      <Form.Group controlId="inviteForm.name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          size="sm"
                          value={invite.displayName}
                          onChange={(e) => {
                            updateValues(
                              invites,
                              "displayName",
                              e.target.value,
                              index,
                              setInvites
                            );
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="inviteForm.email">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="name@example.com"
                          size="sm"
                          value={invite.email}
                          onChange={(e) => {
                            updateValues(
                              invites,
                              "email",
                              e.target.value,
                              index,
                              setInvites
                            );
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="inviteForm.email">
                        <Form.Label>SME</Form.Label>
                        <Form.Control
                          as="select"
                          value={invite.isSME}
                          onChange={(e) => {
                            updateValues(
                              invites,
                              "isSME",
                              e.target.value,
                              index,
                              setInvites
                            );
                          }}
                        >
                          <option>No</option>
                          <option>Yes</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="inviteForm.email">
                        <Form.Label>Manager</Form.Label>
                        <Form.Control
                          as="select"
                          value={invite.isManager}
                          onChange={(e) => {
                            updateValues(
                              invites,
                              "isManager",
                              e.target.value,
                              index,
                              setInvites
                            );
                          }}
                        >
                          <option>No</option>
                          <option>Yes</option>
                        </Form.Control>
                      </Form.Group>
                      <Button
                        variant="outline-primary"
                        onClick={handleInvite}
                        size="sm"
                        className="float-right"
                        style={{ marginTop: "10px" }}
                      >
                        Invite
                      </Button>
                    </ListGroup.Item>
                  );
                })}
            </ListGroup>
          </Form>
          <h6>Pending Invites - {organization.invites.length}</h6>
          {organization.invites.length === 0 ? (
            <Alert variant="secondary">The are no pending invites.</Alert>
          ) : (
            " "
          )}
          <ListGroup as="ul" style={{ marginBottom: "5px" }}>
            {Array.isArray(organization.invites) &&
              organization.invites.map((invite) => {
                //console.log("[THIS IS AN INVITE] ", invite);
                return (
                  <ListGroup.Item
                    as="li"
                    key={invite.id}
                    style={{ padding: ".5rem .75rem", cursor: "pointer" }}
                  >
                    <Row>
                      <Col xs={6}>
                        <small>
                          {invite.email}
                          <i style={{ fontSize: "8px" }}>{invite.status}</i>
                        </small>
                      </Col>
                      <Col xs={6}>
                        <Button
                          variant="danger"
                          className="float-right"
                          size="sm"
                          onClick={(e) => {
                            deleteInvite(invite);
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </Row>
  );
}

export default OrganizationView;
