import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from "draft-js";
import {
  Row,
  Col,
  Button,
  Modal,
  Container,
  ButtonGroup,
  ButtonToolbar,
  Form,
  Jumbotron,
  Alert,
  Badge,
  InputGroup,
  Card,
} from "react-bootstrap";

import { useParams } from "react-router-dom";

import "draft-js/dist/Draft.css";
import "./step-wisdom.styles.css";

import { db } from "./../../../../config/firebase";

import { api } from "./../../../../common/utils/api";

import StepContentForm from "./step-content-form";
import { ContentPlayer } from "./../content";
import { useSkillPathContext } from "./../providers";
import { isSuperUser, useAuthState } from "../../../../providers/AuthState";

import { RichTextEditor } from "./../../../../common/components";
import { WisdomPlayer } from "./../wisdom";

function StepWisdom({ skillStep, canEdit, isComplete }) {
  const [wisdomList, setWisdomList] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showExistingModal, setShowExistingModal] = useState(false);
  const [wisdomTitle, setWisdomTitle] = useState("");
  const [activeStyle, setActiveStyle] = useState("");

  const [tags, setTags] = useState([]);
  const [activeTag, setActiveTag] = useState("");

  const [experienceForWisdom, setExperienceForWisdom] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [testEditor, setTestEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [mySubmittedWisdom, setMySubmittedWisdom] = useState([]);
  const [allContent, setAllContent] = useState([]);
  const [sortedContent, setSortedContent] = useState([]);
  const [selectedWisdom, setSelectedWisdom] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterActiveWisdom, setFilterActiveWisdom] = useState("");

  const [isCustom, setIsCustom] = useState(false);

  const { orgProfile, orgContext } = useAuthState();
  const { skillPath } = useSkillPathContext();
  const { orgId } = useParams();

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      db.collection(`organizations/${orgContext.id}/skills`)
        .where("id", "==", skillPath.id)
        .get()
        .then((orgSkillPathDocs) => {
          orgSkillPathDocs.forEach((orgSkillPathDoc) => {
            const orgSkillPath = orgSkillPathDoc.data();
            let experienceForWisdom = false;
            if (
              orgSkillPath.stepExperienceForWisdom &&
              orgSkillPath.stepExperienceForWisdom[skillStep.ref.id]
            ) {
              experienceForWisdom =
                orgSkillPath.stepExperienceForWisdom[skillStep.ref.id];
            }
            setExperienceForWisdom(experienceForWisdom);

            let isCustomSkill = false;
            if (orgSkillPath.custom) {
              isCustomSkill = true;
            }
            setIsCustom(isCustomSkill);
            return true;
          });
        });
    }

    return () => {
      isCurrent = false;
    };
  }, [orgContext.id, skillStep.ref.id]);

  useEffect(() => {
    let stepId = skillStep.ref.id;
    let stageId = skillStep.ref.parent.parent.id;
    let pathId = skillStep.ref.parent.parent.parent.parent.id;

    if (stepId === "staged") {
      stepId = skillStep.ref.parent.parent.id;
      stageId = skillStep.ref.parent.parent.parent.parent.id;
      pathId = skillStep.ref.parent.parent.parent.parent.parent.parent.id;
    }

    return db
      .collection(
        `organizations/${orgContext.id}/paths/${pathId}/stages/${stageId}/steps/${stepId}/wisdom`
      )
      .where("active", "==", true)
      .onSnapshot((snapshot) => {
        let wisdomArr = [];

        snapshot.forEach((doc) => {
          const currentWisdom = { ...doc.data(), id: doc.id, ref: doc.ref };
          currentWisdom.body = EditorState.createWithContent(
            convertFromRaw(currentWisdom.body)
          );
          wisdomArr.push(currentWisdom);
        });
        setWisdomList(wisdomArr);
      });
  }, [skillStep.ref.id]);

  useEffect(() => {
    let isCurrent = true;
    let stepId = skillStep.ref.id;
    let stageId = skillStep.ref.parent.parent.id;
    let pathId = skillStep.ref.parent.parent.parent.parent.id;

    if (stepId === "staged") {
      stepId = skillStep.ref.parent.parent.id;
      stageId = skillStep.ref.parent.parent.parent.parent.id;
      pathId = skillStep.ref.parent.parent.parent.parent.parent.parent.id;
    }

    if (isCurrent)
      db.collection(
        `organizations/${orgContext.id}/paths/${pathId}/stages/${stageId}/steps/${stepId}/wisdom`
      )
        .where("createdBy.id", "==", orgProfile.id)
        .where("approved", "==", false)
        .get()
        .then((querySnapshot) => {
          const myWisdomArr = [];
          querySnapshot.forEach((doc) => {
            const currentWisdom = { ...doc.data(), id: doc.id, ref: doc.ref };
            currentWisdom.body = EditorState.createWithContent(
              convertFromRaw(currentWisdom.body)
            );
            myWisdomArr.push(currentWisdom);
          });

          setMySubmittedWisdom(myWisdomArr);
        });

    return () => {
      isCurrent = false;
    };
  }, [skillStep.ref.id]);

  function handleOpen() {
    setShowEditModal(true);
  }

  function handleClose() {
    setShowEditModal(false);
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    saveWisdom();
  }

  function handleExistingClose() {
    setShowExistingModal(false);
  }

  function saveWisdom() {
    let stepId = skillStep.ref.id;
    let stageId = skillStep.ref.parent.parent.id;
    let pathId = skillStep.ref.parent.parent.parent.parent.id;

    if (stepId === "staged") {
      stepId = skillStep.ref.parent.parent.id;
      stageId = skillStep.ref.parent.parent.parent.parent.id;
      pathId = skillStep.ref.parent.parent.parent.parent.parent.parent.id;
    }

    const wisdomDoc = {
      step: {
        id: skillStep.ref.id,
        ref: skillStep.ref,
        name: skillStep.name,
      },
      path: {
        id: skillPath.ref.id,
        ref: skillPath.ref,
        name: skillPath.name,
        code: skillPath.code,
      },
      createdBy: {
        id: orgProfile.id,
        displayName: orgProfile.displayName,
        path: orgProfile.ref.path,
      },
      createdDate: new Date(),
      body: convertToRaw(editorState.getCurrentContent()),
      bodyText: editorState.getCurrentContent().getPlainText(),
      title: wisdomTitle,
      tags: tags,
      active: false,
      approved: false,
      experienceCreditForApprovedWisdom: experienceForWisdom,
    };

    let wisdomRefDoc = null;
    db.collection(
      `organizations/${orgContext.id}/paths/${pathId}/stages/${stageId}/steps/${stepId}/wisdom`
    )
      .add(wisdomDoc)
      .then((wisdomRef) => {
        wisdomRefDoc = wisdomRef;
        // Add approval
        const approvalDoc = {
          wisdomRef: wisdomRef,
          step: {
            id: skillStep.ref.id,
            ref: skillStep.ref,
            name: skillStep.name,
          },
          path: {
            id: skillPath.ref.id,
            ref: skillPath.ref,
            name: skillPath.name,
            code: skillPath.code,
          },
          status: "Submitted",
          submittedDate: new Date(),
          submittedBy: {
            id: orgProfile.id,
            displayName: orgProfile.displayName,
            path: orgProfile.ref.path,
          },
        };

        return db
          .collection(`organizations/${orgContext.id}/wisdomApprovals`)
          .add(approvalDoc);
      })
      .then((approvalRef) => {
        return api.logging.createLogEntry(
          orgContext.id,
          api.logging.TYPES.WISDOM.SUBMIT,
          {
            message: `${orgProfile.displayName} has submitted wisdom.`,
            related: wisdomRefDoc,
            response: "SUCCESS",
          }
        );
      });
    handleClose();
  }

  function convertSortedMapToArr(sortedByStageAndSkillMap) {
    const sortedContent = [];

    for (const stageKey in sortedByStageAndSkillMap) {
      const sortedSteps = [];
      for (const stepKey in sortedByStageAndSkillMap[stageKey].steps) {
        sortedSteps.push(sortedByStageAndSkillMap[stageKey].steps[stepKey]);
      }
      const newStage = {
        ...sortedByStageAndSkillMap[stageKey],
        steps: sortedSteps
          .filter((sortStep) => {
            return sortStep.content.length > 0;
          })
          .sort((left, right) => {
            return left.order - right.order;
          }),
      };
      if (newStage.steps.length > 0) {
        sortedContent.push(newStage);
      }
    }

    return sortedContent.sort((left, right) => {
      return left.order - right.order;
    });
  }

  function sortAndFilterContent(allContent, searchTerm) {
    const sortedByStageAndSkillMap = {};

    allContent.forEach((curContent) => {
      const curStage = sortedByStageAndSkillMap[curContent.stage.id] || {
        ...curContent.stage,
        steps: {},
      };
      const curStep = curStage.steps[curContent.step.id] || {
        ...curContent.step,
        content: [],
      };

      if (
        (!searchTerm ||
          curContent.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          curContent.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) &&
        (filterActiveWisdom === "" ||
          (filterActiveWisdom === "Active" && curContent.active) ||
          (filterActiveWisdom === "Inactive" && !curContent.active))
      ) {
        curStep.content.push(curContent);
      }

      curStage.steps[curContent.step.id] = curStep;
      sortedByStageAndSkillMap[curContent.stage.id] = curStage;
    });
    setSortedContent(convertSortedMapToArr(sortedByStageAndSkillMap));
  }

  function setSortedContentList(allContent) {
    const sortedByOrder = allContent.sort((left, right) => {
      return (
        left.stage.order * 1000 +
        left.step.order * 100 +
        left.order -
        (right.stage.order * 1000 + right.step.order * 100 + right.order)
      );
    });
    setAllContent(sortedByOrder);
  }

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && skillPath.id !== "") {
      if (isCustom) {
        api.skillPath.getAllWisdomForCustomSkillPath(
          orgId,
          skillPath.id,
          setSortedContentList
        );
      } else {
        api.skillPath.getAllWisdomForSkillPath(
          orgId,
          skillPath.id,
          setSortedContentList
        );
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [skillPath, isCustom]);

  useEffect(() => {
    sortAndFilterContent(allContent, searchTerm);
  }, [searchTerm, filterActiveWisdom, allContent]);

  function handleSelect(wisdom) {
    let stepId = skillStep.ref.id;
    let stageId = skillStep.ref.parent.parent.id;
    let pathId = skillStep.ref.parent.parent.parent.parent.id;

    if (stepId === "staged") {
      stepId = skillStep.ref.parent.parent.id;
      stageId = skillStep.ref.parent.parent.parent.parent.id;
      pathId = skillStep.ref.parent.parent.parent.parent.parent.parent.id;
    }

    const updateDoc = {
      ...wisdom,
      hasParent: true,
      parentContent: wisdom.ref,
      parentContentId: wisdom.id,
    };

    db.collection(
      `organizations/${orgContext.id}/paths/${pathId}/stages/${stageId}/steps/${stepId}/wisdom`
    )
      .add(updateDoc)
      .then(() => {
        handleExistingClose();
      });
  }

  return (
    <div>
      <Row>
        <Col>
          <div className="float-right">
            {!canEdit && (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  handleOpen();
                }}
              >
                Submit Wisdom
              </Button>
            )}
            {canEdit && (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  setShowExistingModal(true);
                }}
              >
                Add Existing Wisdom
              </Button>
            )}
          </div>
        </Col>
      </Row>
      {wisdomList.length <= 0 && mySubmittedWisdom.length <= 0 && (
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Jumbotron>
              <h5 className="text-primary" style={{ textAlign: "center" }}>
                {!canEdit && "Be the first to submit wisdom for this step"}
                {canEdit && "Add existing wisdom to this step"}
              </h5>
            </Jumbotron>
          </Col>
        </Row>
      )}

      {(wisdomList.length > 0 || mySubmittedWisdom.length > 0) && (
        <WisdomPlayer
          wisdomList={[...wisdomList, ...mySubmittedWisdom]}
          skillStep={skillStep}
        />
      )}

      <Modal show={showEditModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Widsom: {skillStep.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="wisdomName">
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      value={wisdomTitle}
                      onChange={(e) => {
                        setWisdomTitle(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: "20px" }}>
                <Col>
                  <div>
                    <RichTextEditor
                      editorState={editorState}
                      setEditorState={setEditorState}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <label htmlFor="tags">Tags</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="tags"
                        type="text"
                        placeholder="Add a tag"
                        value={activeTag}
                        onChange={(e) => {
                          setActiveTag(e.target.value);
                        }}
                      />
                      <InputGroup.Append>
                        <Button
                          variant="outline-secondary"
                          onClick={() => {
                            setTags([...tags, activeTag.toLowerCase()]);
                            setActiveTag("");
                          }}
                        >
                          +
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                  <div style={{ fontSize: "1.2em" }}>
                    {tags.map((tag) => {
                      return (
                        <Badge variant="primary" style={{ marginRight: "3px" }}>
                          {tag}
                        </Badge>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              {experienceForWisdom && !isComplete && (
                <Row>
                  <Col>
                    <Alert variant="success mt-2 text-center">
                      This wisdom will satisfy an experience requirement if
                      approved
                    </Alert>
                  </Col>
                </Row>
              )}
              <Form.Row>
                <Col>
                  <Form.Row>
                    <Col>
                      <div className="float-right">
                        <Button variant="outline-primary" type="submit">
                          Submit for review
                        </Button>{" "}
                        <Button variant="outline-danger" onClick={handleClose}>
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Form.Row>
                </Col>
              </Form.Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>

      {canEdit && (
        <Modal show={showExistingModal} onHide={handleExistingClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Add Existing Widsom: {skillStep.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {sortedContent.length > 0 &&
                sortedContent.map((sortedStage) => {
                  return (
                    <div>
                      <h6
                        style={{
                          borderBottom: "1px solid #123",
                          marginBottom: "20px",
                        }}
                      >
                        {sortedStage.order}. {sortedStage.name}
                      </h6>
                      {sortedStage.steps.map((sortedStep) => {
                        return (
                          <div
                            style={{ marginLeft: "10px", marginBottom: "10px" }}
                          >
                            <h6>
                              {sortedStep.order}. {sortedStep.name}
                            </h6>
                            <Row>
                              {sortedStep.content.map((content) => {
                                return (
                                  <Col sm={4} style={{ marginBottom: "15px" }}>
                                    <Card
                                      style={{
                                        backgroundColor: content.active
                                          ? ""
                                          : "#eaeaea",
                                      }}
                                      className={`content-card ${content === selectedWisdom
                                          ? "active"
                                          : ""
                                        }`}
                                      onClick={() => {
                                        setSelectedWisdom(content);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Card.Body>
                                        <Card.Title>
                                          {sortedStage.order}.{sortedStep.order}
                                          : {content.title}
                                        </Card.Title>

                                        <Card.Text
                                          style={{
                                            height: "250px",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <Editor
                                            editorState={EditorState.createWithContent(
                                              convertFromRaw(content.body)
                                            )}
                                            readOnly={true}
                                          />
                                        </Card.Text>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {selectedWisdom && (
              <Button
                onClick={() => {
                  handleSelect(selectedWisdom);
                }}
              >
                Add Selected Wisdom
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default StepWisdom;
