import React, { useContext, useReducer, useCallback } from "react";

const OrgStateContext = React.createContext();

const initialState = {
  currentActiveUser: { id: "" },
  currentActiveApprovalGroup: { id: "", name: "", approvers: [] },
  lastActiveApprovalGroup: { id: "", name: "", approvers: [] },
};

export function OrgStateProvider({ children }) {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET_CURRENT_ORG_USER": {
        return {
          ...state,
          currentActiveUser: action.payload.currentActiveUser,
        };
      }
      case "SET_CURRENT_APPROVAL_GROUP": {
        return {
          ...state,
          currentActiveApprovalGroup: action.payload.currentActiveApprovalGroup,
        };
      }
      case "SET_LAST_APPROVAL_GROUP": {
        return {
          ...state,
          lastActiveApprovalGroup: action.payload.lastActiveApprovalGroup,
        };
      }
      default:
        return state;
    }
  }, initialState);

  const value = {
    ...state,
    dispatch: useCallback(dispatch, []),
  };

  return <OrgStateContext.Provider value={value} children={children} />;
}

export function useOrgState() {
  return useContext(OrgStateContext);
}
