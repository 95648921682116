import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

import moment from "moment";

import "../../style/Dashboard.scss";

import { useAuthState } from "../../providers/AuthState";

import createRecognitionMachine from "../../machines/RecognitionMachine";

import { useMachine } from "@xstate/react";

import { Link } from "react-router-dom";

import { firebase } from "../../config/firebase";

import {
  RecognitionButton,
  RecognitionModal,
  RecognitionPanel,
} from "../../core/recognitions";

import {
  OrganizationAcceptanceModal,
  OrganizationPendingInvites,
} from "../../core/organizations";

import SelfRegistrationModal from "../organizations/components/organization-onboarding/self-registration-onboarding-modal";

import DashboardLayout from "../skills/layouts/user-dashboard.js";

import ApprovalPreview from "./ApprovalPreview";
import NoRecognitions from "./NoRecognitions";

import { logExperience } from "./utils/experience";

function Admin() {
  const { orgContext, orgProfile, adminOrg } = useAuthState();
  const [currentOrgInvite, setCurrentOrgInvite] = useState({});
  const [orgAcceptanceModalShow, setOrgAcceptenceModalShow] = useState(false);
  const [showRecognitionModal, setShowRecognitionModal] = useState(false);
  const [orgLogo, setOrgLogo] = useState();
  const [confetti, setConfetti] = useState(false);
  const { width, height } = useWindowSize();

  const [state, send] = useMachine(createRecognitionMachine(orgContext));

  const { user, loggedInTime } = useAuthState();

  function giveRecognition() {
    setShowRecognitionModal(true);
  }

  function logRecognition(userRecognition) {
    logExperience(orgProfile, userRecognition);
  }

  useEffect(
    (e) => {
      let fire = false;
      state.context.recognitions.forEach(function (recognition) {
        if (recognition.created.toDate) {
          if (
            recognition.recognizedTo === user.uid &&
            moment(recognition.created.toDate()).isAfter(loggedInTime)
          ) {
            fire = true;
            setTimeout(function () {
              setConfetti(false);
            }, 10000);
          }
        }
      });
      setConfetti(fire);
    },
    [state.context.recognitions]
  );

  useEffect(() => {
    if (orgContext.logoPath) {
      if (orgContext.logoPath.length) {
        let storageRef = firebase.storage().ref();

        console.log("organization: ", orgContext.logoPath);
        let photoRef = storageRef.child(orgContext.logoPath);
        photoRef
          .getDownloadURL()
          .then(function (url) {
            setOrgLogo(url);
          })
          .catch(function (error) {
            console.log("Error: ", error);
          });
      }
    }
  }, [orgContext.logoPath]);

  return (
    <div>
      <OrganizationPendingInvites
        user={user}
        setCurrentOrgInvite={setCurrentOrgInvite}
        setOrgAcceptenceModalShow={setOrgAcceptenceModalShow}
      />
      {confetti ? (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={500}
          recycle={false}
        />
      ) : (
          ""
        )}
      <div>
        <Row>
          <Col xs={4}>
            <h4
              className="text-secondary"
              style={{ letterSpacing: "-.02em", fontWeight: "600" }}
            >
              Admin Dashboard
            </h4>
          </Col>
          <Col xs={4} className="text-center">
            {orgLogo ? <img src={orgLogo} style={{ width: "100px" }} /> : ""}
          </Col>
          <Col xs={4}>
            {orgContext && orgContext.status !== "TRIAL" ? (
              <RecognitionButton onClickHandler={giveRecognition} />
            ) : (
                ""
              )}
          </Col>
        </Row>
      </div>
      {orgContext && orgContext.status !== "TRIAL" ? (
        <div>
          <Row>
            <Col>
              <h5 className="text-primary font-weight-bold">
                <span className="mt-1">Recognitions</span>
              </h5>
            </Col>
          </Row>
          {state.context.recognitions.length > 0 ? (
            <div>
              <div style={{ background: "#EDF2F7", borderRadius: "0.3rem" }}>
                <RecognitionPanel
                  panelSize={4}
                  recognitions={state.context.recognitions}
                  send={send}
                />
              </div>
              <Row>
                <Col xs={12}>
                  <span className="float-right">
                    <Link to="/recognitions">View All Recognitions</Link>
                  </span>
                </Col>
              </Row>
            </div>
          ) : (
              <NoRecognitions />
            )}
        </div>
      ) : (
          ""
        )}
      <Row>
        <Col xs={12} sm={9}>
          <DashboardLayout context="h5" />
        </Col>
        <Col xs={12} sm={3}>
          <ApprovalPreview />
        </Col>
      </Row>
      <RecognitionModal
        showRecognitionModal={showRecognitionModal}
        setShowRecognitionModal={setShowRecognitionModal}
        sendRecognition={send}
        postSubmitHandler={logRecognition}
      />
      <OrganizationAcceptanceModal
        setOrgAcceptenceModalShow={setOrgAcceptenceModalShow}
        currentOrgInvite={currentOrgInvite}
        orgAcceptanceModalShow={orgAcceptanceModalShow}
      />
      <SelfRegistrationModal
        user={user}
        setCurrentOrgInvite={setCurrentOrgInvite}
        setOrgAcceptenceModalShow={setOrgAcceptenceModalShow}
      />
    </div>
  );
}

export default Admin;
