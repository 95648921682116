import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Page } from "../components/common";
import {
  PathDetail,
  PathThreads,
  PathHeader,
  PathVersions,
} from "../components/skill-path";

import SkillStageLayout from "./skill-stage";

import { SkillPathProvider } from "./../components/providers";
import { useAuthState } from "../../../providers/AuthState";

function SkillPathLayout() {
  const { skillPathId, orgId } = useParams();

  return (
    <SkillPathProvider code={skillPathId} version="edit" orgId={orgId}>
      <Page>
        <PathHeader orgId={orgId} />
        <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example">
          <Tab eventKey="overview" title="Overview">
            <SkillStageLayout />
          </Tab>
          <Tab eventKey="details" title="Details">
            <PathDetail />
          </Tab>
          <Tab eventKey="threads" title="Threads">
            <PathThreads />
          </Tab>
          <Tab eventKey="versions" title="Versions">
            <PathVersions />
          </Tab>
        </Tabs>
      </Page>
    </SkillPathProvider>
  );
}

export default SkillPathLayout;
