import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";

import { useSkillPathContext } from "./../providers";
import { useSkillStageForm } from "./hooks";

function StageForm() {
  const { skillStageSlug, orgId } = useParams();

  const { skillPath } = useSkillPathContext();
  const skillStage = useSkillStageForm(skillPath, skillStageSlug);

  let history = useHistory();

  function handleCancel() {
    history.goBack();
  }

  function handleSubmit(event) {
    event.preventDefault();

    const skillStageInstance = skillStage.getSkillStageInstance();

    skillStageInstance
      .setParentIds(skillPath.ref.parent.parent.id)
      .save(orgId)
      // .then(() => {
      //   skillPath.addStageSlug(skillStageInstance);
      //   return skillPath.save();
      // })
      .then(() => {
        if (orgId) {
          history.push(
            `/organization/${orgId}/skills/${skillPath.code}/${skillStageInstance.order}`
          );
        } else {
          history.push(`/skills/${skillPath.code}/${skillStageInstance.order}`);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col md={4}>
          <Form.Group controlId="stageName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Stage Name"
              defaultValue={skillStage.name}
              onChange={(e) => {
                skillStage.updateField("name", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="order">
            <Form.Label>Order</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="Order"
              value={skillStage.order}
              disabled={!skillStage.id}
              onChange={(e) => {
                skillStage.updateField("order", parseInt(e.target.value));
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group controlId="type">
            <Form.Label>Type</Form.Label>
            <Form.Control
              as="select"
              defaultValue={skillStage.type}
              onChange={(e) => {
                skillStage.updateField("type", e.target.value);
              }}
            >
              <option>Progress</option>
              <option>Maintenance</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col sm={9}>
          <Form.Group controlId="objective">
            <Form.Label>Objective</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Objective"
              defaultValue={skillStage.objective}
              onChange={(e) => {
                skillStage.updateField("objective", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={1}>
          <Button variant="outline-primary" type="submit">
            Save
          </Button>
        </Col>
        <Col md={1}>
          <Button variant="outline-danger" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

export default StageForm;
