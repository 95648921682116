import React, { useState, useEffect } from "react";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import { ListGroup } from "react-bootstrap";

import { useSkillPathContext } from "./../providers";

function StepRelated({ skillStep }) {
  const [relatedSteps, setRelatedSteps] = useState([]);
  const { skillPath, addingContent } = useSkillPathContext();

  let { url } = useRouteMatch();
  const { orgId } = useParams();

  let urlStart = "/skills";
  if (url.indexOf("user") > -1) {
    urlStart += "/user";
  } else if (orgId) {
    if (addingContent) {
      urlStart = `/organization/${orgId}/content/skills`;
    } else {
      urlStart = `/organization/${orgId}/skills`;
    }
  }

  useEffect(() => {
    skillStep.thread &&
      setRelatedSteps(
        skillPath.threads.filter((thread) => {
          return thread.name === skillStep.thread;
        })[0].steps || []
      );
  }, [skillStep, skillPath]);

  return (
    skillStep && (
      <div>
        <strong>Related Steps</strong>
        <ListGroup variant="flush">
          {relatedSteps
            .sort((prevStep, step) => {
              return (
                prevStep.skillStageSlug * 100 +
                prevStep.order -
                (step.skillStageSlug * 100 + step.order)
              );
            })
            .filter((step) => {
              return step.name !== skillStep.name;
            })
            .map((step, ind) => {
              return (
                <ListGroup.Item>
                  <Link to={`${urlStart}/${step.url}`}>{step.name}</Link>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </div>
    )
  );
}

export default StepRelated;
