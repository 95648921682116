import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { db } from "./../../../config/firebase";
import { useAuthState } from "../../../providers/AuthState";
import { Cpu } from "react-bootstrap-icons";

function UserSkillProgress({ orgId }) {
  const [skillPath, setSkillPath] = useState(null);
  const [users, setUsers] = useState([]);
  const [currentStage, setCurrentStage] = useState(0);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [experiencePath, setExperiencePath] = useState({ stepId: "" });
  const [experiences, setExperiences] = useState([]);

  const { skillId } = useParams();

  const { orgProfile, profileType } = useAuthState();

  const isAdmin = profileType === "ADMIN";

  function handleShowExperienceModal() {
    setShowExperienceModal(true);
  }

  function handleCloseExperienceModal() {
    setShowExperienceModal(false);
  }

  useEffect(() => {
    if (skillId) {
      db.collection(`/paths`)
        .where("code", "==", skillId)
        .get()
        .then((docs) => {
          let skillPath = null;
          docs.forEach((doc) => {
            skillPath = {
              ...doc.data(),
              id: doc.id,
              ref: doc.ref,
            };
            setSkillPath(skillPath);
            return true;
          });

          return skillPath;
        })
        .then(() => {
          let query = db.collection(`organizations/${orgId}/users`);
          if (!isAdmin) {
            query = query.where("manager", "==", orgProfile.id);
          }
          query.get().then((snapshotDocs) => {
            const orgUserDocs = [];
            const proms = [];
            snapshotDocs.forEach((doc) => {
              const curUserSkill =
                doc.data().skills &&
                doc.data().skills.find((skill) => {
                  return skill.code === skillId;
                });
              if (curUserSkill) {
                proms.push(
                  db
                    .doc(`${doc.ref.path}/paths/${curUserSkill.id}`)
                    .get()
                    .then((userProgressDoc) => {
                      orgUserDocs.push({
                        ...doc.data(),
                        id: doc.id,
                        ref: doc.ref,
                        skillProgress: {
                          ...userProgressDoc.data(),
                          id: userProgressDoc.id,
                          ref: userProgressDoc.ref,
                        },
                      });
                      return true;
                    })
                );
              }
            });
            Promise.all(proms).then(() => {
              setUsers(orgUserDocs);
            });
          });
        });
    }
  }, [skillId, orgId, orgProfile.id]);

  useEffect(() => {
    if (experiencePath.stepId) {
      db.collection(
        `${experiencePath.user.ref.path}/paths/${skillPath.id}/experiences`
      )
        .where("step.id", "==", experiencePath.stepId)
        .get()
        .then((snapshotDocs) => {
          const expDocs = [];
          snapshotDocs.forEach((doc) => {
            expDocs.push({ ...doc.data() });
          });
          setExperiences(expDocs);
        });
    }
  }, [experiencePath.stepId, experiencePath.user, skillPath]);

  function userHasPath(user) {
    return user.paths && user.paths[skillPath.id];
  }

  function getStepStatusForUser(user, step) {
    let status = "-";
    if (
      user.skillProgress.stages[skillPath.stages[currentStage].id].steps[
        step.id
      ].status === "Approved"
    ) {
      status = "A";
    } else if (
      user.skillProgress.stages[skillPath.stages[currentStage].id].steps[
        step.id
      ].status === "Submitted"
    ) {
      status = "S";
    } else if (
      user.skillProgress.stages[skillPath.stages[currentStage].id].steps[
        step.id
      ].status === "Returned"
    ) {
      status = "R";
    }

    return status;
  }

  return (
    skillPath && (
      <div>
        <Row>
          <Col>
            <h2>
              {skillPath.code} - {skillPath.name}
            </h2>
          </Col>

        </Row>
        <Form.Group controlId="stage">
          <Form.Label>Stage</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              setCurrentStage(e.target.value);
            }}
            value={currentStage}
          >
            {skillPath.stages.map((stage, ind) => {
              return <option value={ind}>{stage.name}</option>;
            })}
          </Form.Control>
        </Form.Group>
        <Table>
          <thead>
            <tr>
              <td>Name</td>
              {skillPath.stages[currentStage].steps.map((step) => {
                return <td>{step.name}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr>
                  <td>{user.displayName}</td>
                  {skillPath.stages[currentStage].steps.map((step) => {
                    return (
                      <td>
                        <span
                          style={{
                            cursor: "pointer",
                            color:
                              getStepStatusForUser(user, step) === "S"
                                ? "#007bff"
                                : getStepStatusForUser(user, step) === "A"
                                  ? "#28a745"
                                  : "gray",
                          }}
                          onClick={() => {
                            setExperiencePath({ stepId: step.id, user: user });
                            handleShowExperienceModal();
                          }}
                        >
                          {getStepStatusForUser(user, step)}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>

        {experiences.length > 0 && (
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            show={showExperienceModal}
            onHide={handleCloseExperienceModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                User Experience
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {experiences.map((experience) => {
                return (
                  <Row
                    style={{
                      marginBottom: "15px",
                    }}
                  >
                    <Col>
                      <strong>Submitted</strong>
                      <p>
                        {new Date(
                          experience.dateSubmitted.seconds * 1000
                        ).toLocaleString()}
                      </p>
                      <strong>Status</strong>
                      <p>{experience.status}</p>
                      {experience.status === "Approved" && (
                        <div>
                          <strong>Approver's Comments</strong>
                          <p>{experience.approverComments}</p>
                        </div>
                      )}
                      <strong>Experience</strong>
                      <p>{experience.experienceLog}</p>
                      <hr></hr>
                    </Col>
                  </Row>
                );
              })}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseExperienceModal}>Close</Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    )
  );
}

export default UserSkillProgress;
