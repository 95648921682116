import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { HandThumbsUp } from "react-bootstrap-icons";

import { FullDate } from "../../helpers/moment-components";

import { useAuthState } from "../../../providers/AuthState";

function RecognitionCard({ recognition, handleOpen, send }) {
  let {
    recognizedToDisplayName,
    recognizedFromDisplayName,
    created,
    description,
    likes,
  } = recognition;

  console.log("[RECOGNITION] ", recognition.created);

  const { user } = useAuthState();

  function like() {
    send({ type: "TOGGLE_LIKE", recognition, user });
  }

  function isLiked() {
    if (likes && Array.isArray(likes)) {
      return likes.includes(user.uid);
    }
  }

  let buttonVariant = isLiked() ? "primary" : "outline-primary";

  return (
    <Card bg="light" text="dark" className="recognition-card">
      <Card.Body>
        <Row>
          <Col xs={12}>
            <Card.Title style={{ marginBottom: "0px" }} className="text-center">
              {recognizedToDisplayName}
            </Card.Title>
            {/* <Card.Subtitle className="mb-2 text-muted text-center">
              recognized for <a href="#">Onboarding</a>
            </Card.Subtitle> */}
          </Col>
        </Row>
        <div>
          <div className="recognition-card-blockquote text-center mt-3 mb-3">
            <div className="mb-0">
              <small>
                {description.substring(0, 100)}
                {description.length > 100 ? "..." : ""}
              </small>
            </div>
            <div className="recognition-card-footer">
              <small>
                {recognizedFromDisplayName} on{" "}
                {recognition.created.toDate ? (
                  <FullDate date={recognition.created} />
                ) : (
                  "NaN"
                )}
              </small>
            </div>
          </div>
        </div>
        <Row>
          <Col xs={8}>
            <Button variant={buttonVariant} size="sm" onClick={like}>
              <HandThumbsUp size={12} style={{ marginTop: "-2px" }} /> Like
            </Button>
            {handleOpen ? (
              <Button
                variant="outline-primary"
                size="sm"
                className="ml-1"
                onClick={(e) => {
                  handleOpen(recognition);
                }}
              >
                View
              </Button>
            ) : (
              ""
            )}
          </Col>
          <Col xs={4}>
            {<div className="float-right mt-1">{likes.length} Likes</div>}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default RecognitionCard;
