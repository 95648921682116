import React from "react";

import { ContentDetail } from "./../common";
import { Row, Col } from "react-bootstrap";

function StepApprovals({ skillStep }) {
  return (
    skillStep && (
      <div>
        <Row>
          <Col md={6}>
            <ContentDetail
              heading={"Approval Type"}
              content={skillStep.approvalType}
            />
          </Col>
          <Col md={6}>
            <ContentDetail
              heading={"Attachment Required"}
              content={skillStep.attachmentRequired ? "Yes" : "No"}
            />
          </Col>
        </Row>

        <ContentDetail
          heading={"Evidence Required"}
          content={skillStep.evidenceRequired}
        />
        <ContentDetail
          heading={"Coaching Guidelines"}
          content={skillStep.coachingGuidelines}
        />
      </div>
    )
  );
}

export default StepApprovals;
