import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import SkillStepLayout from "./skill-step";
import CreateSkillStepLayout from "./create-skill-step";

import { useSkillPathContext } from "./../components/providers";

function ManageSkillStepLayout() {
  let { path } = useRouteMatch();

  const { skillPath } = useSkillPathContext();
  return (
    skillPath && (
      <Switch>
        <Route exact path={`${path}/new`} component={CreateSkillStepLayout} />
        <Route path={`${path}/:skillStepSlug`} component={SkillStepLayout} />
        <Route path={`${path}`} component={SkillStepLayout} />
      </Switch>
    )
  );
}

export default ManageSkillStepLayout;
