import { useState, useEffect } from "react";

import { db } from "./../../../../../config/firebase";

import { api } from "../../../utils";
import { SkillStep } from "../../../utils/classes/skill-step";

function useSkillStep(skillStepRefPath) {
  const [skillStep, setSkillStep] = useState(new SkillStep({}));

  useEffect(() => {
    if (!skillStepRefPath) {
      let isCurrent = true;
      if (isCurrent) {
        setSkillStep(new SkillStep({}));
      }
      return () => {
        isCurrent = false;
      };
    }

    const stepRef = db.doc(skillStepRefPath);

    return api.skillStep.getStepByRef(stepRef, setSkillStep);
  }, [skillStepRefPath]);

  return skillStep;
}

export default useSkillStep;
