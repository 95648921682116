import React, { useState } from "react";

import Recognition from "./recognition";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../../style/Recognition.scss";
import { RecognitionViewModal } from "..";

import { useAuthState } from "../../../providers/AuthState";

function Recognitions({ recognitions, send, recognizedFilter }) {
  const { orgContext } = useAuthState();

  const [showRecognitionModal, setShowRecognitionModal] = useState(false);
  const [showViewRecognitionModal, setShowViewRecognitionModal] = useState(
    false
  );
  const [currentRecognition, setCurrentRecognition] = useState({});

  function handleOpen(recognition) {
    setCurrentRecognition(recognition);
    setShowViewRecognitionModal(true);
  }
  return (
    <Row>
      <Col className="recognition-cards">
        <Row>
          {recognitions.map(function (recognition) {
            if (
              recognizedFilter === recognition.recognizedTo ||
              recognizedFilter === "ALL"
            ) {
              return (
                <Recognition
                  recognition={recognition}
                  send={send}
                  handleOpen={handleOpen}
                />
              );
            }
            return "";
          })}
        </Row>
      </Col>
      <RecognitionViewModal
        showRecognitionModal={showViewRecognitionModal}
        setShowRecognitionModal={setShowViewRecognitionModal}
        sendRecognition={send}
        recognition={currentRecognition}
      />
    </Row>
  );
}

export default Recognitions;
