import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";

import { useSkillPathContext } from "./../providers";
import { useSkillStepForm } from "./hooks";

import { db } from "./../../../../config/firebase";
import { useAuthState } from "../../../../providers/AuthState";

function StepForm({ existingSkillStep }) {
  const [smeGroups, setSMEGroups] = useState([]);
  const [retireApprovedSteps, setRetireApprovedSteps] = useState(false);

  const { skillStageSlug, orgId } = useParams();
  const { skillPath } = useSkillPathContext();
  const skillStep = useSkillStepForm(
    skillPath,
    skillStageSlug,
    existingSkillStep
  );

  const { orgContext } = useAuthState();
  useEffect(() => {
    db.collection(`/organizations/${orgContext.id}/approvals`)
      .get()
      .then((docs) => {
        const groups = [];
        docs.forEach((doc) => {
          groups.push({
            label: doc.data().name,
            id: doc.id,
          });
        });

        setSMEGroups(groups);
      });
  }, [orgContext.id]);

  // useEffect(() => {
  //   let isCurrent = true;

  //   if (isCurrent && skillStep) {
  //     let skillStepId = skillStep.ref.id;
  //     if (skillStepId === "staged") {
  //       skillStepId = skillStep.ref.parent.parent.id;
  //     }

  //     let defaultRetire = false;
  //     if (skillPath.stepsToRetire && skillPath.stepsToRetire[skillStepId] && skillPath.stepsToRetire[skillStepId].retire) {
  //       defaultRetire = true;
  //     }
  //     setRetireApprovedSteps(defaultRetire);
  //   }

  //   return () => {
  //     isCurrent = false;
  //   }
  // }, [skillPath.id, skillStep])

  let history = useHistory();
  function handleCancel() {
    history.goBack();
  }

  function handleSubmit(event) {
    event.preventDefault();

    const skillStepInstance = skillStep.getSkillStepInstance();
    const skillStageId = skillPath.stages[skillStageSlug - 1].ref.id;

    // Need to use skillPath.ref.parent.parent.id, since we are updating the staged doc and need to use the id of the actual skill path
    let skillPathId = skillPath.ref.id;
    // let skillStepId = skillStep.ref.id;
    if (skillPathId === "staged") {
      skillPathId = skillPath.ref.parent.parent.id;
    }
    // if (skillStepId === "staged") {
    //   skillStepId = skillStep.ref.parent.parent.id;
    // }

    skillStepInstance
      .setParentIds({
        skillPathId: skillPathId,
        skillStageId,
        skillPathCode: skillPath.code,
      })
      .setUrl(skillPath.code, skillStageSlug, skillStepInstance.order)
      .save(orgId)
      // .then(() => {
      //   const skillPathUpdate = { [`stepsToRetire.${skillStepId}`]: { retire: retireApprovedSteps, stepName: `${skillStageSlug}.${skillStepInstance.order}: ${skillStepInstance.name}` } };
      //   return skillPath.ref.update(skillPathUpdate);
      // })
      .then(() => {
        let urlToGoTo = `skills/${skillPath.code}/${skillStageSlug}/${skillStepInstance.order}`;
        if (orgId) {
          urlToGoTo = `/organization/${orgId}/${urlToGoTo}`;
        }

        history.push(urlToGoTo);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Form onSubmit={handleSubmit}>
      {/* {skillStep.id && <Form.Row>
        <Col>
          <Form.Group controlId="retireApprovedSteps">
            <Form.Check
              type="switch"
              label="Retire Approved Steps"
              defaultChecked={retireApprovedSteps}
              onChange={(e) => {
                setRetireApprovedSteps(e.target.checked);
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>} */}
      <Form.Row>
        <Col md={8}>
          <Form.Group controlId="stepName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Step Name"
              defaultValue={skillStep.name}
              onChange={(e) => {
                skillStep.updateField("name", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="order">
            <Form.Label>Order</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="Order"
              value={skillStep.order}
              disabled={!skillStep.id}
              onChange={(e) => {
                skillStep.updateField("order", parseInt(e.target.value));
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col md={6}>
          <Form.Group controlId="threads">
            <Form.Label>Thread</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                skillStep.updateField("thread", e.target.value);
              }}
              value={skillStep.thread}
            >
              <option />
              {skillPath.threads.map((thread) => {
                return <option key={thread.name}>{thread.name}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId="objective">
            <Form.Label>Objective</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Objective"
              defaultValue={skillStep.objective}
              onChange={(e) => {
                skillStep.updateField("objective", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId="instructions">
            <Form.Label>Instructions</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Instructions"
              defaultValue={skillStep.instructions}
              onChange={(e) => {
                skillStep.updateField("instructions", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={8}>
          <Form.Group controlId="approvalType">
            <Form.Label>Approval Type</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                skillStep.updateField("approvalType", e.target.value);

                if (e.target.value !== "Subject Matter Expert") {
                  skillStep.updateField("smeGroupId", "");
                }
                if (e.target.value === "Recognition") {
                  skillStep.updateField("ignoreLogLimit", true);
                }
              }}
              value={skillStep.approvalType}
            >
              <option>Manager</option>
              <option>Subject Matter Expert</option>
              <option>Recognition</option>
              <option>Self Validated</option>
            </Form.Control>
          </Form.Group>
        </Col>
        {skillStep.approvalType === "Subject Matter Expert" && (
          <Col sm={4}>
            <Form.Group controlId="smeGroup">
              <Form.Label>Approval Group</Form.Label>
              <Form.Control
                as="select"
                value={skillStep.smeGroupId}
                onChange={(e) => {
                  skillStep.updateField("smeGroupId", e.target.value);
                }}
              >
                <option></option>
                {smeGroups.map((smeGroup) => {
                  return <option value={smeGroup.id}>{smeGroup.label}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        )}
      </Form.Row>
      {skillStep.approvalType !== "Self Validated" && (
        <Form.Row>
          <Col md={4}>
            <Form.Group controlId="numberOfLogsRequired">
              <Form.Label>Number of Logs Required</Form.Label>
              <Form.Control
                type="number"
                placeholder="1"
                value={skillStep.numberOfLogsRequired}
                onChange={(e) => {
                  skillStep.updateField(
                    "numberOfLogsRequired",
                    parseInt(e.target.value)
                  );
                }}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="ignoreLogLimit">
              <Form.Check
                style={{ marginTop: "38px" }}
                type="switch"
                label="Ignore Log Limit"
                checked={skillStep.ignoreLogLimit}
                onChange={(e) => {
                  skillStep.updateField(
                    "ignoreLogLimit",
                    !skillStep.ignoreLogLimit
                  );
                }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      )}

      {skillStep.approvalType !== "Self Validated" && (
        <Form.Row>
          <Col md={4}>
            <Form.Group controlId="attachmentRequired">
              <Form.Check
                type="switch"
                label="Attachment Required"
                checked={skillStep.attachmentRequired}
                onChange={(e) => {
                  skillStep.updateField(
                    "attachmentRequired",
                    !skillStep.attachmentRequired
                  );
                }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      )}
      <Form.Row>
        <Col>
          <Form.Group controlId="evidenceRequired">
            <Form.Label>Evidence Required</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Evidence Required"
              defaultValue={skillStep.evidenceRequired}
              onChange={(e) => {
                skillStep.updateField("evidenceRequired", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId="coachingGuidelines">
            <Form.Label>Coaching Guidelines</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              placeholder="Coaching Guidelines"
              defaultValue={skillStep.coachingGuidelines}
              onChange={(e) => {
                skillStep.updateField("coachingGuidelines", e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Row>
            <Col md={1}>
              <Button variant="outline-primary" type="submit">
                Save
              </Button>
            </Col>
            <Col md={1}>
              <Button variant="outline-danger" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
    </Form>
  );
}

export default StepForm;
