import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import MetisphereSymbol from "./metisphere-symbol-no-tm.svg"

import {
  useHistory
} from "react-router-dom";

import { useOrganizationForm } from "../hooks";

import { SkillPath } from "../../../skills/utils/classes/skill-path"

import { db, functions } from "../../../../config/firebase";
import { useAuthState } from "../../../../providers/AuthState";

import Auth from "../../../../core/auth/utils/classes/auth";

function Finalize({ setStep }) {
  const { orgContext, user, dispatch } = useAuthState();

  const [finalizeStatus, setFinalizeStatus] = useState("START");
  const [onboardingSkillId, setOnboardingSkillId] = useState("tmOXqrwoU64V0sB0zoQI");
  const [blankSkillId, setBlankSkillId] = useState("Y7qdf7mjLqIe7DvZXtyu");
  const [psp1010Id, setPsp1010Id] = useState("lQs6WWQhUAePawwRjNrY");
  const [zcsdSkillId, setZcsdId] = useState("PxlXIULv0KyIvCOy6N0f");
  const [onboardingSkill, setOnboardingSkill] = useState();
  const [blankSkill, setBlankSkill] = useState();
  const [pspSkill, setPSPSkill] = useState();
  const [zcsdSkill, setZcsdSkill] = useState();

  let organization = useOrganizationForm(orgContext.id);

  function addSkillToUser(currentUser, skill) {
    organization.addSkillToUser(currentUser, skill);
    var assignPath = functions.httpsCallable("skills-assignPath");
    assignPath({
      pathId: skill.id,
      userId: currentUser.id,
      organizationId: organization.id,
    });
  }

  useEffect(() => {
    db.collection("paths").doc(onboardingSkillId).get().then(function (doc) {
      let skillData = {
        ...doc.data(),
        id: doc.id
      }

      let skill = new SkillPath(skillData);

      console.log("[Onboarding Skill]", skill, doc);

      setOnboardingSkill(skill);
    });
    db.collection("paths").doc(blankSkillId).get().then(function (doc) {
      let blankSkillData = {
        ...doc.data(),
        id: doc.id
      }

      let blankSkill = new SkillPath(blankSkillData);

      setBlankSkill(blankSkill);
    });
    db.collection("paths").doc(psp1010Id).get().then(function (doc) {
      let pspSkillData = {
        ...doc.data(),
        id: doc.id
      }

      let pspSkill = new SkillPath(pspSkillData);

      setPSPSkill(pspSkill);
    });

    db.collection("paths").doc(zcsdSkillId).get().then(function (doc) {
      let zcsdSkillData = {
        ...doc.data(),
        id: doc.id
      }

      let zcsdSkill = new SkillPath(zcsdSkillData);

      setZcsdSkill(zcsdSkill);
    });
  }, [onboardingSkillId])


  const history = useHistory();

  let finalizeBody;

  switch (finalizeStatus) {
    case "SAVE":
      // code block
      finalizeBody = <div>
        {/* <Spinner animation="grow" variant="secondary" /> */}
        <div className="loader"></div>
        <img
          src={MetisphereSymbol}
          style={{
            width: "50px",
            height: "50px",
            marginLeft: "2px",
            marginTop: "-244px",
          }}
          alt="Metisphere Logo"
        />{" "}
        <div>Saving Organization details...</div>

      </div>;
      break;
    case "ENROLL":
      // code block
      finalizeBody = <div>
        {/* <Spinner animation="grow" variant="secondary" /> */}
        <div className="loader"></div>
        <img
          src={MetisphereSymbol}
          style={{
            width: "50px",
            height: "50px",
            marginLeft: "2px",
            marginTop: "-244px",
          }}
          alt="Metisphere Logo"
        />{" "}
        <div>Enrolling learners...</div>
      </div>
        ;
    case "DONE":
      // code block
      finalizeBody = <div>
        {/* <Spinner animation="grow" variant="secondary" /> */}
        <div className="loader"></div>
        <img
          src={MetisphereSymbol}
          style={{
            width: "50px",
            height: "50px",
            marginLeft: "2px",
            marginTop: "-244px",
          }}
          alt="Metisphere Logo"
        />{" "}
        <div>Finishing up...</div>
      </div>
        ;
      break;
  }

  useEffect(() => {
    if (finalizeStatus === "DONE" && organization.skills.length > 2) {

      let auth = new Auth({ user: user });
      auth.loadContext().then(function (context) {
        dispatch(context);
        history.push("skills/user/MTO-001/1/1");
      });
    }
  }, [finalizeStatus])

  return (
    <div>
      <div style={{ minHeight: "400px", textAlign: "center" }}>
        {/* */}
        {finalizeStatus === "START" ? <div>
          <Row>
            <Col xs={12}>
              <Alert variant="success">You've successfully setup your organization!</Alert>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5 style={{ marginBottom: "15px" }}>Here are the next steps…</h5>
              <p>Now that you've completed onboarding, please click finalize to save your organizations details and explore new ways to foster learning within your organization!</p>
              <p>You will be taken to our Metisphere Onboarding Skill in order to familiarize yourself with the platform.</p>
              <p>You may exit that skill at any time and either go to the SKILLS menu to edit your blank skill template or go directly to the MY SKILLS menu in order to begin working on your selected skill path.</p>

            </Col>
          </Row>
        </div> : ""}
        {finalizeStatus === "SAVE" ? <div>
          {/* <Spinner animation="grow" variant="secondary" /> */}
          <div className="loader"></div>
          <img
            src={MetisphereSymbol}
            style={{
              width: "50px",
              height: "50px",
              marginLeft: "2px",
              marginTop: "-244px",
            }}
            alt="Metisphere Logo"
          />{" "}
          <div>Saving...</div>
        </div> : ""}
        {finalizeStatus === "ENROLL" ? <div>
          {/* <Spinner animation="grow" variant="secondary" /> */}
          <div className="loader"></div>
          <img
            src={MetisphereSymbol}
            style={{
              width: "50px",
              height: "50px",
              marginLeft: "2px",
              marginTop: "-244px",
            }}
            alt="Metisphere Logo"
          />{" "}
          <div>Enrolling learners...</div>
        </div> : ""}
        {finalizeStatus === "DONE" ? <div>
          {/* <Spinner animation="grow" variant="secondary" /> */}
          <div className="loader"></div>
          <img
            src={MetisphereSymbol}
            style={{
              width: "50px",
              height: "50px",
              marginLeft: "2px",
              marginTop: "-244px",
            }}
            alt="Metisphere Logo"
          />{" "}
          <div>Finishing up...</div>
        </div> : ""}
      </div>
      <div>

        <Row>
          <Col xs={6}></Col>
          <Col xs={6}>
            <div className="text-right">
              <Button
                variant="outline-secondary"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setStep(3);
                }}
              >
                Back
              </Button>
              <Button
                variant="outline-primary"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setFinalizeStatus("SAVE");
                  let blankSkillJSON = { ...blankSkill.toFirestoreFormat(), custom: true };

                  organization.addSkill(blankSkillJSON);
                  organization.addSkill(onboardingSkill.toFirestoreFormat());
                  organization.addSkill(pspSkill.toFirestoreFormat());
                  organization.addSkill(zcsdSkill.toFirestoreFormat());

                  setTimeout(function () {
                    let copySkillToOrg = functions.httpsCallable("orgSkills-copySkillToOrg");
                    copySkillToOrg({
                      skillPathId: blankSkillId,
                      orgId: organization.id,
                    })
                  }, 3000);

                  setTimeout(function () {

                    setFinalizeStatus("ENROLL");

                    organization.users.forEach(function (user) {
                      addSkillToUser(user, onboardingSkill);
                      addSkillToUser(user, pspSkill);
                      addSkillToUser(user, zcsdSkill);
                      if (user.email === organization.adminEmail) {
                        addSkillToUser(user, blankSkill);
                      }
                    });

                  }, 6000);

                  setTimeout(function () {
                    db.collection("organizations")
                      .doc(orgContext.id)
                      .update({ onboarding: true })
                      .then(function () {
                        setFinalizeStatus("DONE");
                      });
                  }, 15000);

                  // setTimeout(function () {
                  //   setFinalizeStatus(4)
                  // }, 4000)
                  // let copySkillToOrg = functions.httpsCallable("orgSkills-copySkillToOrg");
                  // let skillPathId = skillPath.id;
                  // copySkillToOrg({
                  //   skillPathId: skillPathId,
                  //   orgId: organization.id,
                  // }).then(() => {
                  //   console.log(skillPath);
                  //   skillPath.ref.update({ custom: true });
                  // });
                  // }


                }}
              >
                Finalize
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div >
  );
}

export default Finalize;
