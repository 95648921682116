import { useReducer, useEffect } from "react";
import { SkillPath } from "../../../utils/classes/skill-path";
import { api } from "../../../utils";

function pathFormReducer(state, action) {
  switch (action.type) {
    case "LOAD_PATH": {
      return { ...action.payload, codeToCheck: action.payload.code };
    }
    case "ADD_THREAD_ROW": {
      return { ...state, threads: [...state.threads, { name: "", steps: [] }] };
    }
    case "REMOVE_THREAD_ROW": {
      const threads = state.threads.filter((thread, ind) => {
        return ind !== action.payload.index;
      });
      return { ...state, threads };
    }
    case "UPDATE_FIELD": {
      let published = state.published;
      if (action.payload.field !== "status") {
        published = false;
      }
      return {
        ...state,
        [action.payload.field]: action.payload.value,
        published,
      };
    }
    default: {
      return state;
    }
  }
}

function useSkillPathForm(skillPathCode, vendorId = "metisphere", orgId) {
  const [state, dispatch] = useReducer(
    pathFormReducer,
    new SkillPath({ vendorId })
  );

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent && skillPathCode) {
      if (orgId) {
        api.skillPath.getStagedSkillPathByCodeForOrg(
          skillPathCode,
          orgId,
          (skillPath) => {
            dispatch({ type: "LOAD_PATH", payload: skillPath });
          }
        );
      } else {
        api.skillPath.getStagedSkillPathByCode(skillPathCode, (skillPath) => {
          dispatch({ type: "LOAD_PATH", payload: skillPath });
        });
      }
    }
    return () => {
      isCurrent = false;
    };
  }, [skillPathCode]);

  const value = {
    ...state,
    updateField(field, value) {
      dispatch({
        type: "UPDATE_FIELD",
        payload: { field: field, value: value },
      });
    },
    addThreadRow() {
      dispatch({ type: "ADD_THREAD_ROW" });
    },
    removeThreadRow(ind) {
      dispatch({ type: "REMOVE_THREAD_ROW", payload: { index: ind } });
    },
    getSkillPathInstance() {
      return new SkillPath(state);
    },
  };
  return value;
}

export default useSkillPathForm;
