import { api } from "./../api";
import { FileBreakFill } from "react-bootstrap-icons";
import { isObservable } from "xstate/lib/utils";

const activeStatuses = [
  "Available",
  "On Hold",
  "In Development",
  "Out of Date",
];

export class SkillPath {
  constructor(doc = {}) {
    this.id = doc.id || "";
    this.name = doc.name || "";
    this.code = doc.code || "";
    this.status = doc.status || "In Development";
    this.availability = doc.availability || "Private";
    this.objective = doc.objective || "";

    this.vendorId = doc.vendorId || "";
    this.version = doc.version || 1;
    this.published = doc.published || false;

    this.active = doc.active || true;
    this.custom = doc.custom || false;

    this.ref = doc.ref || null;

    this.threads =
      (doc.threads &&
        doc.threads.map((thread) => {
          return {
            ...thread,
            steps: thread.steps.map((stepStubDoc) => {
              return new SkillStepStub(stepStubDoc);
            }),
          };
        })) ||
      [];

    this.stages =
      (doc.stages &&
        doc.stages.map((stageStubDoc) => {
          return new SkillStageStub(stageStubDoc);
        })) ||
      [];


    // this.stepsToRetire = doc.stepsToRetire ?? {};

    return this;
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      status: this.status,
      objective: this.objective,
      vendorId: this.vendorId,
      availability: this.availability,
      version: this.version,
      published: this.published,
      // stepsToRetire: this.stepsToRetire,
      threads: this.threads
        .filter((thread) => {
          return thread.name !== "";
        })
        .map((thread) => {
          return {
            ...thread,
            steps: thread.steps.map((stepStub) => {
              return stepStub.toFirestoreFormat();
            }),
          };
        }),
      stages: this.stages.map((stageStub) => {
        return stageStub.toFirestoreFormat();
      }),
    };
  }

  toReducerFormat() { }

  deleteStage(skillStage) {
    const stageIndex = this.stages.findIndex((stage) => {
      return stage.id === skillStage.id;
    });

    this.stages.splice(stageIndex, 1);
    this.published = false;
    return this;
  }

  deleteStep(skillStep) {
    // Find and remove the skillstep
    for (const stage of this.stages) {
      const stepIndex = stage.steps.findIndex((step) => {
        return step.id === skillStep.id;
      });
      if (stepIndex > -1) {
        stage.steps.splice(stepIndex, 1);
        this.published = false;
        break;
      }
    }
    return this;
  }

  save(orgId) {
    if (!this.id) {
      this.id = api.skillPath.getDoc().id;
    }

    let pathPromise;
    if (orgId) {
      pathPromise = api.skillPath.updateStagedOnOrg(
        this.toFirestoreFormat(),
        orgId
      );
    } else {
      pathPromise = api.skillPath.updateStaged(this.toFirestoreFormat());
    }

    // If this hasn't been published update both the staged version and the path doc
    if (this.version === 1 && !orgId) {
      pathPromise.then(() => {
        return api.skillPath.update(this.toFirestoreFormat());
      });
    }

    return pathPromise;
  }

  addStageSlug(stage) {
    this.published = false;
    if (this.stages[stage.order - 1]) {
      this.stages[stage.order - 1].update(stage);
    } else {
      this.stages.push(new SkillStageStub(stage));
    }
    return this;
  }

  incrementVersion() {
    this.version = this.version + 1 || 1;
    return this;
  }

  addStep(stageIndex, skillStep) {
    this.published = false;
    if (this.stages[stageIndex].steps[skillStep.order - 1]) {
      this.stages[stageIndex].steps[skillStep.order - 1].update(skillStep);
    } else {
      this.stages[stageIndex].steps.push(new SkillStepStub(skillStep));
      this.threads
        .find((thread) => {
          return skillStep.thread === thread.name;
        })
        .steps.push(new SkillStepStub(skillStep));
    }

    return this;
  }

  getSkillStepRefFromSlugs({ skillStageSlug, skillStepSlug }) {
    let skillStepRef = null;
    // Get skill stage
    const skillStage = this.stages.find((stage) => {
      return stage.order + "" === skillStageSlug;
    });

    if (skillStage) {
      skillStepRef = this.stages[skillStageSlug - 1].steps.find((step) => {
        return step.order + "" === skillStepSlug + "";
      });
    }

    return skillStepRef;
  }

  getSkillStageRefFromSlugs({ skillStageSlug }) {
    return (
      this.stages.length > skillStageSlug - 1 &&
      this.stages[skillStageSlug - 1].ref
    );
  }

  // publish() {
  //   //console.log("Publishing");
  //   // Move to cloud functions - need to iterate through everything and publish
  //   //console.log(`paths/${this.id}/versions`);

  //   const version = this.toFirestoreFormat();
  //   version.datePublished = new Date();
  //   version.author = "Jason Manners";

  //   return db
  //     .collection(`paths/${this.id}/versions`)
  //     .add(version)
  //     .then(() => {
  //       this.published = true;
  //       return this.incrementVersion().save();
  //     })
  //     .then(() => {
  //       this.stages.forEach((stage, ind) => {
  //         stage.steps.forEach((step) => {
  //           api.skillStep.getStepDataByRef(step.ref, (skillStep) => {
  //             skillStep.publish(this.id, stage.ref.id);
  //           });
  //         });
  //       });
  //     });
  // }

  static getActiveStatuses() {
    return activeStatuses;
  }

  static firebaseConvertor(skillPath) {
    return skillPath.toFirestoreFormat();
  }
}

class SkillStageStub {
  constructor(doc, ref) {
    this.id = doc.id;
    this.name = doc.name;
    this.ref = doc.ref || ref || "";
    // this.order = doc.order || "";
    this.order = -1;
    if (doc.order !== undefined) {
      this.order = doc.order;
    }
    this.steps =
      (doc.steps &&
        doc.steps.map((step) => {
          return new SkillStepStub(step);
        })) ||
      [];

    return this;
  }

  update(stage) {
    this.name = stage.name;
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      name: this.name,
      ref: this.ref,
      order: this.order,
      steps: this.steps.map((stepStub) => {
        return stepStub.toFirestoreFormat();
      }),
    };
  }
}

class SkillStepStub {
  constructor(doc, ref) {
    this.id = doc.id;
    this.name = doc.name;
    this.ref = doc.ref || ref || "";
    // this.order = (doc.order !== undefined && doc.order) || -1;
    this.skillStageSlug = doc.skillStageSlug || "";
    this.url = doc.url || "";
    this.version = doc.version || "1";
    this.stage = doc.stage || null;

    this.order = -1;
    if (doc.order !== undefined) {
      this.order = doc.order;
    }
    return this;
  }

  update(step) {
    this.name = step.name;
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      name: this.name,
      ref: this.ref,
      order: this.order,
      skillStageSlug: this.skillStageSlug,
      url: this.url,
      version: this.version,
      stage: this.stage,
    };
  }
}
