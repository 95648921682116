import signup from "./signup";
import auth from "./auth";
import profile from "./profile";
import organization from "./organization";

export const api = {
  signup,
  auth,
  profile,
  organization,
};
