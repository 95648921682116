import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import DashboardLayout from "./dashboard";
import SkillPathLayout from "./manage-skill-path";
import CreateSkillPathLayout from "./create-skill-path";
import UserDashboardLayout from "./user-dashboard";
import UserSkillPath from "./user-skill-path";

function SkillsLayout() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={DashboardLayout} />
      <Route exact path={`${path}/user`} component={UserDashboardLayout} />
      <Route path={`${path}/user/:skillPathId`} component={UserSkillPath} />
      <Route exact path={`${path}/new`} component={CreateSkillPathLayout} />

      <Route
        path={`${path}/:skillPathId/edit`}
        component={CreateSkillPathLayout}
      />

      <Route path={`${path}/:skillPathId`} component={SkillPathLayout} />
    </Switch>
  );
}

export default SkillsLayout;
