import { api } from "../api";

export default class User {
  constructor(doc) {
    /* Fields */
    this.id = doc.id || "";
    this.displayName = doc.displayName || "";
    this.manager = doc.manager || "";
    this.email = doc.email || "";
    this.isManager = doc.isManager || "No";
    this.isSME = doc.isSME || "No";
    this.claimed = doc.claimed || false;
    this.skills = doc.skills || [];
    this.orgId = doc.orgId || "";
    this.createdDate = doc.createdDate || new Date();
    if (this.orgId === "") {
      throw new Error("User must have an Org ID");
    }
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      displayName: this.displayName,
      email: this.email,
      manager: this.manager,
      isManager: this.isManager,
      isSME: this.isSME,
      createdDate: this.createdDate,
      orgId: this.orgId,
      skills: this.skills,
    };
  }

  toReducerFormat() {}

  save() {
    //console.log("Saving...");
    return api.organization.update(this.toFirestoreFormat());
  }

  get(orgId, inviteId) {
    return api.invite.get(orgId, inviteId);
  }

  addSkill(skill) {
    return api.organization.addSkillToUser(this.orgId, this.id, skill);
  }

  removeSkill(skill) {
    return api.organization.removeSkillToUser(this.orgId, this.id, skill);
  }

  updateField(field, value) {
    this[field] = value;
    //console.log("[USER UPDATED] ", this);
  }

  static firebaseConvertor() {
    return this.toFirestoreFormat();
  }
}
