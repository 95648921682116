import React, { useEffect } from "react";

import { Row, Col, Form, Button, ListGroup } from "react-bootstrap";

import { Link, useHistory } from "react-router-dom";

import { api } from "../../utils/api";

import { db } from "../../../../config/firebase";

import { firebase } from "../../../../config/firebase";

import { useAuthState } from "../../../../providers/AuthState";

import { useProfileForm } from "../profile/hooks";

import profile from "../../../../core/auth/utils/api/profile";

import ProfileOrganizationList from "./profile-organization-list";

function ProfileEdit() {
  let uid = undefined;
  const profileForm = useProfileForm();

  let storageRef = firebase.storage().ref();

  const { user } = useAuthState();

  if (user) {
    uid = user.uid;
  } else {
    uid = undefined;
  }

  const history = useHistory();

  useEffect(() => {
    if (user) {
      api.profile.get(uid).then(function (doc) {
        let p = {
          ...doc.data(),
          id: doc.id,
        };
        if (user.b.b.a === "google.com") {
          profileForm.update(p);
        } else {
          let photoRef = storageRef.child(p.photoURL);
          photoRef.getDownloadURL().then(function (url) {
            //console.log("URL: ", url);
            p.photoPath = url;
            profileForm.update(p);
          });
        }
      });
    }
  }, [uid, user, storageRef, profileForm]);

  // function viewState() {
  //   //console.log(profileForm);
  // }

  function handleSubmit(e) {
    e.preventDefault();
    api.profile.update(profileForm.get().toFirestoreFormat()).then(function () {
      history.push("/profile");
    });
  }

  ////console.log("Is Third Party Auth: ", isThirdPartyAuth(user));

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,.125)",
                marginBottom: "15px",
                marginTop: "15px",
              }}
            >
              <Row>
                <Col xs={6}>
                  <div className="text-info header-pretitle">Profile</div>
                  <h4
                    className="text-secondary"
                    style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                  >
                    Erich Rusch
                  </h4>
                </Col>
                <Col>
                  <Button
                    variant="outline-primary"
                    className="font-weight-bold float-right"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  <Link
                    className="btn btn-outline-secondary font-weight-bold float-right"
                    to="/profile"
                    style={{ marginRight: "5px" }}
                  >
                    Cancel
                  </Link>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12}>
                      <div className="sub-header-container">
                        <Row>
                          <Col xs={12}>
                            <h6 className="sub-header text-primary">
                              Google Profile Details
                            </h6>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center" xs={4}>
                      <img
                        src={user.photoURL}
                        className="profile-photo-url"
                        alt="Profile Details"
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <AttributeCard
                            label="Name"
                            value={user.displayName}
                          />
                        </Col>
                        <Col>
                          <AttributeCard label="Login" value={user.b.b.a} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <AttributeCard label="Email" value={user.email} />
                        </Col>
                        <Col>
                          <AttributeCard
                            label="Email Verified"
                            value={user.emailVerified ? "Yes" : "No"}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <AttributeCard
                            label="Phone"
                            value={user.phoneNumber ? user.phoneNumber : "NA"}
                          />
                        </Col>
                        <Col>
                          <AttributeCard
                            label="User Type"
                            value={profileForm.type}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col xs={12}>
                  {" "}
                  <div
                    style={{
                      borderBottom: "1px solid rgba(0,0,0,.125)",
                      marginBottom: "15px",
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <h6
                          className="text-primary"
                          style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                        >
                          Metisphere Details
                        </h6>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-left" xs={4}>
                  <h6 className="text-secondary font-weight-bold">
                    Organizations
                  </h6>
                  <ProfileOrganizationList />
                </Col>
                <Col>
                  <Form>
                    <Row>
                      <Col>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            Recovery Email Address
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Recovery Email"
                            defaultValue={profileForm.recoveryEmail}
                            onChange={(e) => {
                              profileForm.updateField(
                                "recoveryEmail",
                                e.target.value
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        {" "}
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            Transcript Email Address
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Transcript Email"
                            defaultValue={profileForm.transcriptEmail}
                            onChange={(e) => {
                              profileForm.updateField(
                                "transcriptEmail",
                                e.target.value
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            CITY
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Location (City)"
                            value={profileForm.city}
                            onChange={(e) => {
                              profileForm.updateField("city", e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            COUNTRY
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={profileForm.country}
                            onChange={(e) => {
                              profileForm.updateField(
                                "country",
                                e.target.value
                              );
                            }}
                          >
                            <option>United States</option>
                            <option>Canada</option>
                            <option>United Kingdom</option>
                            <option>Mexico</option>
                            <option>Australia</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            Learner Summary
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={profileForm.learnerSummaryFrequency}
                            onChange={(e) => {
                              profileForm.updateField(
                                "learnerSummaryFrequency",
                                e.target.value
                              );
                            }}
                          >
                            <option>Weekly</option>
                            <option>Monthly</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        {" "}
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            SME Summary - {profileForm.smeSummaryFrequency}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={profileForm.smeSummaryFrequency}
                            onChange={(e) => {
                              profileForm.updateField(
                                "smeSummaryFrequency",
                                e.target.value
                              );
                            }}
                          >
                            <option>Weekly</option>
                            <option>Monthly</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function AttributeCard({ label, value, args }) {
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      <div>{value}</div>
    </div>
  );
}

// function FormGroupCard({ label, value, args }) {
//   return (
//     <div style={{ paddingBottom: "15px" }}>
//       <div className="text-info header-pretitle">{label}</div>
//       <div style={{ paddingTop: "15px" }}>{value}</div>
//     </div>
//   );
// }

export default ProfileEdit;
