import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  ListGroup,
  Media,
  Jumbotron,
  Container,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Route, useRouteMatch, Link, useParams } from "react-router-dom";
import { MainHeading } from "../../../core/helpers/layout-components";

import { db } from "./../../../config/firebase";

import { Page } from "./../../skills/components/common";
import { useAuthState } from "../../../providers/AuthState";
import moment from "moment";

import WisdomApprovalsLayout from "./wisdom-approvals";
import WisdomSkillListLayout from "./wisdom-skill-list";

function WisdomDashboardLayout() {
  const [currentActiveKey, setCurrentActiveKey] = useState("approvals");

  const { orgId } = useParams();
  const { orgContext } = useAuthState();

  const currentOrgId = orgId ?? orgContext.id;

  return (
    <Page>
      <Container>
        <Row>
          <Col md={6}>
            <MainHeading title="Collective Wisdom" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey="approvals"
              id="wisdom-tabs"
              activeKey={currentActiveKey}
              onSelect={(key) => setCurrentActiveKey(key)}
            >
              <Tab eventKey="approvals" title="Approvals">
                <WisdomApprovalsLayout orgId={currentOrgId} />
              </Tab>
              <Tab eventKey="wisdom" title="Manage Skill Wisdom">
                <WisdomSkillListLayout orgId={currentOrgId} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default WisdomDashboardLayout;
