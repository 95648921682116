import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import SuperUserDashboardAnalytics from "./tabs/super-user-analytics";
import SuperUserDashboardOrganizations from "./tabs/super-user-organizations";
import SuperUserDashboardUsers from "./tabs/super-user-user-list";
import TrialViewsDashboard from "./tabs/trial-views";

import { Link } from "react-router-dom";

import "../../../../style/Dashboard.scss";

function SuperUserDashboard() {
  return (
    <Container>
      <Row style={{ marginTop: "15px" }}>
        <Col>
          <Row>
            <Col>
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0,0,.125)",
                  marginBottom: "15px",
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="text-info header-pretitle">OVERVIEW</div>
                    <h4
                      className="text-secondary"
                      style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                    >
                      Metisphere Super User Dashboard
                    </h4>
                  </Col>
                  <Col>
                    <Link
                      variant="secondary"
                      className="btn btn-outline-secondary font-weight-bold float-right"
                      to="/organization/new"
                    >
                      Onboard New Customer
                    </Link>
                  </Col>
                </Row>
                <Tabs
                  defaultActiveKey="customers"
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="customers" title="Customers">
                    <SuperUserDashboardOrganizations />
                  </Tab>
                  <Tab eventKey="superusers" title="Super Users">
                    <SuperUserDashboardUsers />
                  </Tab>
                  <Tab eventKey="analytics" title="Analytics">
                    <SuperUserDashboardAnalytics />
                  </Tab>
                  <Tab eventKey="trialviews" title="Trial Views">
                    <TrialViewsDashboard />
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SuperUserDashboard;
