import React from "react";

import { RecognitionCard } from "..";

import Col from "react-bootstrap/Col";

import "../../../style/Recognition.scss";

function Recognition({ recognition, send, handleOpen }) {
  return (
    <Col xs={12} sm={4} style={{ marginBottom: "15px" }}>
      <RecognitionCard
        recognition={recognition}
        send={send}
        handleOpen={handleOpen}
      />
    </Col>
  );
}

export default Recognition;
