import React, { useState, useEffect } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import { useAuthState } from "../../../providers/AuthState";
import { Recognition } from "../utils/classes/recognition";
import SkillStage from "./recognition-skill-stage";

import { db } from "./../../../config/firebase";

import { hasActiveRecognition } from "./../utils/common/experience";

function RecognitionModal({
  showRecognitionModal,
  setShowRecognitionModal,
  sendRecognition,
  disableSkillSelection,
  path,
  stage,
  step,
  disabledConfetti,
  postSubmitHandler,
}) {
  const {
    user,
    orgUsers,
    orgProfile,
    orgManagers,
    skillPaths,
  } = useAuthState();
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentManager, setCurrentManager] = useState();
  const [managerWarning, setManagerWarning] = useState("");

  const [selectedPathIndex, setSelectedPathIndex] = useState(0);
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedStep, setSelectedStep] = useState("");
  const [recognitionErrors, setRecognitionErrors] = useState([]);
  const [hasActiveRecognitionStep, setHasActiveRecognitionStep] = useState(
    false
  );

  const [recognition, setRecognition] = useState(
    new Recognition(
      "",
      "",
      user.uid,
      user.displayName,
      "",
      "",
      "",
      "",
      undefined,
      "",
      "",
      undefined,
      undefined,
      [],
      [],
      0
    )
  );

  if (skillPaths) {
    console.log("Skill: ", skillPaths);
    // skillPaths[0].stages.map(function (stage) {
    //   return console.log("Stage: ", stage.id);
    // });
  }

  useEffect((e) => {}, [path, stage, step]);

  function handleHideRecognitionModal() {
    setShowRecognitionModal(false);
  }

  // function handleShowRecognitionModal() {
  //   setShowRecognitionModal(true);
  // }

  function submitRecognition() {
    let errors = [];
    if (!recognition.recognizedTo) {
      errors.push("Please select someone to recognize!");
    }

    if (!selectedPath) {
      errors.push("Please select a skill for recognition!");
    }

    if (!recognition.description || !recognition.witness) {
      errors.push(
        "Please detail the recognition and describe what you witnessed!"
      );
    }

    if (errors.length === 0) {
      setShowRecognitionModal(false);
      sendRecognition({ type: "ADD_RECOGNITION", recognition });
      if (postSubmitHandler) {
        postSubmitHandler(recognition);
      }
      setShowConfetti(true);
      setTimeout(function () {
        setShowConfetti(false);
      }, 8000);
    } else {
      setRecognitionErrors(errors);
    }
  }

  function toggleNotifyManager(managerId) {
    console.log("[TOGGLE MANAGER] ", managerId);
    if (managerId) {
      if (recognition.managers.includes(managerId)) {
        recognition.managers = recognition.managers.filter(function (
          currentManagerId
        ) {
          return currentManagerId !== managerId;
        });
      } else {
        recognition.managers.push(managerId);
      }
    }

    console.log("[RECOGNITION MANAGERS] ", recognition.managers);

    setRecognition(recognition);
  }

  function notifyManager(managerId) {
    console.log("[NOTIFY MANAGER] ", recognition.managers.includes(managerId));
    if (recognition.managers.includes(managerId)) {
      return true;
    }
    return false;
  }

  function isMyManager(current, mine) {
    return current == mine;
  }

  function toggleEscalation() {
    recognition.escalation = !recognition.escalation;
    setRecognition(recognition);
  }

  // if (!recognition.managers.includes(orgProfile.manager)) {
  //   recognition.managers.push(orgProfile.manager);
  // }

  console.log("[NOTIFY MANAGER] ", recognition.managers);

  function getCurrentManager(userId) {
    let selectedUser = orgUsers.filter(function (orgUser) {
      return userId === orgUser.id;
    });
    let manager = "";
    console.log("[SELECTED MANAGER] ", selectedUser[0]);
    if (selectedUser.length > 0) {
      manager = selectedUser[0].manager;
    }
    console.log("[SELECTED MANAGER] ", manager);

    return manager;
  }

  function getSkillStageDisplayName(selectedPath, id) {
    return new Promise(function (resolve, reject) {
      console.log(`paths/${selectedPath}`);
      return db
        .collection("paths")
        .doc(selectedPath)
        .get()
        .then(function (doc) {
          let stages = doc.data().stages;
          let stageName;
          stages.forEach(function (stage) {
            if (stage.id === id) {
              stageName = stage.name;
            }
          });
          console.log("STAGE NAME: ", stageName);
          resolve(stageName);
        });
    });
  }

  return (
    <div>
      {showConfetti && !disabledConfetti ? (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={500}
          recycle={false}
        />
      ) : (
        ""
      )}
      <Modal
        size="lg"
        show={showRecognitionModal}
        onHide={handleHideRecognitionModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Give Recognition
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {recognitionErrors.length > 0
            ? recognitionErrors.map(function (error) {
                return <Alert variant="danger mt-2 text-center">{error}</Alert>;
              })
            : ""}
          <Form.Group controlId="recognitionForm.target">
            <Form.Label className="font-weight-bold">
              Select Someone to Recognize
            </Form.Label>
            <Form.Control
              as="select"
              value={recognition.recognizedTo}
              onChange={(e) => {
                let newRecognitionForm = { ...recognition };
                let displayName = e.target.options[e.target.selectedIndex].text;
                newRecognitionForm.recognizedTo = e.target.value;
                newRecognitionForm.recognizedToDisplayName = displayName;

                let manager = getCurrentManager(e.target.value);
                if (manager) {
                  setCurrentManager(manager);
                  newRecognitionForm.managers.push(manager);
                } else {
                  setManagerWarning(
                    `${displayName} does not have a direct manager.`
                  );
                }
                setRecognition(newRecognitionForm);
              }}
            >
              <option value="">None</option>

              {Array.isArray(orgUsers) &&
                orgUsers.map(function (orgUser) {
                  //console.log("Org User ID ", orgUser.id, user.uid);
                  if (orgUser.id !== user.uid) {
                    return (
                      <option value={orgUser.id} key={orgUser.id}>
                        {orgUser.displayName}
                      </option>
                    );
                  }
                  return "";
                })}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="recognitionForm.target">
            <Form.Row className="align-items-center">
              <Col xs="auto" className="my-1">
                <b>Skill</b>
              </Col>
            </Form.Row>
            <Form.Row className="align-items-center">
              <Col xs="12" className="my-1">
                <Form.Label
                  className="mr-sm-2"
                  htmlFor="inlineFormCustomSelect"
                  srOnly
                >
                  Path
                </Form.Label>
                <Form.Control
                  as="select"
                  className="mr-sm-2"
                  id="inlineFormCustomSelect"
                  custom
                  onChange={(e) => {
                    let newRecognitionForm = { ...recognition };
                    setSelectedPathIndex(e.target.value);
                    if (e.target.value !== "") {
                      setSelectedPath(skillPaths[e.target.value].id);
                      newRecognitionForm.path = skillPaths[e.target.value].id;
                      newRecognitionForm.pathDisplayName =
                        skillPaths[e.target.value].name;
                      setRecognition(newRecognitionForm);

                      hasActiveRecognition(
                        orgProfile,
                        skillPaths[e.target.value].id
                      ).then((hasActiveStep) => {
                        setHasActiveRecognitionStep(hasActiveStep);
                      });
                    } else {
                      setSelectedPath("");
                      setHasActiveRecognitionStep(false);
                    }
                  }}
                  disabled={disableSkillSelection}
                >
                  <option value="">Select Skill Path</option>
                  {Array.isArray(skillPaths) &&
                    skillPaths.map(function (path, index) {
                      return (
                        <option key={path.id} value={index}>
                          {path.name}
                        </option>
                      );
                    })}
                </Form.Control>
              </Col>

              {/* <Col xs="6" className="my-1">
                <Form.Label
                  className="mr-sm-2"
                  htmlFor="inlineFormCustomSelect"
                  srOnly
                >
                  Path
                </Form.Label>
                <Form.Control
                  as="select"
                  className="mr-sm-2"
                  id="inlineFormCustomSelect"
                  custom
                  onChange={(e) => {
                    let newRecognitionForm = { ...recognition };
                    setSelectedStage(e.target.value);
                    newRecognitionForm.stage = e.target.value;

                    if (
                      skillPaths[selectedPathIndex].stages[
                        e.target.value
                      ].hasOwnProperty("name")
                    ) {
                      newRecognitionForm.stageDisplayName =
                        skillPaths[selectedPathIndex].stages[
                          selectedStage
                        ].name;
                    } else {
                      getSkillStageDisplayName(
                        selectedPath,
                        e.target.value
                      ).then(function (stageName) {
                        newRecognitionForm.stageDisplayName = stageName;
                      });
                    }
                    setRecognition(newRecognitionForm);
                  }}
                  disabled={disableSkillSelection}
                >
                  <option value="0">Skill Stage</option>
                  {selectedPathIndex &&
                    skillPaths[selectedPathIndex] &&
                    Object.keys(skillPaths[selectedPathIndex].stages).map(
                      function (key) {
                        let stage = skillPaths[selectedPathIndex].stages[key];
                        return (
                          <SkillStage
                            selectedPath={selectedPath}
                            name={stage.name}
                            id={stage.id}
                          />
                        );
                      }
                    )}
                </Form.Control>
              </Col> */}
            </Form.Row>

            {/* <Form.Row className="align-items-center">
              <Col xs="12" className="my-1">
                <Form.Label
                  className="mr-sm-2"
                  htmlFor="inlineFormCustomSelect"
                  srOnly
                >
                  Path
                </Form.Label>
                <Form.Control
                  as="select"
                  className="mr-sm-2"
                  id="inlineFormCustomSelect"
                  custom
                  onChange={(e) => {
                    let newRecognitionForm = { ...recognition };

                    setSelectedStep(e.target.value);

                    newRecognitionForm.step = e.target.value;
                    newRecognitionForm.stepDisplayName =
                      skillPaths[selectedPathIndex].stages[selectedStage].steps[
                        e.target.value
                      ].name;
                    setRecognition(newRecognitionForm);
                  }}
                  disabled={disableSkillSelection}
                >
                  <option value="0">Skill Step</option>
                  {selectedPathIndex &&
                    skillPaths[selectedPathIndex] &&
                    skillPaths[selectedPathIndex].stages[selectedStage] &&
                    Object.keys(
                      skillPaths[selectedPathIndex].stages[selectedStage].steps
                    ).map(function (key) {
                      let step =
                        skillPaths[selectedPathIndex].stages[selectedStage]
                          .steps[key];

                      return (
                        <option value={step.id} key={step.id}>
                          {step.name}
                        </option>
                      );
                    })}
                </Form.Control>
              </Col>
            </Form.Row> */}
            {hasActiveRecognitionStep && (
              <Row>
                <Col>
                  <Alert variant="success mt-2 text-center">
                    This recognition will satisfy a recognition requirement
                  </Alert>
                </Col>
              </Row>
            )}
          </Form.Group>
          <Form.Group controlId="recognitionForm.text">
            <Form.Label className="font-weight-bold">
              Descriptive Title
            </Form.Label>
            <Form.Control
              type="text"
              value={recognition.description}
              onChange={(e) => {
                let newRecognitionForm = { ...recognition };
                newRecognitionForm.description = e.target.value;
                setRecognition(newRecognitionForm);
              }}
            />
          </Form.Group>
          <Form.Group controlId="recognitionForm.text">
            <Form.Label className="font-weight-bold">
              Explain the Behavior Witnessed
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              value={recognition.witness}
              onChange={(e) => {
                let newRecognitionForm = { ...recognition };
                newRecognitionForm.witness = e.target.value;
                setRecognition(newRecognitionForm);
              }}
            />
          </Form.Group>
          {/* <Form.Group controlId="recognitionForm.text">
            <Form.Label className="font-weight-bold">
              How did this help your team achieve success?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              value={recognition.howSuccess}
              onChange={(e) => {
                let newRecognitionForm = { ...recognition };
                newRecognitionForm.howSuccess = e.target.value;
                setRecognition(newRecognitionForm);
              }}
            />
          </Form.Group> */}
          <Form.Group controlId="recognitionForm.target">
            <Form.Label className="font-weight-bold">
              Did this help avoid an escalation?
            </Form.Label>
            <Form.Check
              type="switch"
              defaultChecked={false}
              label=""
              value={recognition.escalation}
              id="recognition-escalation"
              onChange={toggleEscalation}
            />
          </Form.Group>
          {managerWarning ? (
            <Alert variant="warning">
              <b>Warning: </b>
              {managerWarning}
            </Alert>
          ) : (
            ""
          )}
          <Form.Group controlId="recognitionForm.target">
            <Form.Label className="font-weight-bold">
              Select Managers to Notify
            </Form.Label>
            {Array.isArray(orgManagers) &&
              orgManagers.map(function (orgUser) {
                return (
                  <Row key={orgUser.id}>
                    <Col>
                      {currentManager === orgUser.id ? (
                        <Form.Check
                          type="switch"
                          checked
                          id={`notify-switch-${orgUser.id}`}
                          key={orgUser.id}
                          label={orgUser.displayName}
                          onChange={(e) => {
                            toggleNotifyManager(orgUser.id);
                          }}
                          disabled
                          style={{ pointer: "cursor" }}
                        />
                      ) : (
                        <Form.Check
                          type="switch"
                          // defaultValue={notifyManager(
                          //   orgUser.id,
                          //   orgProfile.manager
                          // )}
                          //checked={recognition.managers.includes(orgUser.id)}
                          id={`notify-switch-${orgUser.id}`}
                          key={orgUser.id}
                          label={orgUser.displayName}
                          onChange={(e) => {
                            toggleNotifyManager(orgUser.id);
                          }}
                          disabled={isMyManager(orgUser.id)}
                          style={{ pointer: "cursor" }}
                        />
                      )}
                    </Col>
                  </Row>
                );
              })}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleHideRecognitionModal}
          >
            Close
          </Button>
          <Button variant="outline-secondary" onClick={submitRecognition}>
            Give Recognition
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RecognitionModal;
