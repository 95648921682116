import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { firebase } from "../../config/firebase";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";

import "./../../style/Auth.css";
import MetisphereSymbol from "./../../symbol.svg";

import { useLoginForm } from "./hooks";

function SignIn() {
  // Load boolean to tell us if Google Auth is loading
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState("");

  // Auth Form Hook
  const auth = useLoginForm();
  const history = useHistory();

  // Fxn: Handle Google Auth Case
  function handleSignUpWithGoogle() {
    setLoading(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function ({ user }) {
        history.push("/");
      })
      .catch(function (error) {
        setAuthError(error.message);
      });
  }

  // Fxn: Handle Auth with Username and password
  function handleSubmit(e) {
    e.preventDefault();
    auth
      .login()
      .then(function (user) {
        console.log("[LOGIN]");
        history.push("/updatePassword");
      })
      .catch(function (error) {
        setAuthError(error.message);
      });
  }

  return (
    <Container fluid="false">
      <Row>
        <Col
          xs={12}
          md={5}
          className="align-self-center"
          style={{ margin: "auto" }}
        >
          <Card bg="primary" text="white" style={{ marginTop: "20px" }}>
            <Card.Header className="text-center">
              <img
                src={MetisphereSymbol}
                style={{ width: "40px" }}
                alt="Metisphere Icon"
              />
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12}>
                  <Form onSubmit={handleSubmit}>
                    {authError ? (
                      <Alert variant="danger mt-2 text-center">
                        {authError}
                      </Alert>
                    ) : (
                      ""
                    )}
                    <Alert variant="secondary mt-2 text-center">
                      Please reauthenticate before updating your password!
                    </Alert>
                    <Form.Group controlId="email" style={{ marginTop: "20px" }}>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        onChange={(e) => {
                          auth.updateField("email", e.target.value);
                        }}
                        value={auth.email}
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => {
                          auth.updateField("password", e.target.value);
                        }}
                        value={auth.password}
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}
                    <Button
                      variant="secondary"
                      type="submit"
                      style={{ width: "100%" }}
                    >
                      Log In
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;
