import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useRouteMatch, useHistory, useParams } from "react-router-dom";

import { ContentDetail } from "../common";
import { useSkillPathContext } from "./../providers";

function StepDetail({ skillStep, canEdit, currentVersion }) {
  let history = useHistory();
  let { url } = useRouteMatch();

  const { skillStageSlug } = useParams();
  const { skillPath } = useSkillPathContext();

  const highlightObjective =
    currentVersion && currentVersion.objective !== skillStep.objective;

  function deleteStep() {
    if (skillPath.id === "staged") {
      skillPath.id = skillPath.ref.parent.parent.id;
    }
    if (skillStep.id === "staged") {
      skillStep.id = skillStep.ref.parent.parent.id;
    }

    skillPath
      .deleteStep(skillStep)
      .save()
      .then(() => {
        history.push(`/skills/${skillPath.code}/${skillStageSlug}`);
      });
  }

  return (
    skillStep && (
      <div>
        <Row>
          <Col>
            <div className="float-right">
              {canEdit && (
                <div>
                  <Link
                    className="btn btn-outline-primary btn-sm"
                    to={`${url}/edit`}
                  >
                    Edit
                  </Link>{" "}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => {
                      if (window.confirm("Are you sure?")) {
                        deleteStep();
                      }
                    }}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <ContentDetail heading={"Version #"} content={skillStep.version} /> */}
            {highlightObjective ? (
              <div style={{ color: "#721c24", backgroundColor: "#f8d7da" }}>
                <ContentDetail
                  heading={"Objective"}
                  content={skillStep.objective}
                />
              </div>
            ) : (
              <div>
                <ContentDetail
                  heading={"Objective"}
                  content={skillStep.objective}
                />
              </div>
            )}
            <ContentDetail
              heading={"Instructions"}
              content={skillStep.instructions}
            />
          </Col>
        </Row>
      </div>
    )
  );
}

export default StepDetail;
