import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Card,
  CardDeck,
  Row,
  Col,
  CardColumns,
  Button,
  Form,
} from "react-bootstrap";

import { api } from "./../../utils/api";

import { useAuthState } from "./../../../../providers/AuthState";

import "./existing-content-modal.style.css";

function ExistingContentModal({
  showModal,
  handleSelect,
  handleClose,
  skillPathId,
}) {
  const [allContent, setAllContent] = useState([]);
  const [sortedContent, setSortedContent] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [availablePaths, setAvailablePaths] = useState([]);
  const [selectedPath, setSelectedPath] = useState(skillPathId);

  const { orgContext } = useAuthState();

  function convertSortedMapToArr(sortedByStageAndSkillMap) {
    const sortedContent = [];

    for (const stageKey in sortedByStageAndSkillMap) {
      const sortedSteps = [];
      for (const stepKey in sortedByStageAndSkillMap[stageKey].steps) {
        sortedSteps.push(sortedByStageAndSkillMap[stageKey].steps[stepKey]);
      }
      const newStage = {
        ...sortedByStageAndSkillMap[stageKey],
        steps: sortedSteps
          .filter((sortStep) => {
            return sortStep.content.length > 0;
          })
          .sort((left, right) => {
            return left.order - right.order;
          }),
      };
      if (newStage.steps.length > 0) {
        sortedContent.push(newStage);
      }
    }

    return sortedContent.sort((left, right) => {
      return left.order - right.order;
    });
  }

  function sortAndFilterContent(allContent, searchTerm) {
    const sortedByStageAndSkillMap = {};

    allContent.forEach((curContent) => {
      const curStage = sortedByStageAndSkillMap[curContent.stage.id] || {
        ...curContent.stage,
        steps: {},
      };
      const curStep = curStage.steps[curContent.step.id] || {
        ...curContent.step,
        content: [],
      };

      if (
        !searchTerm ||
        curContent.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        curContent.description.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        curStep.content.push(curContent);
      }

      curStage.steps[curContent.step.id] = curStep;
      sortedByStageAndSkillMap[curContent.stage.id] = curStage;
    });

    setSortedContent(convertSortedMapToArr(sortedByStageAndSkillMap));
  }

  function setSortedContentList(allContent) {
    const sortedByOrder = allContent.sort((left, right) => {
      return (
        left.stage.order * 1000 +
        left.step.order * 100 +
        left.order -
        (right.stage.order * 1000 + right.step.order * 100 + right.order)
      );
    });
    setAllContent(sortedByOrder);
  }

  useEffect(() => {
    let isCurrent = true;

    if (orgContext.id && isCurrent) {
      api.skillPath
        .getSkillPathsForOrganizations(orgContext.id)
        .then((allPaths) => {
          setAvailablePaths(allPaths);
        });
    }

    return () => {
      isCurrent = false;
    };
  }, [orgContext.id]);

  useEffect(() => {
    let isCurrent = true;

    if (selectedPath && isCurrent) {
      api.skillPath.getAllContentForSkillPath(
        selectedPath,
        setSortedContentList
      );
    }

    return () => {
      isCurrent = false;
    };
  }, [selectedPath]);

  useEffect(() => {
    sortAndFilterContent(allContent, searchTerm);
  }, [searchTerm, allContent]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      dialogClassName="modal-80w"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Content</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col sm={3}>
              <Form.Group controlId="availablePaths">
                <Form.Label>Skill Path</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedPath}
                  onChange={(evt) => {
                    setSelectedPath(evt.target.value);
                  }}
                >
                  {availablePaths.map((skillPath) => {
                    return (
                      <option value={skillPath.id}>{skillPath.name}</option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="search">
                <Form.Label>Search Content</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Search for content"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              {searchTerm !== "" && (
                <Button
                  style={{ marginTop: "35px" }}
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {
                    setSearchTerm("");
                  }}
                >
                  Clear Search
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {sortedContent.length > 0 &&
                sortedContent.map((sortedStage) => {
                  return (
                    <div>
                      <h6
                        style={{
                          borderBottom: "1px solid #123",
                          marginBottom: "20px",
                        }}
                      >
                        {sortedStage.order}. {sortedStage.name}
                      </h6>
                      {sortedStage.steps.map((sortedStep) => {
                        return (
                          <div
                            style={{ marginLeft: "10px", marginBottom: "10px" }}
                          >
                            <h6>
                              {sortedStep.order}. {sortedStep.name}
                            </h6>
                            <Row>
                              {sortedStep.content.map((content) => {
                                return (
                                  <Col sm={4} style={{ marginBottom: "15px" }}>
                                    <Card
                                      className={`content-card ${content === selectedContent
                                        ? "active"
                                        : ""
                                        }`}
                                      onClick={() => {
                                        setSelectedContent(content);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Card.Body>
                                        <Card.Title>
                                          {content.stage.order}.
                                          {content.step.order}: {content.title}
                                        </Card.Title>

                                        <Card.Text>
                                          {content.description.substring(
                                            0,
                                            100
                                          )}
                                          {content.description.length > 100
                                            ? "..."
                                            : ""}
                                        </Card.Text>

                                        {content.link && content.type !== 'Link' && (
                                          <Row>
                                            <Col>
                                              <iframe
                                                width="100%"
                                                src={content.link}
                                                frameborder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                title="Content Link"
                                                allowFullScreen
                                              ></iframe>
                                            </Col>
                                          </Row>
                                        )}
                                        {content.link && content.type === "Link" && (
                                          <Row style={{ marginBottom: '15px', verticalAlign: 'top' }}>
                                            <Col>
                                              <a
                                                className="btn btn-outline-primary btn-sm"
                                                href={content.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Go To Link
                                              </a>
                                            </Col>
                                          </Row>
                                        )}
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </Col>
            {/* {contentList
              .filter((curContent) => {
                return (
                 
                );
              })
              .map((content) => {
                return (
                  <Col sm={4} style={{ marginBottom: "15px" }}>
                    <Card
                      className={`content-card ${
                        content === selectedContent ? "active" : ""
                      }`}
                      onClick={() => {
                        setSelectedContent(content);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Card.Body>
                        <Card.Title>
                          {content.stage.order}.{content.step.order}:{" "}
                          {content.title}
                        </Card.Title>

                        <Card.Text>{content.description}</Card.Text>
                        {content.link && (
                          <Row>
                            <Col>
                              <iframe
                                width="100%"
                                src={content.link}
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                title="Content Link"
                                allowFullScreen
                              ></iframe>
                            </Col>
                          </Row>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })} */}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {selectedContent && (
          <Button
            onClick={() => {
              handleSelect(selectedContent);
            }}
          >
            Add Selected Content
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ExistingContentModal;
