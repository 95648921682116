import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Page } from "../components/common";
import {
  PathDetail,
  PathThreads,
  UserPathHeader,
} from "../components/skill-path";

import UserSkillStageLayout from "./user-skill-stage";

import { SkillPathProvider } from "./../components/providers";

import { useAuthState } from "../../../providers/AuthState";

import { db } from "./../../../config/firebase";

function UserSkillPathLayout({ addingContent = false }) {
  const [userProgress, setUserProgress] = useState([]);
  const { skillPathId } = useParams();

  const { user, orgContext } = useAuthState();

  const userId = user.uid;
  const orgId = orgContext.id;

  /* Erich fixed hardcoded organization code */
  useEffect(() => {
    db.collection(`/organizations/${orgId}/users/${userId}/paths`)
      .where("code", "==", skillPathId)
      .onSnapshot((docs) => {
        docs.forEach((doc) => {
          setUserProgress({
            ...doc.data(),
            id: doc.id,
          });
          return true;
        });
      });
  }, [skillPathId, orgId, userId]);

  return (
    <SkillPathProvider
      code={skillPathId}
      orgId={orgId}
      addingContent={addingContent}
    >
      <Page>
        <UserPathHeader />
        <Tabs defaultActiveKey="overview" id="user-skill-path-tabs">
          <Tab eventKey="overview" title="Overview">
            <UserSkillStageLayout />
          </Tab>
          <Tab eventKey="details" title="Details">
            <PathDetail />
          </Tab>
          <Tab eventKey="threads" title="Threads">
            <PathThreads userProgress={userProgress} />
          </Tab>
        </Tabs>
      </Page>
    </SkillPathProvider>
  );
}

export default UserSkillPathLayout;
