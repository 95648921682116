import { db } from "../../../../config/firebase";
import { SkillPath } from "../classes/skill-path";

function getPathCollection(ref, setDocs) {
  return ref.onSnapshot((snapshot) => {
    const docs = [];

    snapshot.forEach((doc) => {
      docs.push({
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      });
    });
    setDocs(docs);
  });
}

// function getPathDoc(ref, setDoc) {}

function update(skillPath) {
  return db.collection("/paths").doc(skillPath.id).set(skillPath);
}

function updateStaged(skillPath) {
  return db.doc(`/paths/${skillPath.id}/versions/staged`).set(skillPath);
}

function updateStagedOnOrg(skillPath, orgId) {
  return db
    .doc(`/organizations/${orgId}/paths/${skillPath.id}/versions/staged`)
    .set(skillPath);
}

function getActiveForVendor(vendorId, setPaths) {
  let pathsRef = db
    .collection("/paths")
    .where("status", "in", SkillPath.getActiveStatuses());

  if (vendorId !== "metisphere") {
    pathsRef = pathsRef.where("vendorId", "==", vendorId);
  }

  getPathCollection(pathsRef, setPaths);
}

function getActiveStagedForVendor(vendorId, setPaths) {
  let pathsRef = db
    .collection("/paths")
    .where("status", "in", SkillPath.getActiveStatuses());

  if (vendorId !== "metisphere") {
    pathsRef = pathsRef.where("vendorId", "==", vendorId);
  }

  return pathsRef.onSnapshot((snapshot) => {
    const docPromises = [];
    const docs = [];
    snapshot.forEach((doc) => {
      const curPromise = db
        .doc(`${doc.ref.path}/versions/staged`)
        .get()
        .then((stagedDoc) => {
          docs.push({
            ...stagedDoc.data(),
            id: stagedDoc.id,
            ref: stagedDoc.ref,
          });
        });

      docPromises.push(curPromise);
    });

    Promise.all(docPromises).then(() => {
      setPaths(docs);
    });
  });
}

function getSkillPath(id, setDoc) {
  return db.doc(`/paths/${id}`).onSnapshot((doc) => {
    setDoc(
      new SkillPath({
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      })
    );
  });
}

function getSkillPathByCode(code, setDoc) {
  return db
    .collection("/paths")
    .where("code", "==", code)
    .onSnapshot((docs) => {
      docs.forEach((doc) => {
        setDoc(
          new SkillPath({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
          })
        );
        return true;
      });
    });
}

function getStagedSkillPathByCodeForOrg(code, orgId, setDoc) {
  return db
    .collection(`/organizations/${orgId}/paths`)
    .where("code", "==", code)
    .where("active", "==", true)
    .get()
    .then((docs) => {
      let doc = null;
      docs.forEach((each) => {
        doc = each;
        return true;
      });

      return db.doc(`${doc.ref.path}/versions/staged`);
    })
    .then((skillPathDocRef) => {
      if (skillPathDocRef.empty) {
        return getStagedSkillPathByCode(code, setDoc);
      } else {
        return skillPathDocRef.onSnapshot((skillPathDoc) => {
          setDoc(
            new SkillPath({
              ...skillPathDoc.data(),
              id: skillPathDoc.id,
              ref: skillPathDoc.ref,
            })
          );
        });
      }
    });
}

function getSkillPathByCodeForOrg(code, orgId, setDoc) {
  return db
    .collection(`/organizations/${orgId}/paths`)
    .where("code", "==", code)
    .where("active", "==", true)
    .get()
    .then((docs) => {
      let doc = null;
      docs.forEach((each) => {
        doc = each;
        return true;
      });

      return doc;
    })
    .then((skillPathDoc) => {
      if (!skillPathDoc) {
        return getSkillPathByCode(code, setDoc);
      } else {
        setDoc(
          new SkillPath({
            ...skillPathDoc.data(),
            id: skillPathDoc.id,
            ref: skillPathDoc.ref,
          })
        );
        return true;
      }
    });
}

function getStagedSkillPathByCode(code, setDoc) {
  // Collection group queries won't work because "versions" can appear in org specific contexts which the user may not have access to
  let snapshotDeconstructor;
  db.collection("/paths")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((publishedPath) => {
        const curDecon = db
          .doc(`${publishedPath.ref.path}/versions/staged`)
          .onSnapshot((stagedDoc) => {
            if (stagedDoc.data().code === code) {
              setDoc(
                new SkillPath({
                  ...stagedDoc.data(),
                  id: stagedDoc.id,
                  ref: stagedDoc.ref,
                })
              );

              snapshotDeconstructor = curDecon;
            }
          });
      });
    });

  return snapshotDeconstructor;
}
//   return db
//     .collection("/paths")
//     .where("code", "==", code)
//     .get()
//     .then((docs) => {
//       let doc = null;
//       docs.forEach((each) => {
//         doc = each;
//         return true;
//       });
//       return db.doc(`${doc.ref.path}/versions/staged`);
//     })
//     .then((ref) => {
//       return ref.onSnapshot((doc) => {
//         setDoc(
//           new SkillPath({
//             ...doc.data(),
//             id: doc.id,
//             ref: doc.ref,
//           })
//         );
//       });
//     });
// }

//
//   return db.collectionGroup('versions')
//     .where("code", "==", code)
//     .get()
//     .then(snapshot => {
//       snapshot.forEach(doc => {
//         console.log(doc)
//         if (doc.id === 'staged') {
//           setDoc(new SkillPath({
//             ...doc.data(),
//             id: doc.id,
//             ref: doc.ref,
//           }));
//           return true;
//         }

//       });
//     });
// }

//       return () => {

//       }
//     })
// }

// console.log(code);
// return db
//   .collection("/paths")
//   .where("code", "==", code)
//   .get()
//   .then((docs) => {
//     let doc = null;
//     docs.forEach((each) => {
//       doc = each;
//       return true;
//     });
//     return db.doc(`${doc.ref.path}/versions/staged`);
//   })
//   .then((ref) => {
//     return ref.onSnapshot((doc) => {
//       setDoc(
//         new SkillPath({
//           ...doc.data(),
//           id: doc.id,
//           ref: doc.ref,
//         })
//       );
//     });
//   });
// }

// function getStagedSkillPath(id, setDoc) {
//   return db.doc(`/paths/${id}/versions/staged`).onSnapshot((doc) => {
//     setDoc(
//       new SkillPath({
//         ...doc.data(),
//         id: doc.id,
//         ref: doc.ref,
//       })
//     );
//   });
// }

function getSkillPathData(id, setDoc) {
  return db
    .doc(`/paths/${id}`)
    .get()
    .then((doc) => {
      setDoc(
        new SkillPath({
          ...doc.data(),
          id: doc.id,
          ref: doc.ref,
        })
      );
    });
}

function getSkillPathVersions(id, setVersions) {
  const versionsRef = db
    .collection(`/paths/${id}/versions`)
    .orderBy("datePublished");

  getPathCollection(versionsRef, setVersions);
}

function getDoc() {
  return db.collection("/paths").doc();
}

function getAllContentForSkillPath(id, setContent) {
  const allContent = [];
  return db
    .collection(`/paths/${id}/stages`)
    .get()
    .then((stageDocs) => {
      const allStageProms = [];

      stageDocs.forEach((stageDoc) => {
        allStageProms.push(
          stageDoc.ref
            .collection("steps")
            .get()
            .then((stepDocs) => {
              const allStepProms = [];
              stepDocs.forEach((stepDoc) => {
                allStepProms.push(
                  stepDoc.ref
                    .collection("content")
                    .get()
                    .then((contentDocs) => {
                      contentDocs.forEach((contentDoc) => {
                        if (!contentDoc.data().hasParent) {
                          allContent.push({
                            ...contentDoc.data(),
                            id: contentDoc.id,
                            ref: contentDoc.ref,
                            step: {
                              id: stepDoc.id,
                              name: stepDoc.data().name,
                              order: stepDoc.data().order,
                            },
                            stage: {
                              id: stageDoc.id,
                              name: stageDoc.data().name,
                              order: stageDoc.data().order,
                            },
                          });
                        }
                      });
                    })
                );
              });

              return Promise.all(allStepProms);
            })
        );
      });

      return Promise.all(allStageProms);
    })
    .then(() => {
      setContent(allContent);
    });
}

function getAllWisdomForCustomSkillPath(orgId, pathId, setWisdom) {
  const allContent = [];
  return db
    .collection(`organizations/${orgId}/paths/${pathId}/stages`)
    .get()
    .then((stageDocs) => {
      const allStageProms = [];
      stageDocs.forEach((stageDoc) => {
        allStageProms.push(
          stageDoc.ref
            .collection("steps")
            .get()
            .then((stepDocs) => {
              const allStepProms = [];
              stepDocs.forEach((stepDoc) => {
                allStepProms.push(
                  // db
                  //   .collection(
                  //     `organizations/${orgId}/paths/${pathId}/stages/${stageDoc.id}/steps/${stepDoc.id}/wisdom`
                  //   )

                  stepDoc.ref
                    .collection("wisdom")
                    .orderBy("active", "desc")
                    .get()
                    .then((contentDocs) => {
                      contentDocs.forEach((contentDoc) => {
                        if (!contentDoc.data().hasParent) {
                          allContent.push({
                            ...contentDoc.data(),
                            id: contentDoc.id,
                            ref: contentDoc.ref,
                            step: {
                              id: stepDoc.id,
                              name: stepDoc.data().name,
                              order: stepDoc.data().order,
                            },
                            stage: {
                              id: stageDoc.id,
                              name: stageDoc.data().name,
                              order: stageDoc.data().order,
                            },
                          });
                        }
                      });
                    })
                );
              });

              return Promise.all(allStepProms);
            })
        );
      });

      return Promise.all(allStageProms);
    })
    .then(() => {
      setWisdom(allContent);
    });
}

function getAllWisdomForSkillPath(orgId, pathId, setWisdom) {
  const allContent = [];
  return (
    db
      // .collection(`organizations/${orgId}/paths/${pathId}/stages`)
      .collection(`paths/${pathId}/stages`)
      .get()
      .then((stageDocs) => {
        const allStageProms = [];
        stageDocs.forEach((stageDoc) => {
          allStageProms.push(
            stageDoc.ref
              .collection("steps")
              .get()
              .then((stepDocs) => {
                const allStepProms = [];
                stepDocs.forEach((stepDoc) => {
                  allStepProms.push(
                    // db
                    //   .collection(
                    //     `organizations/${orgId}/paths/${pathId}/stages/${stageDoc.id}/steps/${stepDoc.id}/wisdom`
                    //   )

                    db
                      .collection(
                        `organizations/${orgId}/${stepDoc.ref.path}/wisdom`
                      )
                      .orderBy("active", "desc")
                      .get()
                      .then((contentDocs) => {
                        contentDocs.forEach((contentDoc) => {
                          if (!contentDoc.data().hasParent) {
                            allContent.push({
                              ...contentDoc.data(),
                              id: contentDoc.id,
                              ref: contentDoc.ref,
                              step: {
                                id: stepDoc.id,
                                name: stepDoc.data().name,
                                order: stepDoc.data().order,
                              },
                              stage: {
                                id: stageDoc.id,
                                name: stageDoc.data().name,
                                order: stageDoc.data().order,
                              },
                            });
                          }
                        });
                      })
                  );
                });

                return Promise.all(allStepProms);
              })
          );
        });

        return Promise.all(allStageProms);
      })
      .then(() => {
        setWisdom(allContent);
      })
  );
}
/*
  Retrieves the skill paths that are authored or customized for the organization

  @params:
    <string> orgId

  @return: 
    <Promise>
*/
function getSkillPathsForOrganizations(orgId) {
  const allPaths = [];
  return db
    .collection(`/organizations/${orgId}/paths`)
    .get()
    .then((snapshotQuery) => {
      snapshotQuery.forEach((doc) => {
        allPaths.push({ ...doc.data(), id: doc.id, ref: doc.ref });
      });
    })
    .then(() => {
      // Update with vendor = orgid
      return db.collection("/paths").get();
    })
    .then((snapshotQuery) => {
      snapshotQuery.forEach((doc) => {
        if (
          !allPaths.some((curPath) => {
            return curPath.id === doc.id;
          })
        ) {
          allPaths.push({ ...doc.data(), id: doc.id, ref: doc.ref });
        }
      });

      return allPaths;
    });
}

export default {
  update,
  updateStaged,
  getActiveForVendor,
  getActiveStagedForVendor,
  getSkillPath,
  getDoc,
  getSkillPathVersions,
  getSkillPathData,
  getSkillPathByCode,
  getStagedSkillPathByCode,
  getSkillPathByCodeForOrg,
  getStagedSkillPathByCodeForOrg,
  getAllContentForSkillPath,
  getSkillPathsForOrganizations,
  updateStagedOnOrg,
  getAllWisdomForSkillPath,
  getAllWisdomForCustomSkillPath,
};
