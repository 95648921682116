import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import WisdomDashboardLayout from "./wisdom-dashboard";

function WisdomLayout() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={WisdomDashboardLayout} />
    </Switch>
  );
}

export default WisdomLayout;
