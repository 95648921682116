import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { db } from "./../../../../config/firebase";
import { useSkillPathContext } from "./../providers";

import ExistingContentModal from "./../content/existing-content-modal";
import { update } from "../../utils/api/skill-stage";

function StepContentForm({ existingStepContent, skillStep, handleClose }) {
  const [type, setType] = useState(
    (existingStepContent && existingStepContent.type) || "Video"
  );
  const [link, setLink] = useState(
    (existingStepContent && existingStepContent.link) || ""
  );
  const [description, setDescription] = useState(
    (existingStepContent && existingStepContent.description) || ""
  );
  const [title, setTitle] = useState(
    (existingStepContent && existingStepContent.title) || ""
  );

  const [order, setOrder] = useState(
    (existingStepContent && existingStepContent.order) || 1
  );

  const [showModal, setShowModal] = useState(false);

  const { skillPath, addingContent } = useSkillPathContext();
  const { orgId } = useParams();

  function saveSelectedContent(selectedContent) {
    const updateDoc = {
      ...selectedContent,
      hasParent: true,
      parentContent: selectedContent.ref,
      parentContentId: selectedContent.id,
    };
    let stepRefPath = skillStep.ref.path;
    if (skillStep.ref.id === "staged") {
      stepRefPath = skillStep.ref.parent.parent.path;
    }

    if (addingContent) {
      stepRefPath = `/organizations/${orgId}/${stepRefPath}`;
    }

    db.collection(`${stepRefPath}/content`)
      .add(updateDoc)
      .then(() => {
        setShowModal(false);
      });
  }

  function getYoutubeId(url) {
    const parsedUrl = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== parsedUrl[2] ? parsedUrl[2].split(/[^0-9a-z_\-]/i)[0] : parsedUrl[0];
  }

  function getVimeoId(url) {
    var m = url.match(/^.+vimeo.com\/(.*\/)?([^#\?]*)/);
    return m ? m[2] || m[1] : url;
  }

  function cleanUpLink(url) {
    let updatedLink = link;
    if (updatedLink !== getYoutubeId(updatedLink)) {
      updatedLink = `https://www.youtube.com/embed/${getYoutubeId(updatedLink)}`
    }
    else if (updatedLink !== getVimeoId(updatedLink)) {
      updatedLink = `https://player.vimeo.com/video/${getVimeoId(updatedLink)}`
    }

    return updatedLink
  }
  function handleSubmit(evt) {
    evt.preventDefault();

    const updateDoc = {
      title,
      type,
      link: cleanUpLink(link),
      description,
      order,
    };

    if (existingStepContent) {
      existingStepContent.ref.update(updateDoc);
      handleClose();
    } else {
      let stepRefPath = skillStep.ref.path;
      if (skillStep.ref.id === "staged") {
        stepRefPath = skillStep.ref.parent.parent.path;
      }

      if (addingContent) {
        stepRefPath = `/organizations/${orgId}/${stepRefPath}`;
      }

      db.collection(`${stepRefPath}/content`).add(updateDoc);
    }

    setTitle("");
    setType("Video");
    setLink("");
    setDescription("");
    setOrder(1);
  }

  function handleCancel() {
    handleClose();
  }

  return (
    <div style={{ marginBottom: "15px" }}>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Col sm={8}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={2}>
            <Form.Group controlId="order">
              <Form.Label>Order</Form.Label>
              <Form.Control
                type="number"
                placeholder="Order"
                value={order}
                onChange={(e) => {
                  setOrder(parseInt(e.target.value));
                }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={2}>
            <Form.Group controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={(evt) => {
                  setType(evt.target.value);
                }}
              >
                <option>Video</option>
                <option>PDF</option>
                <option>Link</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={8}>
            <Form.Group controlId="link">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="link"
                value={link}
                onChange={(evt) => {
                  setLink(evt.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={10}>
            <Form.Group controlId="desription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Description"
                value={description}
                onChange={(evt) => {
                  setDescription(evt.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row style={{ marginBottom: "10px" }}>
          <Col>
            <Button variant="outline-primary" type="submit">
              {existingStepContent ? "Save" : "Add"}
            </Button>{" "}
            {!existingStepContent && (
              <Button
                variant="outline-primary"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Select Existing Content
              </Button>
            )}{" "}
            {existingStepContent && (
              <Button variant="outline-danger" onClick={handleCancel}>
                Cancel
              </Button>
            )}
          </Col>
        </Form.Row>
      </Form>
      <ExistingContentModal
        showModal={showModal}
        handleSelect={saveSelectedContent}
        handleClose={() => {
          setShowModal(false);
        }}
        skillPathId={skillStep.ref.parent.parent.parent.parent.parent.parent.id}
      />
    </div>
  );
}

export default StepContentForm;
