import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  ListGroup,
  Button,
  Form,
  Modal,
  Nav,
  Tab,
  Alert,
} from "react-bootstrap";

import { firebase, db } from "../../../../../../config/firebase";

import moment from "moment";

function OrganizationSettings({ organization, orgId }) {
  const [showLogoChangeModal, setShowLogoChangeModal] = useState(false);
  const [adminOrgUser, setNewOrgAdmin] = useState();
  const [organizationActivationModal, setOrganizationActivation] = useState(
    false
  );
  const [
    showChangeAdministratorModal,
    setShowChangeAdministratorModal,
  ] = useState(false);
  const [orgLogo, setOrgLogo] = useState();
  const [currentAdministrator, setCurrentAdministrator] = useState();

  useEffect(() => {
    if (organization.logoPath) {
      if (organization.logoPath.length) {
        let storageRef = firebase.storage().ref();

        console.log("organization: ", organization.logoPath);
        let photoRef = storageRef.child(organization.logoPath);
        photoRef
          .getDownloadURL()
          .then(function (url) {
            setOrgLogo(url);
          })
          .catch(function (error) {
            console.log("Error: ", error);
          });
      }
    }
  }, [organization.logoPath]);

  useEffect(() => {
    setCurrentAdministrator(organization.orgAdminId);
  }, [orgId, organization.orgAdminId]);

  function handleDeactivateOrganization() {
    db.doc(`organizations/${orgId}`).update({
      active: false,
      lastActive: firebase.firestore.Timestamp.now(),
    });
  }

  function handleReactivateOrganization() {
    db.doc(`organizations/${orgId}`).update({
      active: true,
      lastActive: firebase.firestore.Timestamp.now(),
    });
  }

  function handleOrganizationActivationClose() {
    setOrganizationActivation(false);
  }

  function handleUpdateAdministrator() {
    organization.updateAdministrator(adminOrgUser);
  }

  function handleAdministratorChangeModalClose() {
    setShowChangeAdministratorModal(false);
  }
  function handleLogoChange() {
    setShowLogoChangeModal(true);
  }

  function handleLogoChangeModalClose() {
    setShowLogoChangeModal(false);
  }

  return (
    <div>
      <h5 className="text-secondary font-weight-bold">Actions</h5>
      <div>
        <ListGroup>
          <ListGroup.Item>
            {organization.active ? (
              <div>
                <Row>
                  <Col xs={8}>
                    <div className="font-weight-bold text-danger">
                      Deactivate organization.
                    </div>
                    <div className="text-muted">
                      Deactivate the organization. Organizations deactivatated
                      longer than 90 days will automatically be deleted.
                    </div>
                  </Col>
                  <Col xs={4}>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={handleDeactivateOrganization}
                      style={{ width: "100%" }}
                    >
                      Deactivate Organization
                    </Button>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col xs={12}>
                    {" "}
                    <Alert
                      variant="success-bright"
                      className="text-center mt-4 mb-4 p-4 border"
                    >
                      The organization is currently{" "}
                      <b className="text-success-bright">ACTIVE</b>
                    </Alert>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <Row>
                  <Col xs={8}>
                    <div className="font-weight-bold text-success-bright">
                      Reactivate organization.
                    </div>
                    <div className="text-muted">
                      Reactivate the organization. Organizations deactivatated
                      longer than 90 days will automatically be deleted.
                    </div>
                  </Col>
                  <Col xs={4}>
                    <Button
                      variant="outline-success-bright"
                      size="sm"
                      onClick={handleReactivateOrganization}
                      style={{ width: "100%" }}
                    >
                      Reactivate Organization
                    </Button>
                  </Col>
                </Row>{" "}
                <Row>
                  <Col xs={12}>
                    {" "}
                    <Alert
                      variant="danger"
                      className="text-center mt-4 mb-4 p-4 border"
                    >
                      The organization is currently{" "}
                      <b className="text-danger">INACTIVE</b>
                    </Alert>
                  </Col>
                </Row>
              </div>
            )}
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col xs={8}>
                <div className="font-weight-bold text-secondary">
                  Change the Administrator
                </div>
                <div className="text-muted">
                  Change the administrator associated to this organization.
                </div>
              </Col>
              <Col xs={4}>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={handleUpdateAdministrator}
                  style={{ width: "100%" }}
                >
                  Change Administrator
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {" "}
                <div className="text-center mt-4 mb-4 p-4 border">
                  The current administrator is <b>{organization.adminName}</b>
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col xs={8}>
                <div className="font-weight-bold text-secondary">
                  Change the Logo
                </div>
                <div className="text-muted">
                  Change the logo associated to this organization.
                </div>
              </Col>
              <Col xs={4}>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={handleLogoChange}
                  style={{ width: "100%" }}
                >
                  Change Logo
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="text-center mt-4 mb-4 p-4 border">
                  {orgLogo ? (
                    <img src={orgLogo} style={{ width: "200px" }} />
                  ) : (
                    "No organization logo"
                  )}
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          show={showLogoChangeModal}
          onHide={handleOrganizationActivationClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {organization.active
                ? "Deactivate Organization"
                : "Reactivate Organization"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setShowLogoChangeModal(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                setShowLogoChangeModal(false);
              }}
            >
              Close
            </Button>
            {/* <Button type="submit">Submit</Button> */}
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          show={showLogoChangeModal}
          onHide={handleLogoChangeModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {organization.logoPath
                ? "Change Organization Logo"
                : "Add Organization Logo"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center mt-2 mb-4">
              {orgLogo ? (
                <img src={orgLogo} style={{ width: "200px" }} />
              ) : (
                "No organization logo"
              )}
            </div>
            <Form>
              <Form>
                <Form.File
                  id="organization-logo-file"
                  label="Organization Logo"
                  onChange={(e) => {
                    setShowLogoChangeModal(false);
                    let file = e.target.files[0];
                    let fileName = e.target.files[0].name;

                    let orgLogoPath = `/organization/${orgId}/${fileName}`;
                    let newStorageRef = firebase.storage().ref();
                    let orgLogoImageRef = newStorageRef.child(orgLogoPath);

                    orgLogoImageRef.put(file).then(function (snapshot) {
                      db.doc(`organizations/${orgId}`).set(
                        { logoPath: orgLogoPath },
                        { merge: true }
                      );
                    });
                  }}
                  custom
                />
              </Form>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => {
                setShowLogoChangeModal(false);
              }}
            >
              Close
            </Button>
            {/* <Button type="submit">Submit</Button> */}
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          show={showChangeAdministratorModal}
          onHide={handleAdministratorChangeModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Change the Administrator of Your Organization
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>
                  <b>Select New Administrator</b>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={currentAdministrator}
                  onChange={(e) => {
                    let newAdminUsers = organization.users.filter(function (
                      newUser
                    ) {
                      return e.target.value === newUser.id;
                    });

                    setNewOrgAdmin(newAdminUsers[0]);
                  }}
                >
                  {organization.users.map(function (user) {
                    return <option value={user.id}>{user.displayName}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={handleUpdateAdministrator}
            >
              Change
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                setShowChangeAdministratorModal(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

function OrganizationAttributeCard({
  label,
  value,
  dateValue,
  dateFromFirebase,
}) {
  let displayValue = value ? <div>{value}</div> : "";
  let displayDateValue = "";
  //console.log("[DATE] ", dateValue);
  if (dateValue) {
    //console.log(dateValue.hasOwnProperty("toDate"));
    if (!moment(dateValue).isValid()) {
      displayDateValue = moment(dateValue.toDate()).format("MMM Do YY");
    } else {
      displayDateValue = moment(dateValue).format("MMM Do YY");
    }
  }
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      {displayValue}
      <div>{displayDateValue}</div>
    </div>
  );
}

export default OrganizationSettings;
