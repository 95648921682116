import React, { useState, useEffect } from "react";
import { Row, Col, Card, Container, ProgressBar } from "react-bootstrap";

import { Link } from "react-router-dom";

import { Page } from "./../components/common";

import { useAuthState } from "./../../../providers/AuthState";

import NoSkills from "../../../features/dashboards/NoSkills";

import { MainHeading } from "../../../core/helpers/layout-components";

import { db } from "./../../../config/firebase";

function DashboardLayout({ context }) {
  const [skillPathOverviews, setSkillPathOverviews] = useState([]);
  const { orgProfile } = useAuthState();

  function getStepCountForStatus(steps, status) {
    let count = 0;

    for (const key in steps) {
      if (steps[key].status === status) {
        count++;
      } else {
        // Check for partial amounts in the experiences
        const step = steps[key];
        for (const expKey in step.experiences) {
          if (step.experiences[expKey].status === status) {
            count += 0.25;
          }
        }
      }
    }

    return count;
  }

  function getCompletedStageCount(stages) {
    let stageCount = 0;
    for (const key in stages) {
      if (stages[key].complete) {
        stageCount++;
      }
    }
    return stageCount;
  }

  function getInProgressStageCount(stages) {
    let stageCount = 0;

    for (const key in stages) {
      const curStage = stages[key];
      for (const stepKey in curStage.steps) {
        if (curStage.steps[stepKey].status !== "" && !curStage.complete) {
          stageCount++;
          break;
        }
      }
    }

    return stageCount;
  }

  function getCurrentWorkingStageNum(stages) {
    let inProgressStages = getInProgressStageCount(stages);
    let completedStages = getCompletedStageCount(stages);

    if (
      inProgressStages === 0 &&
      completedStages !== getTotalStageCount(stages)
    ) {
      inProgressStages = 1;
    }

    return inProgressStages + completedStages;
  }

  function getTotalStageCount(stages) {
    return Object.keys(stages).length;
  }

  useEffect(() => {
    if (orgProfile) {
      return db
        .collection(`${orgProfile.ref.path}/paths`)
        .where("active", "==", true)
        .onSnapshot((snapshotDocs) => {
          const pathOverviews = [];
          snapshotDocs.forEach((doc) => {
            pathOverviews.push({ ...doc.data(), id: doc.id, ref: doc.ref });
          });
          setSkillPathOverviews(pathOverviews);
        });
    }
  }, [orgProfile]);

  function getActiveStageProgress() { }

  if (skillPathOverviews.length === 0)
    return (
      <Page>
        <Row>
          <Col>
            <MainHeading title="Skills" context={context} />
          </Col>
        </Row>
        <NoSkills />
      </Page>
    );

  if (skillPathOverviews.length > 0)
    return (
      <Page>
        <Row>
          <Col>
            <MainHeading title="Skills" context={context} />
          </Col>
        </Row>
        <Container
          style={{
            background: "#EDF2F7",
            borderRadius: "0.3rem",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Row>
            <Col lg={12}>
              <Row>
                {skillPathOverviews.map((overview) => {
                  return (
                    <Col lg={6} md={8} style={{ marginBottom: "10px" }}>
                      <Card>
                        <Card.Body>
                          <Container>
                            <Row>
                              <Col>
                                <Card.Title>
                                  <span className="text-secondary">
                                    {overview.code}
                                  </span>{" "}
                                  {overview.name}
                                </Card.Title>
                                <Card.Text>
                                  Current Stage:{" "}
                                  {overview.stages[overview.activeStage].name}
                                </Card.Text>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "15px" }}>
                              <Col lg={6} md={8}>
                                <div style={{ fontWeight: "bold" }}>
                                  Path Progress
                                </div>
                                <div>
                                  Completing stage{" "}
                                  {getCurrentWorkingStageNum(overview.stages)}{" "}
                                  of {getTotalStageCount(overview.stages)}
                                </div>
                                <ProgressBar>
                                  <ProgressBar
                                    variant="success"
                                    now={
                                      (getCompletedStageCount(overview.stages) /
                                        getTotalStageCount(overview.stages)) *
                                      100.0
                                    }
                                    key={1}
                                  />
                                  <ProgressBar
                                    variant="info"
                                    now={
                                      (getInProgressStageCount(
                                        overview.stages
                                      ) /
                                        getTotalStageCount(overview.stages)) *
                                      100.0
                                    }
                                    key={3}
                                  />
                                </ProgressBar>
                              </Col>
                              <Col lg={6} md={8}>
                                <div style={{ fontWeight: "bold" }}>
                                  Stage Progress
                                </div>
                                <div>
                                  {Math.floor(
                                    getStepCountForStatus(
                                      overview.stages[overview.activeStage]
                                        .steps,
                                      "Approved"
                                    )
                                  )}{" "}
                                  /{" "}
                                  {
                                    overview.stages[overview.activeStage]
                                      .totalSteps
                                  }{" "}
                                  Steps Complete
                                </div>
                                <ProgressBar>
                                  <ProgressBar
                                    variant="success"
                                    now={
                                      (getStepCountForStatus(
                                        overview.stages[overview.activeStage]
                                          .steps,
                                        "Approved"
                                      ) /
                                        overview.stages[overview.activeStage]
                                          .totalSteps) *
                                      100.0
                                    }
                                    key={1}
                                  />
                                  <ProgressBar
                                    variant="info"
                                    now={
                                      (getStepCountForStatus(
                                        overview.stages[overview.activeStage]
                                          .steps,
                                        "Submitted"
                                      ) /
                                        overview.stages[overview.activeStage]
                                          .totalSteps) *
                                      100.0
                                    }
                                    key={3}
                                  />
                                  <ProgressBar
                                    variant="danger"
                                    now={
                                      (getStepCountForStatus(
                                        overview.stages[overview.activeStage]
                                          .steps,
                                        "Returned"
                                      ) /
                                        overview.stages[overview.activeStage]
                                          .totalSteps) *
                                      100.0
                                    }
                                    key={3}
                                  />
                                </ProgressBar>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "15px" }}>
                              <Col>
                                <div className="float-right">
                                  <Link
                                    className="btn btn-outline-primary"
                                    to={`/skills/user/${overview.stages[overview.activeStage]
                                        .activeStepUrl
                                      }`}

                                  >
                                    Next Step
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </Page>
    );
}

export default DashboardLayout;
