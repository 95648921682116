import { api } from "../api";

export default class SkillPath {
  constructor(doc) {
    /* Fields */
    this.id = doc.id || "";
    this.name = doc.name || "";
    this.code = doc.code || "";
    this.objective = doc.objective || "";
    this.status = doc.status || true;
    this.published = doc.published || "Available";
    this.orgId = doc.orgId || "";
    this.ref = doc.ref || null;
    this.custom = doc.custom || false;
    this.wisdomApprovalGroup = doc.wisdomApprovalGroup ?? {};
    this.stepExperienceForWisdom = doc.stepExperienceForWisdom ?? {};

    if (this.orgId === "") {
      throw new Error("Approval Group must have an Org ID");
    }
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      objective: this.objective,
      status: this.status,
      published: this.published,
      orgId: this.orgId,
      custom: this.custom,
      wisdomApprovalGroup: this.wisdomApprovalGroup,
      stepExperienceForWisdom: this.stepExperienceForWisdom,
    };
  }

  toReducerFormat() {}

  save() {
    if (this.id) {
      return api.organization.updateSkillPath(
        this.orgId,
        this.toFirestoreFormat()
      );
    } else {
      return api.organization.createSkillPath(
        this.orgId,
        this.toFirestoreFormat()
      );
    }
  }

  get(orgId, approvalGroupId) {
    return api.organization.getApprovalGroup(orgId, approvalGroupId);
  }

  updateField(field, value) {
    this[field] = value;
  }

  addApprover(user) {
    if (!this.approvers.includes(user)) {
      this.approvers.push(user);
    }
  }

  removeApprover(user) {
    if (this.approvers.includes(user)) {
      this.approvers = this.approvers.filter(function (approver) {
        return approver !== user;
      });
    }
  }

  //   deleteInvite(organization, invite) {
  //     return api.invite.deleteInvite(organization.id, this.id);
  //   }

  //   getValidInvites() {
  //     return invites.filter(
  //       function (invite) {
  //         if (
  //           !this.domains.includes(
  //             invite.email
  //               .trim()
  //               .substring(invite.email.indexOf("@") + 1, invite.email.length)
  //           )
  //         ) {
  //           this.inviteErrors.push(
  //             "The email you have entered is not a valid domain!"
  //           );
  //         }
  //       }.bind(this)
  //     );
  //   }

  //   getInviteForm() {
  //     return this.invite.form;
  //   }

  //   processInvite(invite) {
  //     return api.organization
  //       .sendInvite(invite)
  //       .then(this.resetInvites)
  //       .catch(this.catchInviteErrors);
  //   }

  //   resetInvites() {
  //     this.resetInviteErrors();
  //     this.resetInviteForm();
  //   }

  //   resetInviteForm() {
  //     this.invite.form = {
  //       displayName: "",
  //       email: "",
  //       isManager: "No",
  //       isSME: "No",
  //       claimed: false,
  //     };
  //   }

  //   resetInviteErrors() {
  //     this.invite.errors = [];
  //   }

  //   catchInviteErrors() {
  //     //console.log("Error creating invite!");
  //   }

  //   sendInvites() {
  //     let inviteErrors = [];
  //     this.getValidInvites().forEach(this.processInvite);
  //     return;
  //   }

  static firebaseConvertor() {
    return this.toFirestoreFormat();
  }
}
