import React, { useState, useEffect } from "react";
import { useMachine } from "@xstate/react";
import {
  Row,
  Col,
  Tab,
  Button,
  Tabs,
  Modal,
  Container,
  ListGroup,
} from "react-bootstrap";

import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

import { Switch, Route, useRouteMatch, useParams, useHistory } from "react-router-dom";

import {
  RecognitionModal,
  RecognitionButton,
} from "../../../core/recognitions";

import { api } from "./../../../core/logging/utils/api/index";

import {
  StepList,
  UserStepDetail,
  StepRelated,
  ExperienceLogForm,
  StepContent,
  StepWisdom,
} from "./../components/skill-step";

import createRecognitionMachine from "../../../machines/RecognitionMachine";
import { ContentDetail } from "./../components/common";

import { useSkillPathContext } from "./../components/providers";
import { useSkillStep } from "./../components/skill-step/hooks";

import { db } from "./../../../config/firebase";
import { useAuthState } from "../../../providers/AuthState";
import { Recognition } from "../../../core/recognitions/utils/classes/recognition";

function UserSkillStepDetailLayout() {
  const { orgProfile, orgContext } = useAuthState();
  const [show, setShow] = useState(false);
  const [experienceLogs, setExperienceLogs] = useState([]);
  const [userProgress, setUserProgress] = useState(null);
  const [currentExperience, setCurrentExperience] = useState(null);
  const [showRecognitionModal, setShowRecognitionModal] = useState(false);
  const [state, send] = useMachine(createRecognitionMachine(orgContext));
  const [currentActiveKey, setCurrentActiveKey] = useState("overview");

  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);

  const [goToNextStep, setGoToNextStep] = useState(false);

  const [experienceForWisdom, setExperienceForWisdom] = useState(false);

  let { path } = useRouteMatch();

  let history = useHistory();

  const { skillStageSlug, skillStepSlug } = useParams();
  const { skillPath } = useSkillPathContext();

  let skillStepRef = null;
  if (skillPath) {
    skillStepRef = skillPath.getSkillStepRefFromSlugs({
      skillStageSlug,
      skillStepSlug,
    });
  }

  let stepRefPath = "";
  if (skillStepRef) {
    stepRefPath = skillStepRef.ref.path;
  }

  const skillStep = useSkillStep(stepRefPath);

  const handleClose = () => {
    setShow(false);
    setCurrentExperience(null);
  };
  const handleShow = (experience) => {
    setCurrentExperience(experience);
    setShow(true);
  };

  const handleRecognitionShow = (flag) => {
    setShowRecognitionModal(flag);
  };

  function findStage(stages, stageOrder) {
    if (stages) {
      for (const key in stages) {
        if (stages[key].order + "" === stageOrder + "") {
          return stages[key];
        }
      }
    }
    return null;
  }

  function stepIsComplete() {
    let isComplete = false;
    if (userProgress) {
      const currentStage = findStage(userProgress.stages, skillStageSlug);
      const currentStep = currentStage.steps[skillStep.id];
      if (currentStep) {
        isComplete = currentStep.complete;
      }
    }

    return isComplete;
  }

  function getNumOfLogsForStep() {
    let numOfLogs = 0;

    if (userProgress) {
      const currentStage = findStage(userProgress.stages, skillStageSlug);
      const currentStep = currentStage.steps[skillStep.id];

      if (currentStep && currentStep.experiences) {
        numOfLogs = Object.keys(currentStep.experiences).length || 0;
      }
    }

    return numOfLogs;
  }

  function getNumOfApprovedLogsForStep() {
    let numOfLogs = 0;

    if (userProgress) {
      const currentStage = findStage(userProgress.stages, skillStageSlug);
      const currentStep = currentStage.steps[skillStep.id];

      if (currentStep && currentStep.experiences) {
        for (const expKey in currentStep.experiences) {
          if (currentStep.experiences[expKey].status === "Approved") {
            numOfLogs++;
          }
        }
      }
    }

    return numOfLogs;
  }

  function getNumOfLogsRequiredForStep(totalLogs) {
    let numOfLogs = 0;

    if (userProgress) {
      const currentStage = findStage(userProgress.stages, skillStageSlug);
      const currentStep = currentStage.steps[skillStep.id];

      if (currentStep && currentStep.experiences) {
        numOfLogs = Object.keys(currentStep.experiences).length || 0;
      }
    }

    return totalLogs - numOfLogs;
  }

  function canLogExperience() {
    let prevStageComplete = false;
    if (userProgress) {
      const currentStage = findStage(userProgress.stages, skillStageSlug);
      if (currentStage && currentStage.order === 1) {
        prevStageComplete = true;
      } else if (currentStage) {
        const previousStage = findStage(
          userProgress.stages,
          currentStage.order - 1
        );
        if (previousStage && previousStage.complete) {
          prevStageComplete = true;
        }
      }
    }

    return (
      experienceLogs.length < skillStep.numberOfLogsRequired &&
      prevStageComplete
    );
  }

  const requiresRecognition = skillStep.approvalType === "Recognition";

  useEffect(() => {
    if (skillPath.id) {
      return db
        .doc(`${orgProfile.ref.path}/paths/${skillPath.id}`)
        .onSnapshot((snapshot) => {
          setUserProgress({
            ...snapshot.data(),
            id: snapshot.id,
          });
        });
    }
  }, [skillPath.id, orgProfile.ref.path]);

  useEffect(() => {
    if (skillStep.id) {
      setCurrentActiveKey("overview");
      return db
        .collection(
          `${orgProfile.ref.path}/paths/${skillStep.getPathId()}/experiences`
        )
        .where("step.id", "==", skillStep.id)
        .orderBy("dateSubmitted", "desc")
        .onSnapshot((snapshot) => {
          const logs = [];
          snapshot.forEach((doc) => {
            logs.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          setExperienceLogs(logs);
        });
    }
  }, [skillStep.id, orgProfile.ref.path, skillStep]);

  function logCompletedExpererience() {
    const experienceDoc = {
      dateSubmitted: new Date(),
      dateApproved: new Date(),
      status: "Approved",
      experienceLog: "You have completed this step",
      type: "self validated",
      step: {
        id: skillStep.id,
        name: skillStep.name,
        path: skillStep.ref.path,
      },
      skill: {
        id: skillPath.id,
        code: skillPath.code,
        name: skillPath.name,
        path: skillPath.ref.path,
      },
      approver: {
        type: skillStep.approvalType,
      },
    };

    // Add a new experience
    db.collection(
      `${orgProfile.ref.path}/paths/${skillStep.getPathId()}/experiences`
    )
      .add(experienceDoc)
      .then(() => {
        setGoToNextStep(true);
      });
  }


  function getProgressText() {
    let progressText = "";
    if (!stepIsComplete() && skillStep.approvalType !== "Self Validated") {
      progressText = `${getNumOfLogsForStep()} / ${skillStep.numberOfLogsRequired
        } logs complete`;
    } else if (stepIsComplete()) {
      progressText = "Step is complete.";
    }

    return progressText;
  }

  function logRecognition(userRecognition) {
    const experienceDoc = {
      dateSubmitted: new Date(),
      dateApproved: new Date(),
      status: "Approved",
      experienceLog: "You have given a recognition!",
      type: "recognition",
      step: {
        id: skillStep.id,
        name: skillStep.name,
        path: skillStep.ref.path,
      },
      skill: {
        id: skillPath.id,
        code: skillPath.code,
        name: skillPath.name,
        path: skillPath.ref.path,
      },
      approver: {
        id: orgProfile.manager,
        // name: "Jason Manners",
        type: skillStep.approvalType,
      },
    };

    // Add a new experience
    db.collection(
      `${orgProfile.ref.path}/paths/${skillStep.getPathId()}/experiences`
    )
      .add(experienceDoc)
      .then((experienceDoc) => {
        return api.logging.createLogEntry(
          orgContext.id,
          api.logging.TYPES.EXPERIENCE.SUBMIT,
          {
            message: `${orgProfile.displayName} has submitted a recognition.`,
            related: db.doc(
              `${orgProfile.ref.path
              }/paths/${skillStep.getPathId()}/experiences/${experienceDoc.id}`
            ),
            response: "SUCCESS",
          }
        );
      })
      .then(() => {
        setGoToNextStep(true);
      });
  }

  // useEffect to go to the next step if marked complete or recognitions move to another step/stage
  useEffect(() => {
    let isCurrent = true;
    if (isCurrent && goToNextStep && userProgress && userProgress.stages[userProgress.activeStage].activeStepUrl !== skillStep.url) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 6000);
      setGoToNextStep(false);
      history.push(`/skills/user/${userProgress.stages[userProgress.activeStage].activeStepUrl}`);
    }

    return () => {
      isCurrent = false;
    }
  }, [goToNextStep, userProgress]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && skillStep.ref) {
      db.collection(`organizations/${orgContext.id}/skills`)
        .where("id", "==", skillPath.id)
        .get()
        .then((orgSkillPathDocs) => {
          orgSkillPathDocs.forEach((orgSkillPathDoc) => {
            const orgSkillPath = orgSkillPathDoc.data();
            let experienceForWisdom = false;
            if (
              orgSkillPath.stepExperienceForWisdom &&
              orgSkillPath.stepExperienceForWisdom[skillStep.ref.id]
            ) {
              experienceForWisdom =
                orgSkillPath.stepExperienceForWisdom[skillStep.ref.id];
            }
            setExperienceForWisdom(experienceForWisdom);

            return true;
          });
        });
    }

    return () => {
      isCurrent = false;
    };
  }, [orgContext.id, skillStep]);

  return (
    skillPath && (
      <div>
        {showConfetti && <Confetti
          style={{ position: 'fixed', top: '0', left: '0' }}
          width={width}
          height={height}
          numberOfPieces={100}
          recycle={false}
          run={showConfetti}
        />}
        <Row>
          <Col md={3}>
            <h4>Skill Steps</h4>
          </Col>
          <Col md={7}>
            {/* {skillStep.id !== "" && (
              <h4>
                Skill Step: {skillStageSlug}.{skillStep.order}
              </h4>
            )} */}
          </Col>
          <Col>
            {canLogExperience() &&
              !requiresRecognition &&
              skillStep.approvalType !== "Self Validated" && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => {
                    handleShow(null);
                  }}
                >
                  Log Experience
                </Button>
              )}
            {canLogExperience() && skillStep.approvalType === "Self Validated" && (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  logCompletedExpererience();
                }}
              >
                Mark Complete
              </Button>
            )}
            {goToNextStep && skillStep.approvalType === "Self Validated" && (
              <Button
                variant="outline-primary"
                size="sm"
                disabled
              >
                Completing Step...
              </Button>
            )}
            {requiresRecognition && (
              <div>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => {
                    handleRecognitionShow(true);
                  }}
                >
                  Give Recognition
                </Button>
                <RecognitionModal
                  showRecognitionModal={showRecognitionModal}
                  setShowRecognitionModal={setShowRecognitionModal}
                  sendRecognition={send}
                  disabledConfetti={true}
                  postSubmitHandler={logRecognition}
                />
              </div>
            )}
            {goToNextStep && requiresRecognition && (
              <Button
                variant="outline-primary"
                size="sm"
                disabled
              >
                Giving Recognition...
              </Button>
            )}

          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <StepList userProgress={userProgress} />
          </Col>
          <Col md={9}>
            {skillStep.id !== "" && (
              <h5>
                {skillStageSlug}.{skillStep.order}: {skillStep.name} - {skillStep.approvalType}
              </h5>
            )}
            <Switch>
              <Route path={`${path}`}>
                {skillStep.id && (
                  <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                    activeKey={currentActiveKey}
                    onSelect={(key) => setCurrentActiveKey(key)}
                  >
                    <Tab eventKey="overview" title="Instructions">
                      <UserStepDetail
                        skillStep={skillStep}
                        userProgressText={getProgressText()}
                        experienceForWisdom={experienceForWisdom}
                      />
                    </Tab>
                    <Tab eventKey="content" title="Content">
                      <StepContent skillStep={skillStep} canEdit={false} />
                    </Tab>
                    <Tab eventKey="wisom" title="Wisdom">
                      <StepWisdom skillStep={skillStep} canEdit={false} isComplete={stepIsComplete()} />
                    </Tab>

                    <Tab eventKey="experience" title="My Experience">
                      <Row>
                        <Col>
                          <h4 style={{ textDecoration: "underline" }}>
                            Experiences
                          </h4>
                          <p>
                            {getNumOfApprovedLogsForStep()} of{" "}
                            {skillStep.numberOfLogsRequired} approved
                            experiences.
                          </p>
                          <ListGroup variant="flush">
                            {experienceLogs.map((log) => {
                              return (
                                <ListGroup.Item>
                                  <Row>
                                    <Col>
                                      <strong>Status:</strong>{" "}
                                      <span
                                        className={
                                          log.status === "Approved"
                                            ? "text-success"
                                            : log.status === "Returned"
                                              ? "text-danger"
                                              : ""
                                        }
                                      >
                                        {log.status}
                                      </span>
                                    </Col>
                                    <Col>
                                      <p>
                                        <strong>
                                          Date{" "}
                                          {log.status === "Approved"
                                            ? "Approved"
                                            : log.status === "Returned"
                                              ? "Returned"
                                              : "Submitted"}
                                          :
                                        </strong>{" "}
                                        {new Date(
                                          (log.status === "Approved"
                                            ? log.dateApproved
                                            : log.status === "Returned"
                                              ? log.dateReturned
                                              : log.dateSubmitted
                                          ).seconds * 1000
                                        ).toLocaleDateString()}
                                      </p>
                                    </Col>
                                  </Row>
                                  {log.approverComments && (
                                    <Row>
                                      <Col>
                                        <ContentDetail
                                          heading={"Approver's Comments"}
                                          content={log.approverComments}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                  <Row>
                                    <Col>
                                      <ContentDetail
                                        heading={"Experience"}
                                        content={log.experienceLog}
                                      />
                                      {/* Erich Comment JULY142020: Added rel="noopener noreferrer" to
                                          target blank links to cover security risks detailed here:
                                          https://mathiasbynens.github.io/rel-noopener */}
                                      {log.attachment && log.attachment.url && (
                                        <div>
                                          <a
                                            href={log.attachment.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            View Attachment
                                          </a>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                  {log.status === "Returned" && (
                                    <Row>
                                      <Col>
                                        {/* <div className="float-right"> */}
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          onClick={() => {
                                            handleShow(log);
                                          }}
                                        >
                                          Resubmit
                                        </Button>
                                        {/* </div> */}
                                      </Col>
                                    </Row>
                                  )}
                                </ListGroup.Item>
                              );
                            })}
                          </ListGroup>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="related" title="Related">
                      <StepRelated skillStep={skillStep} />
                    </Tab>
                  </Tabs>
                )}
              </Route>
            </Switch>
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Experience Log: {skillStep.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <strong>Approver:</strong> {skillStep.approvalType}
                </Col>
                <Col>
                  <strong>Date Submitted:</strong>{" "}
                  {new Date().toLocaleDateString()}
                </Col>
              </Row>
              <Row style={{ marginBottom: "15px" }}>
                <Col>
                  <ContentDetail
                    heading={"Instructions"}
                    content={skillStep.instructions}
                  />
                  <ContentDetail
                    heading={"Evidence Required"}
                    content={skillStep.evidenceRequired}
                  />
                  {/* <strong>Evidence Required</strong>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {skillStep.evidenceRequired}
                  </p> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ExperienceLogForm
                    handleCancel={handleClose}
                    skillStep={skillStep}
                    currentExperience={currentExperience}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    )
  );
}

export default UserSkillStepDetailLayout;
