import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { Link, useHistory } from "react-router-dom";

import { api } from "../../utils/api";

import { firebase } from "../../../../config/firebase";

import { useAuthState } from "../../../../providers/AuthState";

import { useProfileForm } from "../profile/hooks";

import { db } from "../../../../config/firebase";

function ProfileEdit() {
  let uid = undefined;
  const profileForm = useProfileForm();
  const [show, setShow] = useState(false);

  let storageRef = firebase.storage().ref();

  const { user, orgContext } = useAuthState();

  if (user) {
    uid = user.uid;
  } else {
    uid = undefined;
  }

  const history = useHistory();

  useEffect(() => {
    if (user) {
      api.profile.get(uid).then(function (doc) {
        let p = {
          ...doc.data(),
          id: doc.id,
        };
        if (p.photoURL) {
          let photoRef = storageRef.child(p.photoURL);
          photoRef.getDownloadURL().then(function (url) {
            //console.log("URL: ", url);
            p.photoPath = url;
            profileForm.update(p);
          });
        } else {
          profileForm.update(p);
        }
      });
    }
  }, [uid]);

  function showFileUploadModal() {
    setShow(true);
  }

  function viewState() {
    //console.log(profileForm);
  }

  function handleSubmit(e) {
    e.preventDefault();
    profileForm.save().then(function () {
      db.collection(`organizations/${orgContext.id}/users`)
        .doc(user.uid)
        .update({ displayName: profileForm.displayName });
      user.updateProfile({ displayName: profileForm.displayName });
      history.push("/profile");
    });
  }

  ////console.log("Is Third Party Auth: ", isThirdPartyAuth(user));

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,.125)",
                marginBottom: "15px",
                marginTop: "15px",
              }}
            >
              <Row>
                <Col xs={6}>
                  <div className="text-info header-pretitle">Profile</div>
                  <h4
                    className="text-secondary"
                    style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                  >
                    {user.displayName}
                  </h4>
                </Col>
                <Col>
                  <Button
                    variant="outline-primary"
                    className="font-weight-bold float-right"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  {/* <Button
                    variant="primary"
                    className="font-weight-bold float-right"
                    onClick={viewState}
                  >
                    View State
                  </Button> */}
                  <Link
                    className="btn btn-outline-secondary font-weight-bold float-right"
                    to="/profile"
                    style={{ marginRight: "5px" }}
                  >
                    Cancel
                  </Link>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12}>
                      <div className="sub-header-container">
                        <Row>
                          <Col xs={12}>
                            <h6 className="sub-header text-primary">
                              User Profile Details
                            </h6>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center" xs={4}>
                      {profileForm.photoPath.length ? (
                        <div>
                          <p>
                            <img
                              src={profileForm.photoPath}
                              style={{
                                width: "128px",
                              }}
                              alt="Profile Photo"
                            />
                          </p>
                          <Button
                            variant="outline-secondary"
                            style={{ marginBottom: "15px" }}
                            size="sm"
                            onClick={showFileUploadModal}
                          >
                            Change Profile Photo
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            variant="outline-secondary"
                            style={{ marginBottom: "15px" }}
                            size="sm"
                            onClick={showFileUploadModal}
                          >
                            Add a Profile Photo
                          </Button>
                        </div>
                      )}
                    </Col>

                    <Col>
                      <Row>
                        <Col>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label className="text-info header-pretitle">
                              Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              value={profileForm.displayName}
                              onChange={(e) => {
                                profileForm.updateField(
                                  "displayName",
                                  e.target.value
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col>
                          <AttributeCard
                            label="Email Verified"
                            value={user.emailVerified ? "Yes" : "No"}
                          />
                        </Col>
                        <Col>
                          <AttributeCard label="EMAIL" value={user.email} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <AttributeCard label="Login" value="metisphere.com" />
                        </Col>
                        <Col>
                          {" "}
                          <AttributeCard
                            label="User Type"
                            value={profileForm.type}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  show={show}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Change Profile Photo
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form>
                        <Form.File
                          id="profile-photo-file"
                          label="Photo Profile"
                          onChange={(e) => {
                            setShow(false);
                            let file = e.target.files[0];

                            let childPhotoPath =
                              "/profiles/" + user.uid + ".jpg";
                            let newStorageRef = firebase.storage().ref();
                            let profilePhotoRef = newStorageRef.child(
                              childPhotoPath
                            );

                            console.log("[PROFILE PHOTO] ", childPhotoPath);

                            let p = { ...profileForm };

                            profilePhotoRef.put(file).then(function (snapshot) {
                              profileForm.updateField(
                                "photoURL",
                                childPhotoPath
                              );

                              console.log("[PROFILE FORM] ", profileForm);

                              profilePhotoRef
                                .getDownloadURL()
                                .then(function (url) {
                                  console.log("URL: ", url);
                                  profileForm.updateField("photoPath", url);

                                  profileForm
                                    .get()
                                    .save()
                                    .then(function () {
                                      console.log("[SUCCESS]");
                                    });
                                });
                            });
                            //setPhotoPath(e.target.files[0]);
                          }}
                          custom
                        />
                      </Form>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      Close
                    </Button>
                    <Button type="submit" onClick={viewState}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Row>
            </div>
            <div>
              <Row>
                <Col xs={12}>
                  {" "}
                  <div
                    style={{
                      borderBottom: "1px solid rgba(0,0,0,.125)",
                      marginBottom: "15px",
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <h6
                          className="text-primary"
                          style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                        >
                          Metisphere Details
                        </h6>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-center" xs={4}></Col>
                <Col>
                  <Form>
                    <Row>
                      <Col>
                        {" "}
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            Transcript Email Address
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Transcript Email"
                            defaultValue={profileForm.transcriptEmail}
                            onChange={(e) => {
                              profileForm.updateField(
                                "transcriptEmail",
                                e.target.value
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col></Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            CITY
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Location (City)"
                            value={profileForm.city}
                            onChange={(e) => {
                              profileForm.updateField("city", e.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            COUNTRY
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={profileForm.country}
                            onChange={(e) => {
                              profileForm.updateField(
                                "country",
                                e.target.value
                              );
                            }}
                          >
                            <option>None</option>
                            <option>United States</option>
                            <option>Canada</option>
                            <option>United Kingdom</option>
                            <option>Mexico</option>
                            <option>Australia</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            Learner Summary
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={profileForm.learnerSummaryFrequency}
                            onChange={(e) => {
                              profileForm.updateField(
                                "learnerSummaryFrequency",
                                e.target.value
                              );
                            }}
                          >
                            <option>Weekly</option>
                            <option>Monthly</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        {" "}
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label className="text-info header-pretitle">
                            SME Summary - {profileForm.smeSummaryFrequency}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={profileForm.smeSummaryFrequency}
                            onChange={(e) => {
                              profileForm.updateField(
                                "smeSummaryFrequency",
                                e.target.value
                              );
                            }}
                          >
                            <option>Weekly</option>
                            <option>Monthly</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function AttributeCard({ label, value, args }) {
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      <div>{value}</div>
    </div>
  );
}

// function FormGroupCard({ label, value, args }) {
//   return (
//     <div style={{ paddingBottom: "15px" }}>
//       <div className="text-info header-pretitle">{label}</div>
//       <div style={{ paddingTop: "15px" }}>{value}</div>
//     </div>
//   );
// }

export default ProfileEdit;
