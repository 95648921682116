import { firebase, db } from "../../../../config/firebase";

export function login(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function logout() {
  return firebase.auth().signOut();
}

export function resetPassword(email) {
  return firebase.auth().sendPasswordResetEmail(email);
}

export function updateProfile(user, profile) {
  return user.updateProfile(profile);
}

export function completeToken(tokenDoc) {
  //console.log("[TOKEN IN API - COMPLETE TOKEN]", tokenDoc);
  return db
    .collection("orgInvites")
    .doc(tokenDoc.id)
    .update({ validated: true });
}

export function updatePassword(user, password) {
  return user.updatePassword(password);
}

export default {
  login,
  logout,
  resetPassword,
  updateProfile,
  completeToken,
  updatePassword,
};
