import React, { useState, useEffect } from "react";

import { api } from "../../../../core/auth/utils/api";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import Invite from "../../utils/classes/invite";

import { useAuthState } from "../../../../providers/AuthState";
import { useOrganizationForm } from "../hooks";

import AcceptTerms from "./accept-terms";
import InviteTeamMembers from "./invite-team-members";
import SelectSubjectMatterExperts from "./subject-matter-experts";
import Finalize from "./finalize";

function SelfRegistrationModal({ user, setOrgAcceptenceModalShow }) {
  const [pending, setPending] = useState([]);
  const [currentOrgInvite, setCurrentOrgInvite] = useState();
  const [show, setShow] = useState(true);
  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(4);
  let newInvite = new Invite({});
  const [invites, setInvites] = useState([newInvite]);
  const [inviteErrors, setInviteErrors] = useState([]);
  const handleClose = () => setShow(false);
  const { orgContext } = useAuthState();
  const organization = useOrganizationForm(orgContext.id);

  function updateValues(current, field, value, index, setter) {
    let newObject = [...current];
    newObject[index].updateField(field, value);
    setter(newObject);
  }

  function handleInvite() {
    //console.log("[GET INVITE ERRORS]");
    let errors = organization.getInviteErrors(invites);
    if (errors.length === 0) {
      organization.addInvites(invites);
      invites.forEach((invite) => {
        invite.clear();
      });
      setInvites(invites);
      setInviteErrors([]);
    }
    setInviteErrors(errors);
    //organization.addInvites(invites);
  }

  function deleteInvite(invite) {
    organization.deleteInvite(invite);
  }

  useEffect(() => {
    if (user) {
      api.organization
        .getAllOrgTrialInvitesByUserId(user)
        .then(function (orgs) {
          if (orgs.length > 0) {
            setCurrentOrgInvite(orgs[0]);
          } else {
            setStep(2);
          }
          setPending(orgs);
        });
    }
  }, [user]);

  function acceptOrganizationInvite(invite) {
    setCurrentOrgInvite(invite);
    setOrgAcceptenceModalShow(true);
  }
  let body;
  let title;
  switch (step) {
    case 1:
      // code block
      title = `Onboarding - (${step}/${totalSteps}) - Welcome to Metisphere`;
      body = (
        <AcceptTerms setStep={setStep} currentOrgInvite={currentOrgInvite} />
      );
      break;
    case 2:
      // code block
      title = `Invite Your Team to Metisphere - (${step}/${totalSteps})`;
      body = (
        <InviteTeamMembers
          setStep={setStep}
          currentOrgInvite={currentOrgInvite}
        />
      );
      break;
    case 3:
      // code block
      title = `Set Subject Matter Expert Approval Group - (${step}/${totalSteps})`;
      body = (
        <SelectSubjectMatterExperts
          setStep={setStep}
          currentOrgInvite={currentOrgInvite}
        />
      );
      break;
    case 4:
      // code block
      title = `Finalize - (${step}/${totalSteps})`;
      body = <Finalize setStep={setStep} currentOrgInvite={currentOrgInvite} />;
      break;
    default:
      // code block
      body = (
        <AcceptTerms setStep={setStep} currentOrgInvite={currentOrgInvite} />
      );
      break;
  }

  return (
    <Modal
      size="lg"
      show={!orgContext.onboarding}
      onHide={handleClose}
      centered
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "400px" }}>{body}</Modal.Body>
    </Modal>
  );
}

export default SelfRegistrationModal;
