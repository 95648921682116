import { firebase } from "../../../../config/firebase";

export function create(email, password) {
  //console.log("Signup with Email and Password", email, password);
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export default {
  create,
};
