import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import DefaultReportLayout from "./default-report";

function ReportsLayout() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={DefaultReportLayout} />
    </Switch>
  );
}

export default ReportsLayout;
