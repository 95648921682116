import { api } from "../api";

export default class Profile {
  constructor(doc) {
    this.id = doc.id || "";
    this.displayName = doc.displayName || "";
    this.timezone = doc.timezone || "";
    this.photoURL = doc.photoURL || "";
    this.photoPath = doc.photoPath || "";
    this.type = doc.type || "";
    this.email = doc.email || "";
    this.city = doc.city || "";
    this.country = doc.country || "";
    this.recoveryEmail = doc.recoveryEmail || null;
    this.transcriptEmail = doc.transcriptEmail || null;
    this.learnerSummaryFrequency = doc.learnerSummaryFrequency || null;
    this.smeSummaryFrequency = doc.smeSummaryFrequency || null;

    return this;
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      displayName: this.displayName,
      timezone: this.timezone,
      photoURL: this.photoURL,
      photoPath: this.photoPath,
      type: this.type,
      email: this.email,
      city: this.city,
      country: this.country,
      recoveryEmail: this.recoveryEmail,
      transcriptEmail: this.transcriptEmail,
      learnerSummaryFrequency: this.learnerSummaryFrequency,
      smeSummaryFrequency: this.smeSummaryFrequency,
    };
  }

  toReducerFormat() {}

  save() {
    //console.log("Saving...");
    return api.profile.update(this.toFirestoreFormat());
  }

  static firebaseConvertor(profile) {
    return profile.toFirestoreFormat();
  }
}
