import { api } from "../api";

export default class ApprovalGroup {
  constructor(doc) {
    /* Fields */
    this.id = doc.id || "";
    this.name = doc.name || "";
    this.description = doc.description || "";
    this.createdDate = doc.createdDate || new Date();
    this.approvers = doc.approvers || [];
    this.orgId = doc.orgId || "";
    if (this.orgId === "") {
      throw new Error("Approval Group must have an Org ID");
    }
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      createdDate: this.createdDate,
      approvers: this.approvers,
    };
  }

  toReducerFormat() { }

  save() {
    // if (!this.name) {
    //   throw new Error("Approval groups require a name.");
    // }
    // if (!this.description) {
    //   throw new Error("Approval groups require a description.");
    // }
    // if (!this.approvers.length === 0) {
    //   throw new Error("Approval groups require an approver.");
    // }

    console.log("Saving...");
    console.log("Approvers: ", this.approvers);
    return new Promise(
      function (resolve, reject) {
        let errors = [];
        if (!this.name) {
          errors.push("Approval groups require a name.");
        }
        if (!this.description) {
          errors.push("Approval groups require a description.");
        }
        if (this.approvers.length === 0) {
          errors.push("Approval groups require an approver.");
        }

        if (this.id && errors.length === 0) {
          resolve(
            api.organization.updateApprovalGroup(
              this.orgId,
              this.toFirestoreFormat()
            )
          );
        } else if (errors.length === 0) {
          resolve(
            api.organization.createApprovalGroup(
              this.orgId,
              this.toFirestoreFormat()
            )
          );
        } else {
          reject(errors);
        }
      }.bind(this)
    );
  }

  get(orgId, approvalGroupId) {
    return api.organization.getApprovalGroup(orgId, approvalGroupId);
  }

  updateField(field, value) {
    this[field] = value;
  }

  addApprover(user) {
    if (!this.approvers.includes(user)) {
      this.approvers.push(user);
    }
  }

  removeApprover(user) {
    if (this.approvers.includes(user)) {
      this.approvers = this.approvers.filter(function (approver) {
        return approver !== user;
      });
    }
  }

  //   deleteInvite(organization, invite) {
  //     return api.invite.deleteInvite(organization.id, this.id);
  //   }

  //   getValidInvites() {
  //     return invites.filter(
  //       function (invite) {
  //         if (
  //           !this.domains.includes(
  //             invite.email
  //               .trim()
  //               .substring(invite.email.indexOf("@") + 1, invite.email.length)
  //           )
  //         ) {
  //           this.inviteErrors.push(
  //             "The email you have entered is not a valid domain!"
  //           );
  //         }
  //       }.bind(this)
  //     );
  //   }

  //   getInviteForm() {
  //     return this.invite.form;
  //   }

  //   processInvite(invite) {
  //     return api.organization
  //       .sendInvite(invite)
  //       .then(this.resetInvites)
  //       .catch(this.catchInviteErrors);
  //   }

  //   resetInvites() {
  //     this.resetInviteErrors();
  //     this.resetInviteForm();
  //   }

  //   resetInviteForm() {
  //     this.invite.form = {
  //       displayName: "",
  //       email: "",
  //       isManager: "No",
  //       isSME: "No",
  //       claimed: false,
  //     };
  //   }

  //   resetInviteErrors() {
  //     this.invite.errors = [];
  //   }

  //   catchInviteErrors() {
  //     //console.log("Error creating invite!");
  //   }

  //   sendInvites() {
  //     let inviteErrors = [];
  //     this.getValidInvites().forEach(this.processInvite);
  //     return;
  //   }

  static firebaseConvertor() {
    return this.toFirestoreFormat();
  }
}
