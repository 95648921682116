import React, { useState, useReducer } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import { firebase, db } from "../../../../config/firebase";

import { useParams, Redirect, useHistory } from "react-router-dom";

import { useAuthState } from "../../../../providers/AuthState";

function OrganizationForm({ pageTitle }) {
  let history = useHistory();
  /*
   * Get Auth State Context
   * user, authenticated, and disptach
   */
  const { user, profileType } = useAuthState();

  /*
   * Form State Functionality
   * name - Name of the organization
   * orgEmail - Email of the Administrator
   * validDoamins - Array of valid domains
   * Notes - Internal notes only visible to superusers of Metisphere
   */
  const [name, setName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminName, setAdminName] = useState("");
  const [vendor, setVendor] = useState(false);
  const [licenses, setLicenseCount] = useState(30);
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("Created");
  const [orgCreatedSuccess, setOrgCreatedSuccessfully] = useState(false);

  const [domains, domainDispatch] = useReducer(
    function reducer(state, action) {
      switch (action.type) {
        case "ADD":
          return [...state, ""];
        case "UPDATE":
          const updatedDomains = [...state];
          updatedDomains[action.payload.index] = action.payload.value;
          return updatedDomains;
        case "REMOVE":
          return state.filter(
            (_, currentIndex) => currentIndex !== action.payload.index
          );
        case "CREATE":
          return action.payload.domains;
        default:
          throw new Error();
      }
    },
    [""]
  );

  const { orgId } = useParams();

  if (name === "" && orgId) {
    db.collection("organizations")
      .doc(orgId)
      .get()
      .then(function (doc) {
        let data = doc.data();
        setName(data.name);
        setAdminEmail(data.adminEmail);
        setAdminName(data.adminName);
        setNotes(data.notes);
        setStatus(data.status);
        domainDispatch({
          type: "CREATE",
          payload: { domains: data.domains },
        });
        setName(data.name);
        setLicenseCount(data.licenses);
      });
  }

  function createOrganization(e) {
    e.preventDefault();
    if (orgId) {
      db.collection("organizations")
        .doc(orgId)
        .update({
          name: name,
          adminName: adminName,
          adminEmail: adminEmail,
          status: status,
          domains: domains,
          notes: notes,
          createdBy: user.uid,
          licenses: licenses,
          users: [],
        })
        .then(function () {
          setOrgCreatedSuccessfully(true);
          history.push("/superuser-console");
        })
        .catch(function (err) {
          //console.log("Error!");
        });
    } else {
      db.collection("organizations")
        .add({
          name: name,
          adminName: adminName,
          adminEmail: adminEmail,
          orgAdminId: "",
          domains: domains,
          status: status,
          notes: notes,
          createdBy: user.uid,
          licenses: licenses,
          onboarding: true,
          users: [],
          active: true,
          lastActive: firebase.firestore.Timestamp.now(),
        })
        .then(function () {
          setTimeout(function () {
            setOrgCreatedSuccessfully(true);
            history.push("/superuser-console");
          }, 1000);
        })
        .catch(function (err) {
          //console.log("Error!");
        });
    }
  }

  function viewState() {
    //console.log(name);
    //console.log(adminEmail);
    //console.log(domains);
    //console.log(licenses);
    //console.log(orgId);
  }

  return (
    <Row style={{ marginTop: "40px", marginBottom: "100px" }}>
      <Col>
        <Row>
          <Col>
            <Row>
              <Col xs={{ span: 6, offset: 3 }}>
                <Row>
                  <Col xs={12}>
                    {orgCreatedSuccess ? <Redirect to="/superuser" /> : ""}
                    {/* <div className="text-info header-pretitle">
                      <Link to="/superuser">OVERVIEW</Link> > ACCOUNTS
                    </div> */}
                    <h1
                      className="text-secondary"
                      style={{ letterSpacing: "-.02em", fontWeight: "600" }}
                    >
                      {pageTitle}
                    </h1>
                  </Col>
                  <Col></Col>
                </Row>
                <Form onSubmit={createOrganization}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label className="text-secondary font-weight-bold">
                      Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="My New Customer"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Administrator Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="My Customer Name"
                      value={adminName}
                      onChange={(e) => {
                        setAdminName(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Administrator Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="admin@company.com"
                      value={adminEmail}
                      onChange={(e) => {
                        setAdminEmail(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Vendor / Reseller</Form.Label>
                    <Form.Control
                      type="checkbox"
                      style={{ textAlign: "left" }}
                      value={vendor}
                      onChange={(e) => {
                        setVendor(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="exampleForm.ControlSelect2">
                    <Form.Label>Valid Registered Domains</Form.Label>
                    <Table borderless responsive>
                      <thead>
                        <tr>
                          <th style={{ width: "50px" }}>#</th>
                          <th>Domain</th>
                          <th style={{ width: "150px" }}>
                            <Button
                              size="sm"
                              className="float-right"
                              onClick={() => domainDispatch({ type: "ADD" })}
                            >
                              Add Domain
                            </Button>
                            {/* <Button
                              size="sm"
                              className="float-right"
                              onClick={viewDomainState}
                            >
                              View Domain State
                            </Button> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {domains.map((domain, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <Form.Control
                                  type="text"
                                  placeholder="xyz.com"
                                  value={domains[index]}
                                  onChange={(e) => {
                                    domainDispatch({
                                      type: "UPDATE",
                                      payload: {
                                        index: index,
                                        value: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="float-right"
                                  name={index}
                                  onClick={() =>
                                    domainDispatch({
                                      type: "REMOVE",
                                      payload: { index: index },
                                    })
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Form.Group>
                  {profileType === "SUPERUSER" ? (
                    <div>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Number of Platform Licenses</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          value={licenses}
                          onChange={(e) => {
                            setLicenseCount(parseInt(e.target.value));
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </div>
                  ) : (
                    ""
                  )}

                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className="float-right"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={viewState}
                    size="lg"
                    className="float-right"
                    style={{ marginRight: "10px" }}
                  >
                    View State
                  </Button>
                </Form>
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default OrganizationForm;
