import React, { useState, useEffect } from "react";
import { firebase, db } from "../../config/firebase";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";

import "./../../style/Auth.css";

import MetisphereSymbol from "./../../symbol.svg";

import { useSignUpForm } from "./hooks";
import { ArrowLeftShort } from "react-bootstrap-icons";

/* TODO: Convert to Functional Component */
function SignUp() {
  const [status, setStatus] = useState("PENDING");
  const [trial, setTrial] = useState({
    organizationName: "",
    orgAdminName: "",
    orgAdminEmail: "",
    nonce: "",
  });

  const [errors, setErrors] = useState([]);

  function handleSubmit(e) {
    e.preventDefault();

    let newErrors = [];

    if (trial.organizationName === "") {
      newErrors.push("Organization name cannot be blank.");
    }

    if (trial.orgAdminName === "") {
      newErrors.push("Your name cannot be blank!");
    }

    if (trial.orgAdminEmail === "") {
      newErrors.push("Your email cannot be blank!");
    }

    db.collection("trials")
      .where("nonce", "==", trial.nonce)
      .where("orgAdminEmail", "==", trial.orgAdminEmail)
      .get()
      .then(function (docs) {
        console.log("[Doc]", docs);
        console.log("[Query]", trial);

        if (!docs.empty) {
          newErrors.push("You can only request one trial license at a time!");
        }

        setErrors(newErrors);

        if (newErrors.length > 0) {
        } else {
          db.collection("trials")
            .add(trial)
            .then(function () {
              setStatus("COMPLETE");
            });
        }
      })
      .catch(function () {
        setErrors(newErrors);
      });
    //console.log("This is the current signup state: ", signup, signup.create());
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    let trialNonce = localStorage.getItem("trialNonce");
    if (!trialNonce) {
      trialNonce = makeid(20);
      localStorage.setItem("trialNonce", trialNonce);
      let newTrial = { ...trial };
      newTrial.nonce = trialNonce;
      setTrial(newTrial);
    } else {
      let newTrial = { ...trial };
      newTrial.nonce = trialNonce;
      setTrial(newTrial);
    }
    db.collection("analytics").add({
      type: "TRIAL_VIEW",
      nonce: trialNonce,
      date: new Date()
    })
  }, []);

  // function handleSignUpWithGoogle() {
  //   const provider = new firebase.auth.GoogleAuthProvider();
  //   //console.log(provider);
  //   firebase
  //     .auth()
  //     .signInWithPopup(provider)
  //     .then(function (result) {
  //       //console.log(result);
  //       setUser(result.user);
  //       //console.log(user);
  //     });
  // }

  return (
    <Container fluid="false">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card bg="primary" text="white" style={{ marginTop: "15px" }}>
            <Card.Header className="text-center">
              <img
                src={MetisphereSymbol}
                style={{ width: "40px" }}
                alt="Metisphere Icon"
              />
            </Card.Header>
            <Card.Body>
              <Row>
                <Col
                  md={{ span: 12, offset: 0 }}
                  style={{
                    textAlign: "center",
                    marginBottom: "15px",
                    marginTop: "15px",
                  }}
                >
                  <h3>Sign Up for Your Free Trial!</h3>
                  <h5>
                    To begin the process, enter your details and your
                    organizations details!
                  </h5>
                  <div style={{ marginTop: "30px" }}>
                    {errors.map(function (error) {
                      return (
                        <Alert variant="danger" key={error}>
                          {error}
                        </Alert>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              {status !== "COMPLETE" ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email">
                    <Form.Label className="text-sm">
                      Organization Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={trial.organizationName}
                      onChange={(e) => {
                        let newTrial = { ...trial };
                        newTrial.organizationName = e.target.value;
                        setTrial(newTrial);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Label className="text-sm">Your Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={trial.orgAdminName}
                      onChange={(e) => {
                        let newTrial = { ...trial };
                        newTrial.orgAdminName = e.target.value;
                        setTrial(newTrial);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Label className="text-sm">Your Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={trial.orgAdminEmail}
                      onChange={(e) => {
                        let newTrial = { ...trial };
                        newTrial.orgAdminEmail = e.target.value;
                        setTrial(newTrial);
                      }}
                    />
                  </Form.Group>
                  {/* <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    onChange={(e) => {
                      signup.updateField("password", e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="signUpToken">
                  <Form.Label className="text-sm">Signup Token</Form.Label>
                  <Form.Control0
                    type="text"
                    value={signup.token}
                    onChange={(e) => {
                      signup.updateField("token", e.target.value);
                    }}
                  />
                </Form.Group> */}
                  {/* <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group> */}
                  <Button
                    variant="secondary"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    Start Trial
                  </Button>
                </Form>
              ) : (
                  <Alert variant="success">
                    We've recevied your trial request! Please check your email for
                    your password to your account!
                  </Alert>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SignUp;
