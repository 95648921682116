import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Modal, Container } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";

import { db } from "./../../../config/firebase";

import { api } from "./../../../core/logging/utils/api";

import { ContentDetail } from "./../../skills/components/common";
import { useAuthState } from "../../../providers/AuthState";

function ApprovalDetailLayout() {
  const [approval, setApproval] = useState(null);
  const [skillStep, setSkillStep] = useState(null);
  const [comments, setComments] = useState("");

  const [autoText, setAutoText] = useState("");
  const [show, setShow] = useState(false);
  const [saveResponse, setSaveResponse] = useState(false);
  const [responseScope, setResponseScope] = useState("Generic");
  const [visibility, setVisibility] = useState("Public");

  const [responses, setResponses] = useState([]);

  const { approvalId } = useParams();
  const { orgProfile, orgContext } = useAuthState();

  let history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent && approvalId) {
      db.doc(
        `${orgProfile.ref.parent.parent.path}/experienceApprovals/${approvalId}`
      )
        .get()
        .then((approvalDoc) => {
          const approval = {
            ...approvalDoc.data(),
            id: approvalDoc.id,
            ref: approvalDoc.ref,
          };
          setApproval(approval);
          return approval;
        })
        .then((approval) => {
          approval.step.ref.get().then((stepDoc) => {
            setSkillStep({
              ...stepDoc.data(),
              id: stepDoc.id,
              ref: stepDoc.ref,
            });
          });
        });
    }
    return () => {
      isCurrent = false;
    };
  }, [approvalId, orgProfile.ref.path]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent && approval && orgProfile) {
      const visibilityVals = ["public", orgProfile.id];
      const scopeVals = ["generic", approval.step.id];

      db.collection(`${orgProfile.ref.parent.parent.path}/savedResponses`)
        .where("visibility", "in", visibilityVals)
        .get()
        .then((snapshotDocs) => {
          const responseDocs = [];
          snapshotDocs.forEach((doc) => {
            if (scopeVals.includes(doc.data().scope)) {
              responseDocs.push({
                status: doc.data().status,
                response: doc.data().response,
                id: doc.id,
              });
            }
          });

          setResponses(responseDocs);
        });
    }

    return () => {
      isCurrent = false;
    };
  }, [approval, orgProfile]);

  function saveExperience(status, experienceDoc) {
    return approval.ref.update({ "experience.status": status }).then(() => {
      return approval.experience.ref.update(experienceDoc).then(() => {
        if (saveResponse) {
          orgProfile.ref.parent.parent
            .collection("savedResponses")
            .add({
              response: comments,
              visibilityType: visibility,
              visibility: visibility !== "Public" ? orgProfile.id : "public",
              scope: responseScope !== "Generic" ? approval.step.id : "generic",
              author: orgProfile.id,
              status: status,
            })
            .then(() => {
              //console.log("---[Success] Response Saved");
            });
        }
      });
    });
  }

  function approveExperience() {
    saveExperience("Approved", {
      status: "Approved",
      approverComments: comments,
      dateApproved: new Date(),
    })
      .then((experienceDoc) => {
        return api.logging.createLogEntry(
          orgContext.id,
          api.logging.TYPES.EXPERIENCE.APPROVE,
          {
            message: `${orgProfile.displayName} has approved an experience.`,
            related: approval.experience.ref,
            response: "SUCCESS",
          }
        );
      })
      .then(() => {
        history.push("/approvals");
      });
  }

  function returnExperience() {
    saveExperience("Returned", {
      status: "Returned",
      approverComments: comments,
      dateReturned: new Date(),
    })
      .then((experienceDoc) => {
        return api.logging.createLogEntry(
          orgContext.id,
          api.logging.TYPES.EXPERIENCE.RETURN,
          {
            message: `${orgProfile.displayName} has returned an experience.`,
            related: approval.experience.ref,
            response: "SUCCESS",
          }
        );
      })
      .then(() => {
        history.push("/approvals");
      });
  }

  function insertText() {
    setComments(comments + autoText);
    handleClose();
  }

  return (
    approval &&
    skillStep && (
      <div>
        <h4>
          Approval for{" "}
          {`${approval.skill.code}.${approval.step.stageOrder || "-"}.${
            approval.step.order || "-"
          }`}{" "}
          {approval.step.name} by {approval.submitter.name}
        </h4>
        <div style={{ marginBottom: "25px", marginTop: "25px" }}>
          <div
            style={{
              fontSize: "1.1rem",
              borderBottom: "1px solid #6c757d",
              marginBottom: "10px",
            }}
          >
            Employee Experience
          </div>
          <p style={{ whiteSpace: "pre-line" }}>
            {approval.experience.experienceLog}
          </p>
          {approval.experience.attachmentURL && (
            <div>
              {/* Erich Comment JULY142020: Added rel="noopener noreferrer" to
                    target blank links to cover security risks detailed here:
                    https://mathiasbynens.github.io/rel-noopener */}
              <a
                href={approval.experience.attachmentURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Attachment
              </a>
            </div>
          )}
        </div>
        <Form>
          <Form.Row>
            <Col>
              <Form.Group controlId="approverComments">
                <div style={{ marginBottom: "5px" }}>
                  <div className="float-left">
                    <Form.Label>Approver Comments</Form.Label>
                  </div>
                  <div className="float-right">
                    <Button
                      style={{ marginBottom: "5px" }}
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        handleShow();
                      }}
                    >
                      Saved Responses
                    </Button>
                  </div>
                </div>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Comments"
                  value={comments}
                  onChange={(e) => {
                    setComments(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="saveResponse">
                <Form.Check
                  type="switch"
                  label="Save Response"
                  checked={saveResponse}
                  onChange={(e) => {
                    setSaveResponse(e.target.checked);
                  }}
                />
              </Form.Group>
              {saveResponse && (
                <Form.Group controlId="responseScope">
                  <Form.Label>Scope</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setResponseScope(e.target.value);
                    }}
                    value={responseScope}
                  >
                    <option>Generic</option>
                    <option>Step Specific</option>
                  </Form.Control>
                </Form.Group>
              )}
              {saveResponse && (
                <Form.Group controlId="visibility">
                  <Form.Label>Visibility</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setVisibility(e.target.value);
                    }}
                    value={visibility}
                  >
                    <option>Public</option>
                    <option>Private</option>
                  </Form.Control>
                </Form.Group>
              )}
            </Col>
          </Form.Row>
          <Row>
            <Col>
              <div className="float-right" style={{ marginTop: "10px" }}>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    approveExperience();
                  }}
                >
                  Approve
                </Button>{" "}
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    returnExperience();
                  }}
                >
                  Return
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <hr></hr>
        <Row style={{ marginTop: "25px" }}>
          <Col>
            <h6 style={{ fontSize: "1.2rem" }}>{skillStep.name} Details</h6>
            <ContentDetail
              heading={"Approval Type"}
              content={skillStep.approvalType}
            />
            <ContentDetail
              heading={"Evidence Required"}
              content={skillStep.evidenceRequired}
            />
            <ContentDetail
              heading={"Coaching Guidelines"}
              content={skillStep.coachingGuidelines}
            />
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  {responses.map((response, ind) => {
                    return (
                      <div
                        style={{
                          border: `1px solid ${
                            response.response === autoText
                              ? "#461987"
                              : response.status === "Approved"
                              ? "green"
                              : response.status === "Returned"
                              ? "red"
                              : "#aaa"
                          }`,
                          backgroundColor:
                            response.response === autoText ? "#eee" : "white",
                          borderRadius: "5px",
                          padding: "10px",
                          marginBottom: "10px",
                          whiteSpace: "pre",
                        }}
                        onClick={() => {
                          setAutoText(response.response);
                        }}
                      >
                        {response.response}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={insertText}>
              Insert
            </Button>
            <Button variant="outline-secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
}

export default ApprovalDetailLayout;
