import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";

import { PageTitle, SubTitle } from "../common";

import { useSkillPathForm } from "./hooks";
import { SkillPath } from "../../utils/classes/skill-path";

import { useAuthState } from "./../../../../providers/AuthState";

function displayForm(skillPathId, skillPathForm) {
  if (!skillPathId) {
    return true;
  } else if (skillPathId === skillPathForm.codeToCheck) {
    return true;
  }
  return false;
}

function PathForm() {
  const [saveToDB, setSaveToDB] = useState(true);

  const { adminOrg, superuser } = useAuthState();

  let vendorId = "";
  if (superuser) {
    vendorId = "metisphere";
  }
  else if (adminOrg) {
    vendorId = adminOrg.id;
  }

  const { skillPathId, orgId } = useParams();
  const skillPath = useSkillPathForm(skillPathId, vendorId, orgId);

  let history = useHistory();

  // Cancel the form and go back to previous page
  function handleCancel() {
    history.goBack();
  }

  // Handle form submission
  function handleSubmit(event) {
    const isEdit = skillPathId === skillPath.codeToCheck;
    event.preventDefault();
    if (saveToDB) {
      const skillPathInstance = skillPath.getSkillPathInstance();

      if (skillPathInstance.id === "staged") {
        skillPathInstance.id = skillPathInstance.ref.parent.parent.id;
      }

      skillPathInstance
        .save(orgId)
        .then(() => {
          // history.push("/skills");
          if (isEdit) {
            if (orgId) {
              history.push(`/organization/${orgId}/skills/${skillPath.code}`);
            } else {
              history.push(`/skills/${skillPath.code}`);
            }
          } else {
            history.goBack();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  return (
    displayForm(skillPathId, skillPath) && (
      <Row>
        <Col>
          <PageTitle
            title={skillPath.id ? `Edit ${skillPath.code}` : "Create"}
            pretitle="Overview"
          />
          {/* <Form.Group controlId="saveToDB">
            <Form.Check
              type="switch"
              label="Save to DB"
              defaultChecked={saveToDB}
              onChange={(e) => {
                setSaveToDB(e.target.checked);
              }}
            />
          </Form.Group> */}
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Col md={4}>
                <Form.Group controlId="pathName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Path Name"
                    defaultValue={skillPath.name}
                    onChange={(e) => {
                      skillPath.updateField("name", e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="pathCode">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Path Code"
                    defaultValue={skillPath.code}
                    onChange={(e) => {
                      skillPath.updateField("code", e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={4}>
                <Form.Group controlId="status">
                  <Form.Label>Skill Path Status</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Status"
                    defaultValue={skillPath.status}
                    onChange={(e) => {
                      skillPath.updateField("status", e.target.value);
                    }}
                  >
                    {SkillPath.getActiveStatuses().map((status, ind) => {
                      return <option key={ind}>{status}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="availability">
                  <Form.Label>Availability</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Availability"
                    defaultValue={skillPath.availability}
                    onChange={(e) => {
                      skillPath.updateField("availability", e.target.value);
                    }}
                  >
                    <option>Private</option>
                    <option>Public</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={8}>
                <Form.Group controlId="objective">
                  <Form.Label>Skill Path Objective</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Objective"
                    defaultValue={skillPath.objective}
                    onChange={(e) => {
                      skillPath.updateField("objective", e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={6}>
                <SubTitle title="Threads" />
                <Form.Text className="text-muted">
                  Threads group similar skills across stages.
                </Form.Text>
                {skillPath.threads.map((thread, ind) => {
                  return (
                    <Form.Row key={ind}>
                      <Col md={6}>
                        <Form.Group controlId={`thread_${ind}`}>
                          <Form.Control
                            type="text"
                            placeholder="Thread"
                            value={thread.name}
                            onChange={(e) => {
                              const threads = [...skillPath.threads];
                              threads[ind].name = e.target.value;
                              skillPath.updateField("threads", threads);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => {
                            skillPath.removeThreadRow(ind);
                          }}
                        >
                          -
                        </Button>
                      </Col>
                    </Form.Row>
                  );
                })}
                <Form.Row>
                  <Col md={{ span: 1, offset: 6 }}>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        skillPath.addThreadRow();
                      }}
                    >
                      +
                    </Button>
                  </Col>
                </Form.Row>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={1}>
                <Button variant="outline-primary" type="submit">
                  Save
                </Button>
              </Col>
              <Col md={1}>
                <Button variant="outline-danger" onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    )
  );
}

export default PathForm;
