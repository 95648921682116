import { db } from "./../../../config/firebase";

const TYPES = {
  EXPERIENCE: {
    SUBMIT: "SUBMIT_EXPERIENCE",
    APPROVE: "APPROVE_EXPERIENCE",
    RETURN: "RETURN_EXPERIENCE",
    RESUBMIT: "RESUBMIT_EXPERIENCE",
  },
  WISDOM: {
    SUBMIT: "SUBMIT_WISDOM",
    APPROVE: "APPROVE_WISDOM",
    RETURN: "RETURN_WISDOM",
    RESUBMIT: "RESUBMIT_WISDOM",
  },
};

function createLogEntry(orgId, type, data) {
  console.log(orgId, type, data);
  return db.collection(`organizations/${orgId}/logs`).add({
    created: new Date(),
    type: type,
    ...data,
  });
}

export default {
  TYPES,
  createLogEntry,
};
