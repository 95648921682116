import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import Invite from "../../utils/classes/invite";

import { useAuthState } from "../../../../providers/AuthState";

import { useOrganizationForm } from "../hooks";
import { scryRenderedComponentsWithType } from "react-dom/test-utils";

function InviteTeamMembers({ setStep }) {
  const { user, orgContext } = useAuthState();

  let newInvite = new Invite({ isSME: "Yes" });
  const [invites, setInvites] = useState([newInvite]);
  const [inviteErrors, setInviteErrors] = useState([]);

  const [pendingManagers, setPendingManagers] = useState([]);
  const [pendingLeaners, setPendingLearners] = useState([]);
  const [invitesValid, setInvitesValid] = useState(false);
  const [alertVariant, setAlertVariant] = useState("info");

  function updateValues(current, field, value, index, setter) {
    let newObject = [...current];
    newObject[index].updateField(field, value);
    setter(newObject);
  }

  const organization = useOrganizationForm(orgContext.id);

  function handleInvite() {
    //console.log("[GET INVITE ERRORS]");
    let errors = organization.getInviteErrors(invites);
    if (errors.length === 0) {
      organization.addInvites(invites);
      invites.forEach((invite) => {
        invite.clear();
      });
      setInvites(invites);
      setInviteErrors([]);
    }
    setInviteErrors(errors);
    //organization.addInvites(invites);
  }

  function deleteInvite(invite) {

    organization.deleteInvite(invite);
  }

  function skipStep() {
    setTimeout(function () {
      setStep(3);
    }, 1000);
  }

  function nextStep() {
    setTimeout(function () {
      validateInviteComplete()
    }, 1000);
  }


  function recalculateTypes() {
    let managers = [];
    let learners = [];
    organization.invites.forEach(function (invite) {
      if (invite.isManager == "Yes") {
        managers.push(invite);
      } else {
        learners.push(invite);
      }
    });
    setPendingManagers(managers);
    setPendingLearners(learners);
    if (managers.length > 0 && learners.length > 0) {
      setInvitesValid(true);
    } else {
      setInvitesValid(false);
    }
  }

  useEffect(() => {
    recalculateTypes();
    if (invitesValid) {
      setAlertVariant("success");
    } else {
      setAlertVariant("danger");
    }
    console.log("USE EFFECT")
  }, [organization.invites, invitesValid]);

  function validateInviteComplete() {
    recalculateTypes();
    if (invitesValid) {
      setStep(3);
    }
  }

  return (
    <div>
      {" "}
      <div style={{ minHeight: "400px" }}>
        {Array.isArray(inviteErrors) &&
          inviteErrors.map((inviteError) => {
            if (inviteError !== "") {
              return <Alert variant="danger">{inviteError}</Alert>;
            }
            return "";
          })}
        {organization.users ? <Alert variant={alertVariant} style={{ textAlign: "center" }}>
          <b>Please note:</b> You have a total of 5 trial licenses available to distribute.  You must add at least one admin, one manager and
          one learner before proceeding!
          <table style={{ marginTop: "10px", width: "100%" }}>
            <tr>
              <td>
                <small>MANAGERS</small>
                <br />
                <b>{pendingManagers.length}/1</b>
              </td>
              <td>
                <small>LEARNERS</small>
                <br />
                <b>{pendingLeaners.length}/1</b>
              </td>
              <td>
                <small>TOTAL INVITES</small>
                <br />
                <b>{organization.users.length}/5</b>
              </td>
            </tr>
          </table>
        </Alert> : ""}
        <Form>
          <ListGroup as="ul" style={{ marginBottom: "15px" }}>
            {Array.isArray(invites) &&
              invites.map((invite, index) => {
                return (
                  <ListGroup.Item as="li" key={index}>
                    <Form.Group controlId="inviteForm.name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        size="sm"
                        value={invite.displayName}
                        onChange={(e) => {
                          updateValues(
                            invites,
                            "displayName",
                            e.target.value,
                            index,
                            setInvites
                          );
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="inviteForm.email">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        size="sm"
                        value={invite.email}
                        onChange={(e) => {
                          updateValues(
                            invites,
                            "email",
                            e.target.value,
                            index,
                            setInvites
                          );
                        }}
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="inviteForm.email">
                    <Form.Label>SME</Form.Label>
                    <Form.Control
                      as="select"
                      value={invite.isSME}
                      onChange={(e) => {
                        updateValues(
                          invites,
                          "isSME",
                          e.target.value,
                          index,
                          setInvites
                        );
                      }}
                    >
                      <option>No</option>
                      <option>Yes</option>
                    </Form.Control>
                  </Form.Group> */}
                    <Form.Group controlId="inviteForm.email">
                      <Form.Label>Manager</Form.Label>
                      <Form.Control
                        as="select"
                        value={invite.isManager}
                        onChange={(e) => {
                          updateValues(
                            invites,
                            "isManager",
                            e.target.value,
                            index,
                            setInvites
                          );
                        }}
                      >
                        <option>No</option>
                        <option>Yes</option>
                      </Form.Control>
                    </Form.Group>
                    <Button
                      variant="outline-primary"
                      onClick={handleInvite}
                      size="sm"
                      className="float-right"
                      style={{ marginTop: "10px" }}
                    >
                      Invite
                    </Button>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Form>
        <h5>Users</h5>
        <div></div>
        <ListGroup as="ul" style={{ marginBottom: "10px" }}>
          <ListGroup.Item
            as="li"
            style={{
              padding: ".5rem .75rem",
              cursor: "pointer",
            }}
          >
            <Row>
              <Col xs={6}>
                <small>{user.email} - Admin</small>
              </Col>
              <Col xs={6}></Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
        <h5>Pending Invites {organization.invites.length} </h5>
        {organization.invites.length === 0 ? (
          <Alert variant="secondary">The are no pending invites.</Alert>
        ) : (
            " "
          )}
        <ListGroup as="ul" style={{ marginBottom: "30px" }}>
          {Array.isArray(organization.invites) &&
            organization.invites.map((invite) => {
              //console.log("[THIS IS AN INVITE] ", invite);
              return (
                <ListGroup.Item
                  as="li"
                  key={invite.id}
                  style={{ padding: ".5rem .75rem", cursor: "pointer" }}
                >
                  <Row>
                    <Col xs={6}>
                      <small>
                        {invite.email} -{" "}
                        {invite.isManager === "Yes" ? "Manager" : "Learner"}{" "}
                      </small>
                    </Col>
                    <Col xs={6}>
                      <Button
                        variant="outline-danger"
                        className="float-right"
                        size="sm"
                        onClick={(e) => {
                          deleteInvite(invite);
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </div>
      <Row>
        <Col xs={6}></Col>
        <Col xs={6}>
          <div className="text-right">
            {/* <Button
              variant="outline-secondary"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setStep(2);
              }}
            >
              Back
            </Button> */}
            <Button variant="outline-secondary" onClick={skipStep} style={{ marginRight: "5px" }}>
              Skip Step and Add Later
            </Button>
            <Button variant="outline-primary" onClick={nextStep}>
              Next Step
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default InviteTeamMembers;
