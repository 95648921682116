import React, { useContext, useEffect, useState } from "react";

import { api } from "./../../utils/api";

import { SkillPath } from "../../utils/classes/skill-path";

const SkillPathContext = React.createContext();

// function skillPathReducer(state, action) {
//   switch (action.type) {
//     case "ADD_STAGE": {
//       // Update stage and path
//       return { ...state };
//     }
//     case "ADD_STEP": {
//       // Update step and stage
//       return { ...state };
//     }
//     case "SET_ACTIVE_STAGE": {
//       return { ...state, activeStage: action.stageId };
//     }

//     default:
//       return state;
//   }
// }

// MAKE A REDUCER?
export function SkillPathProvider({
  children,
  code,
  version = "view",
  orgId,
  addingContent = false,
}) {
  const [skillPath, setSkillPath] = useState(new SkillPath());

  useEffect(() => {
    let isCurrent = true;
    if (!code) {
      if (isCurrent) {
        setSkillPath(new SkillPath({}));
      }
      return () => {
        isCurrent = false;
      };
    }

    if (orgId) {
      if (isCurrent && version === "edit") {
        api.skillPath.getStagedSkillPathByCodeForOrg(code, orgId, setSkillPath);
      } else {
        api.skillPath.getSkillPathByCodeForOrg(code, orgId, setSkillPath);
      }
    } else {
      if (isCurrent && version === "edit") {
        api.skillPath.getStagedSkillPathByCode(code, setSkillPath);
      } else {
        api.skillPath.getSkillPathByCode(code, setSkillPath);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [code]);

  const value = {
    skillPath,
    setSkillPath,
    addingContent,
  };
  return <SkillPathContext.Provider value={value} children={children} />;
}

export function useSkillPathContext() {
  return useContext(SkillPathContext);
}
