import { firebase } from "../../../../config/firebase";

class Recognition {
  constructor(
    recognizedTo,
    recognizedToDisplayName,
    recognizedFrom,
    recognizedFromDisplayName,
    path,
    pathDisplayName,
    stage,
    stageDisplayName,
    step,
    stepDisplayName,
    description,
    created,
    updated,
    witness,
    escalation,
    managers,
    likes,
    numLikes
  ) {
    this.recognizedTo = recognizedTo || "";
    this.recognizedToDisplayName = recognizedToDisplayName || "";
    this.recognizedFrom = recognizedFrom || "";
    this.recognizedFromDisplayName = recognizedFromDisplayName || "";
    this.path = path || "";
    this.pathDisplayName = pathDisplayName || "";
    this.stage = stage || "";
    this.stageDisplayName = stageDisplayName || "";
    this.step = step || "";
    this.stepDisplayName = stepDisplayName || "";
    this.description = description || "";
    this.created = created || firebase.firestore.Timestamp.now();
    this.updated = firebase.firestore.Timestamp.now();
    this.witness = witness || "";
    this.escalation = escalation || false;
    this.managers = managers || [];
    this.likes = likes || [];
    this.numLikes = numLikes || 0;

    this.setField = function (field, value) {
      this[field] = value;
    };
  }
}

const recognitionConverter = {
  toFirestore(recognition) {
    return {
      recognizedTo: recognition.recognizedTo,
      recognizedToDisplayName: recognition.recognizedToDisplayName,
      recognizedFrom: recognition.recognizedFrom,
      recognizedFromDisplayName: recognition.recognizedFromDisplayName,
      path: recognition.path,
      pathDisplayName: recognition.pathDisplayName,
      stage: recognition.stage,
      stageDisplayName: recognition.stageDisplayName,
      step: recognition.step,
      stepDisplayName: recognition.stepDisplayName,
      description: recognition.description,
      created: recognition.created,
      updated: recognition.updated,
      witness: recognition.witness,
      escalation: recognition.escalation,
      managers: recognition.managers,
      likes: recognition.likes,
      numLikes: recognition.numLikes,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return new Recognition(
      data.recognizedTo,
      data.recognizedToDisplayName,
      data.recognizedFrom,
      data.recognizedFromDisplayName,
      data.path,
      data.pathDisplayName,
      data.stage,
      data.stageDisplayName,
      data.step,
      data.stepDisplayName,
      data.description,
      data.created,
      data.updated,
      data.witness,
      data.escalation,
      data.managers,
      data.likes,
      data.numLikes
    );
  },
};

export { Recognition, recognitionConverter };
