import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  DropdownButton,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import { MainHeading } from "../../../core/helpers/layout-components";

import { db } from "./../../../config/firebase";

// import { ContentDetail } from "./../../skills/components/common";
import { useAuthState } from "../../../providers/AuthState";

import UserSkillProgress from "./user-skill-progress";
import UserSkillCycles from "./user-skill-cycles";

// const REPORT_TYPES = {
//   PROGRESS: "PROGRESS",
//   CYCLES: "CYCLES",
// };

function DefaultReportLayout() {
  const [activeSkill, setActiveSkill] = useState(null);
  const [orgSkills, setOrgSkills] = useState([]);
  //const [selectedReport, setSelectedReport] = useState();

  const { orgProfile } = useAuthState();

  let { path } = useRouteMatch();

  useEffect(() => {
    if (orgProfile) {
      db.collection(`${orgProfile.ref.parent.parent.path}/skills`)
        .get()
        .then((snapshotDocs) => {
          const skills = [];
          snapshotDocs.forEach((doc) => {
            skills.push({
              ...doc.data(),
              id: doc.id,
              ref: doc.ref,
            });
          });

          setOrgSkills(skills);
        });
    }
  }, [orgProfile]);

  return (
    <div>
      <Row style={{ marginBottom: "15px" }}>
        <Col md={6}>
          <MainHeading title="My Team" />
        </Col>
        <Col>
          <div className="float-right">
            <Link to="/progress" className="btn btn-outline-primary btn-sm">
              View All
            </Link>
            {/* <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                setActiveSkill(null);
              }}
            >
              View All
            </Button> */}
          </div>
        </Col>
      </Row>
      <Switch>
        <Route path={path} exact>
          <Row>
            {orgSkills.map((skill) => {
              return (
                <Col xs={4}>
                  <SkillCard
                    cardTitle={skill.name}
                    cardCode={skill.code}
                    cardObjective={`${skill.objective.substring(0, (skill.objective.length > 100 ? 100 : skill.objective.length))}${skill.objective.length > 100 ? '...' : ''}`}
                    setActiveSkill={setActiveSkill}
                    activeSkill={activeSkill}
                  />
                </Col>
              );
            })}
          </Row>
        </Route>
        <Route path="/progress/progress/:skillId" exact>
          <UserSkillProgress orgId={orgProfile.ref.parent.parent.id} />
        </Route>

        <Route path="/progress/cycles/:skillId" exact>
          <UserSkillCycles orgId={orgProfile.ref.parent.parent.id} />
        </Route>
      </Switch>
    </div>
  );
}

function SkillCard({ cardTitle, cardCode, cardObjective, setActiveSkill }) {
  let { url } = useRouteMatch();

  return (
    <Card style={{ minHeight: "250px" }}>
      <Card.Body>
        <Card.Title>{cardTitle}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{cardCode}</Card.Subtitle>
        <Card.Text>{cardObjective}</Card.Text>
      </Card.Body>
      <Card.Footer>
        {/* <Button
          onClick={() => {
            setActiveSkill(cardCode);
          }}
          variant="outline-primary"
        >
          View Team Progress
        </Button> */}
        <DropdownButton
          variant="outline-primary"
          as={ButtonGroup}
          title="View"
          id="bg-nested-dropdown"
        >
          <Dropdown.Item eventKey="1">
            <Link to={`${url}/progress/${cardCode}`}>Progress</Link>
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            <Link to={`${url}/cycles/${cardCode}`}>Cycles</Link>
          </Dropdown.Item>
        </DropdownButton>
      </Card.Footer>
    </Card>
  );
}

export default DefaultReportLayout;
