import React, { useContext, useReducer, useCallback } from "react";

const AuthStateContext = React.createContext();

const initialState = {
  authenticated: false,
  complete: false,
  user: null,
  profile: null,
  orgContext: null,
};

export function isSuperUser(userType) {
  return userType === "SUPERUSER" ? true : false;
}

export function AuthStateProvider({ children }) {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "LOGIN": {
        ////console.log("Dispatch Login", action);
        // let profile = getUserDetails(user);
        return {
          ...state,
          authenticated: true,
          complete: true,
          user: action.user,
          profile: action.profile,
          profileType: action.profileType,
          adminOrg: action.adminOrg,
          orgUsers: action.orgUsers,
          orgManagers: action.orgManagers,
          orgProfile: action.orgProfile,
          orgContext: action.orgContext,
          superuser: isSuperUser(action.profile.type),
          orgApprovalGroups: action.orgApprovalGroups,
          userApprovalGroups: action.userApprovalGroups,
          skillPaths: action.skillPaths,
          loggedInTime: action.loggedInTime,
          wisdomManagerForSkills: action.wisdomManagerForSkills
        };
      }
      case "AUTH_LOADED": {
        ////console.log("Dispatch Login", action);
        // let profile = getUserDetails(user);
        return { ...state, authenticated: false, complete: true, user: null };
      }
      case "LOGOUT": {
        return { ...initialState, complete: false };
      }
      default:
        return state;
    }
  }, initialState);

  const value = {
    ...state,
    dispatch: useCallback(dispatch, []),
  };

  return <AuthStateContext.Provider value={value} children={children} />;
}

export function useAuthState() {
  return useContext(AuthStateContext);
}
