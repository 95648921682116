import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Modal,
  Badge,
  Container,
  Jumbotron
} from "react-bootstrap";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from "draft-js";

import { db } from "./../../../config/firebase";
import { api } from "./../../skills/utils/api";

import { isSuperUser, useAuthState } from "./../../../providers/AuthState";

import { RichTextEditor } from "./../../../common/components";

function WisdomSkillListLayout({ orgId }) {
  const [orgSkills, setOrgSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);

  const [allContent, setAllContent] = useState([]);
  const [sortedContent, setSortedContent] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterActiveWisdom, setFilterActiveWisdom] = useState("");
  const [selectedWisdom, setSelectedWisdom] = useState(null);
  const [showWisdomDetailModal, setShowWisdomDetailModal] = useState(false);

  const [wisdomIsActive, setWisdomIsActive] = useState(true);
  const [activeWisdomEditorState, setActiveWisdomEditorState] = useState(() => {
    return EditorState.createEmpty();
  });

  const { orgProfile, orgContext, adminOrg, wisdomManagerForSkills, profileType } = useAuthState();

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      db.collection(`organizations/${orgId}/skills`)
        .get()
        .then((snapshot) => {
          const skills = [];
          snapshot.forEach((skillDoc) => {
            if (adminOrg || wisdomManagerForSkills.some(skillId => { return skillId === skillDoc.data().id; }) || isSuperUser(profileType)) {
              skills.push({
                ...skillDoc.data(),
                // id: skillDoc.id, Don't override the id from the db
                ref: skillDoc.ref,
              });
            }
          });

          setOrgSkills(skills);
        });
    }

    return () => {
      isCurrent = false;
    };
  }, [orgId]);

  function convertSortedMapToArr(sortedByStageAndSkillMap) {
    const sortedContent = [];

    for (const stageKey in sortedByStageAndSkillMap) {
      const sortedSteps = [];
      for (const stepKey in sortedByStageAndSkillMap[stageKey].steps) {
        sortedSteps.push(sortedByStageAndSkillMap[stageKey].steps[stepKey]);
      }
      const newStage = {
        ...sortedByStageAndSkillMap[stageKey],
        steps: sortedSteps
          .filter((sortStep) => {
            return sortStep.content.length > 0;
          })
          .sort((left, right) => {
            return left.order - right.order;
          }),
      };
      if (newStage.steps.length > 0) {
        sortedContent.push(newStage);
      }
    }

    return sortedContent.sort((left, right) => {
      return left.order - right.order;
    });
  }

  function sortAndFilterContent(allContent, searchTerm) {
    const sortedByStageAndSkillMap = {};

    allContent.forEach((curContent) => {
      const curStage = sortedByStageAndSkillMap[curContent.stage.id] || {
        ...curContent.stage,
        steps: {},
      };
      const curStep = curStage.steps[curContent.step.id] || {
        ...curContent.step,
        content: [],
      };

      if (
        (!searchTerm ||
          curContent.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          curContent.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) &&
        (filterActiveWisdom === "" ||
          (filterActiveWisdom === "Active" && curContent.active) ||
          (filterActiveWisdom === "Inactive" && !curContent.active))
      ) {
        curStep.content.push(curContent);
      }

      curStage.steps[curContent.step.id] = curStep;
      sortedByStageAndSkillMap[curContent.stage.id] = curStage;
    });
    setSortedContent(convertSortedMapToArr(sortedByStageAndSkillMap));
  }

  function setSortedContentList(allContent) {
    const sortedByOrder = allContent.sort((left, right) => {
      return (
        left.stage.order * 1000 +
        left.step.order * 100 +
        left.order -
        (right.stage.order * 1000 + right.step.order * 100 + right.order)
      );
    });
    setAllContent(sortedByOrder);
  }

  useEffect(() => {
    let isCurrent = true;

    if (selectedSkill && isCurrent) {
      let isCustom = false;
      const selectedSkillDoc = orgSkills.find(orgSkill => { return orgSkill.id === selectedSkill });
      if (selectedSkillDoc.custom) {
        api.skillPath.getAllWisdomForCustomSkillPath(
          orgId,
          selectedSkill,
          setSortedContentList
        );
      }
      else {
        api.skillPath.getAllWisdomForSkillPath(
          orgId,
          selectedSkill,
          setSortedContentList
        );
      }

    }

    return () => {
      isCurrent = false;
    };
  }, [selectedSkill]);

  useEffect(() => {
    sortAndFilterContent(allContent, searchTerm);
  }, [searchTerm, filterActiveWisdom, allContent]);

  function viewWisdomDetail(wisdom) {
    setShowWisdomDetailModal(true);
    setSelectedWisdom(wisdom);

    const wisdomBody = EditorState.createWithContent(
      convertFromRaw(wisdom.body)
    );
    setActiveWisdomEditorState(wisdomBody);
    setWisdomIsActive(wisdom.active);
  }

  function handleClose() {
    setShowWisdomDetailModal(false);
    setSelectedWisdom(null);
  }

  function saveSelectedWisdom() {
    const updatedWisdomDoc = {
      title: selectedWisdom.title,
      body: convertToRaw(activeWisdomEditorState.getCurrentContent()),
      bodyText: activeWisdomEditorState.getCurrentContent().getPlainText(),
      active: wisdomIsActive,
    };

    selectedWisdom.ref.update(updatedWisdomDoc).then(() => {
      handleClose();
    })
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          <Form.Group controlId="skillList">
            <Form.Label>Select Skill</Form.Label>
            <Form.Control
              as="select"
              value={selectedSkill}
              onChange={(e) => {
                setSelectedSkill(e.target.value);
              }}
            >
              <option></option>
              {orgSkills.map((orgSkill) => {
                return <option value={orgSkill.id}>{orgSkill.name}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group controlId="wisdomStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={filterActiveWisdom}
              onChange={(e) => {
                setFilterActiveWisdom(e.target.value);
              }}
            >
              <option></option>
              <option>Active</option>
              <option>Inactive</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {selectedSkill === null && (
            <Jumbotron>
              <h5 className="text-primary" style={{ textAlign: "center" }}>
                Please select a skill to manage.
              </h5>
            </Jumbotron>
          )}
          {sortedContent.length === 0 && selectedSkill !== null && (
            <Jumbotron>
              <h5 className="text-primary" style={{ textAlign: "center" }}>
                No wisdom has been submitted for this skill.
              </h5>
            </Jumbotron>
          )}
          {sortedContent.length > 0 &&
            sortedContent.map((sortedStage) => {
              return (
                <div>
                  <h6
                    style={{
                      borderBottom: "1px solid #123",
                      marginBottom: "20px",
                    }}
                  >
                    {sortedStage.order}. {sortedStage.name}
                  </h6>
                  {sortedStage.steps.map((sortedStep) => {
                    return (
                      <div style={{ marginLeft: "10px", marginBottom: "10px" }}>
                        <h6>
                          {sortedStep.order}. {sortedStep.name}
                        </h6>
                        <Row>
                          {sortedStep.content.map((content) => {
                            return (
                              <Col sm={4} style={{ marginBottom: "15px" }}>
                                <Card
                                  style={{
                                    backgroundColor: content.active
                                      ? ""
                                      : "#eaeaea",
                                  }}
                                >
                                  <Card.Body>
                                    <Card.Title>
                                      {sortedStage.order}.{sortedStep.order}:{" "}
                                      {content.title}
                                    </Card.Title>

                                    <Card.Text style={{ height: "250px", overflow: "hidden" }}>
                                      <Editor
                                        editorState={EditorState.createWithContent(
                                          convertFromRaw(content.body)
                                        )}
                                        readOnly={true}
                                      />
                                    </Card.Text>
                                  </Card.Body>
                                  <Card.Footer>
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      onClick={() => {
                                        viewWisdomDetail(content);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </Card.Footer>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </Col>
      </Row>

      {selectedWisdom && (
        <Modal show={showWisdomDetailModal} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Wisdom Approval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div>
                <Row>
                  <Col>
                    <h4>{selectedWisdom.name}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        fontSize: "1.5rem",
                        marginBottom: "10px",
                      }}
                    >
                      <Form.Group controlId="wisdomName">
                        <Form.Control
                          type="text"
                          placeholder="Title"
                          value={selectedWisdom.title}
                          onChange={(e) => {
                            setSelectedWisdom({ ...selectedWisdom, title: e.target.value });
                            // setWisdomTitle(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col>
                    <div className="float-right">
                      <div style={{ fontSize: "1.2em" }}>
                        {selectedWisdom.tags.map((tag) => {
                          return (
                            <Badge
                              variant="primary"
                              style={{ marginRight: "3px" }}
                            >
                              {tag}
                            </Badge>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row >
                  <Col>
                    <RichTextEditor
                      editorState={activeWisdomEditorState}
                      setEditorState={setActiveWisdomEditorState}
                    />
                    {/* <Editor editorState={selectedWisdom.body} readOnly={true} /> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="saveToDB">
                      <Form.Check
                        type="switch"
                        label="Active"
                        defaultChecked={wisdomIsActive}
                        onChange={(e) => {
                          setWisdomIsActive(!wisdomIsActive);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <Form.Group controlId="saveToDB">
                      <Form.Check
                        type="switch"
                        label="Active"
                        defaultChecked={wisdomIsActive}
                        onChange={(e) => {
                          setWisdomIsActive(!wisdomIsActive);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
              </div>
              <Row>
                <Col>
                  <div className="float-right">
                    <Button variant="outline-primary" onClick={saveSelectedWisdom}>
                      Save
                        </Button>{" "}
                    <Button variant="outline-danger" onClick={handleClose}>
                      Cancel
                        </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default WisdomSkillListLayout;
