import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  ListGroup,
  Button,
  Form,
  Modal,
  Nav,
  Tab,
  Alert,
  Jumbotron,
  Container,
} from "react-bootstrap";

import { db } from "../../../../../../config/firebase";

import moment from "moment";

function OrganizationLogs({ organization, orgId }) {
  const [logs, setLogs] = useState([]);
  const [activeLog, setActiveLog] = useState(null);
  const [showLogDetails, setShowLogDetails] = useState(false);
  const [typeFilter, setTypeFilter] = useState("");
  const [logTypes, setLogTypes] = useState([]);

  useEffect(
    (e) => {
      db.collection(`organizations/${orgId}/logs`)
      .orderBy("created","desc")
        .get()
        .then(function (snapshot) {
          let data = [];
          let logTypesObj = {};
          snapshot.docs.forEach(function (doc) {
            const logType = doc.data().type;
            data.push({ ...doc.data(), id: doc.id });
            logTypesObj[logType] = true;
          });

          setLogs(data);

          const logTypesArr = Object.keys(logTypesObj).sort();
          setLogTypes(logTypesArr);
        });
    },
    [orgId]
  );

  function viewLog(log) {
    setShowLogDetails(true);
    setActiveLog(log);
  }

  function handleClose() {
    setShowLogDetails(false);
    setActiveLog(null);
  }

  return (
    <div>
      <h5 className="text-secondary font-weight-bold">Actions</h5>
      {logs.length > 0 ? (
        <div
          style={{ height: "600px", maxHeight: "600px", overflowY: "scroll" }}
        >
          <Form.Group as={Row} controlId="types">
            <Form.Label column sm="1">
              Type
            </Form.Label>
            <Col md={4}>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setTypeFilter(e.target.value);
                }}
                value={typeFilter}
              >
                <option />
                {logTypes.map((logType) => {
                  return <option>{logType}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <Table size="sm" striped bordered hover variant="dark" responsive>
            <thead>
              <td className="text-center"></td>
              <td style={{ width: "200px" }}>
                <b>Date</b>
              </td>
              <td>
                <b>Type</b>
              </td>
              <td>
                <b>Message</b>
              </td>
              <td>
                <b>Response</b>
              </td>
            </thead>
            <tbody>
              {logs
                .filter((log) => {
                  return typeFilter === "" || typeFilter === log.type;
                })
                .map(function (log, index) {
                  let responseStyle = "text-success-bright";
                  if (log.response !== "SUCCESS") {
                    responseStyle = "text-danger";
                  }
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {/* {log.created.hasOwnProperty("toDate")
                        ? moment(log.created.toDate()).format("MM DD YYYY")
                        : ""} */}
                        {moment(log.created.toDate()).format(
                          "MMM, DD YYYY  h:mm:ss a"
                        )}
                      </td>
                      <td>{log.type}</td>
                      <td>
                        {log.message}{" "}
                        {log.related ? (
                          <a
                            style={{ color: "#fff" }}
                            href="#"
                            onClick={() => {
                              viewLog(log);
                            }}
                          >
                            View
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <b className={responseStyle}>{log.response}</b>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      ) : (
        <Alert variant="secondary">No logs recorded yet.</Alert>
      )}

      {activeLog && (
        <Modal show={showLogDetails} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Log on{" "}
              {new Date(activeLog.created.seconds * 1000).toLocaleDateString()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <strong>Message:</strong>
                  <p>{activeLog.message}</p>
                </Col>
              </Row>
              {activeLog.related && (
                <Row style={{ marginBottom: "15px" }}>
                  <Col>
                    <strong>Related Path:</strong>
                    <p style={{ overflowWrap: "break-word" }}>
                      {activeLog.related.path}
                    </p>
                  </Col>
                </Row>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" size="sm" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

function OrganizationAttributeCard({
  label,
  value,
  dateValue,
  dateFromFirebase,
}) {
  let displayValue = value ? <div>{value}</div> : "";
  let displayDateValue = "";
  //console.log("[DATE] ", dateValue);
  if (dateValue) {
    //console.log(dateValue.hasOwnProperty("toDate"));
    if (!moment(dateValue).isValid()) {
      displayDateValue = moment(dateValue.toDate()).format("MMM Do YY");
    } else {
      displayDateValue = moment(dateValue).format("MMM Do YY");
    }
  }
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      {displayValue}
      <div>{displayDateValue}</div>
    </div>
  );
}

export default OrganizationLogs;
