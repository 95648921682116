import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useAuthState } from "../../providers/AuthState";

import { api } from "../../core/auth/utils/api";

import Auth from "../../core/auth/utils/classes/auth";

function OrganizationAcceptanceModal({
  currentOrgInvite,
  orgAcceptanceModalShow,
  setOrgAcceptenceModalShow,
}) {
  const { user, dispatch } = useAuthState();
  function hideOrgAcceptanceModal() {
    setOrgAcceptenceModalShow(false);
  }

  function confirmAcceptence() {
    api.organization
      .claimOrgInviteByUserId(user, currentOrgInvite)
      .then(function () {
        let auth = new Auth({ user: user });
        auth.loadContext().then(function (context) {
          dispatch(context);
        });
      });
    hideOrgAcceptanceModal();
  }
  return (
    <Modal
      size="lg"
      show={orgAcceptanceModalShow}
      onHide={hideOrgAcceptanceModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Accept Organization Invite
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          By accepting this invite to join manage Metisphere, you warrant that
          you are authorized administrator for{" "}
          <strong>{currentOrgInvite.orgName}</strong>. You acknowledge receipt
          and acceptance of Metisphere's Terms and Conditions and our Privacy
          Policy.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideOrgAcceptanceModal}>Close</Button>
        <Button variant="outline-secondary" onClick={confirmAcceptence}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OrganizationAcceptanceModal;
