import React from "react";

function MainHeading({ title, context }) {
  if (context)
    return (
      <h5 className="text-primary font-weight-bold">
        <span className="mt-1"> {title}</span>
      </h5>
    );

  return (
    <h4
      className="text-secondary"
      style={{ letterSpacing: "-.02em", fontWeight: "600" }}
    >
      {title}
    </h4>
  );
}

export { MainHeading };
