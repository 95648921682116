import React, { useEffect } from "react";
import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";

import StyleButton from "./style-button";

function Link({ contentState, entityKey, children }) {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} style={{ color: "#3b5998", textDecoration: "underline" }}>
      {children}
    </a>
  );
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

function StyleToolbar({ editorState, setEditorState }) {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const currentInlineStyles = editorState.getCurrentInlineStyle();

  function handleBlockToggle(style) {
    const newState = RichUtils.toggleBlockType(editorState, style);
    setEditorState(newState);
  }

  function handleInlineToggle(style) {
    const newState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(newState);
  }

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  useEffect(() => {
    setEditorState(EditorState.set(editorState, { decorator: decorator }));
  }, []);

  return (
    <ButtonToolbar aria-label="Toolbar with button groups">
      <ButtonGroup aria-label="BlockStyle" className="mr-2">
        <StyleButton
          isActive={blockType === "header-one"}
          onToggle={handleBlockToggle}
          style="header-one"
        >
          <span style={{ fontSize: "1em" }}>H1</span>
        </StyleButton>
        <StyleButton
          isActive={blockType === "header-two"}
          onToggle={handleBlockToggle}
          style="header-two"
        >
          <span style={{ fontSize: "0.9em" }}>H2</span>
        </StyleButton>
        <StyleButton
          isActive={blockType === "header-three"}
          onToggle={handleBlockToggle}
          style="header-three"
        >
          <span style={{ fontSize: "0.8em" }}>H3</span>
        </StyleButton>
      </ButtonGroup>
      <ButtonGroup aria-label="InlineStyle" className="mr-2">
        <StyleButton
          isActive={currentInlineStyles.has("BOLD")}
          onToggle={handleInlineToggle}
          style="BOLD"
        >
          <b>B</b>
        </StyleButton>
        <StyleButton
          isActive={currentInlineStyles.has("ITALIC")}
          onToggle={handleInlineToggle}
          style="ITALIC"
        >
          <i>I</i>
        </StyleButton>
        <StyleButton
          isActive={currentInlineStyles.has("UNDERLINE")}
          onToggle={handleInlineToggle}
          style="UNDERLINE"
        >
          <u>U</u>
        </StyleButton>
      </ButtonGroup>
      <ButtonGroup aria-label="BlockStyle" className="mr-2">
        <StyleButton
          isActive={blockType === "unordered-list-item"}
          onToggle={handleBlockToggle}
          style="unordered-list-item"
        >
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-card-list"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
            />
            <path
              fill-rule="evenodd"
              d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"
            />
            <circle cx="3.5" cy="5.5" r=".5" />
            <circle cx="3.5" cy="8" r=".5" />
            <circle cx="3.5" cy="10.5" r=".5" />
          </svg>
        </StyleButton>

        <StyleButton
          isActive={blockType === "link"}
          onToggle={() => {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
              "LINK",
              "MUTABLE",
              { url: "https://www.google.com" }
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {
              currentContent: contentStateWithEntity,
            });
            console.log(contentStateWithEntity);
            setEditorState(
              RichUtils.toggleLink(
                newEditorState,
                newEditorState.getSelection(),
                entityKey
              )
            );
          }}
          style="link"
        >
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-link"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
            <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
          </svg>
        </StyleButton>
      </ButtonGroup>
    </ButtonToolbar>
  );
}

export default StyleToolbar;
