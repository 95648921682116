import moment from "moment";

function FullDate({ date }) {
  console.log("CONVERTING DATE: ", date);
  if (date.toDate) {
    return moment(date.toDate()).format("MMM Do YYYY");
  }
  return "";
}

export { FullDate };
