import React, { useEffect } from "react";

import { ListGroup } from "react-bootstrap";

import { useAuthState } from "../../../../providers/AuthState";

import ProfileOrganiztionListLitem from "./profile-organization-list-item";

function ProfileOrganizationList() {
  const { profile } = useAuthState();

  return (
    <ListGroup>
      {profile.orgs.map(function (org) {
        return <ProfileOrganiztionListLitem org={org} />;
      })}
    </ListGroup>
  );
}

export default ProfileOrganizationList;
