import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Container,
  ButtonGroup,
  ButtonToolbar,
  Form,
  Jumbotron,
  Alert,
  Badge,
  InputGroup,
  Card
} from "react-bootstrap";

import {
  Editor
} from "draft-js";

function WisdomPane({ wisdom, canEdit, skillStep }) {
  return wisdom && (<div style={{ height: "400px" }}>
    <Row>
      <Col>
        <div
          style={{
            fontSize: "1.5rem",
            marginBottom: "10px",
          }}
        >
          <div>
            <span style={{ borderBottom: "1px solid #6c757d" }}>
              {wisdom.title}
            </span>
            {!wisdom.approved && <span style={{ fontSize: "1rem", color: "#505050" }}> - Approval Pending</span>}
          </div>
        </div>
      </Col>
      <Col>
        <div className="float-right">
          <div style={{ fontSize: "1.2em" }}>
            {wisdom.tags.map((tag) => {
              return (
                <Badge
                  variant="primary"
                  style={{ marginRight: "3px" }}
                >
                  {tag}
                </Badge>
              );
            })}
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <Editor
          editorState={wisdom.body}
          readOnly={true}
        />
      </Col>
    </Row>
  </div>);
}

export default WisdomPane;