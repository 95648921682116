import React, { useState, useEffect } from "react";

import { ListGroup } from "react-bootstrap";

import { db } from "./../../../../config/firebase";

function ProfileOrganizationList({ org }) {
  const [name, setName] = useState("");

  useEffect(
    (e) => {
      db.collection("organizations")
        .doc(org.orgId)
        .get()
        .then(function (doc) {
          let orgDetails = doc.data();
          console.log("[ORG] ", orgDetails);
          setName(orgDetails.name);
        })
        .catch(function (error) {
          console.log("[ORG ERROR] ", error);
        });
    },
    [org.orgId]
  );

  return <ListGroup.Item>{name}</ListGroup.Item>;
}

export default ProfileOrganizationList;
