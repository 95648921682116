import React from "react";
import { Link } from "react-router-dom";

import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

import Logo from "./../../metisphere-logo.svg";

import { useAuthState } from "../../providers/AuthState";

function Navigation() {
  const { authenticated, superuser, adminOrg, orgProfile, wisdomManagerForSkills } = useAuthState();

  const links = authenticated ? <SignedInLinks /> : <SignedOutLinks />;

  function SuperUserLink() {
    if (superuser) {
      return (
        <Link
          to="/superuser-console"
          className="nav-link text-uppercase font-weight-bold"
        >
          Superuser
        </Link>
      );
    } else {
      return "";
    }
  }

  function OrgLink() {
    //console.log("[ADMIN ORG] ", adminOrg);
    if (adminOrg) {
      let adminOrgUrl = "/organization/" + adminOrg.id;
      return (
        <Link
          to={adminOrgUrl}
          className="nav-link text-uppercase font-weight-bold"
        >
          Organization
        </Link>
      );
    } else {
      return "";
    }
  }

  //console.log("[ORG PROFILE] ", orgProfile);

  return (
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container>
        <Link to="/" className="navbar-brand">
          <img src={Logo} style={{ width: "100px" }} alt="Metisphere Logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {(superuser || (adminOrg && adminOrg.status !== "TRIAL")) && (
              <Link
                to="/skills"
                className="nav-link text-uppercase font-weight-bold"
              >
                Skills
              </Link>
            )}

            {authenticated && (
              <Link
                to="/skills/user"
                className="nav-link text-uppercase font-weight-bold"
              >
                My Skills
              </Link>
            )}
            {orgProfile &&
              (orgProfile.isManager === "Yes" ||
                orgProfile.isSME === "Yes") && (
                <Link
                  to="/approvals"
                  className="nav-link text-uppercase font-weight-bold"
                >
                  Approvals
                </Link>
              )}
            {(adminOrg || (!adminOrg && wisdomManagerForSkills && wisdomManagerForSkills.length > 0) || superuser) && (
              <Link
                to="/wisdom"
                className="nav-link text-uppercase font-weight-bold"
              >
                Wisdom
              </Link>
            )}
            {orgProfile && orgProfile.isManager === "Yes" && (
              <Link
                to="/progress"
                className="nav-link text-uppercase font-weight-bold"
              >
                Progress
              </Link>
            )}
            {superuser && (
              <Link
                to="/admin"
                className="nav-link text-uppercase font-weight-bold"
              >
                Admin
              </Link>
            )}
            <SuperUserLink />
            <OrgLink />
          </Nav>
          {links}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
