import React, { useState, useEffect } from "react";
import { api } from "../auth/utils/api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

function OrganizationPendingInvites({
  user,
  setCurrentOrgInvite,
  setOrgAcceptenceModalShow,
}) {
  const [pending, setPending] = useState([]);
  useEffect(() => {
    if (user) {
      api.organization.getAllOrgInvitesByUserId(user).then(function (orgs) {
        setPending(orgs);
      });
    }
  }, [user]);

  function acceptOrganizationInvite(invite) {
    setCurrentOrgInvite(invite);
    setOrgAcceptenceModalShow(true);
  }

  return (
    <div>
      {Array.isArray(pending) &&
        pending.map((invite, index) => {
          return (
            <Alert variant="secondary">
              <Row>
                <Col>
                  <div style={{ marginTop: "5px" }}>
                    You have a pending invite to manage:&nbsp;
                    <strong>{invite.orgName}</strong>!
                  </div>
                </Col>
                <Col>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    className="float-right"
                    onClick={(e) => {
                      acceptOrganizationInvite(invite);
                    }}
                  >
                    Accept
                  </Button>
                </Col>
              </Row>
            </Alert>
          );
        })}
    </div>
  );
}

export default OrganizationPendingInvites;
