import React, { useState, useEffect } from "react";

import { Row, Col, Alert, Modal, Form, Button } from "react-bootstrap";

import { firebase, db } from "../../../../../config/firebase";

import AnalyticsCard from "../../../../superusers/components/superuser-dashboard/tabs/superuser-analytics-card";
import { useAuthState } from "../../../../../providers/AuthState";

function OrganizationOverview({ organization, orgId }) {
  return (
    <Row>
      <Col xs={8}>
        <Row>
          <Col xs={12}>
            <div style={{ marginBottom: "20px" }}>
              <Row>
                <Col xs={12}>
                  <h5
                    className="text-primary"
                    style={{
                      letterSpacing: "-.02em",
                      fontWeight: "600",
                    }}
                  >
                    Organization Details
                  </h5>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col col={6}>
            <OrganizationAttributeCard
              label="Organization Name"
              value={organization.name}
              key="name"
            />
            <OrganizationAttributeCardArray
              label="Valid Domains"
              values={organization.domains}
              key="domains"
            />
          </Col>
          <Col col={6}>
            <OrganizationAttributeCard
              label="Organization Admin Name"
              value={organization.adminName}
              key="adminName"
            />
            <OrganizationAttributeCard
              label="Organization Admin Email"
              value={organization.adminEmail}
              key="adminEmail"
            />
          </Col>
          <Col xs={12}>
            <OrganizationAttributeCard
              label="Organization Notes"
              value={organization.notes ? organization.notes : "None"}
              key="notes"
            />
          </Col>
          <Col xs={12}></Col>
        </Row>
      </Col>
      <Col xs={4}>
        {Array.isArray(organization.users) &&
        organization.users.length > organization.licenses ? (
          <Alert variant="warning" className="text-center">
            <b>Compliance Warning:</b> This organization has exceed the maximum
            number of licenses. The organization must be contacted to purchase
            more licenses.
          </Alert>
        ) : (
          ""
        )}
        <AnalyticsCard
          analytic={
            Array.isArray(organization.users) ? organization.users.length : ""
          }
          analyticTitle="Users"
          analyticDescriptor="Total Active Users"
          analyticTheme="text-primary"
        />
        <AnalyticsCard
          analytic={organization.licenses}
          analyticTitle="Platform Licenses"
          analyticDescriptor="Active Platform Licenses"
          analyticTheme="text-secondary"
        />
        {/* <AnalyticsCard
          analytic="1,000"
          analyticTitle="Experiences Submitted"
          analyticDescriptor="Total Experiences Submitted"
          analyticTheme="text-info"
        /> */}
      </Col>
    </Row>
  );
}

function OrganizationAttributeCard({ label, value, args }) {
  return (
    <div style={{ paddingBottom: "15px" }} key={label}>
      <div className="text-info header-pretitle">{label}</div>
      <div>{value}</div>
    </div>
  );
}

function OrganizationAttributeCardArray({ label, values, args }) {
  return (
    <div style={{ paddingBottom: "15px" }} key={label}>
      <div className="text-info header-pretitle">{label}</div>
      {Array.isArray(values) &&
        values.map((value, index) => {
          return <div key={`$(value)-$(index)`}>{value}</div>;
        })}
    </div>
  );
}

export default OrganizationOverview;
