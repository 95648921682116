import React from "react";

function PageTitle({ title, pretitle }) {
  return (
    <div>
      <div className="text-info header-pretitle">{pretitle}</div>
      <h4
        className="text-secondary"
        style={{ letterSpacing: "-.02em", fontWeight: "600" }}
      >
        {title}
      </h4>
    </div>
  );
}

export default PageTitle;
