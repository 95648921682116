import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import AllRecognitionsLayout from "./all-recognitions";

function Recognitions() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={AllRecognitionsLayout} />
    </Switch>
  );
}

export default Recognitions;
