import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";

import { db } from "../../../../../config/firebase";

import "../../../../../style/Dashboard.scss";

function SuperUserDashboardAccounts() {
  /*
   * Form State Functionality
   * organizations - collection of all organizations on the platform
   */
  const [superusers, setSuperUsers] = useState([]);

  /*
   * Use Effect - On page load
   * Get all of the superusers in the entire platform
   * Only for use with superusers!!  DB access is restricted to superusers only
   */
  useEffect(() => {
    db.collection("superusers").onSnapshot((snapshot) => {
      const docs = [];
      snapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setSuperUsers(docs);
    });
  }, []);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Email</th>
          <th>Profile</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(superusers) &&
          superusers.map((superuser, index) => {
            return (
              <tr key={superuser.id}>
                <td>{index + 1}</td>
                <td>{superuser.displayName}</td>
                <td>{superuser.email}</td>
                <td>{superuser.profile}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

export default SuperUserDashboardAccounts;
