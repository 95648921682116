import React from "react";
import { useHistory } from "react-router-dom";

import { useAuthState } from "../../providers/AuthState";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import "./../../style/Auth.css";
import MetisphereSymbol from "./../../symbol.svg";

import { useLoginForm } from "./hooks";

function UpdatedPassword() {
  // Load boolean to tell us if Google Auth is loading
  //const [updatePasswordErrors, setUpdatePasswordErrors] = useState([]);

  // Auth Form Hook
  const auth = useLoginForm();
  const history = useHistory();

  // Get Authentication Context
  const { user, profile, authenticated } = useAuthState();

  // Fxn: Handle Auth with Username and password
  function handlePasswordUpdate(e) {
    e.preventDefault();

    if (authenticated) {
      if (auth.validatePasswordUpdate()) {
        auth.updatePassword(user, profile).then(function () {
          history.push("/");
        });
      } else {
        // let errors = auth.getPasswordUpdateErrors();
        // setUpdatePasswordErrors(errors);
      }
    }
  }

  return (
    <Container>
      <Row>
        <Col xs={12} md={{ span: 6, offset: 3 }}>
          <Card bg="primary" text="white">
            <Card.Header className="text-center">
              <img
                src={MetisphereSymbol}
                style={{ width: "40px" }}
                alt="Metisphere Icon"
              />
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12}>
                  <Form onSubmit={handlePasswordUpdate}>
                    {/* {authError ? (
                      <Alert variant="danger">{authError}</Alert>
                    ) : (
                      ""
                    )} */}
                    <Form.Group controlId="password">
                      <Form.Label className="text-sm">Password</Form.Label>
                      <Form.Control
                        type="password"
                        onChange={(e) => {
                          auth.updateField("password", e.target.value);
                        }}
                        value={auth.password}
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        onChange={(e) => {
                          auth.updateField(
                            "passwordConfirmation",
                            e.target.value
                          );
                        }}
                        value={auth.passwordConfirmation}
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}
                    <Button variant="light" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UpdatedPassword;
