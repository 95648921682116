import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Jumbotron from "react-bootstrap/Jumbotron";
import Alert from "react-bootstrap/Alert";

import moment from "moment";

import "../../../../../style/Dashboard.scss";

import ApprovalGroup from "../../../utils/classes/approvalGroup";

import { useOrgState } from "../../../../../providers/OrgState";

function OrganizationApprovals({ organization, orgId }) {
  /*
   * Form State Functionality
   * orgUsers - collection of all users in the organization on the platform
   */
  let newApprovalGroup = new ApprovalGroup({ orgId: orgId });
  const [modalApprovalGroup, setModalApprovalGroup] = useState({
    form: newApprovalGroup,
    show: false,
  });
  const [search, setSearch] = useState([]);
  const [errors, setErrors] = useState([]);
  const [currentActiveFlag, setCurrenActiveFlag] = useState(false);

  const { currentActiveApprovalGroup, dispatch } = useOrgState();

  function setCurrent(currentApprovalGroup) {
    dispatch({
      type: "SET_CURRENT_APPROVAL_GROUP",
      payload: { currentActiveApprovalGroup: currentApprovalGroup },
    });
  }

  //function showApproversModal() {}
  function handleClose(ag) {
    let mag = { ...modalApprovalGroup, show: false };
    setModalApprovalGroup(mag);
  }

  function addApprover(approverId) {
    let mag = { ...modalApprovalGroup };
    mag.form.addApprover(approverId);
    setModalApprovalGroup(mag);
  }

  function removeApprover(approverId) {
    let mag = { ...modalApprovalGroup };
    mag.form.removeApprover(approverId);
    setModalApprovalGroup(mag);
  }

  function updateApprovalGroup() {
    let mag = { ...modalApprovalGroup, show: false };
    //setModalApprovalGroup(mag);
    mag.form
      .save()
      .then(function (doc) {
        if (doc) {
          mag.form.id = doc.id;
        }
        dispatch({
          type: "SET_CURRENT_APPROVAL_GROUP",
          payload: { currentActiveApprovalGroup: mag.form },
        });

        setModalApprovalGroup(mag);
      })
      .catch(function (errors) {
        setErrors(errors);
      });
  }

  function editApprovalGroupForm(group) {
    let formApprovalGroup = new ApprovalGroup({ ...group, orgId: orgId });
    let mag = { ...modalApprovalGroup, show: true };
    mag.form = formApprovalGroup;
    setModalApprovalGroup(mag);
  }

  function createNewApprovalGroupForm() {
    let formApprovalGroup = new ApprovalGroup({ orgId: orgId });
    //console.log("[Form Approval Group]", formApprovalGroup);
    let mag = { ...modalApprovalGroup, show: true };
    mag.form = formApprovalGroup;
    setModalApprovalGroup(mag);
  }

  // if (organization.approvalGroups && currentActiveApprovalGroup) {
  //   let first = organization.approvalGroups[0];

  //   if (
  //     organization.approvalGroups.length > 0 &&
  //     currentActiveApprovalGroup.id === ""
  //   ) {
  //     dispatch({
  //       type: "SET_CURRENT_APPROVAL_GROUP",
  //       payload: {
  //         currentActiveApprovalGroup: first,
  //       },
  //     });
  //     dispatch({
  //       type: "SET_LAST_APPROVAL_GROUP",
  //       payload: {
  //         lastActiveApprovalGroup: first,
  //       },
  //     });
  //   }
  // }

  // useEffect((e) => {
  //   let smes = organization.users.filter(function (user) {
  //     return user.isSME === "Yes";
  //   });

  //   setSMEs(smes);
  // });

  // let filteredSMEs = organization.users.filter(function (user) {
  //   return user.isSME === "Yes";
  // });

  // setSMEs(filteredSMEs);

  /*
   * Use Effect - On page load
   * Get all of the superusers in the entire platform
   * Only for use with superusers!!  DB access is restricted to superusers only
   */
  useEffect(() => {
    if (organization.users.length > 0 && !currentActiveFlag) {
      let firstUser = organization.approvalGroups[0];
      dispatch({
        type: "SET_CURRENT_APPROVAL_GROUP",
        payload: {
          currentActiveApprovalGroup: firstUser,
        },
      });
      setCurrenActiveFlag(true);
    }
  }, [orgId, organization.users, currentActiveFlag]);

  if (organization.approvalGroups.length > 0) {
    return (
      <div style={{ minHeight: "450px" }}>
        <div
          style={{
            marginBottom: "10px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
          }}
        >
          <Row>
            <Col xs={6}>
              <h5
                className="text-primary"
                style={{
                  letterSpacing: "-.02em",
                  fontWeight: "600",
                }}
              >
                Approval Groups
              </h5>
            </Col>
            <Col xs={6}>
              {currentActiveApprovalGroup.id ? (
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="float-right font-weight-bold"
                  style={{ marginBottom: "5px" }}
                  onClick={(e) => {
                    setErrors([]);
                    editApprovalGroupForm(currentActiveApprovalGroup);
                  }}
                >
                  Edit Approval Group
                </Button>
              ) : (
                ""
              )}

              <Button
                variant="outline-secondary"
                size="sm"
                className="float-right font-weight-bold"
                style={{ marginRight: "5px", marginBottom: "5px" }}
                onClick={(e) => {
                  setErrors([]);
                  createNewApprovalGroupForm();
                }}
              >
                New Approval Group
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={3}>
            <Form>
              <Form.Group
                controlId="searchForm.SearchBox"
                style={{ marginBottom: "5px" }}
              >
                <Form.Control
                  type="text"
                  placeholder="Search"
                  size="sm"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
            <ListGroup as="ul" style={{ marginBottom: "5px" }}>
              {Array.isArray(organization.approvalGroups) &&
                organization.approvalGroups.map((orgApprovalGroup, index) => {
                  //console.log("Org Approver Groups: ", orgApprovalGroup);
                  let listGroupClass = "";
                  if (currentActiveApprovalGroup) {
                    if (
                      orgApprovalGroup.id === currentActiveApprovalGroup.id &&
                      (orgApprovalGroup.name.includes(search) ||
                        search.trim() === "")
                    ) {
                      listGroupClass = "text-sm active";
                    } else if (
                      orgApprovalGroup.name.includes(search) ||
                      search.trim() === ""
                    ) {
                      listGroupClass = "text-sm";
                    } else {
                      listGroupClass = "text-sm d-none";
                    }
                  } else {
                    listGroupClass = "text-sm d-none";
                  }
                  return (
                    <ListGroup.Item
                      as="li"
                      className={listGroupClass}
                      key={orgApprovalGroup.id}
                      style={{ padding: ".5rem .75rem", cursor: "pointer" }}
                      onClick={(e) => {
                        setCurrent(orgApprovalGroup);
                      }}
                    >
                      <small>{orgApprovalGroup.name}</small>
                    </ListGroup.Item>
                  );
                })}
            </ListGroup>
          </Col>
          <Col xs={9}>
            {currentActiveApprovalGroup.id ? (
              <Row>
                <Col xs={6}>
                  <OrganizationAttributeCard
                    label="NAME"
                    value={
                      currentActiveApprovalGroup
                        ? currentActiveApprovalGroup.name
                        : ""
                    }
                  />
                  <OrganizationAttributeCard
                    label="DESCRIPTION"
                    value={
                      currentActiveApprovalGroup
                        ? currentActiveApprovalGroup.description
                        : ""
                    }
                  />
                </Col>
                <Col xs={6}>
                  <OrganizationAttributeCard
                    label="CREATED DATE"
                    dateValue={
                      currentActiveApprovalGroup
                        ? currentActiveApprovalGroup.createdDate
                        : ""
                    }
                  />
                </Col>
                <Col xs={12}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Approver</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentActiveApprovalGroup &&
                        currentActiveApprovalGroup.approvers.map(
                          (approver, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  {organization.getUserDisplayNameById(
                                    approver
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : (
              <Jumbotron>
                <h1 className="text-primary">Select an approval group.</h1>
                <p>
                  To edit approval group information, or change approvers please
                  select a group.
                </p>
              </Jumbotron>
            )}
          </Col>
        </Row>
        <Modal show={modalApprovalGroup.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Approval Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errors.map(function (error) {
              return <Alert variant="danger">{error}</Alert>;
            })}
            <Form>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Approval Group Name"
                  value={
                    modalApprovalGroup.form ? modalApprovalGroup.form.name : ""
                  }
                  onChange={(e) => {
                    let mag = { ...modalApprovalGroup };
                    mag.form.updateField("name", e.target.value);
                    setModalApprovalGroup(mag);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Approval Group Description"
                  value={
                    modalApprovalGroup
                      ? modalApprovalGroup.form.description
                      : ""
                  }
                  onChange={(e) => {
                    let mag = { ...modalApprovalGroup };
                    mag.form.updateField("description", e.target.value);
                    setModalApprovalGroup(mag);
                  }}
                />
              </Form.Group>
              {organization.users.filter(function (user) {
                return user.isSME === "Yes";
              }).length === 0 ? (
                <Alert variant="primary">
                  Before creating an approval group, please ensure at least one
                  organiation user is an SME.
                </Alert>
              ) : (
                ""
              )}
              <ListGroup as="ul" style={{ marginBottom: "15px" }}>
                {Array.isArray(organization.users) &&
                  organization.users
                    .filter(function (user) {
                      return user.isSME === "Yes";
                    })
                    .map((user, index) => {
                      if (Array.isArray(modalApprovalGroup.form.approvers)) {
                        let approverBtn = !modalApprovalGroup.form.approvers.includes(
                          user.id
                        ) ? (
                          <Button
                            variant="primary"
                            className="float-right"
                            size="sm"
                            onClick={(e) => {
                              addApprover(user.id);
                            }}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            variant="danger"
                            className="float-right"
                            size="sm"
                            onClick={(e) => {
                              //console.log("Remove!");
                              removeApprover(user.id);
                            }}
                          >
                            Remove
                          </Button>
                        );
                        return (
                          <ListGroup.Item as="li" key={index}>
                            {user.displayName}
                            {approverBtn}
                          </ListGroup.Item>
                        );
                      }
                      return "";
                    })}
              </ListGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                updateApprovalGroup(modalApprovalGroup);
              }}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    return (
      <Jumbotron>
        <h1>No Approval Groups Created Yet.</h1>
        <p>To start creating approval groups, please click below.</p>
        <p>
          <Button
            variant="outline-primary"
            onClick={createNewApprovalGroupForm}
          >
            Create Approval Group
          </Button>
        </p>
        <Modal show={modalApprovalGroup.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Approval Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Approval Group Name"
                  value={
                    modalApprovalGroup.form ? modalApprovalGroup.form.name : ""
                  }
                  onChange={(e) => {
                    let mag = { ...modalApprovalGroup };
                    mag.form.updateField("name", e.target.value);
                    setModalApprovalGroup(mag);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Approval Group Description"
                  value={
                    modalApprovalGroup
                      ? modalApprovalGroup.form.description
                      : ""
                  }
                  onChange={(e) => {
                    let mag = { ...modalApprovalGroup };
                    mag.form.updateField("description", e.target.value);
                    setModalApprovalGroup(mag);
                  }}
                />
              </Form.Group>
              {organization.users.filter(function (user) {
                return user.isSME === "Yes";
              }).length === 0 ? (
                <Alert variant="primary">
                  Before creating an approval group, please ensure at least one
                  organiation user is an SME.
                </Alert>
              ) : (
                ""
              )}
              <ListGroup as="ul" style={{ marginBottom: "15px" }}>
                {organization.users
                  .filter(function (user) {
                    return user.isSME === "Yes";
                  })
                  .map((user, index) => {
                    if (Array.isArray(modalApprovalGroup.form.approvers)) {
                      let approverBtn = !modalApprovalGroup.form.approvers.includes(
                        user.id
                      ) ? (
                        <Button
                          variant="primary"
                          className="float-right"
                          size="sm"
                          onClick={(e) => {
                            addApprover(user.id);
                          }}
                        >
                          Add
                        </Button>
                      ) : (
                        <Button
                          variant="danger"
                          className="float-right"
                          size="sm"
                          onClick={(e) => {
                            //console.log("Remove!");
                            removeApprover(user.id);
                          }}
                        >
                          Remove
                        </Button>
                      );
                      return (
                        <ListGroup.Item as="li" key={index}>
                          {user.displayName}
                          {approverBtn}
                        </ListGroup.Item>
                      );
                    }
                    return "";
                  })}
              </ListGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                updateApprovalGroup(modalApprovalGroup);
              }}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Jumbotron>
    );
  }
}

function OrganizationAttributeCard({
  label,
  value,
  dateValue,
  dateFromFirebase,
}) {
  let displayValue = value ? <div>{value}</div> : "";
  let displayDateValue = "";
  //console.log("[DATE] ", dateValue);
  if (dateValue) {
    //console.log(dateValue.hasOwnProperty("toDate"));
    if (!moment(dateValue).isValid()) {
      displayDateValue = moment(dateValue.toDate()).format("MMM Do YY");
    } else {
      displayDateValue = moment(dateValue).format("MMM Do YY");
    }
  }
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      {displayValue}
      <div>{displayDateValue}</div>
    </div>
  );
}

export default OrganizationApprovals;
