import { useReducer, useEffect } from "react";
import Organization from "../../utils/classes/organization";
import { api } from "../../utils/api";

function organizationReducer(state, action) {
  switch (action.type) {
    case "UPDATE": {
      //console.log({ ...state, ...action.payload.organization });
      return { ...state, ...action.payload.organization };
    }
    case "UPDATE_FIELD": {
      console.log("[ACTION] ", {
        ...state,
        [action.payload.field]: action.payload.value,
      });
      return { ...state, [action.payload.field]: action.payload.value };
    }
    case "UPDATE_INVITES": {
      return {
        ...state,
        invites: action.payload.invites,
      };
    }
    default:
      return state;
  }
}

function useOrganizationForm(orgId) {
  let initialState = new Organization({ invites: [] });
  //console.log("[INITIAL ORG STATE] ", initialState);
  const [state, dispatch] = useReducer(organizationReducer, initialState);

  useEffect(() => {
    if (orgId) {
      return api.organization.getOrganizationById(orgId, (org) => {
        api.organization.getInviteList(orgId, (invites) => {
          org.setInvites(invites);
          api.organization.getUserList(orgId, (users) => {
            org.setUsers(users);
            api.organization.getApprovalGroupsList(orgId, (approvalGroups) => {
              org.setApprovalGroups(approvalGroups);
              api.organization.getSkillPaths(orgId, (skills) => {
                org.setSkills(skills);
                dispatch({ type: "UPDATE", payload: { organization: org } });
              });
            });
          });
        });
      });
    }
  }, [orgId]);

  const value = {
    ...state,
    updateField(field, value) {
      console.log("[UPDATING STATE] ", field, value);
      dispatch({
        type: "UPDATE_FIELD",
        payload: { field: field, value: value },
      });
    },
    update(organization) {
      dispatch({
        type: "UPDATE",
        payload: { organization: organization },
      });
    },
    load(orgId) {
      let organization = new Organization(state);
      organization.get(orgId).then(
        function (doc) {
          let o = {
            ...doc.data(),
            id: doc.id,
          };
          o.invites = organization.getInviteList();
          this.update(o);
        }.bind(this)
      );
    },
    get() {
      console.log("Get State: ", state);
      return new Organization(state);
    },
    save() {
      let organization = new Organization(state);
      return organization.save();
    },
    addInvites(invites) {
      let organization = new Organization(state);
      return organization.addInvites(invites);
    },
    getInviteErrors(invites) {
      //console.log("[ORG STATE] ", state);
      let organization = new Organization(state);
      return organization.getInviteErrors(invites);
    },

    getTrialInviteErrors(invites) {
      //console.log("[ORG STATE] ", state);
      let organization = new Organization(state);
      return organization.getTrialInviteErrors(invites);
    },
    addSkill(skill) {
      let organization = new Organization(state);
      return organization.addSkill(skill);
    },
    deleteSkill(skill) {
      let organization = new Organization(state);
      return organization.deleteSkill(skill);
    },

    addSkillToUser(user, skill) {
      let organization = new Organization(state);
      //console.log("[USER] ", user);
      user.skills.push(skill.toFirestoreFormat());
      return organization.updateUserskills(user, user.skills);
    },
    removeSkillFromUser(user, skill) {
      let organization = new Organization(state);
      user.skills = user.skills.filter(function (s) {
        //console.log("[REMOVE SKILLS] ", s.id, skill.id);
        return s.id !== skill.id && s !== "";
      });
      return organization.updateUserskills(user, user.skills);
    },
    getInvites() {
      let organization = new Organization(state);
      return organization.getInvites();
    },
    getUser(user) {
      let organization = new Organization(state);
      return organization.getUser(user);
    },
    getUserDisplayNameById(userId) {
      let organization = new Organization(state);
      return organization.getUserDisplayNameById(userId);
    },
    updateUser(user) {
      let organization = new Organization(state);
      return organization.updateUser(user);
    },
    deleteInvite(invite) {
      let organization = new Organization(state);
      return organization.deleteInvite(invite);
    },
    getApprovalGroup(groupId) {
      let organization = new Organization(state);
      return organization.getApprovalGroup(groupId);
    },
    getApprovalGroups() {
      let organization = new Organization(state);
      return organization.getApprovalGroups();
    },
    updateApprovalGroup(group) {
      let organization = new Organization(state);
      return organization.updateApprovalGroup(group);
    },
    createApprovalGroup(group) {
      let organization = new Organization(state);
      return organization.createApprovalGroup(group);
    },
    completeOnboarding() {
      let organization = new Organization(state);
      return organization.completeOnboarding();
    },
  };
  return value;
}

export default useOrganizationForm;
