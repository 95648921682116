import React from "react";
import { Row, Col } from "react-bootstrap";

function ContentDetail({ heading, leftHeading, content }) {
  return (
    <div style={{ marginBottom: "25px" }}>
      <div
        style={{
          fontSize: "1.1rem",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <span style={{ borderBottom: "1px solid #6c757d" }}>{heading}</span>
        {leftHeading && (
          <span className="float-right" style={{ textAlign: "right" }}>
            {leftHeading}
          </span>
        )}
      </div>

      <p style={{ whiteSpace: "pre-line" }}>{content}</p>
    </div>
  );
}

export default ContentDetail;
