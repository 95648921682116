import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ProfileDetail from "./profile-detail";
import ProfileEdit from "./profile-edit";
import ProfileEditThirdParty from "./profile-third-party-edit";

function Profile() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} component={ProfileDetail} exact />
      <Route
        path={`${path}/edit/thirdParty`}
        component={ProfileEditThirdParty}
        exact
      />
      <Route path={`${path}/edit`} component={ProfileEdit} exact />
    </Switch>
  );
}

export default Profile;
