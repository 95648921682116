import React, { useState, useEffect } from "react";
import { Row, Col, ListGroup, Media, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { db } from "./../../config/firebase";

import { useAuthState } from "../../providers/AuthState";
import NoApprovals from "./NoApprovals";

function ApprovalPreview() {
  const [approvals, setApprovals] = useState([]);

  const { orgProfile, userApprovalGroups } = useAuthState();

  useEffect(() => {
    const approverIds = [
      orgProfile.ref.id,
      ...userApprovalGroups.map((group) => group.id),
    ];

    return db
      .collection(`${orgProfile.ref.parent.parent.path}/experienceApprovals`)
      .where("experience.status", "==", "Submitted")
      .where("approver.id", "in", approverIds)
      .orderBy("dateSubmitted", "desc")
      .limit(3)
      .onSnapshot((snapshot) => {
        const docs = [];
        // const steps = [];
        // const users = [];

        snapshot.forEach((doc) => {
          const docData = doc.data();
          //console.log(docData);
          docs.push({
            ...docData,
            id: doc.id,
            ref: doc.ref,
          });
        });
        setApprovals(docs);
      });
  }, [orgProfile]);

  if (approvals.length === 0) return <NoApprovals />;
  if (approvals.length > 0)
    return (
      <Row>
        <Col>
          <Row>
            <Col>
              <h5 className="text-primary font-weight-bold">
                <span className="mt-1">Approvals</span>
              </h5>
              <Container
                style={{
                  background: "#EDF2F7",
                  borderRadius: "0.3rem",
                  minHeight: "288px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <ListGroup>
                  {approvals.map((approval) => {
                    return (
                      // <ListGroup.Item>
                      <Link
                        className="list-group-item list-group-item-action"
                        to={`/approvals/${approval.id}`}
                      >
                        <Media>
                          <Media.Body>
                            <h5 style={{ fontSize: "1.15rem" }}>
                              {approval.submitter.name} - {approval.step.name}
                            </h5>
                            <div>
                              Submitted on{" "}
                              {new Date(
                                approval.dateSubmitted.seconds * 1000
                              ).toLocaleDateString()}
                            </div>
                          </Media.Body>
                        </Media>
                      </Link>
                      // </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    );
}

export default ApprovalPreview;
