import React from "react";
import { Row, Col, Jumbotron } from "react-bootstrap";

export default function NoApprovals() {
  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h5 className="text-primary font-weight-bold">
              <span className="mt-1">Approvals</span>
            </h5>
          </Col>
        </Row>
        <Jumbotron>
          <p>No logs submitted for your approval.</p>
        </Jumbotron>
      </Col>
    </Row>
  );
}
