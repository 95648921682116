import React, { useState } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSkillPathContext } from "./../providers";
import { db, firebase } from "./../../../../config/firebase";
import { useAuthState } from "./../../../../providers/AuthState";

import { api } from "./../../../../core/logging/utils/api/index";

function ExperienceLogForm({ handleCancel, skillStep, currentExperience }) {
  const [attachmentLabel, setAttachmentLabel] = useState("Attachment");
  const [wasOfValue, setWasOfvalue] = useState("");
  const [experience, setExperience] = useState(
    (currentExperience && currentExperience.experienceLog) || ""
  );
  const [attachment, setAttachment] = useState(null);
  const [attachmentURL, setAttachmentURL] = useState("");
  const { orgProfile, orgContext } = useAuthState();

  const { skillPath } = useSkillPathContext();

  const { skillStageSlug } = useParams();

  function uploadAttachment() {
    // Upload attachment
    const newStorageRef = firebase.storage().ref();
    const attachmentPath = `/${orgProfile.id}/${attachment.name}`;

    const attachmentRef = newStorageRef.child(attachmentPath);

    return attachmentRef.put(attachment);
  }

  function getAttachmentURL(snapshot) {
    return snapshot.ref.getDownloadURL();
  }

  function saveExperience(attachmentUrl) {
    const attachmentData = {
      url: attachmentUrl || "",
      name: (attachment && attachment.name) || "",
    };

    let approverId = orgProfile.manager;
    if (skillStep.approvalType === "Subject Matter Expert") {
      approverId = skillStep.smeGroupId;
    }

    const experienceDoc = {
      dateSubmitted: new Date(),
      status: "Submitted",
      experienceLog: experience,
      wasOfValue: wasOfValue,
      // attachment: attachmentData,
      attachmentURL: attachmentURL,
      approverComments: "",
      step: {
        id: skillStep.id,
        name: skillStep.name,
        path: skillStep.ref.path,
        order: skillStep.order,
        stageOrder: skillStageSlug,
      },
      skill: {
        id: skillPath.id,
        code: skillPath.code,
        name: skillPath.name,
        path: skillPath.ref.path,
      },
      approver: {
        id: approverId,
        type: skillStep.approvalType,
      },
    };

    if (currentExperience) {
      // Update the existing exerience and add a version
      db.doc(
        `${orgProfile.ref.path}/paths/${skillStep.getPathId()}/experiences/${
          currentExperience.id
        }`
      )
        .update(experienceDoc)
        .then(() => {
          return db
            .collection(
              `${
                orgProfile.ref.path
              }/paths/${skillStep.getPathId()}/experiences/${
                currentExperience.id
              }/versions`
            )
            .add(currentExperience);
        })
        .then(() => {
          return api.logging.createLogEntry(
            orgContext.id,
            api.logging.TYPES.EXPERIENCE.RESUBMIT,
            {
              message: `${orgProfile.displayName} has resbumitted an experience.`,
              related: db.doc(
                `${
                  orgProfile.ref.path
                }/paths/${skillStep.getPathId()}/experiences/${
                  currentExperience.id
                }`
              ),
              response: "SUCCESS",
            }
          );
        });
    } else {
      // Add a new experience
      db.collection(
        `${orgProfile.ref.path}/paths/${skillStep.getPathId()}/experiences`
      )
        .add(experienceDoc)
        .then((experienceDoc) => {
          return api.logging.createLogEntry(
            orgContext.id,
            api.logging.TYPES.EXPERIENCE.SUBMIT,
            {
              message: `${orgProfile.displayName} has sbumitted an experience.`,
              related: db.doc(
                `${
                  orgProfile.ref.path
                }/paths/${skillStep.getPathId()}/experiences/${
                  experienceDoc.id
                }`
              ),
              response: "SUCCESS",
            }
          );
        });
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    return (
      Promise.resolve()
        // .then(skillStep.attachmentRequired && uploadAttachment)
        // .then(skillStep.attachmentRequired && getAttachmentURL)
        .then(saveExperience)
        .then(handleCancel)
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col>
          <Form.Group controlId="experience">
            <Form.Control
              required
              as="textarea"
              rows="3"
              placeholder="Log your experience"
              value={experience}
              onChange={(e) => {
                setExperience(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      {skillStep.attachmentRequired && (
        <Form.Row>
          <Col>
            <Form.Group controlId="attachmentURL">
              <Form.Label>Attachment Url</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Attachment URL"
                defaultValue={attachmentURL}
                onChange={(e) => {
                  setAttachmentURL(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          {/* <Form.Group>
            <Form.File
              required
              style={{ marginLeft: "5px" }}
              id="experience-attachment"
              label={attachmentLabel}
              onChange={(e) => {
                setAttachment(e.target.files[0]);
                setAttachmentLabel(e.target.files[0].name);
              }}
              custom
            />
          </Form.Group> */}
        </Form.Row>
      )}

      {(!currentExperience || currentExperience.wasOfValue === "No") && (
        <Form.Group controlId="wasOfvalue">
          <Form.Row>
            <Col>
              <Form.Label>
                Was this skill step of value to your skill development?
              </Form.Label>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col md={3}>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setWasOfvalue(e.target.value);
                }}
                value={wasOfValue}
                required
              >
                <option />
                <option>Yes</option>
                <option>No</option>
                <option>Undecided</option>
              </Form.Control>
            </Col>
          </Form.Row>
        </Form.Group>
      )}
      <Form.Row>
        <Col>
          <Form.Row>
            <Col md={2}>
              <Button variant="outline-primary" type="submit">
                Submit
              </Button>
            </Col>
            <Col md={2}>
              <Button variant="outline-danger" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
    </Form>
  );
}

export default ExperienceLogForm;
