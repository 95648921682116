import { api } from "../api";

function getDocIdFromPath(collectionName, ref) {
  if (ref.parent && ref.parent.id === collectionName) {
    return ref.id;
  } else if (ref.parent) {
    return getDocIdFromPath(collectionName, ref.parent);
  }
}

export class SkillStep {
  constructor(doc) {
    this.id = doc.id || "";
    this.name = doc.name || "";
    this.order = -1;
    if (doc.order !== undefined) {
      this.order = doc.order;
    }

    this.objective = doc.objective || "";
    this.instructions = doc.instructions || "";

    this.thread = doc.thread || "";

    this.skillStageSlug = doc.skillStageSlug || "";
    this.skillPathId = "";
    this.skillPathCode = "";
    this.skillStageId = "";
    this.url = doc.url || "";

    // Version
    this.version = doc.version || 1;
    this.published = doc.published || false;

    // Approvals
    this.approvalType = doc.approvalType || "Manager";
    this.evidenceRequired = doc.evidenceRequired || "";
    this.coachingGuidelines = doc.coachingGuidelines || "";
    this.numberOfLogsRequired = doc.numberOfLogsRequired || 1;
    this.ignoreLogLimit = doc.ignoreLogLimit || false;
    this.attachmentRequired = doc.attachmentRequired || false;

    this.smeGroupId = doc.smeGroupId || "";

    this.ref = doc.ref || null;
    return this;
  }

  toFirestoreFormat() {
    return {
      id: this.id,
      name: this.name,
      order: this.order,
      objective: this.objective,
      instructions: this.instructions,
      evidenceRequired: this.evidenceRequired,
      coachingGuidelines: this.coachingGuidelines,
      thread: this.thread,
      url: this.url,
      version: this.version,
      published: this.published,
      approvalType: this.approvalType,
      numberOfLogsRequired: this.numberOfLogsRequired,
      ignoreLogLimit: this.ignoreLogLimit,
      attachmentRequired: this.attachmentRequired,
      smeGroupId: this.smeGroupId,
    };
  }

  toReducerFormat() {}

  update() {}

  setUrl(skillPathCode, skillStageSlug, skillStepSlug) {
    this.url = `${skillPathCode}/${skillStageSlug}/${skillStepSlug}`;

    return this;
  }

  incrementVersion() {
    this.version = parseInt(this.version) + 1 || 1;
    return this;
  }

  setParentIds({ skillPathId, skillStageId, skillPathCode }) {
    this.skillPathId = skillPathId;
    this.skillStageId = skillStageId;
    this.skillPathCode = skillPathCode;

    if (this.id === "" && this.skillPathId && this.skillStageId) {
      const ref = api.skillStep.getId({
        skillPathId: this.skillPathId,
        skillStageId: this.skillStageId,
      });
      this.id = ref.id;
      this.ref = ref;
    }

    return this;
  }

  getPathId() {
    return getDocIdFromPath("paths", this.ref);
  }

  save(orgId) {
    const updateDoc = {
      skillPathId: this.skillPathId,
      skillStageId: this.skillStageId,
      skillStep: this.toFirestoreFormat(),
      orgId,
    };

    // Set the step id to the real id and not the staged id
    if (updateDoc.skillStep.id === "staged") {
      updateDoc.skillStep.id = this.ref.parent.parent.id;
    }

    // return;
    let stepPromise;
    if (orgId) {
      stepPromise = api.skillStep.updateStagedOnOrg(updateDoc);
    } else {
      stepPromise = api.skillStep.updateStaged(updateDoc);
    }
    // If this hasn't been published update both the staged version and the path doc
    if (this.version === 1 && !orgId) {
      stepPromise.then(() => {
        return api.skillStep.update(updateDoc);
      });
    }

    return stepPromise;
  }

  // publish(skillPathId, skillStageId) {
  //   //console.log(`Publishing ${this.name}`);
  //   const version = this.toFirestoreFormat();
  //   version.datePublished = new Date();
  //   version.author = "Jason Manners";

  //   return this.ref
  //     .collection("versions")
  //     .add(version)
  //     .then(() => {
  //       this.published = true;
  //       return this.setParentIds({ skillPathId, skillStageId })
  //         .incrementVersion()
  //         .save();
  //     })
  //     .then(() => {
  //       //console.log("Skill Step Saved");
  //     });
  // }

  static firebaseConvertor(skillStep) {
    return skillStep.toFirestoreFormat();
  }
}
