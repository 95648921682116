import React, { useEffect } from "react";
import { Container, Modal, Alert, Button } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import "./../../style/App.scss";

import Dashboard from "../../features/dashboards/Dashboard";
import SignIn from "../auth/SignIn";
import SelfServiceRegistration from "../auth/SignUp";
import SignInBeforePasswordUpdate from "../auth/SignInBeforePasswordUpdate";
import UpdatePassword from "../auth/UpdatePassword";
import ClaimInvite from "../auth/ClaimInvite";
import ClaimOrg from "../auth/ClaimOrg";
import SuperUserConsole from "../../features/superusers";
import Organization from "../../features/organizations";
import Profile from "../../features/users/components/profile";

import { SkillsLayout } from "./../../features/skills";
import { ApprovalsLayout } from "./../../features/approvals";
import { WisdomLayout } from "./../../features/wisdom";
import { ReportsLayout } from "./../../features/reports";
import { RecognitionsLayout } from "../../core/recognitions";

import { useAuthState } from "../../providers/AuthState";
import { firebase } from "../../config/firebase";

import Navigation from "./Navbar";
import WaitForAuth from "../auth/WaitForAuth";
import { PrivateRoute, SuperUserRoute, PublicOnlyRoute } from "./RouteGuarding";

import Auth from "../auth/utils/classes/auth";

function PrimaryLayout() {
  const history = useHistory();

  const {
    authenticated,
    user,
    complete,
    orgContext,
    profileType,
    dispatch,
  } = useAuthState();

  function handleSignOut() {
    firebase
      .auth()
      .signOut()
      .then(function () {
        history.push("/signIn");
        dispatch({ type: "LOGOUT" });
      })
      .catch(function (error) {
        //console.log("An error has occured!");
      });
  }

  //console.log("[ADMIN ORG]", adminOrg);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser) => {
      var user = firebase.auth().currentUser;
      let auth = new Auth({ user: user });
      if (user && !authenticated) {
        auth
          .loadContext()
          .then(function (context) {
            console.log("[THIS IS THE FINAL CONTEXT] ", context);
            dispatch(context);
          })
          .catch(function (err) {
            setTimeout(function () {
              auth.loadContext().then(function (context) {
                console.log("[THIS IS THE FINAL CONTEXT] ", context);
                dispatch(context);
              });
            }, 5000);
          });
      } else if (!complete) {
        dispatch(auth.loadAuth());
      }
    });
  }, [user, authenticated, complete, dispatch]);

  return (
    <WaitForAuth>
      <div className="Metisphere">
        <header className="Metisphere-header"></header>
        <Router>
          <Navigation />

          <Route
            path="/organizations/:orgId/invites/:inviteId"
            component={ClaimInvite}
          />
          <Route
            path="/org/:orgId/admin/:adminEmail/token/:token"
            component={ClaimOrg}
          />

          <Container style={{ marginTop: "15px" }}>
            <Switch>
              <PrivateRoute path="/" component={Dashboard} exact />
              <PrivateRoute
                path="/updatePassword"
                component={UpdatePassword}
                exact
              />
              <PrivateRoute
                path="/updatePassword/:inviteId"
                component={UpdatePassword}
                exact
              />
              <PrivateRoute path="/organization" component={Organization} />
              <PrivateRoute
                path="/recognitions"
                component={RecognitionsLayout}
              />
              <PrivateRoute path="/skills" component={SkillsLayout} />
              <PrivateRoute path="/approvals" component={ApprovalsLayout} />
              <PrivateRoute
                exact
                path="/wisdom/:orgId"
                component={WisdomLayout}
              />
              <PrivateRoute path="/wisdom" component={WisdomLayout} />
              <PrivateRoute path="/progress" component={ReportsLayout} />
            </Switch>
            <Switch>
              <Route path="/profile" component={Profile} />
              <Route
                path="/signInBeforePasswordUpdate"
                component={SignInBeforePasswordUpdate}
                exact
              />
            </Switch>

            {/* <Switch></Switch> */}
            <Switch>
              <SuperUserRoute
                path="/superuser-console"
                component={SuperUserConsole}
              />
            </Switch>
          </Container>
          <Switch>
            <PublicOnlyRoute
              path="/signUp"
              component={SelfServiceRegistration}
              exact
            />
            <PublicOnlyRoute path="/signIn" component={SignIn} exact />
          </Switch>
        </Router>
        {orgContext && user && profileType && profileType !== "SUPERUSER" ? (
          <Modal show={!orgContext.active} backdrop="static" keyboard={false}>
            <Modal.Header>
              <Modal.Title className="text-center">
                Organization Deactivated
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="danger" className="text-center">
                Your organization has been deactivated. Please contact support
                to reactivate it.
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={handleSignOut}>
                Log Out
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ""
          )}
      </div>
    </WaitForAuth>
  );
}

export default PrimaryLayout;
