import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ContentPane from "./content-pane";

import "./content-player.style.css";

function ContentPlayer({ stepContents, canEdit, skillStep }) {
  const [activeContent, setActiveContent] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);

  function goPrevious(e) {
    e.preventDefault();
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
      setActiveContent(stepContents[activeIndex - 1]);
    }
  }

  function goNext(e) {
    e.preventDefault();
    if (activeIndex < stepContents.length - 1) {
      setActiveIndex(activeIndex + 1);
      setActiveContent(stepContents[activeIndex + 1]);
    }
  }

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setActiveContent(stepContents[0]);
      setActiveIndex(0);
    }

    return () => {
      isCurrent = false;
    };
  }, [skillStep.id, stepContents]);

  if (stepContents.length > 0) {
    return (
      <div>
        <Row>
          <Col sm={1} style={{ verticalAlign: "center" }}>
            {activeIndex > 0 && (
              <div
                className="content-nav"
                style={{ position: "absolute", top: "50%" }}
              >
                <a href="#" onClick={goPrevious}>
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-chevron-compact-left"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
                    />
                  </svg>
                </a>
              </div>
            )}
          </Col>
          <Col sm={10}>
            <ContentPane
              content={activeContent}
              canEdit={canEdit}
              skillStep={skillStep}
            />
          </Col>
          <Col sm={1}>
            {activeIndex < stepContents.length - 1 && (
              <div
                className="content-nav"
                style={{ position: "absolute", top: "50%" }}
              >
                <a href="#" onClick={goNext}>
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-chevron-compact-right"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
                    />
                  </svg>
                </a>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            {stepContents.map((content, ind) => {
              return (
                <span style={{ marginRight: "10px" }}>
                  <a
                    href="#"
                    className={ind === activeIndex ? "active-content-ind" : ""}
                    style={{ fontSize: "1.25rem" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveContent(content);
                      setActiveIndex(ind);
                    }}
                  >
                    {ind + 1}
                  </a>
                </span>
              );
            })}
          </Col>
        </Row>
      </div>
    );
  } else {
    return null;
  }
}

export default ContentPlayer;
