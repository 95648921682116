import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { db } from "../../../../../config/firebase";

import "../../../../../style/Dashboard.scss";

function SuperUserDashboardOrganizations() {
  /*
   * Form State Functionality
   * organizations - collection of all organizations on the platform
   */
  const [organizations, setOrganizations] = useState([]);

  /*
   * Display this message if there are no organizations added yet.
   */
  function NoOrganizations() {
    return organizations.length === 0 ? (
      <tr>
        <td colSpan="9" className="text-center">
          No organizations created yet.
        </td>
      </tr>
    ) : (
      ""
    );
  }

  /*
   * Use Effect - On page load
   * Get all of the organizations in the entire platform
   * Only for use with superusers!!  DB access is restricted to superusers only
   */
  useEffect(() => {
    db.collection("organizations").onSnapshot((snapshot) => {
      const docs = [];
      snapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setOrganizations(docs);
    });
  }, []);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Account</th>
          <th>Admin Name</th>
          <th>Domains</th>
          <th>Licenses</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <NoOrganizations />
        {Array.isArray(organizations) &&
          organizations.map((org, index) => {
            return <Organization org={org} index={index} key={index} />;
          })}
      </tbody>
    </Table>
  );
}

function Organization({ org, index }) {
  let orgViewPath = "/organization/" + org.id;
  let orgEditPath = "/organization/edit/" + org.id;
  let statusClass =
    org.status.toLowerCase() === "complete"
      ? "text-left text-success-bright text-uppercase text-sm font-weight-bold"
      : "text-left text-secondary text-uppercase text-sm font-weight-bold";
  return (
    <tr key={org.id}>
      <td>{index + 1}</td>
      <td>{org.name}</td>
      <td>{org.adminName}</td>
      <td>
        {Array.isArray(org.domains)
          ? org.domains.map((domain, index) => {
              return <div key={domain}>{domain}</div>;
            })
          : ""}
      </td>

      <td className="text-left">{org.licenses}</td>
      <td className={statusClass}>{org.status}</td>
      <td style={{ minWidth: "125px" }} lassName="text-center">
        <Link
          to={orgViewPath}
          className="btn btn-sm btn-outline-primary"
          style={{ marginRight: "5px" }}
        >
          View
        </Link>
        <Link
          to={orgEditPath}
          className="btn btn-sm btn-outline-secondary"
          style={{ marginRight: "5px" }}
        >
          Edit
        </Link>
      </td>
    </tr>
  );
}

function deleteOrganization(id) {
  db.collection("organizations")
    .doc(id)
    .delete()
    .then(function () {
      //console.log("Success");
    });
}

export default SuperUserDashboardOrganizations;
