import React, { useState, useEffect } from "react";
import { Table, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { api } from "../../utils";
import { useAuthState } from "./../../../../providers/AuthState";

function PathList() {
  // Move to custom hook?
  const [skillPaths, setSkillPaths] = useState([]);

  const { superuser, adminOrg } = useAuthState();

  let vendorId = adminOrg && adminOrg.id;
  if (superuser) {
    vendorId = "metisphere";
  }
  // vendorId = "metisphere";
  // Move to custom hook?
  useEffect(() => {
    // return api.skillPath.getActiveForVendor(vendorId, setSkillPaths);
    return api.skillPath.getActiveStagedForVendor(vendorId, setSkillPaths);
  }, [vendorId]);

  return (
    <Row>
      <Col>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Code</th>
              <th>Status</th>
              <th>Published</th>
              <th>Version</th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {skillPaths.map((skillPath) => {
              return (
                <tr key={skillPath.id}>
                  <td>
                    <Link
                      to={
                        skillPath.stages.length > 0
                          ? `/skills/${skillPath.code}/1`
                          : `/skills/${skillPath.code}/new`
                      }
                    >
                      {skillPath.name}
                    </Link>
                  </td>
                  <td>{skillPath.code}</td>
                  <td>{skillPath.status}</td>
                  <td>{skillPath.published ? "Published" : "Not Published"}</td>
                  <td>{skillPath.version - 1}</td>
                  {/* <td>
                    <Link
                      variant="secondary"
                      className="btn btn-sm btn-secondary float-right"
                      to={`/skills/${skillPath.code}/edit`}
                    >
                      Edit
                    </Link>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Link
          className="btn btn-sm btn-outline-secondary float-right"
          to={`/skills/new`}
        >
          Create Skill Path
        </Link>
      </Col>
    </Row>
  );
}

export default PathList;
