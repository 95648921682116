import React, { useState, useEffect } from "react";
import { api } from "../auth/utils/api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useAuthState } from "../../providers/AuthState";

function LearnerPendingInvites() {
  const [pending, setPending] = useState([]);

  const { orgContext, user } = useAuthState();

  console.log("[ORG CONTEXT] ", orgContext);

  useEffect(() => {
    if (user) {
      api.organization
        .getAllOrgUserInvitesByUserId(orgContext, user)
        .then(function (orgs) {
          setPending(orgs);
        });
    }
  }, [user]);

  return (
    <div>
      {Array.isArray(pending) &&
        pending.map((invite, index) => {
          api.organization.markOrgUserInviteClaimed(orgContext, user, invite);

          return (
            <Alert variant="secondary">
              <Row>
                <Col xs={12}>
                  <div style={{ marginTop: "5px" }}>
                    <strong>Congratulations:</strong>&nbsp;You've claimed your
                    license to Metisphere. You can now access skill cards
                    from&nbsp;
                    <strong>{orgContext.name}</strong>!
                  </div>
                </Col>
              </Row>
            </Alert>
          );
        })}
    </div>
  );
}

export default LearnerPendingInvites;
