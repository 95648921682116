import React, { useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,
  ListGroup,
  Button,
  Form,
  Modal,
  Nav,
  Tab,
  Alert,
} from "react-bootstrap";

import moment from "moment";

import "../../../../../style/Dashboard.scss";

import {
  OrganizationActions,
  OrganizationLogs,
  OrganizationAnalytics,
} from "./settings";

function OrganizationSettings({ organization, orgId }) {
  return (
    <div style={{ paddingBottom: "10px" }}>
      <div
        style={{
          marginBottom: "10px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
        }}
      >
        <Row>
          <Col xs={12}>
            <div>
              <Row>
                <Col xs={12}>
                  <h5
                    className="text-primary"
                    style={{
                      letterSpacing: "-.02em",
                      fontWeight: "600",
                    }}
                  >
                    Organization Settings
                  </h5>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="actions">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="actions">Actions</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="logs">Logs</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="analytics">Analytics</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="actions">
                <OrganizationActions
                  organization={organization}
                  orgId={orgId}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="logs">
                <OrganizationLogs organization={organization} orgId={orgId} />
              </Tab.Pane>
              <Tab.Pane eventKey="analytics">
                <OrganizationAnalytics
                  organization={organization}
                  orgId={orgId}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

function OrganizationAttributeCard({
  label,
  value,
  dateValue,
  dateFromFirebase,
}) {
  let displayValue = value ? <div>{value}</div> : "";
  let displayDateValue = "";
  //console.log("[DATE] ", dateValue);
  if (dateValue) {
    //console.log(dateValue.hasOwnProperty("toDate"));
    if (!moment(dateValue).isValid()) {
      displayDateValue = moment(dateValue.toDate()).format("MMM Do YY");
    } else {
      displayDateValue = moment(dateValue).format("MMM Do YY");
    }
  }
  return (
    <div style={{ paddingBottom: "15px" }}>
      <div className="text-info header-pretitle">{label}</div>
      {displayValue}
      <div>{displayDateValue}</div>
    </div>
  );
}

export default OrganizationSettings;
