import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { db } from "../../config/firebase";

import { useAuthState } from "../../providers/AuthState";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import "./../../style/Auth.css";
import MetisphereSymbol from "./../../symbol.svg";

import { useLoginForm } from "./hooks";

function ClaimOrg() {
  // Load boolean to tell us if Google Auth is loading
  const [orgName, setOrgName] = useState("");
  const [authError, setAuthError] = useState("");
  const [tokenDoc, setTokenDoc] = useState({});

  // Auth Form Hook
  const auth = useLoginForm();
  const history = useHistory();

  // Get Authentication Context
  const { authenticated } = useAuthState();

  // Get Authentication Context
  const { token } = useParams();

  // Fxn: Handle Auth with Username and password
  function handleSubmit(e) {
    e.preventDefault();
    auth.login();
  }

  useEffect(() => {
    db.collection("orgInvites")
      .doc(token)
      .get()
      .then(function (doc) {
        //console.log("[New Token Doc] ", doc);

        if (doc.exists) {
          let newToken = { ...doc.data(), id: doc.id };
          auth.updateField("email", newToken.email);
          setTokenDoc(newToken);
          setOrgName(newToken.orgName);
          //console.log("[New Token Doc] ", newToken);
        }
      });
  }, [token, auth]);

  if (authenticated && tokenDoc.id) {
    auth
      .completeToken(tokenDoc)
      .then(function (doc) {
        //console.log("[UPDATE PASSWORD]");
        history.push("/updatePassword");
      })
      .catch(function (err) {
        setAuthError(err.message);
      });
  }

  return (
    <Container>
      <Row>
        <Col xs={12} md={{ span: 6, offset: 3 }}>
          <Row>
            <Col xs={12}>
              <Alert variant="secondary" style={{ marginTop: "20px" }}>
                <Alert.Heading>Step 1 / 2: Setup Your Account</Alert.Heading>
                <p></p>
                <hr />
                <p>Please enter the credentials you received in your email.</p>
                <hr />
              </Alert>
            </Col>
          </Row>
          <Card bg="primary" text="white" style={{ marginTop: "10px" }}>
            <Card.Header className="text-center">
              <img
                src={MetisphereSymbol}
                style={{ width: "40px" }}
                alt="Metisphere Icon"
              />
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} className="text-center">
                  <p>
                    Welcome to Metisphere, the world's leading learning
                    platform! To claim the following organization for{" "}
                    <strong>{orgName} </strong>
                    with provisioning key:
                  </p>
                  <p>
                    <strong className="h4">{token}</strong>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form onSubmit={handleSubmit}>
                    {authError ? (
                      <Alert variant="danger">{authError}</Alert>
                    ) : (
                      ""
                    )}
                    <Form.Group controlId="email">
                      <Form.Label className="text-sm">Email</Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          auth.updateField("email", e.target.value);
                        }}
                        value={auth.email}
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        onChange={(e) => {
                          auth.updateField("password", e.target.value);
                        }}
                        value={auth.password}
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}
                    <Button variant="light" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ClaimOrg;
