import React from "react";
import { Row, Col } from "react-bootstrap";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import { StageForm, StageDetail, StageList } from "./../components/skill-stage";
import ManageSkillStepLayout from "./manage-skill-step";

import { useSkillPathContext } from "./../components/providers";

function SkillStageLayout() {
  const { skillPath } = useSkillPathContext();
  let { path, url } = useRouteMatch();
  return (
    skillPath.id !== "" && (
      <Row>
        <Col>
          <Row>
            <Col>
              <Route path={`${path}/:skillStageSlug`} component={StageList} />
            </Col>
          </Row>
          <Switch>
            <Route exact path={`${path}/new`} component={StageForm} />
            <Route
              path={`${path}/:skillStageSlug/edit`}
              component={StageForm}
            />
            <Route path={`${path}/:skillStageSlug`}>
              <Row>
                <Col>
                  <StageDetail canEdit={true} />
                  <ManageSkillStepLayout />
                </Col>
              </Row>
            </Route>
            {skillPath.stages.length > 0 ? (
              <Redirect to={`${url}/1`} />
            ) : (
              <Redirect to={`${url}/new`} />
            )}
          </Switch>
        </Col>
      </Row>
    )
  );
}

export default SkillStageLayout;
