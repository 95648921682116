import React from "react";

import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from "draft-js";

import StyleToolbar from "./style-toolbar";

function RichTextEditor({ editorState, setEditorState }) {
  return (
    <div
      style={{
        border: "1px solid #ababab",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #ababab",
          paddingBottom: "10px",
          marginBottom: "10px",
        }}
      >
        <StyleToolbar
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </div>
      <div>
        <Editor
          style={{ height: "300px" }}
          editorState={editorState}
          onChange={setEditorState}
          placeholder="Share your wisdom"
        />
      </div>
    </div>
  );
}

export default RichTextEditor;
