import React, { useState, useEffect } from "react";

import { useAuthState } from "../../../providers/AuthState";

import Recognitions from "../components/recognitions";
import WaitingForRecognitions from "../components/waiting-for-recognitions";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import "../../../style/Recognition.scss";

import createRecognitionMachine from "../../../machines/RecognitionMachine";
import { useMachine } from "@xstate/react";

//TODO - Harmonize this
function RecognitionPanelContainer({ children }) {
  return <div className="recognition-panel-container">{children}</div>;
}

function RecognitionsLayout() {
  const [recognizedFilter, setRecognizedFilter] = useState("ALL");

  const { orgContext, orgUsers } = useAuthState();

  const [state, send] = useMachine(createRecognitionMachine(orgContext));

  useEffect(
    (e) => {
      //console.log("LOADED");
    },
    [orgContext]
  );

  return (
    <RecognitionPanelContainer>
      <div>
        <Row>
          <Col xs={9}>
            <h4
              className="text-primary"
              style={{
                letterSpacing: "-.02em",
                fontWeight: "600",
                minHeight: "48px",
              }}
            >
              All Recognitions
            </h4>
          </Col>
          <Col>
            <Form.Group>
              <Form.Control
                as="select"
                value={recognizedFilter}
                onChange={(e) => {
                  setRecognizedFilter(e.target.value);
                }}
                // className="float-right"
              >
                <option value="ALL">All</option>
                {Array.isArray(orgUsers) &&
                  orgUsers.map(function (orgUser) {
                    return (
                      <option value={orgUser.id}>{orgUser.displayName}</option>
                    );
                  })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <Container>
        <Container
          style={{
            background: "#EDF2F7",
            borderRadius: "0.3rem",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          {state.context.recognitions.length > 0 ? (
            <Recognitions
              recognitions={state.context.recognitions}
              send={send}
              recognizedFilter={recognizedFilter}
            />
          ) : (
            <WaitingForRecognitions />
          )}
        </Container>
      </Container>
    </RecognitionPanelContainer>
  );
}

export default RecognitionsLayout;
