import React, { useState } from "react";
import { Row, Col, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import { PageTitle } from "../common";
import { useSkillPathContext } from "./../providers";

import { functions } from "../../../../config/firebase";
import { useAuthState } from "../../../../providers/AuthState";

function PathHeader({ orgId }) {
  const [isPublishing, setIsPublishing] = useState(false);
  const [hasPublished, setHasPublished] = useState(false);
  const [errors, setErrors] = useState("");

  const { skillPath } = useSkillPathContext();
  const { orgProfile } = useAuthState();

  function handlePublish() {
    setIsPublishing(true);
    let publishPath = functions.httpsCallable("skills-publishPath");
    let skillPathId = skillPath.ref.id;
    if (skillPathId === "staged") {
      skillPathId = skillPath.ref.parent.parent.id;
    }
    publishPath({
      pathId: skillPathId,
      orgId: orgId,
      invalidateOldSteps: false,
      pathData: {
        datePublished: new Date(),
        author: orgProfile.displayName,
      },
    })
      .then(() => {
        setIsPublishing(false);
        setHasPublished(true);
      })
      .catch((err) => {
        setIsPublishing(false);
        setErrors(
          "There was an error publishing the path. Please contact an administrator."
        );
      });
  }

  return (
    skillPath.id && (
      <div>
        <Row>
          <Col>
            <PageTitle title={skillPath.name} pretitle={`Skill Path: ${skillPath.code}`} />
          </Col>
          <Col>
            <div className="float-right" style={{ marginTop: "10px" }}>
              {orgId && (
                <Link
                  className="btn btn-outline-primary btn-sm"
                  to={`/organization/${orgId}`}
                >
                  Back to Org
                </Link>
              )}{" "}
              {!skillPath.published && !hasPublished && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handlePublish}
                >
                  Publish
                </Button>
              )}{" "}
              {/* <Button
                variant="outline-primary"
                size="sm"
                onClick={handleAssignment}
              >
                Assign
              </Button>{" "} */}
              <Link
                className="btn btn-outline-primary btn-sm"
                to={
                  orgId
                    ? `/organization/${orgId}/skills/${skillPath.code}/edit`
                    : `/skills/${skillPath.code}/edit`
                }
              >
                Edit
              </Link>{" "}
            </div>
          </Col>
        </Row>
        {!skillPath.published && !isPublishing && !hasPublished && (
          <Row>
            <Col>
              <p className="text-warning">You have unpublished changes</p>
            </Col>
          </Row>
        )}
        {isPublishing && (
          <Alert variant="primary">Publishing in progress...</Alert>
        )}
        {hasPublished && (
          <Alert variant="success">Path has been published successfully</Alert>
        )}
        {errors != "" && <Alert variant="danger">{errors}</Alert>}
      </div>
    )
  );
}

export default PathHeader;
