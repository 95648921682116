import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { OrganizationForm } from "./components/organization-form";
import { OrganizationView } from "./components/organization-view";

import {
  SkillPathLayout,
  CreateSkillPathLayout,
  UserSkillPath,
} from "./../skills";

function Organization() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <OrganizationForm pageTitle="Onboard New Organization" />
      </Route>
      <Route path={`${path}/edit/:orgId`}>
        <OrganizationForm pageTitle="Edit Organization" />
      </Route>
      <Route
        exact
        path={`${path}/:orgId/skills/:skillPathId/edit`}
        component={CreateSkillPathLayout}
      />
      <Route path={`${path}/:orgId/content/skills/:skillPathId`}>
        <UserSkillPath addingContent={true} />
      </Route>
      <Route
        path={`${path}/:orgId/skills/:skillPathId`}
        component={SkillPathLayout}
      />

      <Route path={`${path}/:orgId`}>
        <OrganizationView pageTitle="View Organization" />
      </Route>
    </Switch>
  );
}

export default Organization;
