import { useState, useEffect } from "react";

import { api } from "../../../utils";
import { SkillStage } from "../../../utils/classes/skill-stage";

function useSkillStage(skillStageRef) {
  const [skillStage, setSkillStage] = useState(null);

  useEffect(() => {
    if (!skillStageRef) {
      let isCurrent = true;
      if (isCurrent) {
        setSkillStage(new SkillStage({}));
      }
      return () => {
        isCurrent = false;
      };
    }
    return api.skillStage.getStageByRef(skillStageRef, setSkillStage);
  }, [skillStageRef]);

  return skillStage;
}

export default useSkillStage;
