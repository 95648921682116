import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { db } from "../../config/firebase";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import "./../../style/Auth.css";
import MetisphereSymbol from "./../../symbol.svg";

import { useLoginForm } from "./hooks";

function SignIn() {
  const { orgId, inviteId } = useParams();
  const newInvite = {
    id: inviteId,
    claimed: false,
    account: true,
  };
  const [invite, setInvite] = useState(newInvite);
  const [authError, setAuthError] = useState("");

  // Auth Form Hook
  const auth = useLoginForm();

  // Get History Object
  const history = useHistory();

  //console.log("[CLAIM INVITE]");

  // Fxn: Handle Auth with Username and password
  function handleSubmit(e) {
    e.preventDefault();
    db.collection(`organizations/${orgId}/invites`)
      .doc(inviteId)
      .update(invite)
      .then(function () {
        auth
          .login()
          .then(function () {
            history.push("/updatePassword/" + inviteId);
            //console.log("[HANDLE SUBMIT]");
            setAuthError("");
            setInvite(newInvite);
          })
          .catch(function (error) {
            setAuthError(error.message);
          });
      })
      .catch(function (err) {
        setAuthError("A valid unclaimed token was not found.");
      });
  }

  return (
    <Container fluid="false">
      <Row>
        <Col xs={12} md={{ span: 6, offset: 3 }}>
          <Row>
            <Col xs={12}>
              <Alert variant="secondary" style={{ marginTop: "20px" }}>
                <Alert.Heading>Step 1/2: Setup Your Account</Alert.Heading>
                <p></p>
                <hr />
                <p className="mb-0">
                  Whenever you need to, be sure to use margin utilities to keep
                  things nice and tidy.
                </p>
              </Alert>
            </Col>
          </Row>
          <Card bg="primary" text="white" style={{ marginTop: "10px" }}>
            <Card.Header className="text-center">
              <img
                src={MetisphereSymbol}
                style={{ width: "40px" }}
                alt="Metisphere Icon"
              />
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} className="text-center">
                  <p>
                    Welcome to Metisphere, the world's leading learning
                    platform! To claim the following user license key:
                  </p>
                  <p>
                    <strong className="h4">{invite.id}</strong>
                  </p>
                  <p>
                    Please enter the credentials you received in your email.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form onSubmit={handleSubmit}>
                    {authError ? (
                      <Alert variant="danger">{authError}</Alert>
                    ) : (
                      ""
                    )}
                    <Form.Group controlId="email">
                      <Form.Label className="text-sm">Email</Form.Label>
                      <Form.Control
                        type="email"
                        onChange={(e) => {
                          auth.updateField("email", e.target.value);
                        }}
                        value={auth.email}
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        onChange={(e) => {
                          auth.updateField("password", e.target.value);
                        }}
                        value={auth.password}
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}
                    <Button variant="light" type="submit">
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;
