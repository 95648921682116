import { db } from "./../../../../config/firebase";

/*
  Determines if a recognition would satisfy a requirement for a step given a path and the users active stage
*/
function hasActiveRecognition(userProfile, pathId) {
  return db
    .doc(`${userProfile.ref.path}/paths/${pathId}`)
    .get()
    .then((doc) => {
      const pathProgress = { ...doc.data(), id: doc.id, ref: doc.ref };

      if (
        pathProgress.activeStage &&
        pathProgress.stages[pathProgress.activeStage]
      ) {
        const activeSteps = pathProgress.stages[pathProgress.activeStage].steps;
        const uncompeleteActiveSteps = [];

        for (const key in activeSteps) {
          if (!activeSteps[key].complete) {
            uncompeleteActiveSteps.push(activeSteps[key]);
          }
        }

        if (uncompeleteActiveSteps.length > 0) {
          const uncompleteIds = uncompeleteActiveSteps.map((step) => {
            return step.id;
          });

          return db
            .collection(
              `/paths/${pathId}/stages/${pathProgress.activeStage}/steps`
            )
            .get()
            .then((docs) => {
              const recognitionDocs = [];
              docs.forEach((doc) => {
                const docData = doc.data();
                if (
                  docData.approvalType === "Recognition" &&
                  uncompleteIds.indexOf(doc.id) > -1
                ) {
                  recognitionDocs.push({
                    ...doc.data(),
                    id: doc.id,
                    ref: doc.ref,
                  });
                }
              });

              recognitionDocs.sort((left, right) => {
                return left.order - right.order;
              });

              const recognitionStep = recognitionDocs[0];
              if (recognitionStep) {
                return true;
              } else {
                return false;
              }
            });
        }
      }
    });
}

export { hasActiveRecognition };
